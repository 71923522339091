@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
}
html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  text-size-adjust: none; /* WebKit browsers */
}
body {
  @apply !bg-dark-bgc;

  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
}
input:-internal-autofill-selected {
  -webkit-text-fill-color: #fff !important;
  box-shadow: inset 0 0 0 1000px #18161e !important;
}

* {
  font-family: Roboto, sans-serif;
  transition: background 0.3s, fill 0.3s, stork 0.3s, box-shadow 0.3s;
}

@font-face {
  font-family: 'Roboto';
  src: url('/symbolmarket/font/Roboto-Regular.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer components {
  /* 
        demo for antd
        .{app-prefix}-wrapper {
            .ant-layout {
                background: #fff;
            }
        }
    */
  .elf-form-vertical-custom.elf-form-large {
    .elf-form-item {
      margin-bottom: 16px;
    }
    .elf-form-item.elf-form-item-has-error > .elf-form-item-margin-offset {
      margin-bottom: 0 !important;
    }
    .elf-form-item.elf-form-item-has-error .elf-form-item-explain-error {
      @apply !text-left;
    }
    .elf-form-item-label > label {
      @apply h-[24px] text-white font-medium;
    }
    .elf-input-affix-wrapper {
      @apply !rounded-[6px];
    }

    .elf-form-item-label > label.elf-form-item-required:not(.elf-form-item-required-mark-optional)::before {
      /* display: none; */
    }
    .elf-form-item-label > label.elf-form-item-required:not(.elf-form-item-required-mark-optional)::after {
      /* display: inline-block;
      margin-right: 4px;
      color: #ee3249;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*'; */
    }
  }
}

.closeIcon:hover {
  path {
    @apply fill-primary-border-hover;
  }
}
.closeIcon:active {
  path {
    @apply fill-primary-border-active;
  }
}

/* ::-webkit-scrollbar {
  border-radius: 100px;
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #8b60f7;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
  background: #211a34;
} */

.elf-drawer-mask,
.elf-modal-mask {
  background: rgb(0 0 0 / 80%) !important;
}
.elf-spin-blur::after {
  background: rgb(0 0 0 / 80%) !important;
}
