.logo_logo-container__z7NBC {
    display: flex;
    cursor: pointer;
    align-items: flex-end;
    gap: 0.25rem
}
    .logo_logo-container__z7NBC .logo_logo-wrap__yRl8z {
    position: relative;
    display: flex;
    height: 2.25rem;
    width: 2.25rem;
    align-items: center;
    justify-content: center
}
    .logo_logo-container__z7NBC .logo_logo-text__uwxmC {
    margin-bottom: 3px;
    display: none
}
    @media (min-width: 1280px) {
    .logo_logo-container__z7NBC .logo_logo-text__uwxmC {
        display: inline-block
    }
}

.DropMenu_elf-dropdown-dark__UWxyL .elf-dropdown-menu {
    overflow: hidden;
    border-radius: 0.375rem;
    padding: 0px
}
    .DropMenu_elf-dropdown-dark__UWxyL .elf-dropdown-menu-item {
    --tw-bg-opacity: 1;
    background-color: rgb(14 12 21 / var(--tw-bg-opacity));
    padding: 0px;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    --tw-shadow: 0px -1px 0px 0px #252132 inset;
    --tw-shadow-colored: inset 0px -1px 0px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
    .DropMenu_elf-dropdown-dark__UWxyL .elf-dropdown-menu-item:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(24 19 39 / var(--tw-bg-opacity))
}
    .DropMenu_elf-dropdown-dark__UWxyL .elf-dropdown-menu-item:active {
    --tw-text-opacity: 1;
    color: rgb(130 86 242 / var(--tw-text-opacity))
}
    .DropMenu_elf-dropdown-dark__UWxyL .elf-dropdown-menu-item-active {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(24 19 39 / var(--tw-bg-opacity)) !important
}



.DropMenuForPhone_elf-dropdown-phone-dark__Suu2X .elf-drawer-content {
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(14 12 21 / var(--tw-bg-opacity));
    --tw-shadow: 0px -1px 0px 0px #252132 inset;
    --tw-shadow-colored: inset 0px -1px 0px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
    .DropMenuForPhone_elf-dropdown-phone-dark__Suu2X .elf-drawer-body {
    border-width: 0px;
    border-top-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(37 33 50 / var(--tw-border-opacity))
}
    .DropMenuForPhone_elf-dropdown-phone-dark__Suu2X .elf-drawer-header-title {
    height: 30px
}
    .DropMenuForPhone_elf-dropdown-phone-dark__Suu2X .elf-drawer-close {
    order: 1;
    margin-right: 0px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 1rem
}

:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-yellow: #ff9f18;
  --adm-color-orange: #ff6430;
  --adm-color-wathet: #e7f1ff;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-background: #ffffff;
  --adm-color-highlight: var(--adm-color-danger);
  --adm-color-white: #ffffff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-light-solid: var(--adm-color-white);
  --adm-color-text-dark-solid: #000000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
    helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-border-color: var(--adm-color-border);
}
html[data-prefers-color-scheme='dark'] {
  --adm-color-primary: #3086ff;
  --adm-color-success: #34b368;
  --adm-color-warning: #ffa930;
  --adm-color-danger: #ff4a58;
  --adm-color-yellow: #ffa930;
  --adm-color-orange: #e65a2b;
  --adm-color-wathet: #0d2543;
  --adm-color-text: #e6e6e6;
  --adm-color-text-secondary: #b3b3b3;
  --adm-color-weak: #808080;
  --adm-color-light: #4d4d4d;
  --adm-color-border: #2b2b2b;
  --adm-color-box: #0a0a0a;
  --adm-color-background: #1a1a1a;
  --adm-color-background-body: var(--adm-color-background);
  --adm-border-color: var(--adm-color-border);
}
:root {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  background-color: var(--adm-color-background-body);
}
body {
  color: var(--adm-color-text);
  font-size: var(--adm-font-size-main);
  font-family: var(--adm-font-family);
}
a,
button {
  cursor: pointer;
}
a {
  color: var(--adm-color-primary);
  transition: opacity ease-in-out 0.2s;
}
a:active {
  opacity: 0.8;
}
.adm-plain-anchor {
  color: unset;
  transition: none;
}
.adm-plain-anchor:active {
  opacity: unset;
}
body.adm-overflow-hidden {
  overflow: hidden !important;
}
div.adm-px-tester {
  --size: 1;
  height: calc(var(--size) / 2 * 2px);
  width: 0;
  position: fixed;
  right: -100vw;
  bottom: -100vh;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  pointer-events: none;
}

.adm-dot-loading {
  display: inline-block;
}

.adm-infinite-scroll {
  color: var(--adm-color-weak);
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--adm-font-size-main);
}
.adm-infinite-scroll-failed-text {
  display: inline-block;
  margin-right: 8px;
}

.adm-list {
  --header-font-size: var(--adm-font-size-7);
  --prefix-width: 'auto';
  --prefix-padding-right: 12px;
  --align-items: center;
  --active-background-color: var(--adm-color-border);
  --border-inner: solid 1px var(--adm-color-border);
  --border-top: solid 1px var(--adm-color-border);
  --border-bottom: solid 1px var(--adm-color-border);
  --padding-left: 12px;
  --padding-right: 12px;
  --font-size: var(--adm-font-size-9);
  --extra-max-width: 70%;
}
.adm-list-header {
  color: var(--adm-color-weak);
  font-size: var(--header-font-size);
  padding: 8px var(--padding-right) 8px var(--padding-left);
}
.adm-list-body {
  background-color: var(--adm-color-background);
  overflow: hidden;
  font-size: var(--font-size);
}
.adm-list-body-inner {
  margin-top: -1px;
}
.adm-list-default .adm-list-body {
  border-top: var(--border-top);
  border-bottom: var(--border-bottom);
}
.adm-list-card {
  margin: 12px;
}
.adm-list-card .adm-list-body {
  border-radius: 8px;
}
.adm-list-card .adm-list-header {
  padding-left: 0;
}
.adm-list-item {
  display: block;
  padding-left: var(--padding-left);
  position: relative;
  background-color: var(--adm-color-background);
  line-height: 1.5;
}
.adm-list-item-title,
.adm-list-item-description {
  color: var(--adm-color-weak);
  font-size: var(--adm-font-size-main);
}
.adm-list-item-content {
  display: flex;
  align-items: var(--align-items);
  justify-content: flex-start;
  border-top: var(--border-inner);
  padding-right: var(--padding-right);
}
.adm-list-item-content-prefix {
  width: var(--prefix-width);
  flex: none;
  padding-right: var(--prefix-padding-right);
}
.adm-list-item-content-main {
  flex: auto;
  padding: 12px 0;
}
.adm-list-item-content-extra {
  flex: none;
  padding-left: 12px;
  font-size: var(--adm-font-size-7);
  color: var(--adm-color-weak);
  max-width: var(--extra-max-width);
}
.adm-list-item-content-arrow {
  flex: none;
  display: flex;
  align-items: center;
  margin-left: 4px;
  color: var(--adm-color-light);
  font-size: 19px;
}
.adm-list-item-disabled {
  cursor: not-allowed;
}
.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > * {
  opacity: 0.4;
  pointer-events: none;
}
a.adm-list-item:active:not(.adm-list-item-disabled) {
  background-color: var(--active-background-color);
}
a.adm-list-item:active:not(.adm-list-item-disabled)::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  border-bottom: var(--border-inner);
}

.adm-passcode-input {
  position: relative;
  display: inline-block;
  --cell-size: 40px;
  --cell-gap: 6px;
  --dot-size: 10px;
  --border-color: var(--adm-color-border);
  --border-radius: 8px;
}
.adm-passcode-input-cell-container {
  display: inline-flex;
  vertical-align: top;
}
.adm-passcode-input-cell {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: var(--adm-font-size-10);
  width: var(--cell-size);
  height: var(--cell-size);
  background: var(--adm-color-background);
}
.adm-passcode-input:not(.adm-passcode-input-seperated) {
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 1px solid var(--border-color);
}
.adm-passcode-input:not(.adm-passcode-input-seperated) .adm-passcode-input-cell:not(:last-child) {
  border-right: 1px solid var(--border-color);
}
.adm-passcode-input:not(.adm-passcode-input-seperated).adm-passcode-input-focused {
  border-color: var(--adm-color-primary);
  box-shadow: 0 0 2px 0 var(--adm-color-primary);
  outline: none;
}
.adm-passcode-input:not(.adm-passcode-input-seperated).adm-passcode-input-error {
  border-color: var(--adm-color-danger);
  box-shadow: 0 0 2px 0 var(--adm-color-danger);
  animation: 100ms ease-in-out 0s 3 normal none running adm-shake-horizontal;
}
.adm-passcode-input.adm-passcode-input-seperated .adm-passcode-input-cell {
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
}
.adm-passcode-input.adm-passcode-input-seperated .adm-passcode-input-cell:not(:last-child) {
  margin-right: var(--cell-gap);
}
.adm-passcode-input.adm-passcode-input-seperated .adm-passcode-input-cell-focused {
  border-color: var(--adm-color-primary);
  box-shadow: 0 0 2px 0 var(--adm-color-primary);
}
.adm-passcode-input-seperated.adm-passcode-input-focused {
  outline: none;
}
.adm-passcode-input.adm-passcode-input-seperated.adm-passcode-input-error {
  animation: 100ms ease-in-out 0s 3 normal none running adm-shake-horizontal;
}
.adm-passcode-input.adm-passcode-input-seperated.adm-passcode-input-error .adm-passcode-input-cell {
  border-color: var(--adm-color-danger);
  box-shadow: 0 0 2px 0 var(--adm-color-danger);
}
.adm-passcode-input-cell-dot::before {
  content: '';
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 100%;
  background: var(--adm-color-text);
}
.adm-passcode-input-cell-caret::after {
  content: '';
  width: 2px;
  height: 1.1em;
  margin-left: 1px;
  background: var(--adm-color-primary);
  animation: 1s linear infinite adm-caret-blink;
}
.adm-passcode-input > .adm-passcode-input-native-input {
  position: absolute;
  left: -200vw;
  top: 0;
  display: block;
  width: 50px;
  height: 20px;
  opacity: 0.5;
}
@keyframes adm-shake-horizontal {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-4px);
  }
  20% {
    transform: translateX(5px);
  }
  30% {
    transform: translateX(-1px);
  }
  40% {
    transform: translateX(8px);
  }
  50% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(-8px);
  }
  70% {
    transform: translateX(1px);
  }
  80% {
    transform: translateX(-5px);
  }
  90% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes adm-caret-blink {
  from {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

.adm-safe-area {
  --multiple: var(--adm-safe-area-multiple, 1);
  display: block;
  width: 100%;
}
.adm-safe-area-position-top {
  padding-top: calc(env(safe-area-inset-top) * var(--multiple));
}
.adm-safe-area-position-bottom {
  padding-bottom: calc(env(safe-area-inset-bottom) * var(--multiple));
}

.aelf-web-logout-dialog-title-wrap {
  margin: 40px 0 12px 0;
}
.aelf-web-logout-dialog-title {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
}
.aelf-web-logout-dialog-sub-title {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
.aelf-web-logout-dialog-mt-12{
  margin-top: 12px;
}
.aelf-web-logout-dialog-btn-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 24px 0;
}
.aelf-web-logout-dialog-btn-wrap button:first-child {
  margin-bottom: 12px;
}
.aelf-web-logout-dialog-btn-wrap button{
  height: 40px;
}
.aelf-web-extra-wallets-wrapper-crypto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  gap: 16px;
  padding-bottom: 36px;
}
.aelf-web-extra-wallets-wrapper-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  gap: 32px;
  padding-top: 36px;
  padding-bottom: 8px;
}
.crypto-extra-wallets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 48px;
  padding: 13px 16px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 24px;
  border: 1px solid #c5cbd5;
  background: #fff;
  cursor: pointer;
}
.web2-extra-wallets {
  border-radius: 4px;
}
.crypto-extra-wallets-left {
  color: #515a62;
}
.crypto-extra-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  gap: 12px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.crypto-extra-image-arrow {
  width: 12px;
}
.crypto-wallets-title {
  color: #b6babf;
}
.social-design-wallet-wrapper {
  width: 100%;
  height: 56px;
  padding-left: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px;
  border: 1px solid #c5cbd5;
  background: #fff;
}
.social-design-wallets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
}
.social-design-wallet-wrapper > img {
  width: 28px;
  height: 28px;
}
.web-login-unlock-wrapper > div:nth-child(2) {
  height: 100%;
}
.nested-connect-modal > div:nth-child(2) {
  height: 100%;
}
.nested-title {
  text-align: center;
}
.nested-title-12 {
  padding: 12px;
  text-align: center;
}
.nested-left-icon {
  cursor: pointer;
  position: absolute;
  left: 16px;
  top: 20px;
}
.nested-close-icon {
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;
}
.nested-entry-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 36px 60px;
}
.nested-entry-wrapper-mobile {
  padding-top: 24px;
}
.nested-wallet-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  cursor: pointer;
}
.nested-wallet-wrapper:hover > span {
  color: #5b8ef4;
}
.nested-wallet-wrapper > img {
  width: 48px;
  height: 48px;
}
.nested-drawer-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.unlock-footer-text{
  color: #55585E;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 20px;
  margin-left: 14px;
  margin-right: 14px;
}
.unlock-footer-text .unlock-footer-text-href{
    color: #5D42FF;
    padding: 0 4px;
    cursor: pointer;
  }

/* stylelint-disable no-descending-specificity */
.Modal_modal__ija9a.elf-modal {
  max-width: calc(100vw - 32px);
  overflow: hidden;
}
.Modal_modal__ija9a.elf-modal .elf-modal-content {
  display: flex;
  height: auto;
  max-height: 100%;
  flex-direction: column;
  overflow: hidden;
  border-radius: 6px;
  --tw-bg-opacity: 1;
  background-color: rgb(25 22 36 / var(--tw-bg-opacity));
  padding: 2rem;
}
.Modal_modal__ija9a.elf-modal .elf-modal-content .elf-modal-body {
  box-sizing: border-box;
  flex: 1 1 0%;
  overflow-y: auto;
  border-bottom-width: 0px !important;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(35 31 48 / var(--tw-border-opacity));
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 32px;
  padding-bottom: 32px;
}
.Modal_modal__ija9a.elf-modal .elf-modal-content .elf-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 32px;
}
.Modal_modal__ija9a.elf-modal .elf-modal-content .elf-modal-footer > button {
        flex: 1;
      }
.Modal_modal__ija9a.elf-modal .elf-modal-content .elf-modal-header {
  border-top-left-radius: 0.375rem;
  border-bottom-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(25 22 36 / var(--tw-bg-opacity));
  padding: 0px;
}
.Modal_modal__ija9a.elf-modal .elf-modal-content .elf-modal-header .elf-modal-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  line-height: 30px;
  --tw-text-opacity: 1;
  color: rgb(139 96 247 / var(--tw-text-opacity));
}
.Modal_modal__ija9a.elf-modal .elf-modal-content .elf-modal-close {
  top: 39px;
  right: 2rem;
  height: 1rem;
  width: 1rem;
}
.Modal_modal__ija9a.elf-modal .elf-modal-content .elf-modal-close .elf-modal-close-x {
  display: flex;
  height: 1rem;
  width: 1rem;
  align-items: center;
  justify-content: center;
}
.Modal_modal__ija9a.elf-modal .Modal_closeIcon__bAODv:hover path {
  fill: #A280F9;
}
.Modal_modal__ija9a.elf-modal .Modal_closeIcon__bAODv:active path {
  fill: #8256F2;
}
.Modal_modal-mobile__6dRY3.elf-modal {
  overflow: hidden;
}
.Modal_modal-mobile__6dRY3.elf-modal .elf-modal-content {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.Modal_modal-mobile__6dRY3.elf-modal .elf-modal-content .elf-modal-close {
  top: 31px;
  right: 1.5rem;
}

.Button_button__M6NfZ.ant-btn {
  height: 52px;
  border-radius: 8px;
  font-family: Inter, sans-serif
}
.Button_button__M6NfZ.ant-btn-primary {
  border-color: var(--brand-base);
  background-color: var(--brand-base)
}
.Button_button__M6NfZ.ant-btn-default {
  border-color: var(--brand-base);
  color: var(--brand-base)

  /* @apply hover:bg-[#0E0C15]; */
}
.Button_button__M6NfZ.ant-btn-dashed {
  border-color: var(--brand-base);
  color: var(--brand-base)

  /* @apply hover:bg-[#0E0C15]; */
}
.Button_button__M6NfZ.ant-btn-ghost {
  border-color: var(--brand-base);
  color: var(--brand-base)

  /* @apply hover:bg-[#0E0C15]; */
}
.Button_button__M6NfZ.ant-btn-link {
  color: var(--brand-base)

  /* @apply hover:bg-[#0E0C15]; */
}
.Button_button__M6NfZ.ant-btn-text {
  color: var(--brand-base)

  /* @apply hover:bg-[#0E0C15]; */
}
.tips_footer__76qNI {
    display: flex;
    width: 100%;
    justify-content: center
}
  .tips_footer__76qNI .tips_footer__button__pc__dFAPI {
    height: 3rem;
    width: 280px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem
}
  .tips_footer__76qNI .tips_footer__button__mobile__UGAGe {
    width: 100%
}

/* stylelint-disable no-descending-specificity */
.dropMenuMy_myIcon__W3nPo:hover .dropMenuMy_myIcon-text__CRfuP {
    --tw-text-opacity: 1;
    color: rgb(162 128 249 / var(--tw-text-opacity))
}
.dropMenuMy_myIcon__W3nPo:hover svg > path {
    fill: #A280F9
}
.dropMenuMy_myIcon__W3nPo:active .dropMenuMy_myIcon-text__CRfuP {
    --tw-text-opacity: 1;
    color: rgb(130 86 242 / var(--tw-text-opacity))
}
.dropMenuMy_myIcon__W3nPo:active svg > path {
    fill: #8256F2
}
.dropMenuMy_myIcon__W3nPo svg > * {
    transition-property: none
}

.style_eight__ZDWBC {
  zoom: 67%;
}

.style_fiv__rDUft{
  zoom: 42%;
}

.option_option-item-custom__KWzAX {

  display: flex;

  cursor: pointer;

  align-items: center;

  justify-content: space-between;

  padding-left: 0.75rem;

  padding-right: 0.75rem;

  padding-top: 1rem;

  padding-bottom: 1rem
}
.option_option-item-custom__KWzAX:hover {

  --tw-bg-opacity: 1;

  background-color: rgb(24 19 39 / var(--tw-bg-opacity))
}
  .option_option-item-custom__KWzAX.option_selected__d8t5y {

  --tw-bg-opacity: 1;

  background-color: rgb(24 19 39 / var(--tw-bg-opacity))
}
  .option_option-item-custom__KWzAX {

  border-bottom: 1px solid #252132
}
  .option_option-item-custom__KWzAX .option_info-card__5tawl {

  display: flex;

  flex-direction: row;

  align-items: center
}
  .option_option-item-custom__KWzAX .option_info-expire__TNLb_ {

  display: inline-block;

  font-size: 0.75rem;

  line-height: 1rem;

  font-weight: 400;

  line-height: 18px;

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity))
}

.InputSearch_search-bar-wrap__P6_hx {

  display: flex;

  width: 100%;

  gap: 0.25rem;

  border-width: 0px;

  border-left-width: 1px;

  border-style: solid;

  --tw-border-opacity: 1;

  border-color: rgb(35 31 48 / var(--tw-border-opacity));
}
@media (min-width: 1024px) {
  .InputSearch_search-bar-wrap__P6_hx {

    flex: 1 1 0%;

    gap: 0.5rem;
  }
}
  .InputSearch_search-bar-wrap__P6_hx .InputSearch_search-bar-irregular__qdc7F {

  position: relative;

  display: flex;

  height: auto;

  flex: 1 1 0%;

  align-items: center;

  padding-left: 0.75rem;
}
  @media (min-width: 1024px) {
  .InputSearch_search-bar-wrap__P6_hx .InputSearch_search-bar-irregular__qdc7F {

    height: 32px;
  }
}
  .InputSearch_search-bar-wrap__P6_hx .InputSearch_search-bar-irregular__qdc7F input {

  padding-left: 0.75rem;

  padding-right: 1.5rem;

  font-size: 1rem;

  line-height: 1.5rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
}
  .InputSearch_search-bar-wrap__P6_hx .InputSearch_search-bar-irregular__qdc7F input::-moz-placeholder {

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}
  .InputSearch_search-bar-wrap__P6_hx .InputSearch_search-bar-irregular__qdc7F input::placeholder {

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}
  @media (min-width: 1024px) {
  .InputSearch_search-bar-wrap__P6_hx .InputSearch_search-bar-irregular__qdc7F input {

    padding-right: 2.25rem;
  }
}
  .InputSearch_search-bar-wrap__P6_hx .InputSearch_search-icon__oYS7w {

  display: none;
}

.InputSearch_search-bar-irregular-wrap__YSTEO {

  position: relative;

  display: flex;

  width: 100%;

  gap: 0.25rem;
}

@media (min-width: 1024px) {
  .InputSearch_search-bar-irregular-wrap__YSTEO {

    width: auto;

    gap: 0.5rem;
  }
}

.InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-bar-irregular__qdc7F {

  position: relative;

  display: flex;

  height: auto;

  flex: 1 1 0%;

  align-items: center;

  background-image: url('/symbolmarket/images/search-mobile.png');

  background-clip: border-box;

  background-position: left;

  background-repeat: no-repeat;

  padding: 0.75rem;

  padding-left: 1rem;
}

@media (min-width: 1024px) {
  .InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-bar-irregular__qdc7F {

    height: 72px;

    width: 604px;

    border-radius: 0.5rem;

    background-image: url('/symbolmarket/images/search-pc.png');

    padding: 1.5rem;
  }
}

.InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-bar-irregular__qdc7F {

    background-size: 100% 100%;
  }

.InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-bar-irregular__qdc7F input {

  padding: 0px;

  padding-right: 2rem;

  font-size: 1rem;

  line-height: 1.5rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-bar-irregular__qdc7F input::-moz-placeholder {

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}

.InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-bar-irregular__qdc7F input::placeholder {

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}

.InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-icon__oYS7w {

  height: 3.5rem;

  width: 3.5rem;

  cursor: pointer;

  background-image: url('/symbolmarket/images/query.png');

  background-size: contain;

  background-position: center;

  background-repeat: no-repeat;
}

.InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-icon__oYS7w:hover {

  background-image: url('/symbolmarket/images/query-hover.png');
}

.InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-icon__oYS7w:active {

  background-image: url('/symbolmarket/images/query-active.png');
}

@media (min-width: 1024px) {
  .InputSearch_search-bar-irregular-wrap__YSTEO .InputSearch_search-icon__oYS7w {

    height: 72px;

    width: 72px;
  }
}

.InputSearch_search-bar-irregular-wrap__YSTEO.InputSearch_search-bar-wrap-error__B8vW2 .InputSearch_search-bar-irregular__qdc7F {

  background-image: url('/symbolmarket/images/search-mobile-warning.png');
}

@media (min-width: 1024px) {
  .InputSearch_search-bar-irregular-wrap__YSTEO.InputSearch_search-bar-wrap-error__B8vW2 .InputSearch_search-bar-irregular__qdc7F {

    background-image: url('/symbolmarket/images/search-pc-warning.png');
  }
}

.InputSearch_search-bar-irregular-wrap__YSTEO.InputSearch_search-bar-wrap-error__B8vW2 .InputSearch_search-icon__oYS7w {

  cursor: not-allowed;

  background-image: url('/symbolmarket/images/query-disabled.png');
}

.InputSearch_option-item-custom__mC8_4 {

  display: flex;

  cursor: pointer;

  align-items: center;

  justify-content: space-between;

  padding-left: 0.75rem !important;

  padding-right: 0.75rem !important;

  padding-top: 1rem !important;

  padding-bottom: 1rem !important;
}

.InputSearch_option-item-custom__mC8_4:hover {

  --tw-bg-opacity: 1;

  background-color: rgb(24 19 39 / var(--tw-bg-opacity));
}

.InputSearch_option-item-custom__mC8_4.InputSearch_selected___FZJB {

  --tw-bg-opacity: 1;

  background-color: rgb(24 19 39 / var(--tw-bg-opacity));
}

.InputSearch_option-item-custom__mC8_4 {

  border-bottom: 1px solid #252132;
}

.InputSearch_option-item-custom__mC8_4 .InputSearch_info-card__Z2KEC {

  display: flex;

  flex-direction: row;

  align-items: center;
}

.InputSearch_option-item-custom__mC8_4 .InputSearch_info-expire__PjJwL {

  font-size: 12px;

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}

.InputSearch_elf-search-input__SK72j {
  /* @apply absolute z-10; */
  border-radius: 0.125rem;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.InputSearch_elf-search-input__SK72j.elf-input-affix-wrapper {

  border-width: 1px;

  border-style: solid;

  --tw-border-opacity: 1;

  border-color: rgb(35 31 48 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;

  background-color: rgb(24 22 30 / var(--tw-bg-opacity));

    border: 1px solid #231f30;
}

.InputSearch_elf-search-input__SK72j .elf-input-clear-icon {
    line-height: 0;
  }

.InputSearch_elf-search-input__SK72j .elf-input-clear-icon:hover svg > path {

  fill: #A280F9;
}

.InputSearch_elf-search-input__SK72j .elf-input-clear-icon:active svg > path {

  fill: #8256F2;
}

.InputSearch_elf-search-input__SK72j:focus {

  --tw-border-opacity: 1 !important;

  border-color: rgb(130 86 242 / var(--tw-border-opacity)) !important;

  --tw-shadow: 0 0 #0000 !important;

  --tw-shadow-colored: 0 0 #0000 !important;

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.InputSearch_elf-search-input-m__YwrfX .InputSearch_close-icon-bg__uDCYx {

  border-radius: 2px;

  padding: 0.5rem;
}

.InputSearch_elf-search-input-m__YwrfX .elf-input-clear-icon:active .InputSearch_close-icon-bg__uDCYx {

  background-color: rgb(139 96 247 / var(--tw-bg-opacity));

  --tw-bg-opacity: 0.2;
}

.InputSearch_elf-select-dropdown-custom__SYW0i.elf-select-dropdown {

  border-radius: 0.375rem;

  border-width: 1px;

  border-style: solid;

  --tw-border-opacity: 1;

  border-color: rgb(139 96 247 / var(--tw-border-opacity));
}

.InputSearch_elf-select-dropdown-custom__SYW0i.elf-select-dropdown .elf-select-item-option {

  padding: 0px;

    border-bottom: 1px solid #252132;
}

.style_token-name-modal__MVM3h .elf-modal-body {
    border-bottom-width: 0px !important
}
  .style_token-name-modal__MVM3h .elf-modal-footer {
    padding-top: 1rem !important
}

.style_create-token-modal-success-custom__c49iJ .elf-modal-body {
    border-style: none !important
}

.style_create-wrap__Hj99i {
    position: relative;
    display: flex;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 0.375rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(33 25 56 / var(--tw-border-opacity));
    background-color: #33245866;
    transition-property: none
}
.style_create-wrap__Hj99i:hover {
    --tw-border-opacity: 1;
    border-color: rgb(162 128 249 / var(--tw-border-opacity))
}
.style_create-wrap__Hj99i:active {
    stroke: #8256F2
}
@media (min-width: 1024px) {
    .style_create-wrap__Hj99i {
        width: 114px;
        border-style: none;
        --tw-border-opacity: 1;
        border-color: rgb(255 255 255 / var(--tw-border-opacity));
        background-color: transparent
    }
}
  .style_create-wrap__Hj99i .style_bgSVG__YGPDn {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: none
}
  @media (min-width: 1024px) {
    .style_create-wrap__Hj99i .style_bgSVG__YGPDn {
        display: inline-block
    }
}

.style_create-wrap__Hj99i:hover .style_bgSVG__YGPDn > path {
    stroke: #A280F9
}

.style_create-wrap__Hj99i:active .style_bgSVG__YGPDn > path {
    stroke: #8256F2
}

.SearchItemPanel_search-panel-wrap__acrVL {
    position: absolute;
    top: 60px;
    width: 100%;
    overflow: hidden;
    border-radius: 0.375rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(139 96 247 / var(--tw-border-opacity));
    --tw-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16);
    --tw-shadow-colored: 0px 6px 16px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
@media (min-width: 500px) {
    .SearchItemPanel_search-panel-wrap__acrVL {
        top: 76px
    }
}
  .SearchItemPanel_search-panel-wrap__acrVL .SearchItemPanel_search-panel-item__J0c_Z {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(14 12 21 / var(--tw-bg-opacity));
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1rem;
    --tw-shadow: 0px -1px 0px 0px #252132 inset;
    --tw-shadow-colored: inset 0px -1px 0px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
  .SearchItemPanel_search-panel-wrap__acrVL .SearchItemPanel_search-panel-item__J0c_Z:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(24 19 39 / var(--tw-bg-opacity))
}
  .SearchItemPanel_search-panel-wrap__acrVL.SearchItemPanel_search-panel-wrap-nav__saRPp {
    top: 54px;
    left: 0px
}
  .SearchItemPanel_search-panel-wrap__acrVL.SearchItemPanel_search-panel-wrap-no-border__y5g34 {
    border-style: none
}
  .SearchItemPanel_search-panel-wrap__acrVL.SearchItemPanel_search-panel-wrap-no-border__y5g34 .SearchItemPanel_search-panel-item__J0c_Z {
    padding-left: 0px;
    padding-right: 0px
}
  .SearchItemPanel_search-panel-wrap__acrVL .SearchItemPanel_search-panel-item-clear-icon__IsUrm {
    display: flex;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    align-items: center;
    justify-content: center
}
  .SearchItemPanel_search-panel-wrap__acrVL .SearchItemPanel_search-panel-item-clear-icon__IsUrm:hover path {
    fill: #A280F9
}
  .SearchItemPanel_search-panel-wrap__acrVL .SearchItemPanel_search-panel-item-clear-icon__IsUrm:active path {
    fill: #8256F2
}

.SearchInput_search-bar-wrap__HuING{

  display: flex;

  width: 100%;

  gap: 0.25rem;

  border-width: 0px;

  border-left-width: 1px;

  border-style: solid;

  --tw-border-opacity: 1;

  border-color: rgb(35 31 48 / var(--tw-border-opacity));
}
@media (min-width: 1024px){
  .SearchInput_search-bar-wrap__HuING{

    flex: 1 1 0%;

    gap: 0.5rem;
  }
}
  .SearchInput_search-bar-wrap__HuING .SearchInput_search-bar-irregular__pJHNu{

  position: relative;

  display: flex;

  height: auto;

  flex: 1 1 0%;

  align-items: center;

  padding-left: 0.75rem;
}
  @media (min-width: 1024px){
  .SearchInput_search-bar-wrap__HuING .SearchInput_search-bar-irregular__pJHNu{

    height: 32px;
  }
}
  .SearchInput_search-bar-wrap__HuING .SearchInput_search-bar-irregular__pJHNu input{

  padding-left: 0.75rem;

  padding-right: 1.5rem;

  font-size: 1rem;

  line-height: 1.5rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
}
  .SearchInput_search-bar-wrap__HuING .SearchInput_search-bar-irregular__pJHNu input::-moz-placeholder{

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}
  .SearchInput_search-bar-wrap__HuING .SearchInput_search-bar-irregular__pJHNu input::placeholder{

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}
  @media (min-width: 1024px){
  .SearchInput_search-bar-wrap__HuING .SearchInput_search-bar-irregular__pJHNu input{

    padding-right: 2.25rem;
  }
}
  .SearchInput_search-bar-wrap__HuING .SearchInput_search-icon__WiNfI{

  display: none;
}

.SearchInput_search-bar-irregular-wrap__s4U7w{

  position: relative;

  display: flex;

  width: 100%;

  gap: 0.25rem;
}

@media (min-width: 1024px){
  .SearchInput_search-bar-irregular-wrap__s4U7w{

    width: auto;

    gap: 0.5rem;
  }
}

.SearchInput_search-bar-irregular-wrap__s4U7w{


  /* &.search-bar-wrap-error{
    .search-bar-irregular{
      @apply bg-search-mobile-warning lg:bg-search-pc-warning;
    }
    .search-icon{
      @apply bg-query-disabled cursor-not-allowed;
    }
  } */
}

.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-bar-irregular__pJHNu{

  position: relative;

  display: flex;

  flex: 1 1 0%;

  align-items: center;

  background-image: url('/symbolmarket/images/search-mobile.png');

  background-clip: border-box;

  background-position: left;

  background-repeat: no-repeat;

  padding: 0.75rem;

  padding-left: 1rem;
}

@media (min-width: 500px){
  .SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-bar-irregular__pJHNu{

    border-radius: 0.5rem;

    background-image: url('/symbolmarket/images/search-pc.png');
  }
}

@media (min-width: 1024px){
  .SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-bar-irregular__pJHNu{

    height: 72px;

    width: 604px;

    padding: 1.5rem;
  }
}

.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-bar-irregular__pJHNu {

    background-size: 100% 100%;
  }

.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-bar-irregular__pJHNu input{

  padding: 0px;

  padding-right: 2rem;

  font-size: 1rem;

  line-height: 1.5rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-bar-irregular__pJHNu input::-moz-placeholder{

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}

.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-bar-irregular__pJHNu input::placeholder{

  --tw-text-opacity: 1;

  color: rgb(121 111 148 / var(--tw-text-opacity));
}

.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-icon__WiNfI{

  height: 3.5rem;

  width: 3.5rem;

  cursor: pointer;

  background-image: url('/symbolmarket/images/query.png');

  background-size: contain;

  background-position: center;

  background-repeat: no-repeat;
}

.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-icon__WiNfI:hover{

  background-image: url('/symbolmarket/images/query-hover.png');
}

.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-icon__WiNfI:active{

  background-image: url('/symbolmarket/images/query-active.png');
}

@media (min-width: 500px){
  .SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_search-icon__WiNfI{

    height: 72px;

    width: 72px;
  }
}

.SearchInput_search-bar-wrap__HuING .SearchInput_clear-icon__kSAG0,.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_clear-icon__kSAG0{

  position: absolute;

  right: 0px;

  display: flex;

  height: 2rem;

  width: 2rem;

  cursor: pointer;

  align-items: center;

  justify-content: center;
}

@media (min-width: 500px){
  .SearchInput_search-bar-wrap__HuING .SearchInput_clear-icon__kSAG0,.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_clear-icon__kSAG0{

    right: 0.5rem;
  }
}

.SearchInput_search-bar-wrap__HuING .SearchInput_clear-icon__kSAG0:hover path,.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_clear-icon__kSAG0:hover path{

  fill: #A280F9;
}

.SearchInput_search-bar-wrap__HuING .SearchInput_clear-icon__kSAG0:active path,.SearchInput_search-bar-irregular-wrap__s4U7w .SearchInput_clear-icon__kSAG0:active path{

  fill: #8256F2;
}

.SearchSelect_search-bar-radio-button__VZMGb {
    margin-bottom: 1.5rem;
    display: flex;
    cursor: pointer;
    border-radius: 0.375rem;
    border-width: 1px;
    border-style: solid;
    border-color: #FFFFFF1A;
    --tw-bg-opacity: 1;
    background-color: rgb(54 24 70 / var(--tw-bg-opacity));
    padding: 1px
}
@media (min-width: 500px) {
    .SearchSelect_search-bar-radio-button__VZMGb {
        border-radius: 0.75rem;
        padding: 3px
    }
}
  .SearchSelect_search-bar-radio-button__VZMGb .SearchSelect_radio-button__4Zwnd {
    height: 26px;
    width: 96px;
    border-radius: 0.25rem;
    border-width: 0px;
    background-color: transparent;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    line-height: 26px;
    --tw-text-opacity: 1;
    color: rgb(121 111 148 / var(--tw-text-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px
}
  .SearchSelect_search-bar-radio-button__VZMGb .SearchSelect_radio-button__4Zwnd:hover {
    --tw-text-opacity: 1;
    color: rgb(162 128 249 / var(--tw-text-opacity))
}
  @media (min-width: 500px) {
    .SearchSelect_search-bar-radio-button__VZMGb .SearchSelect_radio-button__4Zwnd {
        height: 2.5rem;
        width: 138px;
        border-radius: 0.5rem;
        font-size: 1rem;
        line-height: 2.5rem
    }
}
  .SearchSelect_search-bar-radio-button__VZMGb .SearchSelect_radio-button-active__tMg7u {
    --tw-bg-opacity: 1;
    background-color: rgb(31 21 56 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
  .SearchSelect_search-bar-radio-button__VZMGb .SearchSelect_radio-button-active__tMg7u:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.SearchSelect_elf-drop-menu-custom__47Bqi {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.SearchSelect_elf-drop-menu-custom__47Bqi:active {
    --tw-text-opacity: 1;
    color: rgb(130 86 242 / var(--tw-text-opacity))
}

.SearchSelect_elf-drop-menu-custom__47Bqi svg * {
    transition-property: none
}

.SearchSelect_elf-drop-menu-custom__47Bqi:active svg > path {
    fill: #8256F2
}

.searchModal_search-modal-wrap__aG5Eq {
    width: 100%;
    --tw-bg-opacity: 1;
    background-color: rgb(14 12 21 / var(--tw-bg-opacity))
}
  .searchModal_search-modal-wrap__aG5Eq .elf-modal {
    margin: 0px !important;
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important
}
  .searchModal_search-modal-wrap__aG5Eq .elf-modal-content {
    background-color: transparent
}
  .searchModal_search-modal-wrap__aG5Eq .elf-modal-body {
    padding: 1rem
}

.header_header-wrap__FzdoM {
    position: absolute;
    left: 1rem;
    right: 1rem;
    z-index: 20;
    display: flex;
    height: 80px;
    max-width: 1360px;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    background-color: transparent;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms
}
@media (min-width: 500px) {
    .header_header-wrap__FzdoM {
        left: 2.5rem;
        right: 2.5rem
    }
}
@media (min-width: 1440px) {
    .header_header-wrap__FzdoM {
        left: auto;
        right: auto;
        width: 100%
    }
}
  .header_header-wrap__FzdoM.header_header-wrap-mask__7_bjb {
    --tw-bg-opacity: 1;
    background-color: rgb(14 12 21 / var(--tw-bg-opacity))
}

.header_inscription__button__B39OK {
    cursor: pointer;
    border-radius: 0.375rem;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: #33245866;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

@media (min-width: 1024px) {
    .header_inscription__button__B39OK {
        --tw-border-opacity: 1;
        border-color: rgb(255 255 255 / var(--tw-border-opacity));
        background-color: transparent
    }
}

.header_inscription__button__B39OK:hover {
    --tw-border-opacity: 1;
    border-color: rgb(162 128 249 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(162 128 249 / var(--tw-text-opacity))
}

.header_inscription__button__B39OK:active {
    --tw-border-opacity: 1;
    border-color: rgb(130 86 242 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(130 86 242 / var(--tw-text-opacity))
}

.Footer_footer-container__qsl4C svg:not(.Footer_tsm-logo__lGXHd):hover path {
    fill: #fff
}

.aelf-web-login.aelf-extra-wallets {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}
.aelf-web-login.aelf-extra-wallets .social-header {
  width: 100% !important;
}
.aelf-web-login.aelf-extra-wallets .social-header .header {
  display: flex !important;
}
.aelf-web-login.aelf-extra-wallets .social-header .header .header-btn {
  padding: 0 !important;
  margin: 24px -8px 0 auto !important;
}
.aelf-web-login.aelf-extra-wallets .social-header .title-icon {
  width: 56px !important;
  height: 56px !important;
  margin: 16px auto !important;
}
.aelf-web-login.aelf-extra-wallets .social-header .title {
  text-align: center !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  color: #25272a !important;
}
.aelf-web-login.aelf-extra-wallets .default-header .title {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #5c6764 !important;
}
.aelf-web-login.aelf-extra-wallets .wallet-entries.social-content {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 12px !important;
  margin-top: 32px !important;
  margin-bottom: 48px !important;
}
.aelf-web-login.aelf-extra-wallets .wallet-entries.web2-content {
  display: flex !important;
  flex-direction: row !important;
  gap: 8px !important;
}
.aelf-web-login.aelf-extra-wallets .social-content .plugin-entry {
  width: 100% !important;
  height: 56px !important;
  padding-left: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
  border-radius: 4px !important;
  border: 1px solid #c5cbd5 !important;
  background: #fff !important;
}
.aelf-web-login.aelf-extra-wallets .wallet-entries.web2-content .plugin-entry {
  height: 48px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.aelf-web-login.aelf-extra-wallets .social-content .plugin-entry .icon {
  width: 28px !important;
  height: 28px !important;
  margin-right: 12px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}
.aelf-web-login.aelf-extra-wallets .social-content .plugin-entry .name {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex !important;
  text-align: center !important;
  color: #5c6764 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}
.aelf-web-login.aelf-extra-wallets .wallet-entries.default-content {
  display: flex !important;
  flex-direction: row !important;
  gap: 56px !important;
  margin-top: 16px !important;
  margin-bottom: 40px !important;
}
.aelf-web-login.aelf-extra-wallets .default-content .plugin-entry .icon {
  width: 42px !important;
  height: 42px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}
.aelf-web-login.aelf-extra-wallets .default-content .plugin-entry .name {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  display: flex !important;
  text-align: center !important;
  color: #5c6764 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}
.aelf-web-logout-dialog {
  display: flex !important;
  flex-direction: column !important;
}
.aelf-web-logout-dialog-content {
  padding: 0 32px 24px !important;
  display: flex !important;
  flex-direction: column !important;
}
.aelf-web-logout-dialog-content:first-child {
  border-bottom: 1px solid #dee2e8 !important;
}
.aelf-web-logout-dialog-content-mobile {
  padding: 0 16px 24px !important;
}
.aelf-web-logout-dialog-close {
  height: 24px !important;
  margin-top: 20px !important;
  margin-right: -11px !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
.aelf-web-logout-dialog-content-mobile .aelf-web-logout-dialog-close {
  margin-right: 9px !important;
}
.aelf-web-logout-dialog-title {
  margin: 12px 0 !important;
  line-height: 32px !important;
  font-size: 24px !important;
  text-align: center !important;
  font-weight: 500 !important;
  color: #252525 !important;
  letter-spacing: -1px !important;
}
.aelf-web-logout-dialog-sub-title {
  margin-top: 12px !important;
  line-height: 24px !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #515a62 !important;
  word-spacing: -2px !important;
}
.aelf-web-logout-dialog-btn {
  margin: 16px 0 0 !important;
  border-radius: 4px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-sizing: border-box !important;
  height: 48px !important;
  margin-top: 16px !important;
  font-size: 16px !important;
}
.aelf-web-logout-dialog .ok-btn {
  background-color: #e7383a !important;
  color: #fff !important;
}
.aelf-web-logout-dialog .cancel-btn {
  color: #5b8ef4 !important;
  border: 1px solid #5b8ef4 !important;
}

:root {
  --brand-base: #8b60f7;
}

.dark-theme {
  --brand-base: #8b60f7;
}

*, ::before, ::after {
      --tw-border-spacing-x: 0;
      --tw-border-spacing-y: 0;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      --tw-pan-x:  ;
      --tw-pan-y:  ;
      --tw-pinch-zoom:  ;
      --tw-scroll-snap-strictness: proximity;
      --tw-gradient-from-position:  ;
      --tw-gradient-via-position:  ;
      --tw-gradient-to-position:  ;
      --tw-ordinal:  ;
      --tw-slashed-zero:  ;
      --tw-numeric-figure:  ;
      --tw-numeric-spacing:  ;
      --tw-numeric-fraction:  ;
      --tw-ring-inset:  ;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgb(59 130 246 / 0.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
      --tw-blur:  ;
      --tw-brightness:  ;
      --tw-contrast:  ;
      --tw-grayscale:  ;
      --tw-hue-rotate:  ;
      --tw-invert:  ;
      --tw-saturate:  ;
      --tw-sepia:  ;
      --tw-drop-shadow:  ;
      --tw-backdrop-blur:  ;
      --tw-backdrop-brightness:  ;
      --tw-backdrop-contrast:  ;
      --tw-backdrop-grayscale:  ;
      --tw-backdrop-hue-rotate:  ;
      --tw-backdrop-invert:  ;
      --tw-backdrop-opacity:  ;
      --tw-backdrop-saturate:  ;
      --tw-backdrop-sepia:  ;
}
::backdrop {
      --tw-border-spacing-x: 0;
      --tw-border-spacing-y: 0;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      --tw-pan-x:  ;
      --tw-pan-y:  ;
      --tw-pinch-zoom:  ;
      --tw-scroll-snap-strictness: proximity;
      --tw-gradient-from-position:  ;
      --tw-gradient-via-position:  ;
      --tw-gradient-to-position:  ;
      --tw-ordinal:  ;
      --tw-slashed-zero:  ;
      --tw-numeric-figure:  ;
      --tw-numeric-spacing:  ;
      --tw-numeric-fraction:  ;
      --tw-ring-inset:  ;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgb(59 130 246 / 0.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
      --tw-blur:  ;
      --tw-brightness:  ;
      --tw-contrast:  ;
      --tw-grayscale:  ;
      --tw-hue-rotate:  ;
      --tw-invert:  ;
      --tw-saturate:  ;
      --tw-sepia:  ;
      --tw-drop-shadow:  ;
      --tw-backdrop-blur:  ;
      --tw-backdrop-brightness:  ;
      --tw-backdrop-contrast:  ;
      --tw-backdrop-grayscale:  ;
      --tw-backdrop-hue-rotate:  ;
      --tw-backdrop-invert:  ;
      --tw-backdrop-opacity:  ;
      --tw-backdrop-saturate:  ;
      --tw-backdrop-sepia:  ;
}
/* 
        demo for antd
        .{app-prefix}-wrapper {
            .ant-layout {
                background: #fff;
            }
        }
    */
.elf-form-vertical-custom.elf-form-large .elf-form-item {
      margin-bottom: 16px;
    }
.elf-form-vertical-custom.elf-form-large .elf-form-item.elf-form-item-has-error > .elf-form-item-margin-offset {
      margin-bottom: 0 !important;
    }
.elf-form-vertical-custom.elf-form-large .elf-form-item.elf-form-item-has-error .elf-form-item-explain-error {
      text-align: left !important;
}
.elf-form-vertical-custom.elf-form-large .elf-form-item-label > label {
      height: 24px;
      font-weight: 500;
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
}
.elf-form-vertical-custom.elf-form-large .elf-input-affix-wrapper {
      border-radius: 6px !important;
}
.elf-form-vertical-custom.elf-form-large .elf-form-item-label > label.elf-form-item-required:not(.elf-form-item-required-mark-optional)::before {
      /* display: none; */
    }
.elf-form-vertical-custom.elf-form-large .elf-form-item-label > label.elf-form-item-required:not(.elf-form-item-required-mark-optional)::after {
      /* display: inline-block;
      margin-right: 4px;
      color: #ee3249;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*'; */
    }
.visible {
      visibility: visible;
}
.static {
      position: static;
}
.fixed {
      position: fixed;
}
.\!absolute {
      position: absolute !important;
}
.absolute {
      position: absolute;
}
.relative {
      position: relative;
}
.inset-y-\[-150px\] {
      top: -150px;
      bottom: -150px;
}
.-bottom-\[358px\] {
      bottom: -358px;
}
.-right-\[160px\] {
      right: -160px;
}
.-top-1 {
      top: -0.25rem;
}
.-top-\[1px\] {
      top: -1px;
}
.bottom-0 {
      bottom: 0px;
}
.left-0 {
      left: 0px;
}
.left-1\/2 {
      left: 50%;
}
.right-0 {
      right: 0px;
}
.right-1 {
      right: 0.25rem;
}
.right-6 {
      right: 1.5rem;
}
.top-0 {
      top: 0px;
}
.top-\[88px\] {
      top: 88px;
}
.z-10 {
      z-index: 10;
}
.z-30 {
      z-index: 30;
}
.z-50 {
      z-index: 50;
}
.z-\[11\] {
      z-index: 11;
}
.z-\[2000\] {
      z-index: 2000;
}
.\!m-0 {
      margin: 0px !important;
}
.m-0 {
      margin: 0px;
}
.\!mx-auto {
      margin-left: auto !important;
      margin-right: auto !important;
}
.\!my-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
}
.-mx-4 {
      margin-left: -1rem;
      margin-right: -1rem;
}
.mx-0 {
      margin-left: 0px;
      margin-right: 0px;
}
.mx-1 {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
}
.mx-2 {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
}
.mx-\[-24px\] {
      margin-left: -24px;
      margin-right: -24px;
}
.mx-\[16px\] {
      margin-left: 16px;
      margin-right: 16px;
}
.my-0 {
      margin-top: 0px;
      margin-bottom: 0px;
}
.my-4 {
      margin-top: 1rem;
      margin-bottom: 1rem;
}
.my-\[32px\] {
      margin-top: 32px;
      margin-bottom: 32px;
}
.\!mb-0 {
      margin-bottom: 0px !important;
}
.\!mb-2 {
      margin-bottom: 0.5rem !important;
}
.\!ml-0 {
      margin-left: 0px !important;
}
.\!ml-4 {
      margin-left: 1rem !important;
}
.\!mr-6 {
      margin-right: 1.5rem !important;
}
.\!mt-0 {
      margin-top: 0px !important;
}
.\!mt-12 {
      margin-top: 3rem !important;
}
.\!mt-4 {
      margin-top: 1rem !important;
}
.-mb-16 {
      margin-bottom: -4rem;
}
.-mt-4 {
      margin-top: -1rem;
}
.-mt-px {
      margin-top: -1px;
}
.mb-2 {
      margin-bottom: 0.5rem;
}
.mb-3 {
      margin-bottom: 0.75rem;
}
.mb-4 {
      margin-bottom: 1rem;
}
.mb-6 {
      margin-bottom: 1.5rem;
}
.mb-\[24px\] {
      margin-bottom: 24px;
}
.mb-\[32px\] {
      margin-bottom: 32px;
}
.mb-\[3px\] {
      margin-bottom: 3px;
}
.ml-1 {
      margin-left: 0.25rem;
}
.ml-2 {
      margin-left: 0.5rem;
}
.ml-3 {
      margin-left: 0.75rem;
}
.ml-4 {
      margin-left: 1rem;
}
.ml-\[16px\] {
      margin-left: 16px;
}
.mr-1 {
      margin-right: 0.25rem;
}
.mr-2 {
      margin-right: 0.5rem;
}
.mr-4 {
      margin-right: 1rem;
}
.mr-6 {
      margin-right: 1.5rem;
}
.mr-\[8px\] {
      margin-right: 8px;
}
.mt-0 {
      margin-top: 0px;
}
.mt-1 {
      margin-top: 0.25rem;
}
.mt-12 {
      margin-top: 3rem;
}
.mt-16 {
      margin-top: 4rem;
}
.mt-2 {
      margin-top: 0.5rem;
}
.mt-4 {
      margin-top: 1rem;
}
.mt-6 {
      margin-top: 1.5rem;
}
.mt-8 {
      margin-top: 2rem;
}
.mt-\[-16px\] {
      margin-top: -16px;
}
.mt-\[120px\] {
      margin-top: 120px;
}
.mt-\[12px\] {
      margin-top: 12px;
}
.mt-\[151px\] {
      margin-top: 151px;
}
.mt-\[16px\] {
      margin-top: 16px;
}
.mt-\[1px\] {
      margin-top: 1px;
}
.mt-\[32px\] {
      margin-top: 32px;
}
.mt-\[64px\] {
      margin-top: 64px;
}
.\!box-border {
      box-sizing: border-box !important;
}
.box-border {
      box-sizing: border-box;
}
.block {
      display: block;
}
.inline-block {
      display: inline-block;
}
.\!flex {
      display: flex !important;
}
.flex {
      display: flex;
}
.inline-flex {
      display: inline-flex;
}
.grid {
      display: grid;
}
.hidden {
      display: none;
}
.aspect-square {
      aspect-ratio: 1 / 1;
}
.\!h-10 {
      height: 2.5rem !important;
}
.\!h-12 {
      height: 3rem !important;
}
.\!h-16 {
      height: 4rem !important;
}
.\!h-\[16px\] {
      height: 16px !important;
}
.\!h-\[48px\] {
      height: 48px !important;
}
.\!h-\[52px\] {
      height: 52px !important;
}
.h-10 {
      height: 2.5rem;
}
.h-12 {
      height: 3rem;
}
.h-16 {
      height: 4rem;
}
.h-3 {
      height: 0.75rem;
}
.h-4 {
      height: 1rem;
}
.h-5 {
      height: 1.25rem;
}
.h-6 {
      height: 1.5rem;
}
.h-64 {
      height: 16rem;
}
.h-8 {
      height: 2rem;
}
.h-9 {
      height: 2.25rem;
}
.h-\[100vh\] {
      height: 100vh;
}
.h-\[120px\] {
      height: 120px;
}
.h-\[14px\] {
      height: 14px;
}
.h-\[15px\] {
      height: 15px;
}
.h-\[16px\] {
      height: 16px;
}
.h-\[192px\] {
      height: 192px;
}
.h-\[1px\] {
      height: 1px;
}
.h-\[274px\] {
      height: 274px;
}
.h-\[28px\] {
      height: 28px;
}
.h-\[32px\] {
      height: 32px;
}
.h-\[40px\] {
      height: 40px;
}
.h-\[52px\] {
      height: 52px;
}
.h-\[59px\] {
      height: 59px;
}
.h-\[637px\] {
      height: 637px;
}
.h-\[64px\] {
      height: 64px;
}
.h-\[94px\] {
      height: 94px;
}
.h-\[953px\] {
      height: 953px;
}
.h-auto {
      height: auto;
}
.h-full {
      height: 100%;
}
.max-h-\[438px\] {
      max-height: 438px;
}
.\!w-16 {
      width: 4rem !important;
}
.\!w-\[16px\] {
      width: 16px !important;
}
.w-0 {
      width: 0px;
}
.w-10 {
      width: 2.5rem;
}
.w-12 {
      width: 3rem;
}
.w-16 {
      width: 4rem;
}
.w-3 {
      width: 0.75rem;
}
.w-4 {
      width: 1rem;
}
.w-5 {
      width: 1.25rem;
}
.w-56 {
      width: 14rem;
}
.w-6 {
      width: 1.5rem;
}
.w-7 {
      width: 1.75rem;
}
.w-8 {
      width: 2rem;
}
.w-9 {
      width: 2.25rem;
}
.w-\[100px\] {
      width: 100px;
}
.w-\[100vw\] {
      width: 100vw;
}
.w-\[121px\] {
      width: 121px;
}
.w-\[122px\] {
      width: 122px;
}
.w-\[128px\] {
      width: 128px;
}
.w-\[142px\] {
      width: 142px;
}
.w-\[148px\] {
      width: 148px;
}
.w-\[14px\] {
      width: 14px;
}
.w-\[152px\] {
      width: 152px;
}
.w-\[16px\] {
      width: 16px;
}
.w-\[196px\] {
      width: 196px;
}
.w-\[1px\] {
      width: 1px;
}
.w-\[207px\] {
      width: 207px;
}
.w-\[20px\] {
      width: 20px;
}
.w-\[222px\] {
      width: 222px;
}
.w-\[223px\] {
      width: 223px;
}
.w-\[231px\] {
      width: 231px;
}
.w-\[26px\] {
      width: 26px;
}
.w-\[310px\] {
      width: 310px;
}
.w-\[500px\] {
      width: 500px;
}
.w-\[52px\] {
      width: 52px;
}
.w-\[581px\] {
      width: 581px;
}
.w-\[605px\] {
      width: 605px;
}
.w-\[64px\] {
      width: 64px;
}
.w-\[69px\] {
      width: 69px;
}
.w-\[74px\] {
      width: 74px;
}
.w-\[94px\] {
      width: 94px;
}
.w-auto {
      width: auto;
}
.w-full {
      width: 100%;
}
.min-w-\[109px\] {
      min-width: 109px;
}
.min-w-\[112px\] {
      min-width: 112px;
}
.min-w-\[125px\] {
      min-width: 125px;
}
.min-w-\[131px\] {
      min-width: 131px;
}
.min-w-\[148px\] {
      min-width: 148px;
}
.min-w-\[246px\] {
      min-width: 246px;
}
.min-w-\[297px\] {
      min-width: 297px;
}
.min-w-\[98px\] {
      min-width: 98px;
}
.max-w-\[1440px\] {
      max-width: 1440px;
}
.max-w-\[264px\] {
      max-width: 264px;
}
.max-w-\[280px\] {
      max-width: 280px;
}
.max-w-\[422px\] {
      max-width: 422px;
}
.max-w-\[438px\] {
      max-width: 438px;
}
.max-w-\[500px\] {
      max-width: 500px;
}
.max-w-\[700px\] {
      max-width: 700px;
}
.max-w-\[70px\] {
      max-width: 70px;
}
.flex-1 {
      flex: 1 1 0%;
}
.flex-shrink {
      flex-shrink: 1;
}
.flex-shrink-0 {
      flex-shrink: 0;
}
.shrink-0 {
      flex-shrink: 0;
}
.flex-grow {
      flex-grow: 1;
}
.basis-0 {
      flex-basis: 0px;
}
.translate-x-\[-50\%\] {
      --tw-translate-x: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
      --tw-rotate: 180deg;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes bounce {
      0%, 100% {
            transform: translateY(-25%);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
      }
      50% {
            transform: none;
            animation-timing-function: cubic-bezier(0,0,0.2,1);
      }
}
.animate-bounce {
      animation: bounce 1s infinite;
}
@keyframes spin {
      to {
            transform: rotate(360deg);
      }
}
.animate-spin {
      animation: spin 1s linear infinite;
}
.cursor-pointer {
      cursor: pointer;
}
.resize {
      resize: both;
}
.grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
}
.flex-row {
      flex-direction: row;
}
.flex-col {
      flex-direction: column;
}
.\!flex-col-reverse {
      flex-direction: column-reverse !important;
}
.flex-col-reverse {
      flex-direction: column-reverse;
}
.flex-wrap {
      flex-wrap: wrap;
}
.flex-nowrap {
      flex-wrap: nowrap;
}
.items-start {
      align-items: flex-start;
}
.items-end {
      align-items: flex-end;
}
.\!items-center {
      align-items: center !important;
}
.items-center {
      align-items: center;
}
.justify-start {
      justify-content: flex-start;
}
.\!justify-center {
      justify-content: center !important;
}
.justify-center {
      justify-content: center;
}
.justify-between {
      justify-content: space-between;
}
.justify-around {
      justify-content: space-around;
}
.gap-1 {
      gap: 0.25rem;
}
.gap-12 {
      gap: 3rem;
}
.gap-2 {
      gap: 0.5rem;
}
.gap-3 {
      gap: 0.75rem;
}
.gap-4 {
      gap: 1rem;
}
.gap-6 {
      gap: 1.5rem;
}
.gap-\[16px\] {
      gap: 16px;
}
.gap-\[32px\] {
      gap: 32px;
}
.self-center {
      align-self: center;
}
.overflow-hidden {
      overflow: hidden;
}
.overflow-x-auto {
      overflow-x: auto;
}
.overflow-y-auto {
      overflow-y: auto;
}
.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
}
.text-ellipsis {
      text-overflow: ellipsis;
}
.\!whitespace-normal {
      white-space: normal !important;
}
.break-words {
      overflow-wrap: break-word;
}
.break-all {
      word-break: break-all;
}
.\!rounded-md {
      border-radius: 0.375rem !important;
}
.rounded-\[42px\] {
      border-radius: 42px;
}
.rounded-\[6px\] {
      border-radius: 6px;
}
.rounded-full {
      border-radius: 9999px;
}
.rounded-lg {
      border-radius: 0.5rem;
}
.rounded-md {
      border-radius: 0.375rem;
}
.rounded-xl {
      border-radius: 0.75rem;
}
.border {
      border-width: 1px;
}
.border-0 {
      border-width: 0px;
}
.border-\[1px\] {
      border-width: 1px;
}
.border-x-0 {
      border-left-width: 0px;
      border-right-width: 0px;
}
.border-b {
      border-bottom-width: 1px;
}
.border-t-0 {
      border-top-width: 0px;
}
.border-t-\[1px\] {
      border-top-width: 1px;
}
.border-solid {
      border-style: solid;
}
.\!border-error-border {
      --tw-border-opacity: 1 !important;
      border-color: rgb(190 40 58 / var(--tw-border-opacity)) !important;
}
.\!border-primary-border-active {
      --tw-border-opacity: 1 !important;
      border-color: rgb(130 86 242 / var(--tw-border-opacity)) !important;
}
.\!border-primary-color {
      --tw-border-opacity: 1 !important;
      border-color: rgb(139 96 247 / var(--tw-border-opacity)) !important;
}
.border-\[\#08D1891F\] {
      border-color: #08D1891F;
}
.border-\[\#231F30\] {
      --tw-border-opacity: 1;
      border-color: rgb(35 31 48 / var(--tw-border-opacity));
}
.border-\[\#BE283A1F\] {
      border-color: #BE283A1F;
}
.border-dark-border-default {
      --tw-border-opacity: 1;
      border-color: rgb(35 31 48 / var(--tw-border-opacity));
}
.border-error-border {
      --tw-border-opacity: 1;
      border-color: rgb(190 40 58 / var(--tw-border-opacity));
}
.border-primary-border {
      --tw-border-opacity: 1;
      border-color: rgb(33 25 56 / var(--tw-border-opacity));
}
.border-primary-color {
      --tw-border-opacity: 1;
      border-color: rgb(139 96 247 / var(--tw-border-opacity));
}
.border-view-more-border {
      border-color: #FFFFFF1A;
}
.border-b-\[\#231F30\] {
      --tw-border-opacity: 1;
      border-bottom-color: rgb(35 31 48 / var(--tw-border-opacity));
}
.\!bg-dark-bgc {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(14 12 21 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#08D1892F\] {
      background-color: #08D1892F;
}
.bg-\[\#0E0C15\] {
      --tw-bg-opacity: 1;
      background-color: rgb(14 12 21 / var(--tw-bg-opacity));
}
.bg-\[\#0E0c15\] {
      --tw-bg-opacity: 1;
      background-color: rgb(14 12 21 / var(--tw-bg-opacity));
}
.bg-\[\#100D1B\] {
      --tw-bg-opacity: 1;
      background-color: rgb(16 13 27 / var(--tw-bg-opacity));
}
.bg-\[\#BE283A2F\] {
      background-color: #BE283A2F;
}
.bg-card-bg {
      --tw-bg-opacity: 1;
      background-color: rgb(16 13 27 / var(--tw-bg-opacity));
}
.bg-dark-bgc {
      --tw-bg-opacity: 1;
      background-color: rgb(14 12 21 / var(--tw-bg-opacity));
}
.bg-dark-border-default {
      --tw-bg-opacity: 1;
      background-color: rgb(35 31 48 / var(--tw-bg-opacity));
}
.bg-dark-modal-bg {
      --tw-bg-opacity: 1;
      background-color: rgb(25 22 36 / var(--tw-bg-opacity));
}
.bg-img-upload-bg {
      background-color: rgba(0, 0, 0, 0.8);
}
.bg-primary-bg {
      background-color: #33245866;
}
.bg-primary-color {
      --tw-bg-opacity: 1;
      background-color: rgb(139 96 247 / var(--tw-bg-opacity));
}
.bg-transparent {
      background-color: transparent;
}
.\!bg-homepage {
      background-image: url('/symbolmarket/images/bg-top-2x.png') !important;
}
.bg-cover {
      background-size: cover;
}
.bg-clip-border {
      background-clip: border-box;
}
.bg-center {
      background-position: center;
}
.bg-no-repeat {
      background-repeat: no-repeat;
}
.object-contain {
      -o-object-fit: contain;
         object-fit: contain;
}
.object-cover {
      -o-object-fit: cover;
         object-fit: cover;
}
.p-4 {
      padding: 1rem;
}
.p-6 {
      padding: 1.5rem;
}
.p-\[23px\] {
      padding: 23px;
}
.\!px-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
}
.\!px-6 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
}
.\!py-3 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
}
.\!py-\[10px\] {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
}
.px-0 {
      padding-left: 0px;
      padding-right: 0px;
}
.px-2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
}
.px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
}
.px-5 {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
}
.px-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
}
.px-\[11px\] {
      padding-left: 11px;
      padding-right: 11px;
}
.px-\[24px\] {
      padding-left: 24px;
      padding-right: 24px;
}
.px-\[5px\] {
      padding-left: 5px;
      padding-right: 5px;
}
.py-1 {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
}
.py-1\.5 {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
}
.py-2 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
}
.py-4 {
      padding-top: 1rem;
      padding-bottom: 1rem;
}
.py-5 {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
}
.py-6 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
}
.py-\[10px\] {
      padding-top: 10px;
      padding-bottom: 10px;
}
.py-\[16px\] {
      padding-top: 16px;
      padding-bottom: 16px;
}
.\!pt-\[24px\] {
      padding-top: 24px !important;
}
.pb-12 {
      padding-bottom: 3rem;
}
.pb-4 {
      padding-bottom: 1rem;
}
.pb-6 {
      padding-bottom: 1.5rem;
}
.pb-\[25px\] {
      padding-bottom: 25px;
}
.pl-1 {
      padding-left: 0.25rem;
}
.pl-11 {
      padding-left: 2.75rem;
}
.pl-3 {
      padding-left: 0.75rem;
}
.pl-\[8px\] {
      padding-left: 8px;
}
.pr-8 {
      padding-right: 2rem;
}
.pt-10 {
      padding-top: 2.5rem;
}
.pt-6 {
      padding-top: 1.5rem;
}
.pt-8 {
      padding-top: 2rem;
}
.text-left {
      text-align: left;
}
.text-center {
      text-align: center;
}
.\!text-xs {
      font-size: 0.75rem !important;
      line-height: 1rem !important;
}
.text-2xl {
      font-size: 1.5rem;
      line-height: 2rem;
}
.text-3xl {
      font-size: 1.875rem;
      line-height: 2.25rem;
}
.text-4xl {
      font-size: 2.25rem;
      line-height: 2.5rem;
}
.text-\[12px\] {
      font-size: 12px;
}
.text-\[14px\] {
      font-size: 14px;
}
.text-\[16px\] {
      font-size: 16px;
}
.text-\[20px\] {
      font-size: 20px;
}
.text-\[24px\] {
      font-size: 24px;
}
.text-\[36px\] {
      font-size: 36px;
}
.text-base {
      font-size: 1rem;
      line-height: 1.5rem;
}
.text-min {
      font-size: 0.75rem;
      line-height: 18px;
      font-weight: 400;
}
.text-sm {
      font-size: 0.875rem;
      line-height: 1.25rem;
}
.text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem;
}
.text-xs {
      font-size: 0.75rem;
      line-height: 1rem;
}
.\!font-bold {
      font-weight: 700 !important;
}
.font-bold {
      font-weight: 700;
}
.font-medium {
      font-weight: 500;
}
.font-normal {
      font-weight: 400;
}
.font-semibold {
      font-weight: 600;
}
.\!leading-5 {
      line-height: 1.25rem !important;
}
.\!leading-6 {
      line-height: 1.5rem !important;
}
.leading-5 {
      line-height: 1.25rem;
}
.leading-6 {
      line-height: 1.5rem;
}
.leading-\[18px\] {
      line-height: 18px;
}
.leading-\[20px\] {
      line-height: 20px;
}
.leading-\[24px\] {
      line-height: 24px;
}
.leading-\[30px\] {
      line-height: 30px;
}
.leading-\[34px\] {
      line-height: 34px;
}
.leading-\[40px\] {
      line-height: 40px;
}
.leading-\[46px\] {
      line-height: 46px;
}
.leading-none {
      line-height: 1;
}
.leading-normal {
      line-height: 1.5;
}
.\!text-\[\#796F94\] {
      --tw-text-opacity: 1 !important;
      color: rgb(121 111 148 / var(--tw-text-opacity)) !important;
}
.\!text-primary-border-active {
      --tw-text-opacity: 1 !important;
      color: rgb(130 86 242 / var(--tw-text-opacity)) !important;
}
.\!text-primary-color {
      --tw-text-opacity: 1 !important;
      color: rgb(139 96 247 / var(--tw-text-opacity)) !important;
}
.\!text-white {
      --tw-text-opacity: 1 !important;
      color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-\[\#08D189\] {
      --tw-text-opacity: 1;
      color: rgb(8 209 137 / var(--tw-text-opacity));
}
.text-\[\#796F94\] {
      --tw-text-opacity: 1;
      color: rgb(121 111 148 / var(--tw-text-opacity));
}
.text-\[\#BE283A\] {
      --tw-text-opacity: 1;
      color: rgb(190 40 58 / var(--tw-text-opacity));
}
.text-dark-caption {
      --tw-text-opacity: 1;
      color: rgb(121 111 148 / var(--tw-text-opacity));
}
.text-dark-link {
      --tw-text-opacity: 1;
      color: rgb(27 118 226 / var(--tw-text-opacity));
}
.text-error-border {
      --tw-text-opacity: 1;
      color: rgb(190 40 58 / var(--tw-text-opacity));
}
.text-error-color {
      --tw-text-opacity: 1;
      color: rgb(190 40 58 / var(--tw-text-opacity));
}
.text-primary-border-active {
      --tw-text-opacity: 1;
      color: rgb(130 86 242 / var(--tw-text-opacity));
}
.text-primary-color {
      --tw-text-opacity: 1;
      color: rgb(139 96 247 / var(--tw-text-opacity));
}
.text-white {
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
}
.caret-primary-color {
      caret-color: #8B60F7;
}
.opacity-80 {
      opacity: 0.8;
}
.shadow-dropMenu {
      --tw-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16);
      --tw-shadow-colored: 0px 6px 16px 0px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-inset {
      --tw-shadow: 0px -1px 0px 0px #252132 inset;
      --tw-shadow-colored: inset 0px -1px 0px 0px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.blur {
      --tw-blur: blur(8px);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition-all {
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-colors {
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.duration-300 {
      transition-duration: 300ms;
}
html {
  font-size: 16px;
}
html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none; /* WebKit browsers */
}
body {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(14 12 21 / var(--tw-bg-opacity)) !important;

  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}
input[type='number'] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input:-internal-autofill-selected {
  -webkit-text-fill-color: #fff !important;
  box-shadow: inset 0 0 0 1000px #18161e !important;
}
* {
  font-family: Roboto, sans-serif;
  transition: background 0.3s, fill 0.3s, stork 0.3s, box-shadow 0.3s;
}
@font-face {
  font-family: 'Roboto';
  src: url('/symbolmarket/font/Roboto-Regular.ttf');
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.closeIcon:hover path {
      fill: #A280F9;
}
.closeIcon:active path {
      fill: #8256F2;
}
/* ::-webkit-scrollbar {
  border-radius: 100px;
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #8b60f7;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
  background: #211a34;
} */
.elf-drawer-mask,
.elf-modal-mask {
  background: rgb(0 0 0 / 80%) !important;
}
.elf-spin-blur::after {
  background: rgb(0 0 0 / 80%) !important;
}
.focus-within\:border-primary-border-active:focus-within {
      --tw-border-opacity: 1;
      border-color: rgb(130 86 242 / var(--tw-border-opacity));
}
.hover\:border-primary-border-hover:hover {
      --tw-border-opacity: 1;
      border-color: rgb(162 128 249 / var(--tw-border-opacity));
}
.hover\:border-primary-color:hover {
      --tw-border-opacity: 1;
      border-color: rgb(139 96 247 / var(--tw-border-opacity));
}
.hover\:bg-dark-bgc-hover:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(24 19 39 / var(--tw-bg-opacity));
}
.hover\:text-primary-border-hover:hover {
      --tw-text-opacity: 1;
      color: rgb(162 128 249 / var(--tw-text-opacity));
}
.hover\:text-primary-color:hover {
      --tw-text-opacity: 1;
      color: rgb(139 96 247 / var(--tw-text-opacity));
}
.hover\:text-primary-color-hover:hover {
      --tw-text-opacity: 1;
      color: rgb(162 128 249 / var(--tw-text-opacity));
}
.active\:border-primary-border-active:active {
      --tw-border-opacity: 1;
      border-color: rgb(130 86 242 / var(--tw-border-opacity));
}
.active\:text-primary-border-active:active {
      --tw-text-opacity: 1;
      color: rgb(130 86 242 / var(--tw-text-opacity));
}
.group:hover .group-hover\:fill-primary-border-hover {
      fill: #A280F9;
}
.group:hover .group-hover\:\!text-primary-border-hover {
      --tw-text-opacity: 1 !important;
      color: rgb(162 128 249 / var(--tw-text-opacity)) !important;
}
.group:hover .group-hover\:text-primary-border-hover {
      --tw-text-opacity: 1;
      color: rgb(162 128 249 / var(--tw-text-opacity));
}
.group:hover .group-hover\:text-primary-color-hover {
      --tw-text-opacity: 1;
      color: rgb(162 128 249 / var(--tw-text-opacity));
}
@media not all and (min-width: 1024px) {
      .max-lg\:mx-auto {
            margin-left: auto;
            margin-right: auto;
      }
}
@media not all and (min-width: 500px) {
      .max-pcMin\:flex {
            display: flex;
      }
      .max-pcMin\:justify-end {
            justify-content: flex-end;
      }
}
@media (min-width: 500px) {
      .pcMin\:-top-20 {
            top: -5rem;
      }
      .pcMin\:mb-\[64px\] {
            margin-bottom: 64px;
      }
      .pcMin\:mt-16 {
            margin-top: 4rem;
      }
      .pcMin\:inline-block {
            display: inline-block;
      }
      .pcMin\:h-\[208px\] {
            height: 208px;
      }
      .pcMin\:w-full {
            width: 100%;
      }
      .pcMin\:min-w-\[500px\] {
            min-width: 500px;
      }
      .pcMin\:max-w-\[206px\] {
            max-width: 206px;
      }
      .pcMin\:gap-4 {
            gap: 1rem;
      }
      .pcMin\:p-\[31px\] {
            padding: 31px;
      }
      .pcMin\:px-10 {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
      }
      .pcMin\:text-\[22px\] {
            font-size: 22px;
      }
      .pcMin\:text-\[32px\] {
            font-size: 32px;
      }
      .pcMin\:text-\[48px\] {
            font-size: 48px;
      }
      .pcMin\:text-xl {
            font-size: 1.25rem;
            line-height: 1.75rem;
      }
      .pcMin\:leading-\[30px\] {
            line-height: 30px;
      }
      .pcMin\:leading-\[40px\] {
            line-height: 40px;
      }
      .pcMin\:leading-\[64px\] {
            line-height: 64px;
      }
}
@media (min-width: 640px) {
      .sm\:grid-cols-1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
      }
      .sm\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }
}
@media (min-width: 768px) {
      .md\:-bottom-\[278px\] {
            bottom: -278px;
      }
      .md\:\!ml-4 {
            margin-left: 1rem !important;
      }
      .md\:mb-0 {
            margin-bottom: 0px;
      }
      .md\:ml-\[16px\] {
            margin-left: 16px;
      }
      .md\:ml-\[8px\] {
            margin-left: 8px;
      }
      .md\:mt-0 {
            margin-top: 0px;
      }
      .md\:flex {
            display: flex;
      }
      .md\:hidden {
            display: none;
      }
      .md\:h-9 {
            height: 2.25rem;
      }
      .md\:\!w-\[280px\] {
            width: 280px !important;
      }
      .md\:\!flex-none {
            flex: none !important;
      }
      .md\:flex-1 {
            flex: 1 1 0%;
      }
      .md\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .md\:\!flex-row {
            flex-direction: row !important;
      }
      .md\:flex-row {
            flex-direction: row;
      }
      .md\:flex-col {
            flex-direction: column;
      }
      .md\:items-center {
            align-items: center;
      }
      .md\:justify-between {
            justify-content: space-between;
      }
      .md\:gap-4 {
            gap: 1rem;
      }
      .md\:gap-\[2px\] {
            gap: 2px;
      }
      .md\:border-view-more-border {
            border-color: #FFFFFF1A;
      }
      .md\:text-5xl {
            font-size: 3rem;
            line-height: 1;
      }
      .md\:leading-\[56px\] {
            line-height: 56px;
      }
}
@media (min-width: 1024px) {
      .lg\:mx-auto {
            margin-left: auto;
            margin-right: auto;
      }
      .lg\:my-0 {
            margin-top: 0px;
            margin-bottom: 0px;
      }
      .lg\:my-\[32px\] {
            margin-top: 32px;
            margin-bottom: 32px;
      }
      .lg\:ml-12 {
            margin-left: 3rem;
      }
      .lg\:mt-\[-8px\] {
            margin-top: -8px;
      }
      .lg\:inline-block {
            display: inline-block;
      }
      .lg\:hidden {
            display: none;
      }
      .lg\:h-\[610px\] {
            height: 610px;
      }
      .lg\:h-\[698px\] {
            height: 698px;
      }
      .lg\:w-\[100px\] {
            width: 100px;
      }
      .lg\:w-\[93px\] {
            width: 93px;
      }
      .lg\:w-\[96px\] {
            width: 96px;
      }
      .lg\:max-w-\[438px\] {
            max-width: 438px;
      }
      .lg\:flex-1 {
            flex: 1 1 0%;
      }
      .lg\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .lg\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .lg\:flex-row {
            flex-direction: row;
      }
      .lg\:items-center {
            align-items: center;
      }
      .lg\:justify-between {
            justify-content: space-between;
      }
      .lg\:gap-4 {
            gap: 1rem;
      }
      .lg\:bg-nav-search-bg {
            --tw-bg-opacity: 1;
            background-color: rgb(24 22 30 / var(--tw-bg-opacity));
      }
      .lg\:pb-16 {
            padding-bottom: 4rem;
      }
      .lg\:text-right {
            text-align: right;
      }
      .lg\:text-2xl {
            font-size: 1.5rem;
            line-height: 2rem;
      }
      .lg\:text-7xl {
            font-size: 4.5rem;
            line-height: 1;
      }
      .lg\:leading-\[34px\] {
            line-height: 34px;
      }
      .lg\:leading-\[82px\] {
            line-height: 82px;
      }
}
@media (min-width: 1280px) {
      .xl\:-bottom-\[221px\] {
            bottom: -221px;
      }
      .xl\:mt-\[48px\] {
            margin-top: 48px;
      }
      .xl\:block {
            display: block;
      }
      .xl\:inline-block {
            display: inline-block;
      }
      .xl\:hidden {
            display: none;
      }
      .xl\:w-\[500px\] {
            width: 500px;
      }
      .xl\:max-w-\[484px\] {
            max-width: 484px;
      }
      .xl\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .xl\:grid-cols-4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      .xl\:gap-\[35px\] {
            gap: 35px;
      }
      .xl\:border-0 {
            border-width: 0px;
      }
      .xl\:py-0 {
            padding-top: 0px;
            padding-bottom: 0px;
      }
      .xl\:pb-8 {
            padding-bottom: 2rem;
      }
      .xl\:pt-8 {
            padding-top: 2rem;
      }
}
@media (min-width: 1440px) {
      .pc\:max-w-\[644px\] {
            max-width: 644px;
      }
      .pc\:gap-\[24px\] {
            gap: 24px;
      }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html {
  --elf-primary-color: #8B60F7;
  --elf-primary-color-hover: #a280f9;
  --elf-primary-color-active: #8256f2;
  --elf-primary-color-outline: rgba(24, 144, 255, 0.2);
  --elf-primary-1: #e6f7ff;
  --elf-primary-2: #bae7ff;
  --elf-primary-3: #91d5ff;
  --elf-primary-4: #69c0ff;
  --elf-primary-5: #a280f9;
  --elf-primary-6: #8B60F7;
  --elf-primary-7: #096dd9;
  --elf-primary-color-deprecated-pure: ;
  --elf-primary-color-deprecated-l-35: #cbe6ff;
  --elf-primary-color-deprecated-l-20: #7ec1ff;
  --elf-primary-color-deprecated-t-20: #46a6ff;
  --elf-primary-color-deprecated-t-50: #8cc8ff;
  --elf-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --elf-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --elf-primary-color-active-deprecated-d-02: #dcf4ff;
  --elf-success-color: #52c41a;
  --elf-success-color-hover: #73d13d;
  --elf-success-color-active: #389e0d;
  --elf-success-color-outline: rgba(82, 196, 26, 0.2);
  --elf-success-color-deprecated-bg: #f6ffed;
  --elf-success-color-deprecated-border: #b7eb8f;
  --elf-error-color: #ff4d4f;
  --elf-error-color-hover: #ff7875;
  --elf-error-color-active: #d9363e;
  --elf-error-color-outline: rgba(255, 77, 79, 0.2);
  --elf-error-color-deprecated-bg: #fff2f0;
  --elf-error-color-deprecated-border: #ffccc7;
  --elf-warning-color: #faad14;
  --elf-warning-color-hover: #ffc53d;
  --elf-warning-color-active: #d48806;
  --elf-warning-color-outline: rgba(250, 173, 20, 0.2);
  --elf-warning-color-deprecated-bg: #fffbe6;
  --elf-warning-color-deprecated-border: #ffe58f;
  --elf-info-color: #8b60f7;
  --elf-info-color-deprecated-bg: #e6f7ff;
  --elf-info-color-deprecated-border: #91d5ff;
}
[class^='elf-']::-ms-clear,
[class*='elf-']::-ms-clear,
[class^='elf-'] input::-ms-clear,
[class*='elf-'] input::-ms-clear,
[class^='elf-'] input::-ms-reveal,
[class*='elf-'] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: var(--elf-primary-color);
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: var(--elf-primary-color-hover);
}
a:active {
  color: var(--elf-primary-color-active);
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: var(--elf-primary-color);
}
::selection {
  color: #fff;
  background: var(--elf-primary-color);
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.elf-fade-enter,
.elf-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-fade-enter.elf-fade-enter-active,
.elf-fade-appear.elf-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.elf-fade-leave.elf-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-fade-enter,
.elf-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.elf-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.elf-move-up-enter,
.elf-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-move-up-enter.elf-move-up-enter-active,
.elf-move-up-appear.elf-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.elf-move-up-leave.elf-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-move-up-enter,
.elf-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.elf-move-down-enter,
.elf-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-move-down-enter.elf-move-down-enter-active,
.elf-move-down-appear.elf-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.elf-move-down-leave.elf-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-move-down-enter,
.elf-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.elf-move-left-enter,
.elf-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-move-left-enter.elf-move-left-enter-active,
.elf-move-left-appear.elf-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.elf-move-left-leave.elf-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-move-left-enter,
.elf-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.elf-move-right-enter,
.elf-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-move-right-enter.elf-move-right-enter-active,
.elf-move-right-appear.elf-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.elf-move-right-leave.elf-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-move-right-enter,
.elf-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[elf-click-animating='true'],
[elf-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: var(--elf-primary-color);
  --scroll-bar: 0;
}
[elf-click-animating-without-extra-node='true']::after,
.elf-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 var(--elf-primary-color);
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 var(--elf-primary-color);
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.elf-slide-up-enter,
.elf-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-slide-up-enter.elf-slide-up-enter-active,
.elf-slide-up-appear.elf-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.elf-slide-up-leave.elf-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-slide-up-enter,
.elf-slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.elf-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.elf-slide-down-enter,
.elf-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-slide-down-enter.elf-slide-down-enter-active,
.elf-slide-down-appear.elf-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.elf-slide-down-leave.elf-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-slide-down-enter,
.elf-slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.elf-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.elf-slide-left-enter,
.elf-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-slide-left-enter.elf-slide-left-enter-active,
.elf-slide-left-appear.elf-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.elf-slide-left-leave.elf-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-slide-left-enter,
.elf-slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.elf-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.elf-slide-right-enter,
.elf-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-slide-right-enter.elf-slide-right-enter-active,
.elf-slide-right-appear.elf-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.elf-slide-right-leave.elf-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-slide-right-enter,
.elf-slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.elf-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.elf-zoom-enter,
.elf-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-enter.elf-zoom-enter-active,
.elf-zoom-appear.elf-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.elf-zoom-leave.elf-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-zoom-enter,
.elf-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-zoom-enter-prepare,
.elf-zoom-appear-prepare {
  transform: none;
}
.elf-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-zoom-big-enter,
.elf-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-big-enter.elf-zoom-big-enter-active,
.elf-zoom-big-appear.elf-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.elf-zoom-big-leave.elf-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-zoom-big-enter,
.elf-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-zoom-big-enter-prepare,
.elf-zoom-big-appear-prepare {
  transform: none;
}
.elf-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-zoom-big-fast-enter,
.elf-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-big-fast-enter.elf-zoom-big-fast-enter-active,
.elf-zoom-big-fast-appear.elf-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.elf-zoom-big-fast-leave.elf-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-zoom-big-fast-enter,
.elf-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-zoom-big-fast-enter-prepare,
.elf-zoom-big-fast-appear-prepare {
  transform: none;
}
.elf-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-zoom-up-enter,
.elf-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-up-enter.elf-zoom-up-enter-active,
.elf-zoom-up-appear.elf-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.elf-zoom-up-leave.elf-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-zoom-up-enter,
.elf-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-zoom-up-enter-prepare,
.elf-zoom-up-appear-prepare {
  transform: none;
}
.elf-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-zoom-down-enter,
.elf-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-down-enter.elf-zoom-down-enter-active,
.elf-zoom-down-appear.elf-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.elf-zoom-down-leave.elf-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-zoom-down-enter,
.elf-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-zoom-down-enter-prepare,
.elf-zoom-down-appear-prepare {
  transform: none;
}
.elf-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-zoom-left-enter,
.elf-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-left-enter.elf-zoom-left-enter-active,
.elf-zoom-left-appear.elf-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.elf-zoom-left-leave.elf-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-zoom-left-enter,
.elf-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-zoom-left-enter-prepare,
.elf-zoom-left-appear-prepare {
  transform: none;
}
.elf-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-zoom-right-enter,
.elf-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.elf-zoom-right-enter.elf-zoom-right-enter-active,
.elf-zoom-right-appear.elf-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.elf-zoom-right-leave.elf-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.elf-zoom-right-enter,
.elf-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.elf-zoom-right-enter-prepare,
.elf-zoom-right-appear-prepare {
  transform: none;
}
.elf-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.elf-affix {
  position: fixed;
  z-index: 10;
}
.elf-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}
.elf-alert-content {
  flex: 1;
  min-width: 0;
}
.elf-alert-icon {
  margin-right: 8px;
}
.elf-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.elf-alert-success {
  background-color: var(--elf-success-color-deprecated-bg);
  border: 1px solid var(--elf-success-color-deprecated-border);
}
.elf-alert-success .elf-alert-icon {
  color: var(--elf-success-color);
}
.elf-alert-info {
  background-color: var(--elf-info-color-deprecated-bg);
  border: 1px solid var(--elf-info-color-deprecated-border);
}
.elf-alert-info .elf-alert-icon {
  color: var(--elf-info-color);
}
.elf-alert-warning {
  background-color: var(--elf-warning-color-deprecated-bg);
  border: 1px solid var(--elf-warning-color-deprecated-border);
}
.elf-alert-warning .elf-alert-icon {
  color: var(--elf-warning-color);
}
.elf-alert-error {
  background-color: var(--elf-error-color-deprecated-bg);
  border: 1px solid var(--elf-error-color-deprecated-border);
}
.elf-alert-error .elf-alert-icon {
  color: var(--elf-error-color);
}
.elf-alert-error .elf-alert-description > pre {
  margin: 0;
  padding: 0;
}
.elf-alert-action {
  margin-left: 8px;
}
.elf-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.elf-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.elf-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.elf-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.elf-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.elf-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.elf-alert-with-description.elf-alert-no-icon {
  padding: 15px 15px;
}
.elf-alert-with-description .elf-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.elf-alert-with-description .elf-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.elf-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.elf-alert-with-description .elf-alert-description {
  display: block;
}
.elf-alert.elf-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-alert.elf-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.elf-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.elf-alert.elf-alert-rtl {
  direction: rtl;
}
.elf-alert-rtl .elf-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.elf-alert-rtl .elf-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.elf-alert-rtl .elf-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.elf-alert-rtl.elf-alert-with-description {
  padding-right: 24px;
  padding-left: 15px;
}
.elf-alert-rtl.elf-alert-with-description .elf-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.elf-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding-left: 2px;
}
.elf-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: transparent;
}
.elf-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.elf-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.06);
  content: ' ';
}
.elf-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--elf-primary-color);
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.elf-anchor-ink-ball.elf-anchor-ink-ball-visible {
  display: inline-block;
}
.elf-anchor-fixed .elf-anchor-ink .elf-anchor-ink-ball {
  display: none;
}
.elf-anchor-link {
  padding: 4px 0 4px 16px;
}
.elf-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.elf-anchor-link-title:only-child {
  margin-bottom: 0;
}
.elf-anchor-link-active > .elf-anchor-link-title {
  color: var(--elf-primary-color);
}
.elf-anchor-link .elf-anchor-link {
  padding-top: 2px;
  padding-bottom: 2px;
}
.elf-anchor-rtl {
  direction: rtl;
}
.elf-anchor-rtl.elf-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.elf-anchor-rtl .elf-anchor-ink {
  right: 0;
  left: auto;
}
.elf-anchor-rtl .elf-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.elf-anchor-rtl .elf-anchor-link {
  padding: 4px 16px 4px 0;
}
.elf-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.elf-select-auto-complete .elf-select-clear {
  right: 13px;
}
.elf-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.elf-avatar-image {
  background: transparent;
}
.elf-avatar .elf-image-img {
  display: block;
}
.elf-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.elf-avatar.elf-avatar-icon {
  font-size: 18px;
}
.elf-avatar.elf-avatar-icon > .anticon {
  margin: 0;
}
.elf-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.elf-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.elf-avatar-lg.elf-avatar-icon {
  font-size: 24px;
}
.elf-avatar-lg.elf-avatar-icon > .anticon {
  margin: 0;
}
.elf-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.elf-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.elf-avatar-sm.elf-avatar-icon {
  font-size: 14px;
}
.elf-avatar-sm.elf-avatar-icon > .anticon {
  margin: 0;
}
.elf-avatar-square {
  border-radius: 2px;
}
.elf-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.elf-avatar-group {
  display: inline-flex;
}
.elf-avatar-group .elf-avatar {
  border: 1px solid #fff;
}
.elf-avatar-group .elf-avatar:not(:first-child) {
  margin-left: -8px;
}
.elf-avatar-group-popover .elf-avatar + .elf-avatar {
  margin-left: 3px;
}
.elf-avatar-group-rtl .elf-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.elf-avatar-group-popover.elf-popover-rtl .elf-avatar + .elf-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.elf-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.elf-back-top:empty {
  display: none;
}
.elf-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.elf-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.elf-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.elf-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .elf-back-top {
    right: 60px;
  }
  .elf-back-top-rtl {
    right: auto;
    left: 60px;
  }
}
@media screen and (max-width: 480px) {
  .elf-back-top {
    right: 20px;
  }
  .elf-back-top-rtl {
    right: auto;
    left: 20px;
  }
}
.elf-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  line-height: 1;
}
.elf-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.elf-badge-count a,
.elf-badge-count a:hover {
  color: #fff;
}
.elf-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.elf-badge-multiple-words {
  padding: 0 8px;
}
.elf-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.elf-badge-dot.elf-scroll-number {
  transition: background 1.5s;
}
.elf-badge-count,
.elf-badge-dot,
.elf-badge .elf-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.elf-badge-count.anticon-spin,
.elf-badge-dot.anticon-spin,
.elf-badge .elf-scroll-number-custom-component.anticon-spin {
  animation: antBadgeLoadingCircle 1s infinite linear;
}
.elf-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.elf-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.elf-badge-status-success {
  background-color: var(--elf-success-color);
}
.elf-badge-status-processing {
  position: relative;
  background-color: var(--elf-primary-color);
}
.elf-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--elf-primary-color);
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.elf-badge-status-default {
  background-color: #d9d9d9;
}
.elf-badge-status-error {
  background-color: var(--elf-error-color);
}
.elf-badge-status-warning {
  background-color: var(--elf-warning-color);
}
.elf-badge-status-pink {
  background: #eb2f96;
}
.elf-badge-status-magenta {
  background: #eb2f96;
}
.elf-badge-status-red {
  background: #f5222d;
}
.elf-badge-status-volcano {
  background: #fa541c;
}
.elf-badge-status-orange {
  background: #fa8c16;
}
.elf-badge-status-yellow {
  background: #fadb14;
}
.elf-badge-status-gold {
  background: #faad14;
}
.elf-badge-status-cyan {
  background: #13c2c2;
}
.elf-badge-status-lime {
  background: #a0d911;
}
.elf-badge-status-green {
  background: #52c41a;
}
.elf-badge-status-blue {
  background: #8b60f7;
}
.elf-badge-status-geekblue {
  background: #2f54eb;
}
.elf-badge-status-purple {
  background: #722ed1;
}
.elf-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.elf-badge-zoom-appear,
.elf-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.elf-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.elf-badge-not-a-wrapper .elf-badge-zoom-appear,
.elf-badge-not-a-wrapper .elf-badge-zoom-enter {
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.elf-badge-not-a-wrapper .elf-badge-zoom-leave {
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.elf-badge-not-a-wrapper:not(.elf-badge-status) {
  vertical-align: middle;
}
.elf-badge-not-a-wrapper .elf-scroll-number-custom-component,
.elf-badge-not-a-wrapper .elf-badge-count {
  transform: none;
}
.elf-badge-not-a-wrapper .elf-scroll-number-custom-component,
.elf-badge-not-a-wrapper .elf-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.elf-scroll-number {
  overflow: hidden;
  direction: ltr;
}
.elf-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.elf-scroll-number-only > p.elf-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.elf-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
.elf-ribbon-wrapper {
  position: relative;
}
.elf-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: var(--elf-primary-color);
  border-radius: 2px;
}
.elf-ribbon-text {
  color: #fff;
}
.elf-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentcolor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.elf-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.elf-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.elf-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.elf-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.elf-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.elf-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.elf-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.elf-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.elf-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.elf-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.elf-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.elf-ribbon-color-blue {
  color: #8b60f7;
  background: #8b60f7;
}
.elf-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.elf-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.elf-ribbon.elf-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.elf-ribbon.elf-ribbon-placement-end .elf-ribbon-corner {
  right: 0;
  border-color: currentcolor transparent transparent currentcolor;
}
.elf-ribbon.elf-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.elf-ribbon.elf-ribbon-placement-start .elf-ribbon-corner {
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.elf-badge-rtl {
  direction: rtl;
}
.elf-badge-rtl.elf-badge:not(.elf-badge-not-a-wrapper) .elf-badge-count,
.elf-badge-rtl.elf-badge:not(.elf-badge-not-a-wrapper) .elf-badge-dot,
.elf-badge-rtl.elf-badge:not(.elf-badge-not-a-wrapper) .elf-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.elf-badge-rtl.elf-badge:not(.elf-badge-not-a-wrapper) .elf-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.elf-badge-rtl .elf-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.elf-badge:not(.elf-badge-not-a-wrapper).elf-badge-rtl .elf-badge-zoom-appear,
.elf-badge:not(.elf-badge-not-a-wrapper).elf-badge-rtl .elf-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}
.elf-badge:not(.elf-badge-not-a-wrapper).elf-badge-rtl .elf-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}
.elf-ribbon-rtl {
  direction: rtl;
}
.elf-ribbon-rtl.elf-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.elf-ribbon-rtl.elf-ribbon-placement-end .elf-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.elf-ribbon-rtl.elf-ribbon-placement-end .elf-ribbon-corner::after {
  border-color: currentcolor currentcolor transparent transparent;
}
.elf-ribbon-rtl.elf-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.elf-ribbon-rtl.elf-ribbon-placement-start .elf-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentcolor transparent transparent currentcolor;
}
.elf-ribbon-rtl.elf-ribbon-placement-start .elf-ribbon-corner::after {
  border-color: currentcolor transparent transparent currentcolor;
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.elf-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.elf-breadcrumb .anticon {
  font-size: 14px;
}
.elf-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.elf-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.elf-breadcrumb a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.elf-breadcrumb li:last-child {
  color: rgba(0, 0, 0, 0.85);
}
.elf-breadcrumb li:last-child a {
  color: rgba(0, 0, 0, 0.85);
}
li:last-child > .elf-breadcrumb-separator {
  display: none;
}
.elf-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.elf-breadcrumb-link > .anticon + span,
.elf-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.elf-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.elf-breadcrumb-rtl {
  direction: rtl;
}
.elf-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.elf-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.elf-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.elf-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.elf-breadcrumb-rtl > span {
  float: right;
}
.elf-breadcrumb-rtl .elf-breadcrumb-link > .anticon + span,
.elf-breadcrumb-rtl .elf-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.elf-breadcrumb-rtl .elf-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.elf-btn {
  font-weight: 500;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(139 96 247 / var(--tw-text-opacity));
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 6px 8px;
  font-size: 14px;
  border-radius: 6px;
}
.elf-btn > .anticon {
  line-height: 1;
}
.elf-btn,
.elf-btn:active,
.elf-btn:focus {
  outline: 0;
}
.elf-btn:not([disabled]):hover {
  text-decoration: none;
}
.elf-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.elf-btn[disabled] {
  cursor: not-allowed;
}
.elf-btn[disabled] > * {
  pointer-events: none;
}
.elf-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.elf-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.elf-btn > a:only-child {
  color: currentcolor;
}
.elf-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn:hover,
.elf-btn:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
  background: #fff;
}
.elf-btn:hover > a:only-child,
.elf-btn:focus > a:only-child {
  color: currentcolor;
}
.elf-btn:hover > a:only-child::after,
.elf-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
  background: #fff;
}
.elf-btn:active > a:only-child {
  color: currentcolor;
}
.elf-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn[disabled],
.elf-btn[disabled]:hover,
.elf-btn[disabled]:focus,
.elf-btn[disabled]:active {
  --tw-border-opacity: 1;
  border-color: rgb(33 26 52 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(121 111 148 / var(--tw-text-opacity));
  text-shadow: none;
  box-shadow: none;
}
.elf-btn[disabled] > a:only-child,
.elf-btn[disabled]:hover > a:only-child,
.elf-btn[disabled]:focus > a:only-child,
.elf-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn[disabled] > a:only-child::after,
.elf-btn[disabled]:hover > a:only-child::after,
.elf-btn[disabled]:focus > a:only-child::after,
.elf-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn:hover,
.elf-btn:focus,
.elf-btn:active {
  text-decoration: none;
  background: #fff;
}
.elf-btn > span {
  display: inline-block;
}
.elf-btn-primary {
  color: #fff;
  border-color: var(--elf-primary-color);
  background: var(--elf-primary-color);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.elf-btn-primary > a:only-child {
  color: currentcolor;
}
.elf-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-primary:hover,
.elf-btn-primary:focus {
  color: #fff;
  border-color: var(--elf-primary-color-hover);
  background: var(--elf-primary-color-hover);
}
.elf-btn-primary:hover > a:only-child,
.elf-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-primary:hover > a:only-child::after,
.elf-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-primary:active {
  color: #fff;
  border-color: var(--elf-primary-color-active);
  background: var(--elf-primary-color-active);
}
.elf-btn-primary:active > a:only-child {
  color: currentcolor;
}
.elf-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-primary[disabled],
.elf-btn-primary[disabled]:hover,
.elf-btn-primary[disabled]:focus,
.elf-btn-primary[disabled]:active {
  color: #796f94;
  border-color: #211a34;
  background: #211a34;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-primary[disabled] > a:only-child,
.elf-btn-primary[disabled]:hover > a:only-child,
.elf-btn-primary[disabled]:focus > a:only-child,
.elf-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-primary[disabled] > a:only-child::after,
.elf-btn-primary[disabled]:hover > a:only-child::after,
.elf-btn-primary[disabled]:focus > a:only-child::after,
.elf-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-group .elf-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: var(--elf-primary-5);
  border-left-color: var(--elf-primary-5);
}
.elf-btn-group .elf-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.elf-btn-group .elf-btn-primary:first-child:not(:last-child) {
  border-right-color: var(--elf-primary-5);
}
.elf-btn-group .elf-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.elf-btn-group .elf-btn-primary:last-child:not(:first-child),
.elf-btn-group .elf-btn-primary + .elf-btn-primary {
  border-left-color: var(--elf-primary-5);
}
.elf-btn-group .elf-btn-primary:last-child:not(:first-child)[disabled],
.elf-btn-group .elf-btn-primary + .elf-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.elf-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: transparent;
}
.elf-btn-ghost > a:only-child {
  color: currentcolor;
}
.elf-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-ghost:hover,
.elf-btn-ghost:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
  background: transparent;
}
.elf-btn-ghost:hover > a:only-child,
.elf-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-ghost:hover > a:only-child::after,
.elf-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-ghost:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
  background: transparent;
}
.elf-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.elf-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-ghost[disabled],
.elf-btn-ghost[disabled]:hover,
.elf-btn-ghost[disabled]:focus,
.elf-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-ghost[disabled] > a:only-child,
.elf-btn-ghost[disabled]:hover > a:only-child,
.elf-btn-ghost[disabled]:focus > a:only-child,
.elf-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-ghost[disabled] > a:only-child::after,
.elf-btn-ghost[disabled]:hover > a:only-child::after,
.elf-btn-ghost[disabled]:focus > a:only-child::after,
.elf-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.elf-btn-dashed > a:only-child {
  color: currentcolor;
}
.elf-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dashed:hover,
.elf-btn-dashed:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
  background: #fff;
}
.elf-btn-dashed:hover > a:only-child,
.elf-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-dashed:hover > a:only-child::after,
.elf-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dashed:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
  background: #fff;
}
.elf-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dashed[disabled],
.elf-btn-dashed[disabled]:hover,
.elf-btn-dashed[disabled]:focus,
.elf-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-dashed[disabled] > a:only-child,
.elf-btn-dashed[disabled]:hover > a:only-child,
.elf-btn-dashed[disabled]:focus > a:only-child,
.elf-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dashed[disabled] > a:only-child::after,
.elf-btn-dashed[disabled]:hover > a:only-child::after,
.elf-btn-dashed[disabled]:focus > a:only-child::after,
.elf-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-danger {
  color: #fff;
  border-color: var(--elf-error-color);
  background: var(--elf-error-color);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.elf-btn-danger > a:only-child {
  color: currentcolor;
}
.elf-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-danger:hover,
.elf-btn-danger:focus {
  color: #fff;
  border-color: var(--elf-error-color-hover);
  background: var(--elf-error-color-hover);
}
.elf-btn-danger:hover > a:only-child,
.elf-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-danger:hover > a:only-child::after,
.elf-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-danger:active {
  color: #fff;
  border-color: var(--elf-error-color-active);
  background: var(--elf-error-color-active);
}
.elf-btn-danger:active > a:only-child {
  color: currentcolor;
}
.elf-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-danger[disabled],
.elf-btn-danger[disabled]:hover,
.elf-btn-danger[disabled]:focus,
.elf-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-danger[disabled] > a:only-child,
.elf-btn-danger[disabled]:hover > a:only-child,
.elf-btn-danger[disabled]:focus > a:only-child,
.elf-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-danger[disabled] > a:only-child::after,
.elf-btn-danger[disabled]:hover > a:only-child::after,
.elf-btn-danger[disabled]:focus > a:only-child::after,
.elf-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-link {
  color: var(--elf-primary-color);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.elf-btn-link > a:only-child {
  color: currentcolor;
}
.elf-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-link:hover,
.elf-btn-link:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
  background: transparent;
}
.elf-btn-link:hover > a:only-child,
.elf-btn-link:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-link:hover > a:only-child::after,
.elf-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-link:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
  background: transparent;
}
.elf-btn-link:active > a:only-child {
  color: currentcolor;
}
.elf-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-link[disabled],
.elf-btn-link[disabled]:hover,
.elf-btn-link[disabled]:focus,
.elf-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-link[disabled] > a:only-child,
.elf-btn-link[disabled]:hover > a:only-child,
.elf-btn-link[disabled]:focus > a:only-child,
.elf-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-link[disabled] > a:only-child::after,
.elf-btn-link[disabled]:hover > a:only-child::after,
.elf-btn-link[disabled]:focus > a:only-child::after,
.elf-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-link:hover {
  background: transparent;
}
.elf-btn-link:hover,
.elf-btn-link:focus,
.elf-btn-link:active {
  border-color: transparent;
}
.elf-btn-link[disabled],
.elf-btn-link[disabled]:hover,
.elf-btn-link[disabled]:focus,
.elf-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-link[disabled] > a:only-child,
.elf-btn-link[disabled]:hover > a:only-child,
.elf-btn-link[disabled]:focus > a:only-child,
.elf-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-link[disabled] > a:only-child::after,
.elf-btn-link[disabled]:hover > a:only-child::after,
.elf-btn-link[disabled]:focus > a:only-child::after,
.elf-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.elf-btn-text > a:only-child {
  color: currentcolor;
}
.elf-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-text:hover,
.elf-btn-text:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
  background: transparent;
}
.elf-btn-text:hover > a:only-child,
.elf-btn-text:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-text:hover > a:only-child::after,
.elf-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-text:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
  background: transparent;
}
.elf-btn-text:active > a:only-child {
  color: currentcolor;
}
.elf-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-text[disabled],
.elf-btn-text[disabled]:hover,
.elf-btn-text[disabled]:focus,
.elf-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-text[disabled] > a:only-child,
.elf-btn-text[disabled]:hover > a:only-child,
.elf-btn-text[disabled]:focus > a:only-child,
.elf-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-text[disabled] > a:only-child::after,
.elf-btn-text[disabled]:hover > a:only-child::after,
.elf-btn-text[disabled]:focus > a:only-child::after,
.elf-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-text:hover,
.elf-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.elf-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.elf-btn-text[disabled],
.elf-btn-text[disabled]:hover,
.elf-btn-text[disabled]:focus,
.elf-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-text[disabled] > a:only-child,
.elf-btn-text[disabled]:hover > a:only-child,
.elf-btn-text[disabled]:focus > a:only-child,
.elf-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-text[disabled] > a:only-child::after,
.elf-btn-text[disabled]:hover > a:only-child::after,
.elf-btn-text[disabled]:focus > a:only-child::after,
.elf-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous {
  color: var(--elf-error-color);
  border-color: var(--elf-error-color);
  background: #fff;
}
.elf-btn-dangerous > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous:hover,
.elf-btn-dangerous:focus {
  color: var(--elf-error-color-hover);
  border-color: var(--elf-error-color-hover);
  background: #fff;
}
.elf-btn-dangerous:hover > a:only-child,
.elf-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous:hover > a:only-child::after,
.elf-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous:active {
  color: var(--elf-error-color-active);
  border-color: var(--elf-error-color-active);
  background: #fff;
}
.elf-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous[disabled],
.elf-btn-dangerous[disabled]:hover,
.elf-btn-dangerous[disabled]:focus,
.elf-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-dangerous[disabled] > a:only-child,
.elf-btn-dangerous[disabled]:hover > a:only-child,
.elf-btn-dangerous[disabled]:focus > a:only-child,
.elf-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous[disabled] > a:only-child::after,
.elf-btn-dangerous[disabled]:hover > a:only-child::after,
.elf-btn-dangerous[disabled]:focus > a:only-child::after,
.elf-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-primary {
  color: #fff;
  border-color: var(--elf-error-color);
  background: var(--elf-error-color);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.elf-btn-dangerous.elf-btn-primary > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-primary:hover,
.elf-btn-dangerous.elf-btn-primary:focus {
  color: #fff;
  border-color: var(--elf-error-color-hover);
  background: var(--elf-error-color-hover);
}
.elf-btn-dangerous.elf-btn-primary:hover > a:only-child,
.elf-btn-dangerous.elf-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-primary:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-primary:active {
  color: #fff;
  border-color: var(--elf-error-color-active);
  background: var(--elf-error-color-active);
}
.elf-btn-dangerous.elf-btn-primary:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-primary[disabled],
.elf-btn-dangerous.elf-btn-primary[disabled]:hover,
.elf-btn-dangerous.elf-btn-primary[disabled]:focus,
.elf-btn-dangerous.elf-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-dangerous.elf-btn-primary[disabled] > a:only-child,
.elf-btn-dangerous.elf-btn-primary[disabled]:hover > a:only-child,
.elf-btn-dangerous.elf-btn-primary[disabled]:focus > a:only-child,
.elf-btn-dangerous.elf-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-primary[disabled] > a:only-child::after,
.elf-btn-dangerous.elf-btn-primary[disabled]:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-primary[disabled]:focus > a:only-child::after,
.elf-btn-dangerous.elf-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-link {
  color: var(--elf-error-color);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.elf-btn-dangerous.elf-btn-link > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-link:hover,
.elf-btn-dangerous.elf-btn-link:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
  background: transparent;
}
.elf-btn-dangerous.elf-btn-link:hover > a:only-child,
.elf-btn-dangerous.elf-btn-link:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-link:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-link:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
  background: transparent;
}
.elf-btn-dangerous.elf-btn-link:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-link[disabled],
.elf-btn-dangerous.elf-btn-link[disabled]:hover,
.elf-btn-dangerous.elf-btn-link[disabled]:focus,
.elf-btn-dangerous.elf-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-dangerous.elf-btn-link[disabled] > a:only-child,
.elf-btn-dangerous.elf-btn-link[disabled]:hover > a:only-child,
.elf-btn-dangerous.elf-btn-link[disabled]:focus > a:only-child,
.elf-btn-dangerous.elf-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-link[disabled] > a:only-child::after,
.elf-btn-dangerous.elf-btn-link[disabled]:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-link[disabled]:focus > a:only-child::after,
.elf-btn-dangerous.elf-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-link:hover,
.elf-btn-dangerous.elf-btn-link:focus {
  color: var(--elf-error-color-hover);
  border-color: transparent;
  background: transparent;
}
.elf-btn-dangerous.elf-btn-link:hover > a:only-child,
.elf-btn-dangerous.elf-btn-link:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-link:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-link:active {
  color: var(--elf-error-color-active);
  border-color: transparent;
  background: transparent;
}
.elf-btn-dangerous.elf-btn-link:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-link[disabled],
.elf-btn-dangerous.elf-btn-link[disabled]:hover,
.elf-btn-dangerous.elf-btn-link[disabled]:focus,
.elf-btn-dangerous.elf-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-dangerous.elf-btn-link[disabled] > a:only-child,
.elf-btn-dangerous.elf-btn-link[disabled]:hover > a:only-child,
.elf-btn-dangerous.elf-btn-link[disabled]:focus > a:only-child,
.elf-btn-dangerous.elf-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-link[disabled] > a:only-child::after,
.elf-btn-dangerous.elf-btn-link[disabled]:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-link[disabled]:focus > a:only-child::after,
.elf-btn-dangerous.elf-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-text {
  color: var(--elf-error-color);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.elf-btn-dangerous.elf-btn-text > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-text:hover,
.elf-btn-dangerous.elf-btn-text:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
  background: transparent;
}
.elf-btn-dangerous.elf-btn-text:hover > a:only-child,
.elf-btn-dangerous.elf-btn-text:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-text:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-text:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
  background: transparent;
}
.elf-btn-dangerous.elf-btn-text:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-text[disabled],
.elf-btn-dangerous.elf-btn-text[disabled]:hover,
.elf-btn-dangerous.elf-btn-text[disabled]:focus,
.elf-btn-dangerous.elf-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-dangerous.elf-btn-text[disabled] > a:only-child,
.elf-btn-dangerous.elf-btn-text[disabled]:hover > a:only-child,
.elf-btn-dangerous.elf-btn-text[disabled]:focus > a:only-child,
.elf-btn-dangerous.elf-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-text[disabled] > a:only-child::after,
.elf-btn-dangerous.elf-btn-text[disabled]:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-text[disabled]:focus > a:only-child::after,
.elf-btn-dangerous.elf-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-text:hover,
.elf-btn-dangerous.elf-btn-text:focus {
  color: var(--elf-error-color-hover);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.elf-btn-dangerous.elf-btn-text:hover > a:only-child,
.elf-btn-dangerous.elf-btn-text:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-text:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-text:active {
  color: var(--elf-error-color-active);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.elf-btn-dangerous.elf-btn-text:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-dangerous.elf-btn-text[disabled],
.elf-btn-dangerous.elf-btn-text[disabled]:hover,
.elf-btn-dangerous.elf-btn-text[disabled]:focus,
.elf-btn-dangerous.elf-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-dangerous.elf-btn-text[disabled] > a:only-child,
.elf-btn-dangerous.elf-btn-text[disabled]:hover > a:only-child,
.elf-btn-dangerous.elf-btn-text[disabled]:focus > a:only-child,
.elf-btn-dangerous.elf-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-dangerous.elf-btn-text[disabled] > a:only-child::after,
.elf-btn-dangerous.elf-btn-text[disabled]:hover > a:only-child::after,
.elf-btn-dangerous.elf-btn-text[disabled]:focus > a:only-child::after,
.elf-btn-dangerous.elf-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.elf-btn-icon-only > * {
  font-size: 16px;
}
.elf-btn-icon-only.elf-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.elf-btn-icon-only.elf-btn-lg > * {
  font-size: 18px;
}
.elf-btn-icon-only.elf-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.elf-btn-icon-only.elf-btn-sm > * {
  font-size: 14px;
}
.elf-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.elf-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.elf-btn-icon-only {
  vertical-align: -1px;
}
a.elf-btn-icon-only > .anticon {
  display: inline;
}
.elf-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.elf-btn-round.elf-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.elf-btn-round.elf-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.elf-btn-round.elf-btn-icon-only {
  width: auto;
}
.elf-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.elf-btn-circle.elf-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.elf-btn-circle.elf-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.elf-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.elf-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-btn .anticon.anticon-plus > svg,
.elf-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.elf-btn.elf-btn-loading {
  position: relative;
  cursor: default;
}
.elf-btn.elf-btn-loading::before {
  display: block;
}
.elf-btn > .elf-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-btn > .elf-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.elf-btn > .elf-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.elf-btn-group {
  position: relative;
  display: inline-flex;
}
.elf-btn-group > .elf-btn,
.elf-btn-group > span > .elf-btn {
  position: relative;
}
.elf-btn-group > .elf-btn:hover,
.elf-btn-group > span > .elf-btn:hover,
.elf-btn-group > .elf-btn:focus,
.elf-btn-group > span > .elf-btn:focus,
.elf-btn-group > .elf-btn:active,
.elf-btn-group > span > .elf-btn:active {
  z-index: 2;
}
.elf-btn-group > .elf-btn[disabled],
.elf-btn-group > span > .elf-btn[disabled] {
  z-index: 0;
}
.elf-btn-group .elf-btn-icon-only {
  font-size: 14px;
}
.elf-btn-group .elf-btn + .elf-btn,
.elf-btn + .elf-btn-group,
.elf-btn-group span + .elf-btn,
.elf-btn-group .elf-btn + span,
.elf-btn-group > span + span,
.elf-btn-group + .elf-btn,
.elf-btn-group + .elf-btn-group {
  margin-left: -1px;
}
.elf-btn-group .elf-btn-primary + .elf-btn:not(.elf-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.elf-btn-group .elf-btn {
  border-radius: 0;
}
.elf-btn-group > .elf-btn:first-child,
.elf-btn-group > span:first-child > .elf-btn {
  margin-left: 0;
}
.elf-btn-group > .elf-btn:only-child {
  border-radius: 2px;
}
.elf-btn-group > span:only-child > .elf-btn {
  border-radius: 2px;
}
.elf-btn-group > .elf-btn:first-child:not(:last-child),
.elf-btn-group > span:first-child:not(:last-child) > .elf-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.elf-btn-group > .elf-btn:last-child:not(:first-child),
.elf-btn-group > span:last-child:not(:first-child) > .elf-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.elf-btn-group-sm > .elf-btn:only-child {
  border-radius: 2px;
}
.elf-btn-group-sm > span:only-child > .elf-btn {
  border-radius: 2px;
}
.elf-btn-group-sm > .elf-btn:first-child:not(:last-child),
.elf-btn-group-sm > span:first-child:not(:last-child) > .elf-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.elf-btn-group-sm > .elf-btn:last-child:not(:first-child),
.elf-btn-group-sm > span:last-child:not(:first-child) > .elf-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.elf-btn-group > .elf-btn-group {
  float: left;
}
.elf-btn-group > .elf-btn-group:not(:first-child):not(:last-child) > .elf-btn {
  border-radius: 0;
}
.elf-btn-group > .elf-btn-group:first-child:not(:last-child) > .elf-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-btn-group > .elf-btn-group:last-child:not(:first-child) > .elf-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-btn-rtl.elf-btn-group .elf-btn + .elf-btn,
.elf-btn-rtl.elf-btn + .elf-btn-group,
.elf-btn-rtl.elf-btn-group span + .elf-btn,
.elf-btn-rtl.elf-btn-group .elf-btn + span,
.elf-btn-rtl.elf-btn-group > span + span,
.elf-btn-rtl.elf-btn-group + .elf-btn,
.elf-btn-rtl.elf-btn-group + .elf-btn-group,
.elf-btn-group-rtl.elf-btn-group .elf-btn + .elf-btn,
.elf-btn-group-rtl.elf-btn + .elf-btn-group,
.elf-btn-group-rtl.elf-btn-group span + .elf-btn,
.elf-btn-group-rtl.elf-btn-group .elf-btn + span,
.elf-btn-group-rtl.elf-btn-group > span + span,
.elf-btn-group-rtl.elf-btn-group + .elf-btn,
.elf-btn-group-rtl.elf-btn-group + .elf-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.elf-btn-group.elf-btn-group-rtl {
  direction: rtl;
}
.elf-btn-group-rtl.elf-btn-group > .elf-btn:first-child:not(:last-child),
.elf-btn-group-rtl.elf-btn-group > span:first-child:not(:last-child) > .elf-btn {
  border-radius: 0 2px 2px 0;
}
.elf-btn-group-rtl.elf-btn-group > .elf-btn:last-child:not(:first-child),
.elf-btn-group-rtl.elf-btn-group > span:last-child:not(:first-child) > .elf-btn {
  border-radius: 2px 0 0 2px;
}
.elf-btn-group-rtl.elf-btn-group-sm > .elf-btn:first-child:not(:last-child),
.elf-btn-group-rtl.elf-btn-group-sm > span:first-child:not(:last-child) > .elf-btn {
  border-radius: 0 2px 2px 0;
}
.elf-btn-group-rtl.elf-btn-group-sm > .elf-btn:last-child:not(:first-child),
.elf-btn-group-rtl.elf-btn-group-sm > span:last-child:not(:first-child) > .elf-btn {
  border-radius: 2px 0 0 2px;
}
.elf-btn:focus > span,
.elf-btn:active > span {
  position: relative;
}
.elf-btn > .anticon + span,
.elf-btn > span + .anticon {
  margin-left: 8px;
}
.elf-btn.elf-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.elf-btn.elf-btn-background-ghost,
.elf-btn.elf-btn-background-ghost:hover,
.elf-btn.elf-btn-background-ghost:active,
.elf-btn.elf-btn-background-ghost:focus {
  background: transparent;
}
.elf-btn.elf-btn-background-ghost:hover,
.elf-btn.elf-btn-background-ghost:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
}
.elf-btn.elf-btn-background-ghost:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
}
.elf-btn.elf-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.elf-btn-background-ghost.elf-btn-primary {
  color: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
  text-shadow: none;
}
.elf-btn-background-ghost.elf-btn-primary > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-primary:hover,
.elf-btn-background-ghost.elf-btn-primary:focus {
  color: var(--elf-primary-color-hover);
  border-color: var(--elf-primary-color-hover);
  background: transparent;
}
.elf-btn-background-ghost.elf-btn-primary:hover > a:only-child,
.elf-btn-background-ghost.elf-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-primary:hover > a:only-child::after,
.elf-btn-background-ghost.elf-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-primary:active {
  color: var(--elf-primary-color-active);
  border-color: var(--elf-primary-color-active);
  background: transparent;
}
.elf-btn-background-ghost.elf-btn-primary:active > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-primary[disabled],
.elf-btn-background-ghost.elf-btn-primary[disabled]:hover,
.elf-btn-background-ghost.elf-btn-primary[disabled]:focus,
.elf-btn-background-ghost.elf-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-background-ghost.elf-btn-primary[disabled] > a:only-child,
.elf-btn-background-ghost.elf-btn-primary[disabled]:hover > a:only-child,
.elf-btn-background-ghost.elf-btn-primary[disabled]:focus > a:only-child,
.elf-btn-background-ghost.elf-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-primary[disabled] > a:only-child::after,
.elf-btn-background-ghost.elf-btn-primary[disabled]:hover > a:only-child::after,
.elf-btn-background-ghost.elf-btn-primary[disabled]:focus > a:only-child::after,
.elf-btn-background-ghost.elf-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-danger {
  color: var(--elf-error-color);
  border-color: var(--elf-error-color);
  text-shadow: none;
}
.elf-btn-background-ghost.elf-btn-danger > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-danger:hover,
.elf-btn-background-ghost.elf-btn-danger:focus {
  color: var(--elf-error-color-hover);
  border-color: var(--elf-error-color-hover);
  background: transparent;
}
.elf-btn-background-ghost.elf-btn-danger:hover > a:only-child,
.elf-btn-background-ghost.elf-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-danger:hover > a:only-child::after,
.elf-btn-background-ghost.elf-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-danger:active {
  color: var(--elf-error-color-active);
  border-color: var(--elf-error-color-active);
  background: transparent;
}
.elf-btn-background-ghost.elf-btn-danger:active > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-danger[disabled],
.elf-btn-background-ghost.elf-btn-danger[disabled]:hover,
.elf-btn-background-ghost.elf-btn-danger[disabled]:focus,
.elf-btn-background-ghost.elf-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-background-ghost.elf-btn-danger[disabled] > a:only-child,
.elf-btn-background-ghost.elf-btn-danger[disabled]:hover > a:only-child,
.elf-btn-background-ghost.elf-btn-danger[disabled]:focus > a:only-child,
.elf-btn-background-ghost.elf-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-danger[disabled] > a:only-child::after,
.elf-btn-background-ghost.elf-btn-danger[disabled]:hover > a:only-child::after,
.elf-btn-background-ghost.elf-btn-danger[disabled]:focus > a:only-child::after,
.elf-btn-background-ghost.elf-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-dangerous {
  color: var(--elf-error-color);
  border-color: var(--elf-error-color);
  text-shadow: none;
}
.elf-btn-background-ghost.elf-btn-dangerous > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-dangerous:hover,
.elf-btn-background-ghost.elf-btn-dangerous:focus {
  color: var(--elf-error-color-hover);
  border-color: var(--elf-error-color-hover);
  background: transparent;
}
.elf-btn-background-ghost.elf-btn-dangerous:hover > a:only-child,
.elf-btn-background-ghost.elf-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-dangerous:hover > a:only-child::after,
.elf-btn-background-ghost.elf-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-dangerous:active {
  color: var(--elf-error-color-active);
  border-color: var(--elf-error-color-active);
  background: transparent;
}
.elf-btn-background-ghost.elf-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-dangerous[disabled],
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:hover,
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:focus,
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-background-ghost.elf-btn-dangerous[disabled] > a:only-child,
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:hover > a:only-child,
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:focus > a:only-child,
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-dangerous[disabled] > a:only-child::after,
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:hover > a:only-child::after,
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:focus > a:only-child::after,
.elf-btn-background-ghost.elf-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link {
  color: var(--elf-error-color);
  border-color: transparent;
  text-shadow: none;
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:hover,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:focus {
  color: var(--elf-error-color-active);
  border-color: transparent;
  background: transparent;
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:hover > a:only-child,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:focus > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:hover > a:only-child::after,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:active {
  color: var(--elf-error-color-active);
  border-color: transparent;
  background: transparent;
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:active > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled],
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:hover,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:focus,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled] > a:only-child,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:hover > a:only-child,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:focus > a:only-child,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled] > a:only-child::after,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:hover > a:only-child::after,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:focus > a:only-child::after,
.elf-btn-background-ghost.elf-btn-dangerous.elf-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.elf-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.elf-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.elf-btn.elf-btn-block {
  width: 100%;
}
.elf-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.elf-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}
a.elf-btn-disabled {
  cursor: not-allowed;
}
a.elf-btn-disabled > * {
  pointer-events: none;
}
a.elf-btn-disabled,
a.elf-btn-disabled:hover,
a.elf-btn-disabled:focus,
a.elf-btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.elf-btn-disabled > a:only-child,
a.elf-btn-disabled:hover > a:only-child,
a.elf-btn-disabled:focus > a:only-child,
a.elf-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.elf-btn-disabled > a:only-child::after,
a.elf-btn-disabled:hover > a:only-child::after,
a.elf-btn-disabled:focus > a:only-child::after,
a.elf-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.elf-btn-lg {
  line-height: 38px;
}
a.elf-btn-sm {
  line-height: 22px;
}
.elf-btn-compact-item:not(.elf-btn-compact-last-item):not(.elf-btn-compact-item-rtl) {
  margin-right: -1px;
}
.elf-btn-compact-item:not(.elf-btn-compact-last-item).elf-btn-compact-item-rtl {
  margin-left: -1px;
}
.elf-btn-compact-item:hover,
.elf-btn-compact-item:focus,
.elf-btn-compact-item:active {
  z-index: 2;
}
.elf-btn-compact-item[disabled] {
  z-index: 0;
}
.elf-btn-compact-item:not(.elf-btn-compact-first-item):not(.elf-btn-compact-last-item).elf-btn {
  border-radius: 0;
}
.elf-btn-compact-item.elf-btn.elf-btn-compact-first-item:not(.elf-btn-compact-last-item):not(
    .elf-btn-compact-item-rtl
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-btn-compact-item.elf-btn.elf-btn-compact-last-item:not(.elf-btn-compact-first-item):not(
    .elf-btn-compact-item-rtl
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-btn-compact-item.elf-btn.elf-btn-compact-item-rtl.elf-btn-compact-first-item:not(.elf-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-btn-compact-item.elf-btn.elf-btn-compact-item-rtl.elf-btn-compact-last-item:not(.elf-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-btn-icon-only.elf-btn-compact-item {
  flex: none;
}
.elf-btn-compact-item.elf-btn-primary:not([disabled]) + .elf-btn-compact-item.elf-btn-primary:not([disabled]) {
  position: relative;
}
.elf-btn-compact-item.elf-btn-primary:not([disabled]) + .elf-btn-compact-item.elf-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: 1px;
  height: calc(100% + 1px * 2);
  background-color: var(--elf-primary-5);
  content: ' ';
}
.elf-btn-compact-item-rtl.elf-btn-compact-first-item.elf-btn-compact-item-rtl:not(.elf-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-btn-compact-item-rtl.elf-btn-compact-last-item.elf-btn-compact-item-rtl:not(.elf-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-btn-compact-item-rtl.elf-btn-sm.elf-btn-compact-first-item.elf-btn-compact-item-rtl.elf-btn-sm:not(
    .elf-btn-compact-last-item
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-btn-compact-item-rtl.elf-btn-sm.elf-btn-compact-last-item.elf-btn-compact-item-rtl.elf-btn-sm:not(
    .elf-btn-compact-first-item
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-btn-compact-item-rtl.elf-btn-primary:not([disabled])
  + .elf-btn-compact-item-rtl.elf-btn-primary:not([disabled])::after {
  right: -1px;
}
.elf-btn-compact-vertical-item:not(.elf-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}
.elf-btn-compact-vertical-item:hover,
.elf-btn-compact-vertical-item:focus,
.elf-btn-compact-vertical-item:active {
  z-index: 2;
}
.elf-btn-compact-vertical-item[disabled] {
  z-index: 0;
}
.elf-btn-compact-vertical-item:not(.elf-btn-compact-vertical-first-item):not(.elf-btn-compact-vertical-last-item) {
  border-radius: 0;
}
.elf-btn-compact-vertical-item.elf-btn-compact-vertical-first-item:not(.elf-btn-compact-vertical-last-item) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-btn-compact-vertical-item.elf-btn-compact-vertical-last-item:not(.elf-btn-compact-vertical-first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.elf-btn-compact-vertical-item.elf-btn-primary:not([disabled])
  + .elf-btn-compact-vertical-item.elf-btn-primary:not([disabled]) {
  position: relative;
}
.elf-btn-compact-vertical-item.elf-btn-primary:not([disabled])
  + .elf-btn-compact-vertical-item.elf-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: calc(100% + 1px * 2);
  height: 1px;
  background-color: var(--elf-primary-5);
  content: ' ';
}
.elf-btn-rtl {
  direction: rtl;
}
.elf-btn-group-rtl.elf-btn-group .elf-btn-primary:last-child:not(:first-child),
.elf-btn-group-rtl.elf-btn-group .elf-btn-primary + .elf-btn-primary {
  border-right-color: var(--elf-primary-5);
  border-left-color: #d9d9d9;
}
.elf-btn-group-rtl.elf-btn-group .elf-btn-primary:last-child:not(:first-child)[disabled],
.elf-btn-group-rtl.elf-btn-group .elf-btn-primary + .elf-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: var(--elf-primary-5);
}
.elf-btn-rtl.elf-btn > .elf-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.elf-btn-rtl.elf-btn > .anticon + span,
.elf-btn-rtl.elf-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.elf-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
}
.elf-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.elf-picker-calendar-header .elf-picker-calendar-year-select {
  min-width: 80px;
}
.elf-picker-calendar-header .elf-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.elf-picker-calendar-header .elf-picker-calendar-mode-switch {
  margin-left: 8px;
}
.elf-picker-calendar .elf-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0;
}
.elf-picker-calendar .elf-picker-panel .elf-picker-month-panel,
.elf-picker-calendar .elf-picker-panel .elf-picker-date-panel {
  width: auto;
}
.elf-picker-calendar .elf-picker-panel .elf-picker-body {
  padding: 8px 0;
}
.elf-picker-calendar .elf-picker-panel .elf-picker-content {
  width: 100%;
}
.elf-picker-calendar-mini {
  border-radius: 2px;
}
.elf-picker-calendar-mini .elf-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.elf-picker-calendar-mini .elf-picker-panel {
  border-radius: 0 0 2px 2px;
}
.elf-picker-calendar-mini .elf-picker-content {
  height: 256px;
}
.elf-picker-calendar-mini .elf-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.elf-picker-calendar-mini .elf-picker-cell::before {
  pointer-events: none;
}
.elf-picker-calendar-full .elf-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-body th,
.elf-picker-calendar-full .elf-picker-panel .elf-picker-body td {
  padding: 0;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-cell::before {
  display: none;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-cell:hover .elf-picker-calendar-date {
  background: #f5f5f5;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-cell .elf-picker-calendar-date-today::before {
  display: none;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-cell-selected .elf-picker-calendar-date,
.elf-picker-calendar-full .elf-picker-panel .elf-picker-cell-selected:hover .elf-picker-calendar-date,
.elf-picker-calendar-full .elf-picker-panel .elf-picker-cell-selected .elf-picker-calendar-date-today,
.elf-picker-calendar-full .elf-picker-panel .elf-picker-cell-selected:hover .elf-picker-calendar-date-today {
  background: var(--elf-primary-1);
}
.elf-picker-calendar-full
  .elf-picker-panel
  .elf-picker-cell-selected
  .elf-picker-calendar-date
  .elf-picker-calendar-date-value,
.elf-picker-calendar-full
  .elf-picker-panel
  .elf-picker-cell-selected:hover
  .elf-picker-calendar-date
  .elf-picker-calendar-date-value,
.elf-picker-calendar-full
  .elf-picker-panel
  .elf-picker-cell-selected
  .elf-picker-calendar-date-today
  .elf-picker-calendar-date-value,
.elf-picker-calendar-full
  .elf-picker-panel
  .elf-picker-cell-selected:hover
  .elf-picker-calendar-date-today
  .elf-picker-calendar-date-value {
  color: var(--elf-primary-color);
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.06);
  border-radius: 0;
  transition: background 0.3s;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-calendar-date-today {
  border-color: var(--elf-primary-color);
}
.elf-picker-calendar-full .elf-picker-panel .elf-picker-calendar-date-today .elf-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 480px) {
  .elf-picker-calendar-header {
    display: block;
  }
  .elf-picker-calendar-header .elf-picker-calendar-year-select {
    width: 50%;
  }
  .elf-picker-calendar-header .elf-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .elf-picker-calendar-header .elf-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .elf-picker-calendar-header .elf-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.elf-picker-calendar-rtl {
  direction: rtl;
}
.elf-picker-calendar-rtl .elf-picker-calendar-header .elf-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.elf-picker-calendar-rtl .elf-picker-calendar-header .elf-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.elf-picker-calendar-rtl.elf-picker-calendar-full .elf-picker-panel {
  text-align: left;
}
.elf-picker-calendar-rtl.elf-picker-calendar-full .elf-picker-panel .elf-picker-body th {
  padding: 0 0 5px 12px;
}
.elf-picker-calendar-rtl.elf-picker-calendar-full .elf-picker-panel .elf-picker-calendar-date-content {
  text-align: right;
}
.elf-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 2px;
}
.elf-card-rtl {
  direction: rtl;
}
.elf-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.elf-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.elf-card-bordered {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px 2px 0 0;
}
.elf-card-head::before {
  display: table;
  content: '';
}
.elf-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.elf-card-head::before {
  display: table;
  content: '';
}
.elf-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.elf-card-head-wrapper {
  display: flex;
  align-items: center;
}
.elf-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-card-head-title > .elf-typography,
.elf-card-head-title > .elf-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.elf-card-head .elf-tabs-top {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.elf-card-head .elf-tabs-top-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-card-extra {
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.elf-card-rtl .elf-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.elf-card-body {
  padding: 24px;
}
.elf-card-body::before {
  display: table;
  content: '';
}
.elf-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.elf-card-body::before {
  display: table;
  content: '';
}
.elf-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.elf-card-contain-grid .elf-card-body {
  display: flex;
  flex-wrap: wrap;
}
.elf-card-contain-grid:not(.elf-card-loading) .elf-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.elf-card-grid {
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06), 1px 1px 0 0 rgba(0, 0, 0, 0.06),
    1px 0 0 0 rgba(0, 0, 0, 0.06) inset, 0 1px 0 0 rgba(0, 0, 0, 0.06) inset;
  transition: all 0.3s;
}
.elf-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.elf-card-contain-tabs > .elf-card-head .elf-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.elf-card-contain-tabs > .elf-card-head .elf-card-extra {
  padding-bottom: 0;
}
.elf-card-bordered .elf-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.elf-card-cover > * {
  display: block;
  width: 100%;
}
.elf-card-cover img {
  border-radius: 2px 2px 0 0;
}
.elf-card-actions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-card-actions::before {
  display: table;
  content: '';
}
.elf-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.elf-card-actions::before {
  display: table;
  content: '';
}
.elf-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.elf-card-actions > li {
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.elf-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.elf-card-actions > li > span:hover {
  color: var(--elf-primary-color);
  transition: color 0.3s;
}
.elf-card-actions > li > span a:not(.elf-btn),
.elf-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.elf-card-actions > li > span a:not(.elf-btn):hover,
.elf-card-actions > li > span > .anticon:hover {
  color: var(--elf-primary-color);
}
.elf-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.elf-card-actions > li:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-card-rtl .elf-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-card-type-inner .elf-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.elf-card-type-inner .elf-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.elf-card-type-inner .elf-card-body {
  padding: 16px 24px;
}
.elf-card-type-inner .elf-card-extra {
  padding: 13.5px 0;
}
.elf-card-meta {
  display: flex;
  margin: -4px 0;
}
.elf-card-meta::before {
  display: table;
  content: '';
}
.elf-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.elf-card-meta::before {
  display: table;
  content: '';
}
.elf-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.elf-card-meta-avatar {
  padding-right: 16px;
}
.elf-card-rtl .elf-card-meta-avatar {
  padding-right: 0;
  padding-left: 16px;
}
.elf-card-meta-detail {
  overflow: hidden;
}
.elf-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.elf-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.elf-card-loading {
  overflow: hidden;
}
.elf-card-loading .elf-card-body {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-card-small > .elf-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.elf-card-small > .elf-card-head > .elf-card-head-wrapper > .elf-card-head-title {
  padding: 8px 0;
}
.elf-card-small > .elf-card-head > .elf-card-head-wrapper > .elf-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.elf-card-small > .elf-card-body {
  padding: 12px;
}
.elf-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.elf-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.elf-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.elf-carousel .slick-list:focus {
  outline: none;
}
.elf-carousel .slick-list.dragging {
  cursor: pointer;
}
.elf-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.elf-carousel .slick-list .slick-slide input.elf-radio-input,
.elf-carousel .slick-list .slick-slide input.elf-checkbox-input {
  visibility: hidden;
}
.elf-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.elf-carousel .slick-list .slick-slide.slick-active input.elf-radio-input,
.elf-carousel .slick-list .slick-slide.slick-active input.elf-checkbox-input {
  visibility: visible;
}
.elf-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.elf-carousel .slick-slider .slick-track,
.elf-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.elf-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.elf-carousel .slick-track::before,
.elf-carousel .slick-track::after {
  display: table;
  content: '';
}
.elf-carousel .slick-track::after {
  clear: both;
}
.slick-loading .elf-carousel .slick-track {
  visibility: hidden;
}
.elf-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.elf-carousel .slick-slide img {
  display: block;
}
.elf-carousel .slick-slide.slick-loading img {
  display: none;
}
.elf-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.elf-carousel .slick-initialized .slick-slide {
  display: block;
}
.elf-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.elf-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.elf-carousel .slick-arrow.slick-hidden {
  display: none;
}
.elf-carousel .slick-prev,
.elf-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.elf-carousel .slick-prev:hover,
.elf-carousel .slick-next:hover,
.elf-carousel .slick-prev:focus,
.elf-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.elf-carousel .slick-prev:hover::before,
.elf-carousel .slick-next:hover::before,
.elf-carousel .slick-prev:focus::before,
.elf-carousel .slick-next:focus::before {
  opacity: 1;
}
.elf-carousel .slick-prev.slick-disabled::before,
.elf-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.elf-carousel .slick-prev {
  left: -25px;
}
.elf-carousel .slick-prev::before {
  content: '←';
}
.elf-carousel .slick-next {
  right: -25px;
}
.elf-carousel .slick-next::before {
  content: '→';
}
.elf-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.elf-carousel .slick-dots-bottom {
  bottom: 12px;
}
.elf-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.elf-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.elf-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.elf-carousel .slick-dots li button:hover,
.elf-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.elf-carousel .slick-dots li button::after {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  content: '';
}
.elf-carousel .slick-dots li.slick-active {
  width: 24px;
}
.elf-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.elf-carousel .slick-dots li.slick-active:hover,
.elf-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.elf-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.elf-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.elf-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.elf-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 0;
  vertical-align: baseline;
}
.elf-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.elf-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.elf-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.elf-carousel-rtl {
  direction: rtl;
}
.elf-carousel-rtl .elf-carousel .slick-track {
  right: 0;
  left: auto;
}
.elf-carousel-rtl .elf-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.elf-carousel-rtl .elf-carousel .slick-prev::before {
  content: '→';
}
.elf-carousel-rtl .elf-carousel .slick-next {
  right: auto;
  left: -25px;
}
.elf-carousel-rtl .elf-carousel .slick-next::before {
  content: '←';
}
.elf-carousel-rtl.elf-carousel .slick-dots {
  flex-direction: row-reverse;
}
.elf-carousel-rtl.elf-carousel-vertical .slick-dots {
  flex-direction: column;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.elf-cascader-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.elf-cascader-checkbox-wrapper:hover .elf-cascader-checkbox-inner,
.elf-cascader-checkbox:hover .elf-cascader-checkbox-inner,
.elf-cascader-checkbox-input:focus + .elf-cascader-checkbox-inner {
  border-color: var(--elf-primary-color);
}
.elf-cascader-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--elf-primary-color);
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.elf-cascader-checkbox:hover::after,
.elf-cascader-checkbox-wrapper:hover .elf-cascader-checkbox::after {
  visibility: visible;
}
.elf-cascader-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.elf-cascader-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.elf-cascader-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.elf-cascader-checkbox-checked .elf-cascader-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.elf-cascader-checkbox-checked .elf-cascader-checkbox-inner {
  background-color: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
}
.elf-cascader-checkbox-disabled {
  cursor: not-allowed;
}
.elf-cascader-checkbox-disabled.elf-cascader-checkbox-checked .elf-cascader-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.elf-cascader-checkbox-disabled .elf-cascader-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.elf-cascader-checkbox-disabled .elf-cascader-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.elf-cascader-checkbox-disabled .elf-cascader-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.elf-cascader-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-cascader-checkbox-disabled:hover::after,
.elf-cascader-checkbox-wrapper:hover .elf-cascader-checkbox-disabled::after {
  visibility: hidden;
}
.elf-cascader-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.elf-cascader-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.elf-cascader-checkbox-wrapper.elf-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.elf-cascader-checkbox-wrapper + .elf-cascader-checkbox-wrapper {
  margin-left: 8px;
}
.elf-cascader-checkbox-wrapper.elf-cascader-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.elf-cascader-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.elf-cascader-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.elf-cascader-checkbox-group-item {
  margin-right: 8px;
}
.elf-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}
.elf-cascader-checkbox-group-item + .elf-cascader-checkbox-group-item {
  margin-left: 0;
}
.elf-cascader-checkbox-indeterminate .elf-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.elf-cascader-checkbox-indeterminate .elf-cascader-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--elf-primary-color);
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.elf-cascader-checkbox-indeterminate.elf-cascader-checkbox-disabled .elf-cascader-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.elf-cascader-checkbox-rtl {
  direction: rtl;
}
.elf-cascader-checkbox-group-rtl .elf-cascader-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.elf-cascader-checkbox-group-rtl .elf-cascader-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.elf-cascader-checkbox-group-rtl .elf-cascader-checkbox-group-item + .elf-cascader-checkbox-group-item {
  margin-left: 8px;
}
.elf-cascader {
  width: 184px;
}
.elf-cascader-checkbox {
  top: 0;
  margin-right: 8px;
}
.elf-cascader-menus {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.elf-cascader-menus.elf-cascader-menu-empty .elf-cascader-menu {
  width: 100%;
  height: auto;
}
.elf-cascader-menu {
  flex-grow: 1;
  min-width: 111px;
  height: 180px;
  margin: 0;
  margin: -4px 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.elf-cascader-menu-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-cascader-menu-item:hover {
  background: #f5f5f5;
}
.elf-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-cascader-menu-item-disabled:hover {
  background: transparent;
}
.elf-cascader-menu-empty .elf-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}
.elf-cascader-menu-item-active:not(.elf-cascader-menu-item-disabled),
.elf-cascader-menu-item-active:not(.elf-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: var(--elf-primary-1);
}
.elf-cascader-menu-item-content {
  flex: auto;
}
.elf-cascader-menu-item-expand .elf-cascader-menu-item-expand-icon,
.elf-cascader-menu-item-loading-icon {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.elf-cascader-menu-item-disabled.elf-cascader-menu-item-expand .elf-cascader-menu-item-expand-icon,
.elf-cascader-menu-item-disabled.elf-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}
.elf-cascader-menu-item-keyword {
  color: #ff4d4f;
}
.elf-cascader-compact-item:not(.elf-cascader-compact-last-item):not(.elf-cascader-compact-item-rtl) {
  margin-right: -1px;
}
.elf-cascader-compact-item:not(.elf-cascader-compact-last-item).elf-cascader-compact-item-rtl {
  margin-left: -1px;
}
.elf-cascader-compact-item:hover,
.elf-cascader-compact-item:focus,
.elf-cascader-compact-item:active {
  z-index: 2;
}
.elf-cascader-compact-item[disabled] {
  z-index: 0;
}
.elf-cascader-compact-item:not(.elf-cascader-compact-first-item):not(.elf-cascader-compact-last-item).elf-cascader {
  border-radius: 0;
}
.elf-cascader-compact-item.elf-cascader.elf-cascader-compact-first-item:not(.elf-cascader-compact-last-item):not(
    .elf-cascader-compact-item-rtl
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-cascader-compact-item.elf-cascader.elf-cascader-compact-last-item:not(.elf-cascader-compact-first-item):not(
    .elf-cascader-compact-item-rtl
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-cascader-compact-item.elf-cascader.elf-cascader-compact-item-rtl.elf-cascader-compact-first-item:not(
    .elf-cascader-compact-last-item
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-cascader-compact-item.elf-cascader.elf-cascader-compact-item-rtl.elf-cascader-compact-last-item:not(
    .elf-cascader-compact-first-item
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-cascader-rtl .elf-cascader-menu-item-expand-icon,
.elf-cascader-rtl .elf-cascader-menu-item-loading-icon {
  margin-right: 4px;
  margin-left: 0;
}
.elf-cascader-rtl .elf-cascader-checkbox {
  top: 0;
  margin-right: 0;
  margin-left: 8px;
}
.elf-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.elf-checkbox:not(.elf-checkbox-checked) .elf-checkbox-inner {
  border-color: #7b7782 !important;
}
.elf-checkbox-wrapper:hover .elf-checkbox-inner,
.elf-checkbox:hover .elf-checkbox-inner,
.elf-checkbox-input:focus + .elf-checkbox-inner {
  border-color: var(--elf-primary-color);
}
.elf-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--elf-primary-color);
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.elf-checkbox:hover::after,
.elf-checkbox-wrapper:hover .elf-checkbox::after {
  visibility: visible;
}
.elf-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.elf-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.elf-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.elf-checkbox-checked .elf-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.elf-checkbox-checked .elf-checkbox-inner {
  background-color: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
}
.elf-checkbox-disabled {
  cursor: not-allowed;
}
.elf-checkbox-disabled.elf-checkbox-checked .elf-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.elf-checkbox-disabled .elf-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.elf-checkbox-disabled .elf-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.elf-checkbox-disabled .elf-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.elf-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-checkbox-disabled:hover::after,
.elf-checkbox-wrapper:hover .elf-checkbox-disabled::after {
  visibility: hidden;
}
.elf-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.elf-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.elf-checkbox-wrapper.elf-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.elf-checkbox-wrapper + .elf-checkbox-wrapper {
  margin-left: 8px;
}
.elf-checkbox-wrapper.elf-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.elf-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.elf-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.elf-checkbox-group-item {
  margin-right: 8px;
}
.elf-checkbox-group-item:last-child {
  margin-right: 0;
}
.elf-checkbox-group-item + .elf-checkbox-group-item {
  margin-left: 0;
}
.elf-checkbox-indeterminate .elf-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.elf-checkbox-indeterminate .elf-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--elf-primary-color);
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.elf-checkbox-indeterminate.elf-checkbox-disabled .elf-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.elf-checkbox-rtl {
  direction: rtl;
}
.elf-checkbox-group-rtl .elf-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.elf-checkbox-group-rtl .elf-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.elf-checkbox-group-rtl .elf-checkbox-group-item + .elf-checkbox-group-item {
  margin-left: 8px;
}
.elf-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
}
.elf-collapse > .elf-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.elf-collapse > .elf-collapse-item:last-child,
.elf-collapse > .elf-collapse-item:last-child > .elf-collapse-header {
  border-radius: 0 0 2px 2px;
}
.elf-collapse > .elf-collapse-item > .elf-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.elf-collapse > .elf-collapse-item > .elf-collapse-header .elf-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.elf-collapse > .elf-collapse-item > .elf-collapse-header .elf-collapse-arrow svg {
  transition: transform 0.24s;
}
.elf-collapse > .elf-collapse-item > .elf-collapse-header .elf-collapse-header-text {
  flex: auto;
}
.elf-collapse > .elf-collapse-item > .elf-collapse-header .elf-collapse-extra {
  margin-left: auto;
}
.elf-collapse > .elf-collapse-item > .elf-collapse-header:focus {
  outline: none;
}
.elf-collapse > .elf-collapse-item .elf-collapse-header-collapsible-only {
  cursor: default;
}
.elf-collapse > .elf-collapse-item .elf-collapse-header-collapsible-only .elf-collapse-header-text {
  flex: none;
  cursor: pointer;
}
.elf-collapse > .elf-collapse-item .elf-collapse-icon-collapsible-only {
  cursor: default;
}
.elf-collapse > .elf-collapse-item .elf-collapse-icon-collapsible-only .elf-collapse-expand-icon {
  cursor: pointer;
}
.elf-collapse > .elf-collapse-item.elf-collapse-no-arrow > .elf-collapse-header {
  padding-left: 12px;
}
.elf-collapse-icon-position-end > .elf-collapse-item > .elf-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.elf-collapse-icon-position-end > .elf-collapse-item > .elf-collapse-header .elf-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.elf-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.elf-collapse-content > .elf-collapse-content-box {
  padding: 16px;
}
.elf-collapse-content-hidden {
  display: none;
}
.elf-collapse-item:last-child > .elf-collapse-content {
  border-radius: 0 0 2px 2px;
}
.elf-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.elf-collapse-borderless > .elf-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.elf-collapse-borderless > .elf-collapse-item:last-child,
.elf-collapse-borderless > .elf-collapse-item:last-child .elf-collapse-header {
  border-radius: 0;
}
.elf-collapse-borderless > .elf-collapse-item:last-child {
  border-bottom: 0;
}
.elf-collapse-borderless > .elf-collapse-item > .elf-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.elf-collapse-borderless > .elf-collapse-item > .elf-collapse-content > .elf-collapse-content-box {
  padding-top: 4px;
}
.elf-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.elf-collapse-ghost > .elf-collapse-item {
  border-bottom: 0;
}
.elf-collapse-ghost > .elf-collapse-item > .elf-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.elf-collapse-ghost > .elf-collapse-item > .elf-collapse-content > .elf-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.elf-collapse .elf-collapse-item-disabled > .elf-collapse-header,
.elf-collapse .elf-collapse-item-disabled > .elf-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-collapse-rtl {
  direction: rtl;
}
.elf-collapse-rtl.elf-collapse.elf-collapse-icon-position-end > .elf-collapse-item > .elf-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
}
.elf-collapse-rtl.elf-collapse.elf-collapse-icon-position-end
  > .elf-collapse-item
  > .elf-collapse-header
  .elf-collapse-arrow {
  position: absolute;
  top: 50%;
  right: auto;
  left: 16px;
  margin: 0;
  transform: translateY(-50%);
}
.elf-collapse-rtl .elf-collapse > .elf-collapse-item > .elf-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.elf-collapse-rtl.elf-collapse > .elf-collapse-item > .elf-collapse-header .elf-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.elf-collapse-rtl.elf-collapse > .elf-collapse-item > .elf-collapse-header .elf-collapse-arrow svg {
  transform: rotate(180deg);
}
.elf-collapse-rtl.elf-collapse > .elf-collapse-item > .elf-collapse-header .elf-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.elf-collapse-rtl.elf-collapse > .elf-collapse-item.elf-collapse-no-arrow > .elf-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.elf-comment {
  position: relative;
  background-color: inherit;
}
.elf-comment-inner {
  display: flex;
  padding: 16px 0;
}
.elf-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.elf-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.elf-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.elf-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.elf-comment-content-author > a,
.elf-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.elf-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.elf-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.elf-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.elf-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.elf-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.elf-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.elf-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.elf-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-comment-actions > li > span:hover {
  color: #595959;
}
.elf-comment-nested {
  margin-left: 44px;
}
.elf-comment-rtl {
  direction: rtl;
}
.elf-comment-rtl .elf-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.elf-comment-rtl .elf-comment-content-author > a,
.elf-comment-rtl .elf-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.elf-comment-rtl .elf-comment-actions {
  padding-right: 0;
}
.elf-comment-rtl .elf-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.elf-comment-rtl .elf-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.elf-picker-status-error.elf-picker,
.elf-picker-status-error.elf-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: var(--elf-error-color);
}
.elf-picker-status-error.elf-picker-focused,
.elf-picker-status-error.elf-picker:focus {
  border-color: var(--elf-error-color-hover);
  box-shadow: 0 0 0 2px var(--elf-error-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-picker-status-error.elf-picker .elf-picker-active-bar {
  background: var(--elf-error-color-hover);
}
.elf-picker-status-warning.elf-picker,
.elf-picker-status-warning.elf-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: var(--elf-warning-color);
}
.elf-picker-status-warning.elf-picker-focused,
.elf-picker-status-warning.elf-picker:focus {
  border-color: var(--elf-warning-color-hover);
  box-shadow: 0 0 0 2px var(--elf-warning-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-picker-status-warning.elf-picker .elf-picker-active-bar {
  background: var(--elf-warning-color-hover);
}
.elf-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}
.elf-picker:hover,
.elf-picker-focused {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-picker:hover,
.elf-input-rtl .elf-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-picker-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-picker.elf-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.elf-picker.elf-picker-disabled .elf-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.elf-picker.elf-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.elf-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.elf-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.elf-picker-input > input::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.elf-picker-input > input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-picker-input > input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-picker-input > input:hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-picker-input > input:focus,
.elf-picker-input > input-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-picker-input > input:focus,
.elf-input-rtl .elf-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-picker-input > input-borderless,
.elf-picker-input > input-borderless:hover,
.elf-picker-input > input-borderless:focus,
.elf-picker-input > input-borderless-focused,
.elf-picker-input > input-borderless-disabled,
.elf-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.elf-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.elf-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-picker-input > input-sm {
  padding: 0px 7px;
}
.elf-picker-input > input-rtl {
  direction: rtl;
}
.elf-picker-input > input:focus {
  box-shadow: none;
}
.elf-picker-input > input[disabled] {
  background: transparent;
}
.elf-picker-input:hover .elf-picker-clear {
  opacity: 1;
}
.elf-picker-input-placeholder > input {
  color: #bfbfbf;
}
.elf-picker-large {
  padding: 6.5px 11px 6.5px;
}
.elf-picker-large .elf-picker-input > input {
  font-size: 16px;
}
.elf-picker-small {
  padding: 0px 7px 0px;
}
.elf-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.elf-picker-suffix > * {
  vertical-align: top;
}
.elf-picker-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.elf-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.elf-picker-clear > * {
  vertical-align: top;
}
.elf-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.elf-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.elf-picker-focused .elf-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.elf-picker-disabled .elf-picker-range-separator .elf-picker-separator {
  cursor: not-allowed;
}
.elf-picker-range {
  position: relative;
  display: inline-flex;
}
.elf-picker-range .elf-picker-clear {
  right: 11px;
}
.elf-picker-range:hover .elf-picker-clear {
  opacity: 1;
}
.elf-picker-range .elf-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: var(--elf-primary-color);
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.elf-picker-range.elf-picker-focused .elf-picker-active-bar {
  opacity: 1;
}
.elf-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.elf-picker-range.elf-picker-small .elf-picker-clear {
  right: 7px;
}
.elf-picker-range.elf-picker-small .elf-picker-active-bar {
  margin-left: 7px;
}
.elf-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}
.elf-picker-dropdown-hidden {
  display: none;
}
.elf-picker-dropdown-placement-bottomLeft .elf-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  transform: rotate(-135deg) translateY(1px);
}
.elf-picker-dropdown-placement-topLeft .elf-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  transform: rotate(45deg);
}
.elf-picker-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-picker-dropdown-placement-topLeft,
.elf-picker-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-picker-dropdown-placement-topRight,
.elf-picker-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-picker-dropdown-placement-topLeft,
.elf-picker-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.elf-picker-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-picker-dropdown-placement-bottomLeft,
.elf-picker-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-picker-dropdown-placement-bottomRight,
.elf-picker-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-picker-dropdown-placement-bottomLeft,
.elf-picker-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.elf-picker-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-picker-dropdown-placement-topLeft,
.elf-picker-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.elf-picker-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-picker-dropdown-placement-bottomLeft,
.elf-picker-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.elf-picker-dropdown-range {
  padding: 7.54247233px 0;
}
.elf-picker-dropdown-range-hidden {
  display: none;
}
.elf-picker-dropdown .elf-picker-panel > .elf-picker-time-panel {
  padding-top: 4px;
}
.elf-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.elf-picker-ranges > li {
  display: inline-block;
}
.elf-picker-ranges .elf-picker-preset > .elf-tag-blue {
  color: var(--elf-primary-color);
  background: var(--elf-primary-1);
  border-color: var(--elf-primary-3);
  cursor: pointer;
}
.elf-picker-ranges .elf-picker-ok {
  float: right;
  margin-left: 8px;
}
.elf-picker-range-wrapper {
  display: flex;
}
.elf-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.elf-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path(
    'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
  );
          clip-path: path(
    'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
  );
}
.elf-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.elf-picker-panel-container .elf-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.elf-picker-panel-container .elf-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.elf-picker-panel-container .elf-picker-panel .elf-picker-content,
.elf-picker-panel-container .elf-picker-panel table {
  text-align: center;
}
.elf-picker-panel-container .elf-picker-panel-focused {
  border-color: rgba(0, 0, 0, 0.06);
}
.elf-picker-compact-item:not(.elf-picker-compact-last-item):not(.elf-picker-compact-item-rtl) {
  margin-right: -1px;
}
.elf-picker-compact-item:not(.elf-picker-compact-last-item).elf-picker-compact-item-rtl {
  margin-left: -1px;
}
.elf-picker-compact-item:hover,
.elf-picker-compact-item:focus,
.elf-picker-compact-item:active {
  z-index: 2;
}
.elf-picker-compact-item.elf-picker-focused {
  z-index: 2;
}
.elf-picker-compact-item[disabled] {
  z-index: 0;
}
.elf-picker-compact-item:not(.elf-picker-compact-first-item):not(.elf-picker-compact-last-item).elf-picker {
  border-radius: 0;
}
.elf-picker-compact-item.elf-picker.elf-picker-compact-first-item:not(.elf-picker-compact-last-item):not(
    .elf-picker-compact-item-rtl
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-picker-compact-item.elf-picker.elf-picker-compact-last-item:not(.elf-picker-compact-first-item):not(
    .elf-picker-compact-item-rtl
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-picker-compact-item.elf-picker.elf-picker-compact-item-rtl.elf-picker-compact-first-item:not(
    .elf-picker-compact-last-item
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-picker-compact-item.elf-picker.elf-picker-compact-item-rtl.elf-picker-compact-last-item:not(
    .elf-picker-compact-first-item
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  outline: none;
}
.elf-picker-panel-focused {
  border-color: var(--elf-primary-color);
}
.elf-picker-decade-panel,
.elf-picker-year-panel,
.elf-picker-quarter-panel,
.elf-picker-month-panel,
.elf-picker-week-panel,
.elf-picker-date-panel,
.elf-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.elf-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-picker-header > * {
  flex: none;
}
.elf-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.elf-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.elf-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}
.elf-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.elf-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.elf-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.elf-picker-header-view button:hover {
  color: var(--elf-primary-color);
}
.elf-picker-prev-icon,
.elf-picker-next-icon,
.elf-picker-super-prev-icon,
.elf-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.elf-picker-prev-icon::before,
.elf-picker-next-icon::before,
.elf-picker-super-prev-icon::before,
.elf-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.elf-picker-super-prev-icon::after,
.elf-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.elf-picker-prev-icon,
.elf-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.elf-picker-next-icon,
.elf-picker-super-next-icon {
  transform: rotate(135deg);
}
.elf-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.elf-picker-content th,
.elf-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.elf-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.elf-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.elf-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}
.elf-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.elf-picker-cell .elf-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.elf-picker-cell:hover:not(.elf-picker-cell-in-view) .elf-picker-cell-inner,
.elf-picker-cell:hover:not(.elf-picker-cell-selected):not(.elf-picker-cell-range-start):not(
    .elf-picker-cell-range-end
  ):not(.elf-picker-cell-range-hover-start):not(.elf-picker-cell-range-hover-end)
  .elf-picker-cell-inner {
  background: #f5f5f5;
}
.elf-picker-cell-in-view.elf-picker-cell-today .elf-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid var(--elf-primary-color);
  border-radius: 2px;
  content: '';
}
.elf-picker-cell-in-view.elf-picker-cell-in-range {
  position: relative;
}
.elf-picker-cell-in-view.elf-picker-cell-in-range::before {
  background: var(--elf-primary-1);
}
.elf-picker-cell-in-view.elf-picker-cell-selected .elf-picker-cell-inner,
.elf-picker-cell-in-view.elf-picker-cell-range-start .elf-picker-cell-inner,
.elf-picker-cell-in-view.elf-picker-cell-range-end .elf-picker-cell-inner {
  color: #fff;
  background: var(--elf-primary-color);
}
.elf-picker-cell-in-view.elf-picker-cell-range-start:not(.elf-picker-cell-range-start-single)::before,
.elf-picker-cell-in-view.elf-picker-cell-range-end:not(.elf-picker-cell-range-end-single)::before {
  background: var(--elf-primary-1);
}
.elf-picker-cell-in-view.elf-picker-cell-range-start::before {
  left: 50%;
}
.elf-picker-cell-in-view.elf-picker-cell-range-end::before {
  right: 50%;
}
.elf-picker-cell-in-view.elf-picker-cell-range-hover-start:not(.elf-picker-cell-in-range):not(
    .elf-picker-cell-range-start
  ):not(.elf-picker-cell-range-end)::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-end:not(.elf-picker-cell-in-range):not(
    .elf-picker-cell-range-start
  ):not(.elf-picker-cell-range-end)::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-start.elf-picker-cell-range-start-single::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-start.elf-picker-cell-range-start.elf-picker-cell-range-end.elf-picker-cell-range-end-near-hover::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-end.elf-picker-cell-range-start.elf-picker-cell-range-end.elf-picker-cell-range-start-near-hover::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-end.elf-picker-cell-range-end-single::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover:not(.elf-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-bottom: 1px dashed var(--elf-primary-color-deprecated-l-20);
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.elf-picker-cell-range-hover-start::after,
.elf-picker-cell-range-hover-end::after,
.elf-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover::before,
.elf-picker-cell-in-view.elf-picker-cell-range-start.elf-picker-cell-range-hover::before,
.elf-picker-cell-in-view.elf-picker-cell-range-end.elf-picker-cell-range-hover::before,
.elf-picker-cell-in-view.elf-picker-cell-range-start:not(
    .elf-picker-cell-range-start-single
  ).elf-picker-cell-range-hover-start::before,
.elf-picker-cell-in-view.elf-picker-cell-range-end:not(
    .elf-picker-cell-range-end-single
  ).elf-picker-cell-range-hover-end::before,
.elf-picker-panel
  > :not(.elf-picker-date-panel)
  .elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover-start::before,
.elf-picker-panel
  > :not(.elf-picker-date-panel)
  .elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover-end::before {
  background: var(--elf-primary-color-deprecated-l-35);
}
.elf-picker-cell-in-view.elf-picker-cell-range-start:not(.elf-picker-cell-range-start-single):not(
    .elf-picker-cell-range-end
  )
  .elf-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.elf-picker-cell-in-view.elf-picker-cell-range-end:not(.elf-picker-cell-range-end-single):not(
    .elf-picker-cell-range-start
  )
  .elf-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.elf-picker-date-panel
  .elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover-start
  .elf-picker-cell-inner::after,
.elf-picker-date-panel
  .elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover-end
  .elf-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: var(--elf-primary-color-deprecated-l-35);
  transition: all 0.3s;
  content: '';
}
.elf-picker-date-panel
  .elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover-start
  .elf-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.elf-picker-date-panel
  .elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover-end
  .elf-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.elf-picker-cell-range-hover.elf-picker-cell-range-start::after {
  right: 50%;
}
.elf-picker-cell-range-hover.elf-picker-cell-range-end::after {
  left: 50%;
}
tr > .elf-picker-cell-in-view.elf-picker-cell-range-hover:first-child::after,
tr > .elf-picker-cell-in-view.elf-picker-cell-range-hover-end:first-child::after,
.elf-picker-cell-in-view.elf-picker-cell-start.elf-picker-cell-range-hover-edge-start.elf-picker-cell-range-hover-edge-start-near-range::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-edge-start:not(
    .elf-picker-cell-range-hover-edge-start-near-range
  )::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
tr > .elf-picker-cell-in-view.elf-picker-cell-range-hover:last-child::after,
tr > .elf-picker-cell-in-view.elf-picker-cell-range-hover-start:last-child::after,
.elf-picker-cell-in-view.elf-picker-cell-end.elf-picker-cell-range-hover-edge-end.elf-picker-cell-range-hover-edge-end-near-range::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-edge-end:not(
    .elf-picker-cell-range-hover-edge-end-near-range
  )::after,
.elf-picker-cell-in-view.elf-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.elf-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.elf-picker-cell-disabled .elf-picker-cell-inner {
  background: transparent;
}
.elf-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.elf-picker-cell-disabled.elf-picker-cell-today .elf-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.elf-picker-decade-panel .elf-picker-content,
.elf-picker-year-panel .elf-picker-content,
.elf-picker-quarter-panel .elf-picker-content,
.elf-picker-month-panel .elf-picker-content {
  height: 264px;
}
.elf-picker-decade-panel .elf-picker-cell-inner,
.elf-picker-year-panel .elf-picker-cell-inner,
.elf-picker-quarter-panel .elf-picker-cell-inner,
.elf-picker-month-panel .elf-picker-cell-inner {
  padding: 0 8px;
}
.elf-picker-quarter-panel .elf-picker-content {
  height: 56px;
}
.elf-picker-footer {
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.elf-picker-panel .elf-picker-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.elf-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-picker-now {
  text-align: left;
}
.elf-picker-today-btn {
  color: var(--elf-primary-color);
}
.elf-picker-today-btn:hover {
  color: var(--elf-primary-color-hover);
}
.elf-picker-today-btn:active {
  color: var(--elf-primary-color-active);
}
.elf-picker-today-btn.elf-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-picker-decade-panel .elf-picker-cell-inner {
  padding: 0 4px;
}
.elf-picker-decade-panel .elf-picker-cell::before {
  display: none;
}
.elf-picker-year-panel .elf-picker-body,
.elf-picker-quarter-panel .elf-picker-body,
.elf-picker-month-panel .elf-picker-body {
  padding: 0 8px;
}
.elf-picker-year-panel .elf-picker-cell-inner,
.elf-picker-quarter-panel .elf-picker-cell-inner,
.elf-picker-month-panel .elf-picker-cell-inner {
  width: 60px;
}
.elf-picker-year-panel .elf-picker-cell-range-hover-start::after,
.elf-picker-quarter-panel .elf-picker-cell-range-hover-start::after,
.elf-picker-month-panel .elf-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-radius: 2px 0 0 2px;
}
.elf-picker-panel-rtl .elf-picker-year-panel .elf-picker-cell-range-hover-start::after,
.elf-picker-panel-rtl .elf-picker-quarter-panel .elf-picker-cell-range-hover-start::after,
.elf-picker-panel-rtl .elf-picker-month-panel .elf-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-radius: 0 2px 2px 0;
}
.elf-picker-year-panel .elf-picker-cell-range-hover-end::after,
.elf-picker-quarter-panel .elf-picker-cell-range-hover-end::after,
.elf-picker-month-panel .elf-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-radius: 0 2px 2px 0;
}
.elf-picker-panel-rtl .elf-picker-year-panel .elf-picker-cell-range-hover-end::after,
.elf-picker-panel-rtl .elf-picker-quarter-panel .elf-picker-cell-range-hover-end::after,
.elf-picker-panel-rtl .elf-picker-month-panel .elf-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-radius: 2px 0 0 2px;
}
.elf-picker-week-panel .elf-picker-body {
  padding: 8px 12px;
}
.elf-picker-week-panel .elf-picker-cell:hover .elf-picker-cell-inner,
.elf-picker-week-panel .elf-picker-cell-selected .elf-picker-cell-inner,
.elf-picker-week-panel .elf-picker-cell .elf-picker-cell-inner {
  background: transparent !important;
}
.elf-picker-week-panel-row td {
  transition: background 0.3s;
}
.elf-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.elf-picker-week-panel-row-selected td,
.elf-picker-week-panel-row-selected:hover td {
  background: var(--elf-primary-color);
}
.elf-picker-week-panel-row-selected td.elf-picker-cell-week,
.elf-picker-week-panel-row-selected:hover td.elf-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.elf-picker-week-panel-row-selected td.elf-picker-cell-today .elf-picker-cell-inner::before,
.elf-picker-week-panel-row-selected:hover td.elf-picker-cell-today .elf-picker-cell-inner::before {
  border-color: #fff;
}
.elf-picker-week-panel-row-selected td .elf-picker-cell-inner,
.elf-picker-week-panel-row-selected:hover td .elf-picker-cell-inner {
  color: #fff;
}
.elf-picker-date-panel .elf-picker-body {
  padding: 8px 12px;
}
.elf-picker-date-panel .elf-picker-content {
  width: 252px;
}
.elf-picker-date-panel .elf-picker-content th {
  width: 36px;
}
.elf-picker-datetime-panel {
  display: flex;
}
.elf-picker-datetime-panel .elf-picker-time-panel {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-picker-datetime-panel .elf-picker-date-panel,
.elf-picker-datetime-panel .elf-picker-time-panel {
  transition: opacity 0.3s;
}
.elf-picker-datetime-panel-active .elf-picker-date-panel,
.elf-picker-datetime-panel-active .elf-picker-time-panel {
  opacity: 0.3;
}
.elf-picker-datetime-panel-active .elf-picker-date-panel-active,
.elf-picker-datetime-panel-active .elf-picker-time-panel-active {
  opacity: 1;
}
.elf-picker-time-panel {
  width: auto;
  min-width: auto;
}
.elf-picker-time-panel .elf-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.elf-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.elf-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.elf-picker-datetime-panel .elf-picker-time-panel-column::after {
  height: 198px;
}
.elf-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-picker-time-panel-column-active {
  background: var(--elf-primary-color-active-deprecated-f-30);
}
.elf-picker-time-panel-column:hover {
  overflow-y: auto;
}
.elf-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.elf-picker-time-panel-column > li.elf-picker-time-panel-cell .elf-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.elf-picker-time-panel-column > li.elf-picker-time-panel-cell .elf-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.elf-picker-time-panel-column > li.elf-picker-time-panel-cell-selected .elf-picker-time-panel-cell-inner {
  background: var(--elf-primary-1);
}
.elf-picker-time-panel-column > li.elf-picker-time-panel-cell-disabled .elf-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .elf-picker-range-wrapper .elf-picker-month-panel .elf-picker-cell,
:root .elf-picker-range-wrapper .elf-picker-month-panel .elf-picker-cell,
_:-ms-fullscreen .elf-picker-range-wrapper .elf-picker-year-panel .elf-picker-cell,
:root .elf-picker-range-wrapper .elf-picker-year-panel .elf-picker-cell {
  padding: 21px 0;
}
.elf-picker-rtl {
  direction: rtl;
}
.elf-picker-rtl .elf-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.elf-picker-rtl .elf-picker-clear {
  right: auto;
  left: 0;
}
.elf-picker-rtl .elf-picker-separator {
  transform: rotate(180deg);
}
.elf-picker-panel-rtl .elf-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.elf-picker-rtl.elf-picker-range .elf-picker-clear {
  right: auto;
  left: 11px;
}
.elf-picker-rtl.elf-picker-range .elf-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.elf-picker-rtl.elf-picker-range.elf-picker-small .elf-picker-active-bar {
  margin-right: 7px;
}
.elf-picker-dropdown-rtl .elf-picker-ranges {
  text-align: right;
}
.elf-picker-dropdown-rtl .elf-picker-ranges .elf-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.elf-picker-panel-rtl {
  direction: rtl;
}
.elf-picker-panel-rtl .elf-picker-prev-icon,
.elf-picker-panel-rtl .elf-picker-super-prev-icon {
  transform: rotate(135deg);
}
.elf-picker-panel-rtl .elf-picker-next-icon,
.elf-picker-panel-rtl .elf-picker-super-next-icon {
  transform: rotate(-45deg);
}
.elf-picker-cell .elf-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.elf-picker-panel-rtl .elf-picker-cell-in-view.elf-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.elf-picker-panel-rtl .elf-picker-cell-in-view.elf-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.elf-picker-panel-rtl .elf-picker-cell-in-view.elf-picker-cell-range-start.elf-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.elf-picker-panel-rtl
  .elf-picker-date-panel
  .elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover-start
  .elf-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.elf-picker-panel-rtl
  .elf-picker-date-panel
  .elf-picker-cell-in-view.elf-picker-cell-in-range.elf-picker-cell-range-hover-end
  .elf-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.elf-picker-panel-rtl .elf-picker-cell-range-hover.elf-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.elf-picker-panel-rtl .elf-picker-cell-range-hover.elf-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-range-start:not(.elf-picker-cell-range-start-single):not(
    .elf-picker-cell-range-end
  )
  .elf-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-range-end:not(.elf-picker-cell-range-end-single):not(
    .elf-picker-cell-range-start
  )
  .elf-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.elf-picker-panel-rtl
  tr
  > .elf-picker-cell-in-view.elf-picker-cell-range-hover:not(.elf-picker-cell-selected):first-child::after,
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-start.elf-picker-cell-range-hover-edge-start.elf-picker-cell-range-hover-edge-start-near-range::after,
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-range-hover-edge-start:not(
    .elf-picker-cell-range-hover-edge-start-near-range
  )::after,
.elf-picker-panel-rtl .elf-picker-cell-in-view.elf-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.elf-picker-panel-rtl
  tr
  > .elf-picker-cell-in-view.elf-picker-cell-range-hover:not(.elf-picker-cell-selected):last-child::after,
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-end.elf-picker-cell-range-hover-edge-end.elf-picker-cell-range-hover-edge-end-near-range::after,
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-range-hover-edge-end:not(
    .elf-picker-cell-range-hover-edge-end-near-range
  )::after,
.elf-picker-panel-rtl .elf-picker-cell-in-view.elf-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-radius: 2px 0 0 2px;
}
.elf-picker-panel-rtl tr > .elf-picker-cell-in-view.elf-picker-cell-range-hover-start:last-child::after,
.elf-picker-panel-rtl tr > .elf-picker-cell-in-view.elf-picker-cell-range-hover-end:first-child::after,
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-start.elf-picker-cell-range-hover-edge-start:not(
    .elf-picker-cell-range-hover
  )::after,
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-start.elf-picker-cell-range-hover-end.elf-picker-cell-range-hover-edge-start:not(
    .elf-picker-cell-range-hover
  )::after,
.elf-picker-panel-rtl
  .elf-picker-cell-in-view.elf-picker-cell-end.elf-picker-cell-range-hover-start.elf-picker-cell-range-hover-edge-end:not(
    .elf-picker-cell-range-hover
  )::after,
.elf-picker-panel-rtl
  tr
  > .elf-picker-cell-in-view.elf-picker-cell-start.elf-picker-cell-range-hover.elf-picker-cell-range-hover-edge-start:last-child::after,
.elf-picker-panel-rtl
  tr
  > .elf-picker-cell-in-view.elf-picker-cell-end.elf-picker-cell-range-hover.elf-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-left: 1px dashed var(--elf-primary-color-deprecated-l-20);
  border-radius: 2px;
}
.elf-picker-dropdown-rtl .elf-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.elf-picker-panel-rtl .elf-picker-time-panel {
  direction: ltr;
}
.elf-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.elf-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.elf-descriptions-view {
  width: 100%;
  border-radius: 2px;
}
.elf-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.elf-descriptions-row > th,
.elf-descriptions-row > td {
  padding-bottom: 16px;
}
.elf-descriptions-row:last-child {
  border-bottom: none;
}
.elf-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.elf-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.elf-descriptions-item-label.elf-descriptions-item-no-colon::after {
  content: ' ';
}
.elf-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.elf-descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.elf-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.elf-descriptions-item-container {
  display: flex;
}
.elf-descriptions-item-container .elf-descriptions-item-label,
.elf-descriptions-item-container .elf-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.elf-descriptions-middle .elf-descriptions-row > th,
.elf-descriptions-middle .elf-descriptions-row > td {
  padding-bottom: 12px;
}
.elf-descriptions-small .elf-descriptions-row > th,
.elf-descriptions-small .elf-descriptions-row > td {
  padding-bottom: 8px;
}
.elf-descriptions-bordered .elf-descriptions-view {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-descriptions-bordered .elf-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}
.elf-descriptions-bordered .elf-descriptions-item-label,
.elf-descriptions-bordered .elf-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-descriptions-bordered .elf-descriptions-item-label:last-child,
.elf-descriptions-bordered .elf-descriptions-item-content:last-child {
  border-right: none;
}
.elf-descriptions-bordered .elf-descriptions-item-label {
  background-color: #fafafa;
}
.elf-descriptions-bordered .elf-descriptions-item-label::after {
  display: none;
}
.elf-descriptions-bordered .elf-descriptions-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-descriptions-bordered .elf-descriptions-row:last-child {
  border-bottom: none;
}
.elf-descriptions-bordered.elf-descriptions-middle .elf-descriptions-item-label,
.elf-descriptions-bordered.elf-descriptions-middle .elf-descriptions-item-content {
  padding: 12px 24px;
}
.elf-descriptions-bordered.elf-descriptions-small .elf-descriptions-item-label,
.elf-descriptions-bordered.elf-descriptions-small .elf-descriptions-item-content {
  padding: 8px 16px;
}
.elf-descriptions-rtl {
  direction: rtl;
}
.elf-descriptions-rtl .elf-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.elf-descriptions-rtl.elf-descriptions-bordered .elf-descriptions-item-label,
.elf-descriptions-rtl.elf-descriptions-bordered .elf-descriptions-item-content {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-descriptions-rtl.elf-descriptions-bordered .elf-descriptions-item-label:last-child,
.elf-descriptions-rtl.elf-descriptions-bordered .elf-descriptions-item-content:last-child {
  border-left: none;
}
.elf-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 0;
}
.elf-divider-horizontal.elf-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.elf-divider-horizontal.elf-divider-with-text::before,
.elf-divider-horizontal.elf-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.elf-divider-horizontal.elf-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.elf-divider-horizontal.elf-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.elf-divider-horizontal.elf-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.elf-divider-horizontal.elf-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.elf-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.elf-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.elf-divider-horizontal.elf-divider-with-text.elf-divider-dashed::before,
.elf-divider-horizontal.elf-divider-with-text.elf-divider-dashed::after {
  border-style: dashed none none;
}
.elf-divider-vertical.elf-divider-dashed {
  border-width: 0 0 0 1px;
}
.elf-divider-plain.elf-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.elf-divider-horizontal.elf-divider-with-text-left.elf-divider-no-default-orientation-margin-left::before {
  width: 0;
}
.elf-divider-horizontal.elf-divider-with-text-left.elf-divider-no-default-orientation-margin-left::after {
  width: 100%;
}
.elf-divider-horizontal.elf-divider-with-text-left.elf-divider-no-default-orientation-margin-left
  .elf-divider-inner-text {
  padding-left: 0;
}
.elf-divider-horizontal.elf-divider-with-text-right.elf-divider-no-default-orientation-margin-right::before {
  width: 100%;
}
.elf-divider-horizontal.elf-divider-with-text-right.elf-divider-no-default-orientation-margin-right::after {
  width: 0;
}
.elf-divider-horizontal.elf-divider-with-text-right.elf-divider-no-default-orientation-margin-right
  .elf-divider-inner-text {
  padding-right: 0;
}
.elf-divider-rtl {
  direction: rtl;
}
.elf-divider-rtl.elf-divider-horizontal.elf-divider-with-text-left::before {
  width: 95%;
}
.elf-divider-rtl.elf-divider-horizontal.elf-divider-with-text-left::after {
  width: 5%;
}
.elf-divider-rtl.elf-divider-horizontal.elf-divider-with-text-right::before {
  width: 5%;
}
.elf-divider-rtl.elf-divider-horizontal.elf-divider-with-text-right::after {
  width: 95%;
}
.elf-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.elf-drawer-inline {
  position: absolute;
}
.elf-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
.elf-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all 0.3s;
}
.elf-drawer-content-wrapper-hidden {
  display: none;
}
.elf-drawer-left > .elf-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05),
    12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.elf-drawer-right > .elf-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
    -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.elf-drawer-top > .elf-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
    0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.elf-drawer-bottom > .elf-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05),
    0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.elf-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}
.elf-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.elf-drawer-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.elf-drawer-extra {
  flex: none;
}
.elf-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.elf-drawer-close:focus,
.elf-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.elf-drawer-title {
  flex: 1;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.elf-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}
.elf-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}
.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all 0.3s;
}
.elf-drawer-mask-motion-enter-active,
.elf-drawer-mask-motion-appear-active,
.elf-drawer-mask-motion-leave-active {
  transition: all 0.3s;
}
.elf-drawer-mask-motion-enter,
.elf-drawer-mask-motion-appear {
  opacity: 0;
}
.elf-drawer-mask-motion-enter-active,
.elf-drawer-mask-motion-appear-active {
  opacity: 1;
}
.elf-drawer-mask-motion-leave {
  opacity: 1;
}
.elf-drawer-mask-motion-leave-active {
  opacity: 0;
}
.elf-drawer-panel-motion-left-enter-start,
.elf-drawer-panel-motion-left-appear-start,
.elf-drawer-panel-motion-left-leave-start {
  transition: none;
}
.elf-drawer-panel-motion-left-enter-active,
.elf-drawer-panel-motion-left-appear-active,
.elf-drawer-panel-motion-left-leave-active {
  transition: all 0.3s;
}
.elf-drawer-panel-motion-left-enter-start,
.elf-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}
.elf-drawer-panel-motion-left-enter-active,
.elf-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}
.elf-drawer-panel-motion-left-leave {
  transform: translateX(0);
}
.elf-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}
.elf-drawer-panel-motion-right-enter-start,
.elf-drawer-panel-motion-right-appear-start,
.elf-drawer-panel-motion-right-leave-start {
  transition: none;
}
.elf-drawer-panel-motion-right-enter-active,
.elf-drawer-panel-motion-right-appear-active,
.elf-drawer-panel-motion-right-leave-active {
  transition: all 0.3s;
}
.elf-drawer-panel-motion-right-enter-start,
.elf-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}
.elf-drawer-panel-motion-right-enter-active,
.elf-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}
.elf-drawer-panel-motion-right-leave {
  transform: translateX(0);
}
.elf-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}
.elf-drawer-panel-motion-top-enter-start,
.elf-drawer-panel-motion-top-appear-start,
.elf-drawer-panel-motion-top-leave-start {
  transition: none;
}
.elf-drawer-panel-motion-top-enter-active,
.elf-drawer-panel-motion-top-appear-active,
.elf-drawer-panel-motion-top-leave-active {
  transition: all 0.3s;
}
.elf-drawer-panel-motion-top-enter-start,
.elf-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}
.elf-drawer-panel-motion-top-enter-active,
.elf-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}
.elf-drawer-panel-motion-top-leave {
  transform: translateY(0);
}
.elf-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}
.elf-drawer-panel-motion-bottom-enter-start,
.elf-drawer-panel-motion-bottom-appear-start,
.elf-drawer-panel-motion-bottom-leave-start {
  transition: none;
}
.elf-drawer-panel-motion-bottom-enter-active,
.elf-drawer-panel-motion-bottom-appear-active,
.elf-drawer-panel-motion-bottom-leave-active {
  transition: all 0.3s;
}
.elf-drawer-panel-motion-bottom-enter-start,
.elf-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}
.elf-drawer-panel-motion-bottom-enter-active,
.elf-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}
.elf-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}
.elf-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}
.elf-drawer-rtl {
  direction: rtl;
}
.elf-drawer-rtl .elf-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.elf-dropdown-menu-item.elf-dropdown-menu-item-danger {
  color: var(--elf-error-color);
}
.elf-dropdown-menu-item.elf-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: var(--elf-error-color);
}
.elf-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.elf-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.elf-dropdown-wrap {
  position: relative;
}
.elf-dropdown-wrap .elf-btn > .anticon-down {
  font-size: 10px;
}
.elf-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.elf-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.elf-dropdown-hidden,
.elf-dropdown-menu-hidden,
.elf-dropdown-menu-submenu-hidden {
  display: none;
}
.elf-dropdown-show-arrow.elf-dropdown-placement-topLeft,
.elf-dropdown-show-arrow.elf-dropdown-placement-top,
.elf-dropdown-show-arrow.elf-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.elf-dropdown-show-arrow.elf-dropdown-placement-bottomLeft,
.elf-dropdown-show-arrow.elf-dropdown-placement-bottom,
.elf-dropdown-show-arrow.elf-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.elf-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.elf-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path(
    'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
  );
          clip-path: path(
    'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
  );
}
.elf-dropdown-placement-top > .elf-dropdown-arrow,
.elf-dropdown-placement-topLeft > .elf-dropdown-arrow,
.elf-dropdown-placement-topRight > .elf-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.elf-dropdown-placement-top > .elf-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.elf-dropdown-placement-topLeft > .elf-dropdown-arrow {
  left: 16px;
}
.elf-dropdown-placement-topRight > .elf-dropdown-arrow {
  right: 16px;
}
.elf-dropdown-placement-bottom > .elf-dropdown-arrow,
.elf-dropdown-placement-bottomLeft > .elf-dropdown-arrow,
.elf-dropdown-placement-bottomRight > .elf-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.elf-dropdown-placement-bottom > .elf-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.elf-dropdown-placement-bottomLeft > .elf-dropdown-arrow {
  left: 16px;
}
.elf-dropdown-placement-bottomRight > .elf-dropdown-arrow {
  right: 16px;
}
.elf-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.elf-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.elf-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.elf-dropdown-menu-submenu-popup ul,
.elf-dropdown-menu-submenu-popup li {
  list-style: none;
}
.elf-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.elf-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.elf-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.elf-dropdown-menu-title-content {
  flex: auto;
}
.elf-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.elf-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.elf-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.elf-dropdown-menu-item,
.elf-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-dropdown-menu-item-selected,
.elf-dropdown-menu-submenu-title-selected {
  color: var(--elf-primary-color);
  background-color: var(--elf-primary-1);
}
.elf-dropdown-menu-item:hover,
.elf-dropdown-menu-submenu-title:hover,
.elf-dropdown-menu-item.elf-dropdown-menu-item-active,
.elf-dropdown-menu-item.elf-dropdown-menu-submenu-title-active,
.elf-dropdown-menu-submenu-title.elf-dropdown-menu-item-active,
.elf-dropdown-menu-submenu-title.elf-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.elf-dropdown-menu-item-disabled,
.elf-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-dropdown-menu-item-disabled:hover,
.elf-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.elf-dropdown-menu-item-disabled a,
.elf-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.elf-dropdown-menu-item-divider,
.elf-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-dropdown-menu-item .elf-dropdown-menu-submenu-expand-icon,
.elf-dropdown-menu-submenu-title .elf-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.elf-dropdown-menu-item .elf-dropdown-menu-submenu-expand-icon .elf-dropdown-menu-submenu-arrow-icon,
.elf-dropdown-menu-submenu-title .elf-dropdown-menu-submenu-expand-icon .elf-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.elf-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.elf-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.elf-dropdown-menu-submenu-vertical {
  position: relative;
}
.elf-dropdown-menu-submenu-vertical > .elf-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.elf-dropdown-menu-submenu.elf-dropdown-menu-submenu-disabled .elf-dropdown-menu-submenu-title,
.elf-dropdown-menu-submenu.elf-dropdown-menu-submenu-disabled
  .elf-dropdown-menu-submenu-title
  .elf-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.elf-dropdown-menu-submenu-selected .elf-dropdown-menu-submenu-title {
  color: var(--elf-primary-color);
}
.elf-dropdown.elf-slide-down-enter.elf-slide-down-enter-active.elf-dropdown-placement-bottomLeft,
.elf-dropdown.elf-slide-down-appear.elf-slide-down-appear-active.elf-dropdown-placement-bottomLeft,
.elf-dropdown.elf-slide-down-enter.elf-slide-down-enter-active.elf-dropdown-placement-bottom,
.elf-dropdown.elf-slide-down-appear.elf-slide-down-appear-active.elf-dropdown-placement-bottom,
.elf-dropdown.elf-slide-down-enter.elf-slide-down-enter-active.elf-dropdown-placement-bottomRight,
.elf-dropdown.elf-slide-down-appear.elf-slide-down-appear-active.elf-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.elf-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-dropdown-placement-topLeft,
.elf-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-dropdown-placement-topLeft,
.elf-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-dropdown-placement-top,
.elf-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-dropdown-placement-top,
.elf-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-dropdown-placement-topRight,
.elf-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.elf-dropdown.elf-slide-down-leave.elf-slide-down-leave-active.elf-dropdown-placement-bottomLeft,
.elf-dropdown.elf-slide-down-leave.elf-slide-down-leave-active.elf-dropdown-placement-bottom,
.elf-dropdown.elf-slide-down-leave.elf-slide-down-leave-active.elf-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.elf-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-dropdown-placement-topLeft,
.elf-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-dropdown-placement-top,
.elf-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.elf-dropdown-trigger > .anticon.anticon-down,
.elf-dropdown-link > .anticon.anticon-down,
.elf-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.elf-dropdown-button {
  white-space: nowrap;
}
.elf-dropdown-button.elf-btn-group > .elf-btn-loading,
.elf-dropdown-button.elf-btn-group > .elf-btn-loading + .elf-btn {
  cursor: default;
  pointer-events: none;
}
.elf-dropdown-button.elf-btn-group > .elf-btn-loading + .elf-btn::before {
  display: block;
}
.elf-dropdown-button.elf-btn-group > .elf-btn:last-child:not(:first-child):not(.elf-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.elf-dropdown-menu-dark,
.elf-dropdown-menu-dark .elf-dropdown-menu {
  background: #001529;
}
.elf-dropdown-menu-dark .elf-dropdown-menu-item,
.elf-dropdown-menu-dark .elf-dropdown-menu-submenu-title,
.elf-dropdown-menu-dark .elf-dropdown-menu-item > a,
.elf-dropdown-menu-dark .elf-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.elf-dropdown-menu-dark .elf-dropdown-menu-item .elf-dropdown-menu-submenu-arrow::after,
.elf-dropdown-menu-dark .elf-dropdown-menu-submenu-title .elf-dropdown-menu-submenu-arrow::after,
.elf-dropdown-menu-dark .elf-dropdown-menu-item > a .elf-dropdown-menu-submenu-arrow::after,
.elf-dropdown-menu-dark .elf-dropdown-menu-item > .anticon + span > a .elf-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.elf-dropdown-menu-dark .elf-dropdown-menu-item:hover,
.elf-dropdown-menu-dark .elf-dropdown-menu-submenu-title:hover,
.elf-dropdown-menu-dark .elf-dropdown-menu-item > a:hover,
.elf-dropdown-menu-dark .elf-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.elf-dropdown-menu-dark .elf-dropdown-menu-item-selected,
.elf-dropdown-menu-dark .elf-dropdown-menu-item-selected:hover,
.elf-dropdown-menu-dark .elf-dropdown-menu-item-selected > a {
  color: #fff;
  background: var(--elf-primary-color);
}
.elf-dropdown-rtl {
  direction: rtl;
}
.elf-dropdown-rtl.elf-dropdown::before {
  right: -7px;
  left: 0;
}
.elf-dropdown-menu.elf-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.elf-dropdown-rtl .elf-dropdown-menu-item-group-title,
.elf-dropdown-menu-submenu-rtl .elf-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.elf-dropdown-menu-submenu-popup.elf-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.elf-dropdown-rtl .elf-dropdown-menu-submenu-popup ul,
.elf-dropdown-rtl .elf-dropdown-menu-submenu-popup li {
  text-align: right;
}
.elf-dropdown-rtl .elf-dropdown-menu-item,
.elf-dropdown-rtl .elf-dropdown-menu-submenu-title {
  text-align: right;
}
.elf-dropdown-rtl .elf-dropdown-menu-item > .anticon:first-child,
.elf-dropdown-rtl .elf-dropdown-menu-submenu-title > .anticon:first-child,
.elf-dropdown-rtl .elf-dropdown-menu-item > span > .anticon:first-child,
.elf-dropdown-rtl .elf-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.elf-dropdown-rtl .elf-dropdown-menu-item .elf-dropdown-menu-submenu-expand-icon,
.elf-dropdown-rtl .elf-dropdown-menu-submenu-title .elf-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.elf-dropdown-rtl .elf-dropdown-menu-item .elf-dropdown-menu-submenu-expand-icon .elf-dropdown-menu-submenu-arrow-icon,
.elf-dropdown-rtl
  .elf-dropdown-menu-submenu-title
  .elf-dropdown-menu-submenu-expand-icon
  .elf-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.elf-dropdown-rtl .elf-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.elf-dropdown-rtl .elf-dropdown-menu-submenu-vertical > .elf-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.elf-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.elf-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.elf-empty-image img {
  height: 100%;
}
.elf-empty-image svg {
  height: 100%;
  margin: auto;
}
.elf-empty-footer {
  margin-top: 16px;
}
.elf-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.elf-empty-normal .elf-empty-image {
  height: 40px;
}
.elf-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.elf-empty-small .elf-empty-image {
  height: 35px;
}
.elf-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.elf-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.elf-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.elf-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.elf-empty-img-default-path-4 {
  fill: #dce0e6;
}
.elf-empty-img-default-path-5 {
  fill: #dce0e6;
}
.elf-empty-img-default-g {
  fill: #fff;
}
.elf-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.elf-empty-img-simple-g {
  stroke: #d9d9d9;
}
.elf-empty-img-simple-path {
  fill: #fafafa;
}
.elf-empty-rtl {
  direction: rtl;
}
.elf-form-item .elf-input-number + .elf-form-text {
  margin-left: 8px;
}
.elf-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.elf-form-inline .elf-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.elf-form-inline .elf-form-item-with-help {
  margin-bottom: 24px;
}
.elf-form-inline .elf-form-item > .elf-form-item-label,
.elf-form-inline .elf-form-item > .elf-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.elf-form-inline .elf-form-item > .elf-form-item-label {
  flex: none;
}
.elf-form-inline .elf-form-item .elf-form-text {
  display: inline-block;
}
.elf-form-inline .elf-form-item .elf-form-item-has-feedback {
  display: inline-block;
}
.elf-form-horizontal .elf-form-item-label {
  flex-grow: 0;
}
.elf-form-horizontal .elf-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}
.elf-form-horizontal .elf-form-item-label[class$='-24'] + .elf-form-item-control,
.elf-form-horizontal .elf-form-item-label[class*='-24 '] + .elf-form-item-control {
  min-width: unset;
}
.elf-form-vertical .elf-form-item-row {
  flex-direction: column;
}
.elf-form-vertical .elf-form-item-label > label {
  height: auto;
}
.elf-form-vertical .elf-form-item .elf-form-item-control {
  width: 100%;
}
.elf-form-vertical .elf-form-item-label,
.elf-col-24.elf-form-item-label,
.elf-col-xl-24.elf-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}
.elf-form-vertical .elf-form-item-label > label,
.elf-col-24.elf-form-item-label > label,
.elf-col-xl-24.elf-form-item-label > label {
  margin: 0;
}
.elf-form-vertical .elf-form-item-label > label::after,
.elf-col-24.elf-form-item-label > label::after,
.elf-col-xl-24.elf-form-item-label > label::after {
  display: none;
}
.elf-form-rtl.elf-form-vertical .elf-form-item-label,
.elf-form-rtl.elf-col-24.elf-form-item-label,
.elf-form-rtl.elf-col-xl-24.elf-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .elf-form-item .elf-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .elf-form-item .elf-form-item-label > label {
    margin: 0;
  }
  .elf-form-item .elf-form-item-label > label::after {
    display: none;
  }
  .elf-form-rtl.elf-form-item .elf-form-item-label {
    text-align: right;
  }
  .elf-form .elf-form-item {
    flex-wrap: wrap;
  }
  .elf-form .elf-form-item .elf-form-item-label,
  .elf-form .elf-form-item .elf-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .elf-col-xs-24.elf-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .elf-col-xs-24.elf-form-item-label > label {
    margin: 0;
  }
  .elf-col-xs-24.elf-form-item-label > label::after {
    display: none;
  }
  .elf-form-rtl.elf-col-xs-24.elf-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .elf-col-sm-24.elf-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .elf-col-sm-24.elf-form-item-label > label {
    margin: 0;
  }
  .elf-col-sm-24.elf-form-item-label > label::after {
    display: none;
  }
  .elf-form-rtl.elf-col-sm-24.elf-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .elf-col-md-24.elf-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .elf-col-md-24.elf-form-item-label > label {
    margin: 0;
  }
  .elf-col-md-24.elf-form-item-label > label::after {
    display: none;
  }
  .elf-form-rtl.elf-col-md-24.elf-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .elf-col-lg-24.elf-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .elf-col-lg-24.elf-form-item-label > label {
    margin: 0;
  }
  .elf-col-lg-24.elf-form-item-label > label::after {
    display: none;
  }
  .elf-form-rtl.elf-col-lg-24.elf-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .elf-col-xl-24.elf-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .elf-col-xl-24.elf-form-item-label > label {
    margin: 0;
  }
  .elf-col-xl-24.elf-form-item-label > label::after {
    display: none;
  }
  .elf-form-rtl.elf-col-xl-24.elf-form-item-label {
    text-align: right;
  }
}
.elf-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.elf-form-item-explain-error {
  color: var(--elf-error-color);
}
.elf-form-item-explain-warning {
  color: var(--elf-warning-color);
}
.elf-form-item-has-feedback .elf-switch {
  margin: 2px 0 4px;
}
.elf-form-item-has-warning .elf-form-item-split {
  color: var(--elf-warning-color);
}
.elf-form-item-has-error .elf-form-item-split {
  color: var(--elf-error-color);
}
.elf-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.elf-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.elf-form label {
  font-size: 14px;
}
.elf-form input[type='search'] {
  box-sizing: border-box;
}
.elf-form input[type='radio'],
.elf-form input[type='checkbox'] {
  line-height: normal;
}
.elf-form input[type='file'] {
  display: block;
}
.elf-form input[type='range'] {
  display: block;
  width: 100%;
}
.elf-form select[multiple],
.elf-form select[size] {
  height: auto;
}
.elf-form input[type='file']:focus,
.elf-form input[type='radio']:focus,
.elf-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.elf-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.elf-form .elf-form-text {
  display: inline-block;
  padding-right: 8px;
}
.elf-form-small .elf-form-item-label > label {
  height: 24px;
}
.elf-form-small .elf-form-item-control-input {
  min-height: 24px;
}
.elf-form-large .elf-form-item-label > label {
  height: 40px;
}
.elf-form-large .elf-form-item-control-input {
  min-height: 40px;
}
.elf-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}
.elf-form-item-with-help {
  transition: none;
}
.elf-form-item-hidden,
.elf-form-item-hidden.elf-row {
  display: none;
}
.elf-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.elf-form-item-label-left {
  text-align: left;
}
.elf-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.elf-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.elf-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.elf-form-item-label > label.elf-form-item-required:not(.elf-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.elf-form-hide-required-mark
  .elf-form-item-label
  > label.elf-form-item-required:not(.elf-form-item-required-mark-optional)::before {
  display: none;
}
.elf-form-item-label > label .elf-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.elf-form-hide-required-mark .elf-form-item-label > label .elf-form-item-optional {
  display: none;
}
.elf-form-item-label > label .elf-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}
.elf-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.elf-form-item-label > label.elf-form-item-no-colon::after {
  content: ' ';
}
.elf-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.elf-form-item-control:first-child:not([class^='elf-col-']):not([class*=' elf-col-']) {
  width: 100%;
}
.elf-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.elf-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.elf-form-item-explain,
.elf-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.elf-form-item-explain-connected {
  width: 100%;
}
.elf-form-item-extra {
  min-height: 24px;
}
.elf-form-item-with-help .elf-form-item-explain {
  height: auto;
  opacity: 1;
}
.elf-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.elf-form-item-feedback-icon-success {
  color: var(--elf-success-color);
}
.elf-form-item-feedback-icon-error {
  color: var(--elf-error-color);
}
.elf-form-item-feedback-icon-warning {
  color: var(--elf-warning-color);
}
.elf-form-item-feedback-icon-validating {
  color: var(--elf-primary-color);
}
.elf-show-help {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-show-help-appear,
.elf-show-help-enter {
  opacity: 0;
}
.elf-show-help-appear-active,
.elf-show-help-enter-active {
  opacity: 1;
}
.elf-show-help-leave {
  opacity: 1;
}
.elf-show-help-leave-active {
  opacity: 0;
}
.elf-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.elf-show-help-item-appear,
.elf-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.elf-show-help-item-appear-active,
.elf-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.elf-show-help-item-leave {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.elf-show-help-item-leave-active {
  transform: translateY(-5px);
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.elf-form-rtl {
  direction: rtl;
}
.elf-form-rtl .elf-form-item-label {
  text-align: left;
}
.elf-form-rtl .elf-form-item-label > label.elf-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.elf-form-rtl .elf-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.elf-form-rtl .elf-form-item-label > label .elf-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.elf-col-rtl .elf-form-item-control:first-child {
  width: 100%;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-input {
  padding-right: 11px;
  padding-left: 24px;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-input-affix-wrapper .elf-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-input-affix-wrapper .elf-input {
  padding: 0;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-input-number-affix-wrapper .elf-input-number {
  padding: 0;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-input-search:not(.elf-input-search-enter-button) .elf-input-suffix {
  right: auto;
  left: 28px;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-input-number {
  padding-left: 18px;
}
.elf-form-rtl .elf-form-item-has-feedback > .elf-select .elf-select-arrow,
.elf-form-rtl .elf-form-item-has-feedback > .elf-select .elf-select-clear,
.elf-form-rtl .elf-form-item-has-feedback :not(.elf-input-group-addon) > .elf-select .elf-select-arrow,
.elf-form-rtl .elf-form-item-has-feedback :not(.elf-input-group-addon) > .elf-select .elf-select-clear,
.elf-form-rtl .elf-form-item-has-feedback :not(.elf-input-number-group-addon) > .elf-select .elf-select-arrow,
.elf-form-rtl .elf-form-item-has-feedback :not(.elf-input-number-group-addon) > .elf-select .elf-select-clear {
  right: auto;
  left: 32px;
}
.elf-form-rtl .elf-form-item-has-feedback > .elf-select .elf-select-selection-selected-value,
.elf-form-rtl
  .elf-form-item-has-feedback
  :not(.elf-input-group-addon)
  > .elf-select
  .elf-select-selection-selected-value,
.elf-form-rtl
  .elf-form-item-has-feedback
  :not(.elf-input-number-group-addon)
  > .elf-select
  .elf-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.elf-form-rtl .elf-form-item-has-feedback .elf-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.elf-form-rtl .elf-form-item-has-feedback.elf-form-item-has-success .elf-form-item-children-icon,
.elf-form-rtl .elf-form-item-has-feedback.elf-form-item-has-warning .elf-form-item-children-icon,
.elf-form-rtl .elf-form-item-has-feedback.elf-form-item-has-error .elf-form-item-children-icon,
.elf-form-rtl .elf-form-item-has-feedback.elf-form-item-is-validating .elf-form-item-children-icon {
  right: auto;
  left: 0;
}
.elf-form-rtl.elf-form-inline .elf-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.elf-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
}
.elf-row::before,
.elf-row::after {
  display: flex;
}
.elf-row-no-wrap {
  flex-wrap: nowrap;
}
.elf-row-start {
  justify-content: flex-start;
}
.elf-row-center {
  justify-content: center;
}
.elf-row-end {
  justify-content: flex-end;
}
.elf-row-space-between {
  justify-content: space-between;
}
.elf-row-space-around {
  justify-content: space-around;
}
.elf-row-space-evenly {
  justify-content: space-evenly;
}
.elf-row-top {
  align-items: flex-start;
}
.elf-row-middle {
  align-items: center;
}
.elf-row-bottom {
  align-items: flex-end;
}
.elf-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.elf-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.elf-col-push-24 {
  left: 100%;
}
.elf-col-pull-24 {
  right: 100%;
}
.elf-col-offset-24 {
  margin-left: 100%;
}
.elf-col-order-24 {
  order: 24;
}
.elf-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.elf-col-push-23 {
  left: 95.83333333%;
}
.elf-col-pull-23 {
  right: 95.83333333%;
}
.elf-col-offset-23 {
  margin-left: 95.83333333%;
}
.elf-col-order-23 {
  order: 23;
}
.elf-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.elf-col-push-22 {
  left: 91.66666667%;
}
.elf-col-pull-22 {
  right: 91.66666667%;
}
.elf-col-offset-22 {
  margin-left: 91.66666667%;
}
.elf-col-order-22 {
  order: 22;
}
.elf-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.elf-col-push-21 {
  left: 87.5%;
}
.elf-col-pull-21 {
  right: 87.5%;
}
.elf-col-offset-21 {
  margin-left: 87.5%;
}
.elf-col-order-21 {
  order: 21;
}
.elf-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.elf-col-push-20 {
  left: 83.33333333%;
}
.elf-col-pull-20 {
  right: 83.33333333%;
}
.elf-col-offset-20 {
  margin-left: 83.33333333%;
}
.elf-col-order-20 {
  order: 20;
}
.elf-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.elf-col-push-19 {
  left: 79.16666667%;
}
.elf-col-pull-19 {
  right: 79.16666667%;
}
.elf-col-offset-19 {
  margin-left: 79.16666667%;
}
.elf-col-order-19 {
  order: 19;
}
.elf-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.elf-col-push-18 {
  left: 75%;
}
.elf-col-pull-18 {
  right: 75%;
}
.elf-col-offset-18 {
  margin-left: 75%;
}
.elf-col-order-18 {
  order: 18;
}
.elf-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.elf-col-push-17 {
  left: 70.83333333%;
}
.elf-col-pull-17 {
  right: 70.83333333%;
}
.elf-col-offset-17 {
  margin-left: 70.83333333%;
}
.elf-col-order-17 {
  order: 17;
}
.elf-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.elf-col-push-16 {
  left: 66.66666667%;
}
.elf-col-pull-16 {
  right: 66.66666667%;
}
.elf-col-offset-16 {
  margin-left: 66.66666667%;
}
.elf-col-order-16 {
  order: 16;
}
.elf-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.elf-col-push-15 {
  left: 62.5%;
}
.elf-col-pull-15 {
  right: 62.5%;
}
.elf-col-offset-15 {
  margin-left: 62.5%;
}
.elf-col-order-15 {
  order: 15;
}
.elf-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.elf-col-push-14 {
  left: 58.33333333%;
}
.elf-col-pull-14 {
  right: 58.33333333%;
}
.elf-col-offset-14 {
  margin-left: 58.33333333%;
}
.elf-col-order-14 {
  order: 14;
}
.elf-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.elf-col-push-13 {
  left: 54.16666667%;
}
.elf-col-pull-13 {
  right: 54.16666667%;
}
.elf-col-offset-13 {
  margin-left: 54.16666667%;
}
.elf-col-order-13 {
  order: 13;
}
.elf-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.elf-col-push-12 {
  left: 50%;
}
.elf-col-pull-12 {
  right: 50%;
}
.elf-col-offset-12 {
  margin-left: 50%;
}
.elf-col-order-12 {
  order: 12;
}
.elf-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.elf-col-push-11 {
  left: 45.83333333%;
}
.elf-col-pull-11 {
  right: 45.83333333%;
}
.elf-col-offset-11 {
  margin-left: 45.83333333%;
}
.elf-col-order-11 {
  order: 11;
}
.elf-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.elf-col-push-10 {
  left: 41.66666667%;
}
.elf-col-pull-10 {
  right: 41.66666667%;
}
.elf-col-offset-10 {
  margin-left: 41.66666667%;
}
.elf-col-order-10 {
  order: 10;
}
.elf-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.elf-col-push-9 {
  left: 37.5%;
}
.elf-col-pull-9 {
  right: 37.5%;
}
.elf-col-offset-9 {
  margin-left: 37.5%;
}
.elf-col-order-9 {
  order: 9;
}
.elf-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.elf-col-push-8 {
  left: 33.33333333%;
}
.elf-col-pull-8 {
  right: 33.33333333%;
}
.elf-col-offset-8 {
  margin-left: 33.33333333%;
}
.elf-col-order-8 {
  order: 8;
}
.elf-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.elf-col-push-7 {
  left: 29.16666667%;
}
.elf-col-pull-7 {
  right: 29.16666667%;
}
.elf-col-offset-7 {
  margin-left: 29.16666667%;
}
.elf-col-order-7 {
  order: 7;
}
.elf-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.elf-col-push-6 {
  left: 25%;
}
.elf-col-pull-6 {
  right: 25%;
}
.elf-col-offset-6 {
  margin-left: 25%;
}
.elf-col-order-6 {
  order: 6;
}
.elf-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.elf-col-push-5 {
  left: 20.83333333%;
}
.elf-col-pull-5 {
  right: 20.83333333%;
}
.elf-col-offset-5 {
  margin-left: 20.83333333%;
}
.elf-col-order-5 {
  order: 5;
}
.elf-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.elf-col-push-4 {
  left: 16.66666667%;
}
.elf-col-pull-4 {
  right: 16.66666667%;
}
.elf-col-offset-4 {
  margin-left: 16.66666667%;
}
.elf-col-order-4 {
  order: 4;
}
.elf-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.elf-col-push-3 {
  left: 12.5%;
}
.elf-col-pull-3 {
  right: 12.5%;
}
.elf-col-offset-3 {
  margin-left: 12.5%;
}
.elf-col-order-3 {
  order: 3;
}
.elf-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.elf-col-push-2 {
  left: 8.33333333%;
}
.elf-col-pull-2 {
  right: 8.33333333%;
}
.elf-col-offset-2 {
  margin-left: 8.33333333%;
}
.elf-col-order-2 {
  order: 2;
}
.elf-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.elf-col-push-1 {
  left: 4.16666667%;
}
.elf-col-pull-1 {
  right: 4.16666667%;
}
.elf-col-offset-1 {
  margin-left: 4.16666667%;
}
.elf-col-order-1 {
  order: 1;
}
.elf-col-0 {
  display: none;
}
.elf-col-push-0 {
  left: auto;
}
.elf-col-pull-0 {
  right: auto;
}
.elf-col-push-0 {
  left: auto;
}
.elf-col-pull-0 {
  right: auto;
}
.elf-col-offset-0 {
  margin-left: 0;
}
.elf-col-order-0 {
  order: 0;
}
.elf-col-push-0.elf-col-rtl {
  right: auto;
}
.elf-col-pull-0.elf-col-rtl {
  left: auto;
}
.elf-col-push-0.elf-col-rtl {
  right: auto;
}
.elf-col-pull-0.elf-col-rtl {
  left: auto;
}
.elf-col-offset-0.elf-col-rtl {
  margin-right: 0;
}
.elf-col-push-1.elf-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.elf-col-pull-1.elf-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.elf-col-offset-1.elf-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.elf-col-push-2.elf-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.elf-col-pull-2.elf-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.elf-col-offset-2.elf-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.elf-col-push-3.elf-col-rtl {
  right: 12.5%;
  left: auto;
}
.elf-col-pull-3.elf-col-rtl {
  right: auto;
  left: 12.5%;
}
.elf-col-offset-3.elf-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.elf-col-push-4.elf-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.elf-col-pull-4.elf-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.elf-col-offset-4.elf-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.elf-col-push-5.elf-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.elf-col-pull-5.elf-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.elf-col-offset-5.elf-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.elf-col-push-6.elf-col-rtl {
  right: 25%;
  left: auto;
}
.elf-col-pull-6.elf-col-rtl {
  right: auto;
  left: 25%;
}
.elf-col-offset-6.elf-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.elf-col-push-7.elf-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.elf-col-pull-7.elf-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.elf-col-offset-7.elf-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.elf-col-push-8.elf-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.elf-col-pull-8.elf-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.elf-col-offset-8.elf-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.elf-col-push-9.elf-col-rtl {
  right: 37.5%;
  left: auto;
}
.elf-col-pull-9.elf-col-rtl {
  right: auto;
  left: 37.5%;
}
.elf-col-offset-9.elf-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.elf-col-push-10.elf-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.elf-col-pull-10.elf-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.elf-col-offset-10.elf-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.elf-col-push-11.elf-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.elf-col-pull-11.elf-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.elf-col-offset-11.elf-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.elf-col-push-12.elf-col-rtl {
  right: 50%;
  left: auto;
}
.elf-col-pull-12.elf-col-rtl {
  right: auto;
  left: 50%;
}
.elf-col-offset-12.elf-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.elf-col-push-13.elf-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.elf-col-pull-13.elf-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.elf-col-offset-13.elf-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.elf-col-push-14.elf-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.elf-col-pull-14.elf-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.elf-col-offset-14.elf-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.elf-col-push-15.elf-col-rtl {
  right: 62.5%;
  left: auto;
}
.elf-col-pull-15.elf-col-rtl {
  right: auto;
  left: 62.5%;
}
.elf-col-offset-15.elf-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.elf-col-push-16.elf-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.elf-col-pull-16.elf-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.elf-col-offset-16.elf-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.elf-col-push-17.elf-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.elf-col-pull-17.elf-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.elf-col-offset-17.elf-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.elf-col-push-18.elf-col-rtl {
  right: 75%;
  left: auto;
}
.elf-col-pull-18.elf-col-rtl {
  right: auto;
  left: 75%;
}
.elf-col-offset-18.elf-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.elf-col-push-19.elf-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.elf-col-pull-19.elf-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.elf-col-offset-19.elf-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.elf-col-push-20.elf-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.elf-col-pull-20.elf-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.elf-col-offset-20.elf-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.elf-col-push-21.elf-col-rtl {
  right: 87.5%;
  left: auto;
}
.elf-col-pull-21.elf-col-rtl {
  right: auto;
  left: 87.5%;
}
.elf-col-offset-21.elf-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.elf-col-push-22.elf-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.elf-col-pull-22.elf-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.elf-col-offset-22.elf-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.elf-col-push-23.elf-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.elf-col-pull-23.elf-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.elf-col-offset-23.elf-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.elf-col-push-24.elf-col-rtl {
  right: 100%;
  left: auto;
}
.elf-col-pull-24.elf-col-rtl {
  right: auto;
  left: 100%;
}
.elf-col-offset-24.elf-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.elf-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.elf-col-xs-push-24 {
  left: 100%;
}
.elf-col-xs-pull-24 {
  right: 100%;
}
.elf-col-xs-offset-24 {
  margin-left: 100%;
}
.elf-col-xs-order-24 {
  order: 24;
}
.elf-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.elf-col-xs-push-23 {
  left: 95.83333333%;
}
.elf-col-xs-pull-23 {
  right: 95.83333333%;
}
.elf-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.elf-col-xs-order-23 {
  order: 23;
}
.elf-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.elf-col-xs-push-22 {
  left: 91.66666667%;
}
.elf-col-xs-pull-22 {
  right: 91.66666667%;
}
.elf-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.elf-col-xs-order-22 {
  order: 22;
}
.elf-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.elf-col-xs-push-21 {
  left: 87.5%;
}
.elf-col-xs-pull-21 {
  right: 87.5%;
}
.elf-col-xs-offset-21 {
  margin-left: 87.5%;
}
.elf-col-xs-order-21 {
  order: 21;
}
.elf-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.elf-col-xs-push-20 {
  left: 83.33333333%;
}
.elf-col-xs-pull-20 {
  right: 83.33333333%;
}
.elf-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.elf-col-xs-order-20 {
  order: 20;
}
.elf-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.elf-col-xs-push-19 {
  left: 79.16666667%;
}
.elf-col-xs-pull-19 {
  right: 79.16666667%;
}
.elf-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.elf-col-xs-order-19 {
  order: 19;
}
.elf-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.elf-col-xs-push-18 {
  left: 75%;
}
.elf-col-xs-pull-18 {
  right: 75%;
}
.elf-col-xs-offset-18 {
  margin-left: 75%;
}
.elf-col-xs-order-18 {
  order: 18;
}
.elf-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.elf-col-xs-push-17 {
  left: 70.83333333%;
}
.elf-col-xs-pull-17 {
  right: 70.83333333%;
}
.elf-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.elf-col-xs-order-17 {
  order: 17;
}
.elf-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.elf-col-xs-push-16 {
  left: 66.66666667%;
}
.elf-col-xs-pull-16 {
  right: 66.66666667%;
}
.elf-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.elf-col-xs-order-16 {
  order: 16;
}
.elf-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.elf-col-xs-push-15 {
  left: 62.5%;
}
.elf-col-xs-pull-15 {
  right: 62.5%;
}
.elf-col-xs-offset-15 {
  margin-left: 62.5%;
}
.elf-col-xs-order-15 {
  order: 15;
}
.elf-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.elf-col-xs-push-14 {
  left: 58.33333333%;
}
.elf-col-xs-pull-14 {
  right: 58.33333333%;
}
.elf-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.elf-col-xs-order-14 {
  order: 14;
}
.elf-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.elf-col-xs-push-13 {
  left: 54.16666667%;
}
.elf-col-xs-pull-13 {
  right: 54.16666667%;
}
.elf-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.elf-col-xs-order-13 {
  order: 13;
}
.elf-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.elf-col-xs-push-12 {
  left: 50%;
}
.elf-col-xs-pull-12 {
  right: 50%;
}
.elf-col-xs-offset-12 {
  margin-left: 50%;
}
.elf-col-xs-order-12 {
  order: 12;
}
.elf-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.elf-col-xs-push-11 {
  left: 45.83333333%;
}
.elf-col-xs-pull-11 {
  right: 45.83333333%;
}
.elf-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.elf-col-xs-order-11 {
  order: 11;
}
.elf-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.elf-col-xs-push-10 {
  left: 41.66666667%;
}
.elf-col-xs-pull-10 {
  right: 41.66666667%;
}
.elf-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.elf-col-xs-order-10 {
  order: 10;
}
.elf-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.elf-col-xs-push-9 {
  left: 37.5%;
}
.elf-col-xs-pull-9 {
  right: 37.5%;
}
.elf-col-xs-offset-9 {
  margin-left: 37.5%;
}
.elf-col-xs-order-9 {
  order: 9;
}
.elf-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.elf-col-xs-push-8 {
  left: 33.33333333%;
}
.elf-col-xs-pull-8 {
  right: 33.33333333%;
}
.elf-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.elf-col-xs-order-8 {
  order: 8;
}
.elf-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.elf-col-xs-push-7 {
  left: 29.16666667%;
}
.elf-col-xs-pull-7 {
  right: 29.16666667%;
}
.elf-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.elf-col-xs-order-7 {
  order: 7;
}
.elf-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.elf-col-xs-push-6 {
  left: 25%;
}
.elf-col-xs-pull-6 {
  right: 25%;
}
.elf-col-xs-offset-6 {
  margin-left: 25%;
}
.elf-col-xs-order-6 {
  order: 6;
}
.elf-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.elf-col-xs-push-5 {
  left: 20.83333333%;
}
.elf-col-xs-pull-5 {
  right: 20.83333333%;
}
.elf-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.elf-col-xs-order-5 {
  order: 5;
}
.elf-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.elf-col-xs-push-4 {
  left: 16.66666667%;
}
.elf-col-xs-pull-4 {
  right: 16.66666667%;
}
.elf-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.elf-col-xs-order-4 {
  order: 4;
}
.elf-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.elf-col-xs-push-3 {
  left: 12.5%;
}
.elf-col-xs-pull-3 {
  right: 12.5%;
}
.elf-col-xs-offset-3 {
  margin-left: 12.5%;
}
.elf-col-xs-order-3 {
  order: 3;
}
.elf-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.elf-col-xs-push-2 {
  left: 8.33333333%;
}
.elf-col-xs-pull-2 {
  right: 8.33333333%;
}
.elf-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.elf-col-xs-order-2 {
  order: 2;
}
.elf-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.elf-col-xs-push-1 {
  left: 4.16666667%;
}
.elf-col-xs-pull-1 {
  right: 4.16666667%;
}
.elf-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.elf-col-xs-order-1 {
  order: 1;
}
.elf-col-xs-0 {
  display: none;
}
.elf-col-push-0 {
  left: auto;
}
.elf-col-pull-0 {
  right: auto;
}
.elf-col-xs-push-0 {
  left: auto;
}
.elf-col-xs-pull-0 {
  right: auto;
}
.elf-col-xs-offset-0 {
  margin-left: 0;
}
.elf-col-xs-order-0 {
  order: 0;
}
.elf-col-push-0.elf-col-rtl {
  right: auto;
}
.elf-col-pull-0.elf-col-rtl {
  left: auto;
}
.elf-col-xs-push-0.elf-col-rtl {
  right: auto;
}
.elf-col-xs-pull-0.elf-col-rtl {
  left: auto;
}
.elf-col-xs-offset-0.elf-col-rtl {
  margin-right: 0;
}
.elf-col-xs-push-1.elf-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.elf-col-xs-pull-1.elf-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.elf-col-xs-offset-1.elf-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.elf-col-xs-push-2.elf-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.elf-col-xs-pull-2.elf-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.elf-col-xs-offset-2.elf-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.elf-col-xs-push-3.elf-col-rtl {
  right: 12.5%;
  left: auto;
}
.elf-col-xs-pull-3.elf-col-rtl {
  right: auto;
  left: 12.5%;
}
.elf-col-xs-offset-3.elf-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.elf-col-xs-push-4.elf-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.elf-col-xs-pull-4.elf-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.elf-col-xs-offset-4.elf-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.elf-col-xs-push-5.elf-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.elf-col-xs-pull-5.elf-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.elf-col-xs-offset-5.elf-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.elf-col-xs-push-6.elf-col-rtl {
  right: 25%;
  left: auto;
}
.elf-col-xs-pull-6.elf-col-rtl {
  right: auto;
  left: 25%;
}
.elf-col-xs-offset-6.elf-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.elf-col-xs-push-7.elf-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.elf-col-xs-pull-7.elf-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.elf-col-xs-offset-7.elf-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.elf-col-xs-push-8.elf-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.elf-col-xs-pull-8.elf-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.elf-col-xs-offset-8.elf-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.elf-col-xs-push-9.elf-col-rtl {
  right: 37.5%;
  left: auto;
}
.elf-col-xs-pull-9.elf-col-rtl {
  right: auto;
  left: 37.5%;
}
.elf-col-xs-offset-9.elf-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.elf-col-xs-push-10.elf-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.elf-col-xs-pull-10.elf-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.elf-col-xs-offset-10.elf-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.elf-col-xs-push-11.elf-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.elf-col-xs-pull-11.elf-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.elf-col-xs-offset-11.elf-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.elf-col-xs-push-12.elf-col-rtl {
  right: 50%;
  left: auto;
}
.elf-col-xs-pull-12.elf-col-rtl {
  right: auto;
  left: 50%;
}
.elf-col-xs-offset-12.elf-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.elf-col-xs-push-13.elf-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.elf-col-xs-pull-13.elf-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.elf-col-xs-offset-13.elf-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.elf-col-xs-push-14.elf-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.elf-col-xs-pull-14.elf-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.elf-col-xs-offset-14.elf-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.elf-col-xs-push-15.elf-col-rtl {
  right: 62.5%;
  left: auto;
}
.elf-col-xs-pull-15.elf-col-rtl {
  right: auto;
  left: 62.5%;
}
.elf-col-xs-offset-15.elf-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.elf-col-xs-push-16.elf-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.elf-col-xs-pull-16.elf-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.elf-col-xs-offset-16.elf-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.elf-col-xs-push-17.elf-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.elf-col-xs-pull-17.elf-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.elf-col-xs-offset-17.elf-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.elf-col-xs-push-18.elf-col-rtl {
  right: 75%;
  left: auto;
}
.elf-col-xs-pull-18.elf-col-rtl {
  right: auto;
  left: 75%;
}
.elf-col-xs-offset-18.elf-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.elf-col-xs-push-19.elf-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.elf-col-xs-pull-19.elf-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.elf-col-xs-offset-19.elf-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.elf-col-xs-push-20.elf-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.elf-col-xs-pull-20.elf-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.elf-col-xs-offset-20.elf-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.elf-col-xs-push-21.elf-col-rtl {
  right: 87.5%;
  left: auto;
}
.elf-col-xs-pull-21.elf-col-rtl {
  right: auto;
  left: 87.5%;
}
.elf-col-xs-offset-21.elf-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.elf-col-xs-push-22.elf-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.elf-col-xs-pull-22.elf-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.elf-col-xs-offset-22.elf-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.elf-col-xs-push-23.elf-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.elf-col-xs-pull-23.elf-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.elf-col-xs-offset-23.elf-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.elf-col-xs-push-24.elf-col-rtl {
  right: 100%;
  left: auto;
}
.elf-col-xs-pull-24.elf-col-rtl {
  right: auto;
  left: 100%;
}
.elf-col-xs-offset-24.elf-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .elf-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .elf-col-sm-push-24 {
    left: 100%;
  }
  .elf-col-sm-pull-24 {
    right: 100%;
  }
  .elf-col-sm-offset-24 {
    margin-left: 100%;
  }
  .elf-col-sm-order-24 {
    order: 24;
  }
  .elf-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .elf-col-sm-push-23 {
    left: 95.83333333%;
  }
  .elf-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .elf-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .elf-col-sm-order-23 {
    order: 23;
  }
  .elf-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .elf-col-sm-push-22 {
    left: 91.66666667%;
  }
  .elf-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .elf-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .elf-col-sm-order-22 {
    order: 22;
  }
  .elf-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .elf-col-sm-push-21 {
    left: 87.5%;
  }
  .elf-col-sm-pull-21 {
    right: 87.5%;
  }
  .elf-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .elf-col-sm-order-21 {
    order: 21;
  }
  .elf-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .elf-col-sm-push-20 {
    left: 83.33333333%;
  }
  .elf-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .elf-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .elf-col-sm-order-20 {
    order: 20;
  }
  .elf-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .elf-col-sm-push-19 {
    left: 79.16666667%;
  }
  .elf-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .elf-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .elf-col-sm-order-19 {
    order: 19;
  }
  .elf-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .elf-col-sm-push-18 {
    left: 75%;
  }
  .elf-col-sm-pull-18 {
    right: 75%;
  }
  .elf-col-sm-offset-18 {
    margin-left: 75%;
  }
  .elf-col-sm-order-18 {
    order: 18;
  }
  .elf-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .elf-col-sm-push-17 {
    left: 70.83333333%;
  }
  .elf-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .elf-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .elf-col-sm-order-17 {
    order: 17;
  }
  .elf-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .elf-col-sm-push-16 {
    left: 66.66666667%;
  }
  .elf-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .elf-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .elf-col-sm-order-16 {
    order: 16;
  }
  .elf-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .elf-col-sm-push-15 {
    left: 62.5%;
  }
  .elf-col-sm-pull-15 {
    right: 62.5%;
  }
  .elf-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .elf-col-sm-order-15 {
    order: 15;
  }
  .elf-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .elf-col-sm-push-14 {
    left: 58.33333333%;
  }
  .elf-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .elf-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .elf-col-sm-order-14 {
    order: 14;
  }
  .elf-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .elf-col-sm-push-13 {
    left: 54.16666667%;
  }
  .elf-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .elf-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .elf-col-sm-order-13 {
    order: 13;
  }
  .elf-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .elf-col-sm-push-12 {
    left: 50%;
  }
  .elf-col-sm-pull-12 {
    right: 50%;
  }
  .elf-col-sm-offset-12 {
    margin-left: 50%;
  }
  .elf-col-sm-order-12 {
    order: 12;
  }
  .elf-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .elf-col-sm-push-11 {
    left: 45.83333333%;
  }
  .elf-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .elf-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .elf-col-sm-order-11 {
    order: 11;
  }
  .elf-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .elf-col-sm-push-10 {
    left: 41.66666667%;
  }
  .elf-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .elf-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .elf-col-sm-order-10 {
    order: 10;
  }
  .elf-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .elf-col-sm-push-9 {
    left: 37.5%;
  }
  .elf-col-sm-pull-9 {
    right: 37.5%;
  }
  .elf-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .elf-col-sm-order-9 {
    order: 9;
  }
  .elf-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .elf-col-sm-push-8 {
    left: 33.33333333%;
  }
  .elf-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .elf-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .elf-col-sm-order-8 {
    order: 8;
  }
  .elf-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .elf-col-sm-push-7 {
    left: 29.16666667%;
  }
  .elf-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .elf-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .elf-col-sm-order-7 {
    order: 7;
  }
  .elf-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .elf-col-sm-push-6 {
    left: 25%;
  }
  .elf-col-sm-pull-6 {
    right: 25%;
  }
  .elf-col-sm-offset-6 {
    margin-left: 25%;
  }
  .elf-col-sm-order-6 {
    order: 6;
  }
  .elf-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .elf-col-sm-push-5 {
    left: 20.83333333%;
  }
  .elf-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .elf-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .elf-col-sm-order-5 {
    order: 5;
  }
  .elf-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .elf-col-sm-push-4 {
    left: 16.66666667%;
  }
  .elf-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .elf-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .elf-col-sm-order-4 {
    order: 4;
  }
  .elf-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .elf-col-sm-push-3 {
    left: 12.5%;
  }
  .elf-col-sm-pull-3 {
    right: 12.5%;
  }
  .elf-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .elf-col-sm-order-3 {
    order: 3;
  }
  .elf-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .elf-col-sm-push-2 {
    left: 8.33333333%;
  }
  .elf-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .elf-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .elf-col-sm-order-2 {
    order: 2;
  }
  .elf-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .elf-col-sm-push-1 {
    left: 4.16666667%;
  }
  .elf-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .elf-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .elf-col-sm-order-1 {
    order: 1;
  }
  .elf-col-sm-0 {
    display: none;
  }
  .elf-col-push-0 {
    left: auto;
  }
  .elf-col-pull-0 {
    right: auto;
  }
  .elf-col-sm-push-0 {
    left: auto;
  }
  .elf-col-sm-pull-0 {
    right: auto;
  }
  .elf-col-sm-offset-0 {
    margin-left: 0;
  }
  .elf-col-sm-order-0 {
    order: 0;
  }
  .elf-col-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-sm-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-sm-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-sm-offset-0.elf-col-rtl {
    margin-right: 0;
  }
  .elf-col-sm-push-1.elf-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .elf-col-sm-pull-1.elf-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .elf-col-sm-offset-1.elf-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .elf-col-sm-push-2.elf-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .elf-col-sm-pull-2.elf-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .elf-col-sm-offset-2.elf-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .elf-col-sm-push-3.elf-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .elf-col-sm-pull-3.elf-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .elf-col-sm-offset-3.elf-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .elf-col-sm-push-4.elf-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .elf-col-sm-pull-4.elf-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .elf-col-sm-offset-4.elf-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .elf-col-sm-push-5.elf-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .elf-col-sm-pull-5.elf-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .elf-col-sm-offset-5.elf-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .elf-col-sm-push-6.elf-col-rtl {
    right: 25%;
    left: auto;
  }
  .elf-col-sm-pull-6.elf-col-rtl {
    right: auto;
    left: 25%;
  }
  .elf-col-sm-offset-6.elf-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .elf-col-sm-push-7.elf-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .elf-col-sm-pull-7.elf-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .elf-col-sm-offset-7.elf-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .elf-col-sm-push-8.elf-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .elf-col-sm-pull-8.elf-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .elf-col-sm-offset-8.elf-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .elf-col-sm-push-9.elf-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .elf-col-sm-pull-9.elf-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .elf-col-sm-offset-9.elf-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .elf-col-sm-push-10.elf-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .elf-col-sm-pull-10.elf-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .elf-col-sm-offset-10.elf-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .elf-col-sm-push-11.elf-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .elf-col-sm-pull-11.elf-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .elf-col-sm-offset-11.elf-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .elf-col-sm-push-12.elf-col-rtl {
    right: 50%;
    left: auto;
  }
  .elf-col-sm-pull-12.elf-col-rtl {
    right: auto;
    left: 50%;
  }
  .elf-col-sm-offset-12.elf-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .elf-col-sm-push-13.elf-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .elf-col-sm-pull-13.elf-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .elf-col-sm-offset-13.elf-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .elf-col-sm-push-14.elf-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .elf-col-sm-pull-14.elf-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .elf-col-sm-offset-14.elf-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .elf-col-sm-push-15.elf-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .elf-col-sm-pull-15.elf-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .elf-col-sm-offset-15.elf-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .elf-col-sm-push-16.elf-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .elf-col-sm-pull-16.elf-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .elf-col-sm-offset-16.elf-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .elf-col-sm-push-17.elf-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .elf-col-sm-pull-17.elf-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .elf-col-sm-offset-17.elf-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .elf-col-sm-push-18.elf-col-rtl {
    right: 75%;
    left: auto;
  }
  .elf-col-sm-pull-18.elf-col-rtl {
    right: auto;
    left: 75%;
  }
  .elf-col-sm-offset-18.elf-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .elf-col-sm-push-19.elf-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .elf-col-sm-pull-19.elf-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .elf-col-sm-offset-19.elf-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .elf-col-sm-push-20.elf-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .elf-col-sm-pull-20.elf-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .elf-col-sm-offset-20.elf-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .elf-col-sm-push-21.elf-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .elf-col-sm-pull-21.elf-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .elf-col-sm-offset-21.elf-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .elf-col-sm-push-22.elf-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .elf-col-sm-pull-22.elf-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .elf-col-sm-offset-22.elf-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .elf-col-sm-push-23.elf-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .elf-col-sm-pull-23.elf-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .elf-col-sm-offset-23.elf-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .elf-col-sm-push-24.elf-col-rtl {
    right: 100%;
    left: auto;
  }
  .elf-col-sm-pull-24.elf-col-rtl {
    right: auto;
    left: 100%;
  }
  .elf-col-sm-offset-24.elf-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .elf-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .elf-col-md-push-24 {
    left: 100%;
  }
  .elf-col-md-pull-24 {
    right: 100%;
  }
  .elf-col-md-offset-24 {
    margin-left: 100%;
  }
  .elf-col-md-order-24 {
    order: 24;
  }
  .elf-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .elf-col-md-push-23 {
    left: 95.83333333%;
  }
  .elf-col-md-pull-23 {
    right: 95.83333333%;
  }
  .elf-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .elf-col-md-order-23 {
    order: 23;
  }
  .elf-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .elf-col-md-push-22 {
    left: 91.66666667%;
  }
  .elf-col-md-pull-22 {
    right: 91.66666667%;
  }
  .elf-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .elf-col-md-order-22 {
    order: 22;
  }
  .elf-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .elf-col-md-push-21 {
    left: 87.5%;
  }
  .elf-col-md-pull-21 {
    right: 87.5%;
  }
  .elf-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .elf-col-md-order-21 {
    order: 21;
  }
  .elf-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .elf-col-md-push-20 {
    left: 83.33333333%;
  }
  .elf-col-md-pull-20 {
    right: 83.33333333%;
  }
  .elf-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .elf-col-md-order-20 {
    order: 20;
  }
  .elf-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .elf-col-md-push-19 {
    left: 79.16666667%;
  }
  .elf-col-md-pull-19 {
    right: 79.16666667%;
  }
  .elf-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .elf-col-md-order-19 {
    order: 19;
  }
  .elf-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .elf-col-md-push-18 {
    left: 75%;
  }
  .elf-col-md-pull-18 {
    right: 75%;
  }
  .elf-col-md-offset-18 {
    margin-left: 75%;
  }
  .elf-col-md-order-18 {
    order: 18;
  }
  .elf-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .elf-col-md-push-17 {
    left: 70.83333333%;
  }
  .elf-col-md-pull-17 {
    right: 70.83333333%;
  }
  .elf-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .elf-col-md-order-17 {
    order: 17;
  }
  .elf-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .elf-col-md-push-16 {
    left: 66.66666667%;
  }
  .elf-col-md-pull-16 {
    right: 66.66666667%;
  }
  .elf-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .elf-col-md-order-16 {
    order: 16;
  }
  .elf-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .elf-col-md-push-15 {
    left: 62.5%;
  }
  .elf-col-md-pull-15 {
    right: 62.5%;
  }
  .elf-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .elf-col-md-order-15 {
    order: 15;
  }
  .elf-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .elf-col-md-push-14 {
    left: 58.33333333%;
  }
  .elf-col-md-pull-14 {
    right: 58.33333333%;
  }
  .elf-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .elf-col-md-order-14 {
    order: 14;
  }
  .elf-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .elf-col-md-push-13 {
    left: 54.16666667%;
  }
  .elf-col-md-pull-13 {
    right: 54.16666667%;
  }
  .elf-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .elf-col-md-order-13 {
    order: 13;
  }
  .elf-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .elf-col-md-push-12 {
    left: 50%;
  }
  .elf-col-md-pull-12 {
    right: 50%;
  }
  .elf-col-md-offset-12 {
    margin-left: 50%;
  }
  .elf-col-md-order-12 {
    order: 12;
  }
  .elf-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .elf-col-md-push-11 {
    left: 45.83333333%;
  }
  .elf-col-md-pull-11 {
    right: 45.83333333%;
  }
  .elf-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .elf-col-md-order-11 {
    order: 11;
  }
  .elf-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .elf-col-md-push-10 {
    left: 41.66666667%;
  }
  .elf-col-md-pull-10 {
    right: 41.66666667%;
  }
  .elf-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .elf-col-md-order-10 {
    order: 10;
  }
  .elf-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .elf-col-md-push-9 {
    left: 37.5%;
  }
  .elf-col-md-pull-9 {
    right: 37.5%;
  }
  .elf-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .elf-col-md-order-9 {
    order: 9;
  }
  .elf-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .elf-col-md-push-8 {
    left: 33.33333333%;
  }
  .elf-col-md-pull-8 {
    right: 33.33333333%;
  }
  .elf-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .elf-col-md-order-8 {
    order: 8;
  }
  .elf-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .elf-col-md-push-7 {
    left: 29.16666667%;
  }
  .elf-col-md-pull-7 {
    right: 29.16666667%;
  }
  .elf-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .elf-col-md-order-7 {
    order: 7;
  }
  .elf-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .elf-col-md-push-6 {
    left: 25%;
  }
  .elf-col-md-pull-6 {
    right: 25%;
  }
  .elf-col-md-offset-6 {
    margin-left: 25%;
  }
  .elf-col-md-order-6 {
    order: 6;
  }
  .elf-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .elf-col-md-push-5 {
    left: 20.83333333%;
  }
  .elf-col-md-pull-5 {
    right: 20.83333333%;
  }
  .elf-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .elf-col-md-order-5 {
    order: 5;
  }
  .elf-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .elf-col-md-push-4 {
    left: 16.66666667%;
  }
  .elf-col-md-pull-4 {
    right: 16.66666667%;
  }
  .elf-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .elf-col-md-order-4 {
    order: 4;
  }
  .elf-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .elf-col-md-push-3 {
    left: 12.5%;
  }
  .elf-col-md-pull-3 {
    right: 12.5%;
  }
  .elf-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .elf-col-md-order-3 {
    order: 3;
  }
  .elf-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .elf-col-md-push-2 {
    left: 8.33333333%;
  }
  .elf-col-md-pull-2 {
    right: 8.33333333%;
  }
  .elf-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .elf-col-md-order-2 {
    order: 2;
  }
  .elf-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .elf-col-md-push-1 {
    left: 4.16666667%;
  }
  .elf-col-md-pull-1 {
    right: 4.16666667%;
  }
  .elf-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .elf-col-md-order-1 {
    order: 1;
  }
  .elf-col-md-0 {
    display: none;
  }
  .elf-col-push-0 {
    left: auto;
  }
  .elf-col-pull-0 {
    right: auto;
  }
  .elf-col-md-push-0 {
    left: auto;
  }
  .elf-col-md-pull-0 {
    right: auto;
  }
  .elf-col-md-offset-0 {
    margin-left: 0;
  }
  .elf-col-md-order-0 {
    order: 0;
  }
  .elf-col-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-md-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-md-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-md-offset-0.elf-col-rtl {
    margin-right: 0;
  }
  .elf-col-md-push-1.elf-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .elf-col-md-pull-1.elf-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .elf-col-md-offset-1.elf-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .elf-col-md-push-2.elf-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .elf-col-md-pull-2.elf-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .elf-col-md-offset-2.elf-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .elf-col-md-push-3.elf-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .elf-col-md-pull-3.elf-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .elf-col-md-offset-3.elf-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .elf-col-md-push-4.elf-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .elf-col-md-pull-4.elf-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .elf-col-md-offset-4.elf-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .elf-col-md-push-5.elf-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .elf-col-md-pull-5.elf-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .elf-col-md-offset-5.elf-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .elf-col-md-push-6.elf-col-rtl {
    right: 25%;
    left: auto;
  }
  .elf-col-md-pull-6.elf-col-rtl {
    right: auto;
    left: 25%;
  }
  .elf-col-md-offset-6.elf-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .elf-col-md-push-7.elf-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .elf-col-md-pull-7.elf-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .elf-col-md-offset-7.elf-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .elf-col-md-push-8.elf-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .elf-col-md-pull-8.elf-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .elf-col-md-offset-8.elf-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .elf-col-md-push-9.elf-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .elf-col-md-pull-9.elf-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .elf-col-md-offset-9.elf-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .elf-col-md-push-10.elf-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .elf-col-md-pull-10.elf-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .elf-col-md-offset-10.elf-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .elf-col-md-push-11.elf-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .elf-col-md-pull-11.elf-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .elf-col-md-offset-11.elf-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .elf-col-md-push-12.elf-col-rtl {
    right: 50%;
    left: auto;
  }
  .elf-col-md-pull-12.elf-col-rtl {
    right: auto;
    left: 50%;
  }
  .elf-col-md-offset-12.elf-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .elf-col-md-push-13.elf-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .elf-col-md-pull-13.elf-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .elf-col-md-offset-13.elf-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .elf-col-md-push-14.elf-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .elf-col-md-pull-14.elf-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .elf-col-md-offset-14.elf-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .elf-col-md-push-15.elf-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .elf-col-md-pull-15.elf-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .elf-col-md-offset-15.elf-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .elf-col-md-push-16.elf-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .elf-col-md-pull-16.elf-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .elf-col-md-offset-16.elf-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .elf-col-md-push-17.elf-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .elf-col-md-pull-17.elf-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .elf-col-md-offset-17.elf-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .elf-col-md-push-18.elf-col-rtl {
    right: 75%;
    left: auto;
  }
  .elf-col-md-pull-18.elf-col-rtl {
    right: auto;
    left: 75%;
  }
  .elf-col-md-offset-18.elf-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .elf-col-md-push-19.elf-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .elf-col-md-pull-19.elf-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .elf-col-md-offset-19.elf-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .elf-col-md-push-20.elf-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .elf-col-md-pull-20.elf-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .elf-col-md-offset-20.elf-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .elf-col-md-push-21.elf-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .elf-col-md-pull-21.elf-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .elf-col-md-offset-21.elf-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .elf-col-md-push-22.elf-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .elf-col-md-pull-22.elf-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .elf-col-md-offset-22.elf-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .elf-col-md-push-23.elf-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .elf-col-md-pull-23.elf-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .elf-col-md-offset-23.elf-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .elf-col-md-push-24.elf-col-rtl {
    right: 100%;
    left: auto;
  }
  .elf-col-md-pull-24.elf-col-rtl {
    right: auto;
    left: 100%;
  }
  .elf-col-md-offset-24.elf-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .elf-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .elf-col-lg-push-24 {
    left: 100%;
  }
  .elf-col-lg-pull-24 {
    right: 100%;
  }
  .elf-col-lg-offset-24 {
    margin-left: 100%;
  }
  .elf-col-lg-order-24 {
    order: 24;
  }
  .elf-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .elf-col-lg-push-23 {
    left: 95.83333333%;
  }
  .elf-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .elf-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .elf-col-lg-order-23 {
    order: 23;
  }
  .elf-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .elf-col-lg-push-22 {
    left: 91.66666667%;
  }
  .elf-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .elf-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .elf-col-lg-order-22 {
    order: 22;
  }
  .elf-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .elf-col-lg-push-21 {
    left: 87.5%;
  }
  .elf-col-lg-pull-21 {
    right: 87.5%;
  }
  .elf-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .elf-col-lg-order-21 {
    order: 21;
  }
  .elf-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .elf-col-lg-push-20 {
    left: 83.33333333%;
  }
  .elf-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .elf-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .elf-col-lg-order-20 {
    order: 20;
  }
  .elf-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .elf-col-lg-push-19 {
    left: 79.16666667%;
  }
  .elf-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .elf-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .elf-col-lg-order-19 {
    order: 19;
  }
  .elf-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .elf-col-lg-push-18 {
    left: 75%;
  }
  .elf-col-lg-pull-18 {
    right: 75%;
  }
  .elf-col-lg-offset-18 {
    margin-left: 75%;
  }
  .elf-col-lg-order-18 {
    order: 18;
  }
  .elf-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .elf-col-lg-push-17 {
    left: 70.83333333%;
  }
  .elf-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .elf-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .elf-col-lg-order-17 {
    order: 17;
  }
  .elf-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .elf-col-lg-push-16 {
    left: 66.66666667%;
  }
  .elf-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .elf-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .elf-col-lg-order-16 {
    order: 16;
  }
  .elf-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .elf-col-lg-push-15 {
    left: 62.5%;
  }
  .elf-col-lg-pull-15 {
    right: 62.5%;
  }
  .elf-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .elf-col-lg-order-15 {
    order: 15;
  }
  .elf-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .elf-col-lg-push-14 {
    left: 58.33333333%;
  }
  .elf-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .elf-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .elf-col-lg-order-14 {
    order: 14;
  }
  .elf-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .elf-col-lg-push-13 {
    left: 54.16666667%;
  }
  .elf-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .elf-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .elf-col-lg-order-13 {
    order: 13;
  }
  .elf-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .elf-col-lg-push-12 {
    left: 50%;
  }
  .elf-col-lg-pull-12 {
    right: 50%;
  }
  .elf-col-lg-offset-12 {
    margin-left: 50%;
  }
  .elf-col-lg-order-12 {
    order: 12;
  }
  .elf-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .elf-col-lg-push-11 {
    left: 45.83333333%;
  }
  .elf-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .elf-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .elf-col-lg-order-11 {
    order: 11;
  }
  .elf-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .elf-col-lg-push-10 {
    left: 41.66666667%;
  }
  .elf-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .elf-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .elf-col-lg-order-10 {
    order: 10;
  }
  .elf-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .elf-col-lg-push-9 {
    left: 37.5%;
  }
  .elf-col-lg-pull-9 {
    right: 37.5%;
  }
  .elf-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .elf-col-lg-order-9 {
    order: 9;
  }
  .elf-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .elf-col-lg-push-8 {
    left: 33.33333333%;
  }
  .elf-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .elf-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .elf-col-lg-order-8 {
    order: 8;
  }
  .elf-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .elf-col-lg-push-7 {
    left: 29.16666667%;
  }
  .elf-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .elf-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .elf-col-lg-order-7 {
    order: 7;
  }
  .elf-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .elf-col-lg-push-6 {
    left: 25%;
  }
  .elf-col-lg-pull-6 {
    right: 25%;
  }
  .elf-col-lg-offset-6 {
    margin-left: 25%;
  }
  .elf-col-lg-order-6 {
    order: 6;
  }
  .elf-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .elf-col-lg-push-5 {
    left: 20.83333333%;
  }
  .elf-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .elf-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .elf-col-lg-order-5 {
    order: 5;
  }
  .elf-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .elf-col-lg-push-4 {
    left: 16.66666667%;
  }
  .elf-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .elf-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .elf-col-lg-order-4 {
    order: 4;
  }
  .elf-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .elf-col-lg-push-3 {
    left: 12.5%;
  }
  .elf-col-lg-pull-3 {
    right: 12.5%;
  }
  .elf-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .elf-col-lg-order-3 {
    order: 3;
  }
  .elf-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .elf-col-lg-push-2 {
    left: 8.33333333%;
  }
  .elf-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .elf-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .elf-col-lg-order-2 {
    order: 2;
  }
  .elf-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .elf-col-lg-push-1 {
    left: 4.16666667%;
  }
  .elf-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .elf-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .elf-col-lg-order-1 {
    order: 1;
  }
  .elf-col-lg-0 {
    display: none;
  }
  .elf-col-push-0 {
    left: auto;
  }
  .elf-col-pull-0 {
    right: auto;
  }
  .elf-col-lg-push-0 {
    left: auto;
  }
  .elf-col-lg-pull-0 {
    right: auto;
  }
  .elf-col-lg-offset-0 {
    margin-left: 0;
  }
  .elf-col-lg-order-0 {
    order: 0;
  }
  .elf-col-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-lg-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-lg-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-lg-offset-0.elf-col-rtl {
    margin-right: 0;
  }
  .elf-col-lg-push-1.elf-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .elf-col-lg-pull-1.elf-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .elf-col-lg-offset-1.elf-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .elf-col-lg-push-2.elf-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .elf-col-lg-pull-2.elf-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .elf-col-lg-offset-2.elf-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .elf-col-lg-push-3.elf-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .elf-col-lg-pull-3.elf-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .elf-col-lg-offset-3.elf-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .elf-col-lg-push-4.elf-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .elf-col-lg-pull-4.elf-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .elf-col-lg-offset-4.elf-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .elf-col-lg-push-5.elf-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .elf-col-lg-pull-5.elf-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .elf-col-lg-offset-5.elf-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .elf-col-lg-push-6.elf-col-rtl {
    right: 25%;
    left: auto;
  }
  .elf-col-lg-pull-6.elf-col-rtl {
    right: auto;
    left: 25%;
  }
  .elf-col-lg-offset-6.elf-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .elf-col-lg-push-7.elf-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .elf-col-lg-pull-7.elf-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .elf-col-lg-offset-7.elf-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .elf-col-lg-push-8.elf-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .elf-col-lg-pull-8.elf-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .elf-col-lg-offset-8.elf-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .elf-col-lg-push-9.elf-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .elf-col-lg-pull-9.elf-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .elf-col-lg-offset-9.elf-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .elf-col-lg-push-10.elf-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .elf-col-lg-pull-10.elf-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .elf-col-lg-offset-10.elf-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .elf-col-lg-push-11.elf-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .elf-col-lg-pull-11.elf-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .elf-col-lg-offset-11.elf-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .elf-col-lg-push-12.elf-col-rtl {
    right: 50%;
    left: auto;
  }
  .elf-col-lg-pull-12.elf-col-rtl {
    right: auto;
    left: 50%;
  }
  .elf-col-lg-offset-12.elf-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .elf-col-lg-push-13.elf-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .elf-col-lg-pull-13.elf-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .elf-col-lg-offset-13.elf-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .elf-col-lg-push-14.elf-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .elf-col-lg-pull-14.elf-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .elf-col-lg-offset-14.elf-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .elf-col-lg-push-15.elf-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .elf-col-lg-pull-15.elf-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .elf-col-lg-offset-15.elf-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .elf-col-lg-push-16.elf-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .elf-col-lg-pull-16.elf-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .elf-col-lg-offset-16.elf-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .elf-col-lg-push-17.elf-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .elf-col-lg-pull-17.elf-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .elf-col-lg-offset-17.elf-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .elf-col-lg-push-18.elf-col-rtl {
    right: 75%;
    left: auto;
  }
  .elf-col-lg-pull-18.elf-col-rtl {
    right: auto;
    left: 75%;
  }
  .elf-col-lg-offset-18.elf-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .elf-col-lg-push-19.elf-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .elf-col-lg-pull-19.elf-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .elf-col-lg-offset-19.elf-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .elf-col-lg-push-20.elf-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .elf-col-lg-pull-20.elf-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .elf-col-lg-offset-20.elf-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .elf-col-lg-push-21.elf-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .elf-col-lg-pull-21.elf-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .elf-col-lg-offset-21.elf-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .elf-col-lg-push-22.elf-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .elf-col-lg-pull-22.elf-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .elf-col-lg-offset-22.elf-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .elf-col-lg-push-23.elf-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .elf-col-lg-pull-23.elf-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .elf-col-lg-offset-23.elf-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .elf-col-lg-push-24.elf-col-rtl {
    right: 100%;
    left: auto;
  }
  .elf-col-lg-pull-24.elf-col-rtl {
    right: auto;
    left: 100%;
  }
  .elf-col-lg-offset-24.elf-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .elf-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .elf-col-xl-push-24 {
    left: 100%;
  }
  .elf-col-xl-pull-24 {
    right: 100%;
  }
  .elf-col-xl-offset-24 {
    margin-left: 100%;
  }
  .elf-col-xl-order-24 {
    order: 24;
  }
  .elf-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .elf-col-xl-push-23 {
    left: 95.83333333%;
  }
  .elf-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .elf-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .elf-col-xl-order-23 {
    order: 23;
  }
  .elf-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .elf-col-xl-push-22 {
    left: 91.66666667%;
  }
  .elf-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .elf-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .elf-col-xl-order-22 {
    order: 22;
  }
  .elf-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .elf-col-xl-push-21 {
    left: 87.5%;
  }
  .elf-col-xl-pull-21 {
    right: 87.5%;
  }
  .elf-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .elf-col-xl-order-21 {
    order: 21;
  }
  .elf-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .elf-col-xl-push-20 {
    left: 83.33333333%;
  }
  .elf-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .elf-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .elf-col-xl-order-20 {
    order: 20;
  }
  .elf-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .elf-col-xl-push-19 {
    left: 79.16666667%;
  }
  .elf-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .elf-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .elf-col-xl-order-19 {
    order: 19;
  }
  .elf-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .elf-col-xl-push-18 {
    left: 75%;
  }
  .elf-col-xl-pull-18 {
    right: 75%;
  }
  .elf-col-xl-offset-18 {
    margin-left: 75%;
  }
  .elf-col-xl-order-18 {
    order: 18;
  }
  .elf-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .elf-col-xl-push-17 {
    left: 70.83333333%;
  }
  .elf-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .elf-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .elf-col-xl-order-17 {
    order: 17;
  }
  .elf-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .elf-col-xl-push-16 {
    left: 66.66666667%;
  }
  .elf-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .elf-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .elf-col-xl-order-16 {
    order: 16;
  }
  .elf-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .elf-col-xl-push-15 {
    left: 62.5%;
  }
  .elf-col-xl-pull-15 {
    right: 62.5%;
  }
  .elf-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .elf-col-xl-order-15 {
    order: 15;
  }
  .elf-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .elf-col-xl-push-14 {
    left: 58.33333333%;
  }
  .elf-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .elf-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .elf-col-xl-order-14 {
    order: 14;
  }
  .elf-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .elf-col-xl-push-13 {
    left: 54.16666667%;
  }
  .elf-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .elf-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .elf-col-xl-order-13 {
    order: 13;
  }
  .elf-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .elf-col-xl-push-12 {
    left: 50%;
  }
  .elf-col-xl-pull-12 {
    right: 50%;
  }
  .elf-col-xl-offset-12 {
    margin-left: 50%;
  }
  .elf-col-xl-order-12 {
    order: 12;
  }
  .elf-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .elf-col-xl-push-11 {
    left: 45.83333333%;
  }
  .elf-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .elf-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .elf-col-xl-order-11 {
    order: 11;
  }
  .elf-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .elf-col-xl-push-10 {
    left: 41.66666667%;
  }
  .elf-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .elf-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .elf-col-xl-order-10 {
    order: 10;
  }
  .elf-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .elf-col-xl-push-9 {
    left: 37.5%;
  }
  .elf-col-xl-pull-9 {
    right: 37.5%;
  }
  .elf-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .elf-col-xl-order-9 {
    order: 9;
  }
  .elf-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .elf-col-xl-push-8 {
    left: 33.33333333%;
  }
  .elf-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .elf-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .elf-col-xl-order-8 {
    order: 8;
  }
  .elf-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .elf-col-xl-push-7 {
    left: 29.16666667%;
  }
  .elf-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .elf-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .elf-col-xl-order-7 {
    order: 7;
  }
  .elf-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .elf-col-xl-push-6 {
    left: 25%;
  }
  .elf-col-xl-pull-6 {
    right: 25%;
  }
  .elf-col-xl-offset-6 {
    margin-left: 25%;
  }
  .elf-col-xl-order-6 {
    order: 6;
  }
  .elf-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .elf-col-xl-push-5 {
    left: 20.83333333%;
  }
  .elf-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .elf-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .elf-col-xl-order-5 {
    order: 5;
  }
  .elf-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .elf-col-xl-push-4 {
    left: 16.66666667%;
  }
  .elf-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .elf-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .elf-col-xl-order-4 {
    order: 4;
  }
  .elf-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .elf-col-xl-push-3 {
    left: 12.5%;
  }
  .elf-col-xl-pull-3 {
    right: 12.5%;
  }
  .elf-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .elf-col-xl-order-3 {
    order: 3;
  }
  .elf-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .elf-col-xl-push-2 {
    left: 8.33333333%;
  }
  .elf-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .elf-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .elf-col-xl-order-2 {
    order: 2;
  }
  .elf-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .elf-col-xl-push-1 {
    left: 4.16666667%;
  }
  .elf-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .elf-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .elf-col-xl-order-1 {
    order: 1;
  }
  .elf-col-xl-0 {
    display: none;
  }
  .elf-col-push-0 {
    left: auto;
  }
  .elf-col-pull-0 {
    right: auto;
  }
  .elf-col-xl-push-0 {
    left: auto;
  }
  .elf-col-xl-pull-0 {
    right: auto;
  }
  .elf-col-xl-offset-0 {
    margin-left: 0;
  }
  .elf-col-xl-order-0 {
    order: 0;
  }
  .elf-col-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-xl-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-xl-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-xl-offset-0.elf-col-rtl {
    margin-right: 0;
  }
  .elf-col-xl-push-1.elf-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .elf-col-xl-pull-1.elf-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .elf-col-xl-offset-1.elf-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .elf-col-xl-push-2.elf-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .elf-col-xl-pull-2.elf-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .elf-col-xl-offset-2.elf-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .elf-col-xl-push-3.elf-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .elf-col-xl-pull-3.elf-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .elf-col-xl-offset-3.elf-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .elf-col-xl-push-4.elf-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .elf-col-xl-pull-4.elf-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .elf-col-xl-offset-4.elf-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .elf-col-xl-push-5.elf-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .elf-col-xl-pull-5.elf-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .elf-col-xl-offset-5.elf-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .elf-col-xl-push-6.elf-col-rtl {
    right: 25%;
    left: auto;
  }
  .elf-col-xl-pull-6.elf-col-rtl {
    right: auto;
    left: 25%;
  }
  .elf-col-xl-offset-6.elf-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .elf-col-xl-push-7.elf-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .elf-col-xl-pull-7.elf-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .elf-col-xl-offset-7.elf-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .elf-col-xl-push-8.elf-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .elf-col-xl-pull-8.elf-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .elf-col-xl-offset-8.elf-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .elf-col-xl-push-9.elf-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .elf-col-xl-pull-9.elf-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .elf-col-xl-offset-9.elf-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .elf-col-xl-push-10.elf-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .elf-col-xl-pull-10.elf-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .elf-col-xl-offset-10.elf-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .elf-col-xl-push-11.elf-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .elf-col-xl-pull-11.elf-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .elf-col-xl-offset-11.elf-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .elf-col-xl-push-12.elf-col-rtl {
    right: 50%;
    left: auto;
  }
  .elf-col-xl-pull-12.elf-col-rtl {
    right: auto;
    left: 50%;
  }
  .elf-col-xl-offset-12.elf-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .elf-col-xl-push-13.elf-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .elf-col-xl-pull-13.elf-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .elf-col-xl-offset-13.elf-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .elf-col-xl-push-14.elf-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .elf-col-xl-pull-14.elf-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .elf-col-xl-offset-14.elf-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .elf-col-xl-push-15.elf-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .elf-col-xl-pull-15.elf-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .elf-col-xl-offset-15.elf-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .elf-col-xl-push-16.elf-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .elf-col-xl-pull-16.elf-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .elf-col-xl-offset-16.elf-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .elf-col-xl-push-17.elf-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .elf-col-xl-pull-17.elf-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .elf-col-xl-offset-17.elf-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .elf-col-xl-push-18.elf-col-rtl {
    right: 75%;
    left: auto;
  }
  .elf-col-xl-pull-18.elf-col-rtl {
    right: auto;
    left: 75%;
  }
  .elf-col-xl-offset-18.elf-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .elf-col-xl-push-19.elf-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .elf-col-xl-pull-19.elf-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .elf-col-xl-offset-19.elf-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .elf-col-xl-push-20.elf-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .elf-col-xl-pull-20.elf-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .elf-col-xl-offset-20.elf-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .elf-col-xl-push-21.elf-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .elf-col-xl-pull-21.elf-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .elf-col-xl-offset-21.elf-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .elf-col-xl-push-22.elf-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .elf-col-xl-pull-22.elf-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .elf-col-xl-offset-22.elf-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .elf-col-xl-push-23.elf-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .elf-col-xl-pull-23.elf-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .elf-col-xl-offset-23.elf-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .elf-col-xl-push-24.elf-col-rtl {
    right: 100%;
    left: auto;
  }
  .elf-col-xl-pull-24.elf-col-rtl {
    right: auto;
    left: 100%;
  }
  .elf-col-xl-offset-24.elf-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .elf-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .elf-col-xxl-push-24 {
    left: 100%;
  }
  .elf-col-xxl-pull-24 {
    right: 100%;
  }
  .elf-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .elf-col-xxl-order-24 {
    order: 24;
  }
  .elf-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .elf-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .elf-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .elf-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .elf-col-xxl-order-23 {
    order: 23;
  }
  .elf-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .elf-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .elf-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .elf-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .elf-col-xxl-order-22 {
    order: 22;
  }
  .elf-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .elf-col-xxl-push-21 {
    left: 87.5%;
  }
  .elf-col-xxl-pull-21 {
    right: 87.5%;
  }
  .elf-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .elf-col-xxl-order-21 {
    order: 21;
  }
  .elf-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .elf-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .elf-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .elf-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .elf-col-xxl-order-20 {
    order: 20;
  }
  .elf-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .elf-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .elf-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .elf-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .elf-col-xxl-order-19 {
    order: 19;
  }
  .elf-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .elf-col-xxl-push-18 {
    left: 75%;
  }
  .elf-col-xxl-pull-18 {
    right: 75%;
  }
  .elf-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .elf-col-xxl-order-18 {
    order: 18;
  }
  .elf-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .elf-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .elf-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .elf-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .elf-col-xxl-order-17 {
    order: 17;
  }
  .elf-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .elf-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .elf-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .elf-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .elf-col-xxl-order-16 {
    order: 16;
  }
  .elf-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .elf-col-xxl-push-15 {
    left: 62.5%;
  }
  .elf-col-xxl-pull-15 {
    right: 62.5%;
  }
  .elf-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .elf-col-xxl-order-15 {
    order: 15;
  }
  .elf-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .elf-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .elf-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .elf-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .elf-col-xxl-order-14 {
    order: 14;
  }
  .elf-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .elf-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .elf-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .elf-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .elf-col-xxl-order-13 {
    order: 13;
  }
  .elf-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .elf-col-xxl-push-12 {
    left: 50%;
  }
  .elf-col-xxl-pull-12 {
    right: 50%;
  }
  .elf-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .elf-col-xxl-order-12 {
    order: 12;
  }
  .elf-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .elf-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .elf-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .elf-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .elf-col-xxl-order-11 {
    order: 11;
  }
  .elf-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .elf-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .elf-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .elf-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .elf-col-xxl-order-10 {
    order: 10;
  }
  .elf-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .elf-col-xxl-push-9 {
    left: 37.5%;
  }
  .elf-col-xxl-pull-9 {
    right: 37.5%;
  }
  .elf-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .elf-col-xxl-order-9 {
    order: 9;
  }
  .elf-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .elf-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .elf-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .elf-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .elf-col-xxl-order-8 {
    order: 8;
  }
  .elf-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .elf-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .elf-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .elf-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .elf-col-xxl-order-7 {
    order: 7;
  }
  .elf-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .elf-col-xxl-push-6 {
    left: 25%;
  }
  .elf-col-xxl-pull-6 {
    right: 25%;
  }
  .elf-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .elf-col-xxl-order-6 {
    order: 6;
  }
  .elf-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .elf-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .elf-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .elf-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .elf-col-xxl-order-5 {
    order: 5;
  }
  .elf-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .elf-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .elf-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .elf-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .elf-col-xxl-order-4 {
    order: 4;
  }
  .elf-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .elf-col-xxl-push-3 {
    left: 12.5%;
  }
  .elf-col-xxl-pull-3 {
    right: 12.5%;
  }
  .elf-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .elf-col-xxl-order-3 {
    order: 3;
  }
  .elf-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .elf-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .elf-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .elf-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .elf-col-xxl-order-2 {
    order: 2;
  }
  .elf-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .elf-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .elf-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .elf-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .elf-col-xxl-order-1 {
    order: 1;
  }
  .elf-col-xxl-0 {
    display: none;
  }
  .elf-col-push-0 {
    left: auto;
  }
  .elf-col-pull-0 {
    right: auto;
  }
  .elf-col-xxl-push-0 {
    left: auto;
  }
  .elf-col-xxl-pull-0 {
    right: auto;
  }
  .elf-col-xxl-offset-0 {
    margin-left: 0;
  }
  .elf-col-xxl-order-0 {
    order: 0;
  }
  .elf-col-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-xxl-push-0.elf-col-rtl {
    right: auto;
  }
  .elf-col-xxl-pull-0.elf-col-rtl {
    left: auto;
  }
  .elf-col-xxl-offset-0.elf-col-rtl {
    margin-right: 0;
  }
  .elf-col-xxl-push-1.elf-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .elf-col-xxl-pull-1.elf-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .elf-col-xxl-offset-1.elf-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .elf-col-xxl-push-2.elf-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .elf-col-xxl-pull-2.elf-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .elf-col-xxl-offset-2.elf-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .elf-col-xxl-push-3.elf-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .elf-col-xxl-pull-3.elf-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .elf-col-xxl-offset-3.elf-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .elf-col-xxl-push-4.elf-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .elf-col-xxl-pull-4.elf-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .elf-col-xxl-offset-4.elf-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .elf-col-xxl-push-5.elf-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .elf-col-xxl-pull-5.elf-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .elf-col-xxl-offset-5.elf-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .elf-col-xxl-push-6.elf-col-rtl {
    right: 25%;
    left: auto;
  }
  .elf-col-xxl-pull-6.elf-col-rtl {
    right: auto;
    left: 25%;
  }
  .elf-col-xxl-offset-6.elf-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .elf-col-xxl-push-7.elf-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .elf-col-xxl-pull-7.elf-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .elf-col-xxl-offset-7.elf-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .elf-col-xxl-push-8.elf-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .elf-col-xxl-pull-8.elf-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .elf-col-xxl-offset-8.elf-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .elf-col-xxl-push-9.elf-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .elf-col-xxl-pull-9.elf-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .elf-col-xxl-offset-9.elf-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .elf-col-xxl-push-10.elf-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .elf-col-xxl-pull-10.elf-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .elf-col-xxl-offset-10.elf-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .elf-col-xxl-push-11.elf-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .elf-col-xxl-pull-11.elf-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .elf-col-xxl-offset-11.elf-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .elf-col-xxl-push-12.elf-col-rtl {
    right: 50%;
    left: auto;
  }
  .elf-col-xxl-pull-12.elf-col-rtl {
    right: auto;
    left: 50%;
  }
  .elf-col-xxl-offset-12.elf-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .elf-col-xxl-push-13.elf-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .elf-col-xxl-pull-13.elf-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .elf-col-xxl-offset-13.elf-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .elf-col-xxl-push-14.elf-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .elf-col-xxl-pull-14.elf-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .elf-col-xxl-offset-14.elf-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .elf-col-xxl-push-15.elf-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .elf-col-xxl-pull-15.elf-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .elf-col-xxl-offset-15.elf-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .elf-col-xxl-push-16.elf-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .elf-col-xxl-pull-16.elf-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .elf-col-xxl-offset-16.elf-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .elf-col-xxl-push-17.elf-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .elf-col-xxl-pull-17.elf-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .elf-col-xxl-offset-17.elf-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .elf-col-xxl-push-18.elf-col-rtl {
    right: 75%;
    left: auto;
  }
  .elf-col-xxl-pull-18.elf-col-rtl {
    right: auto;
    left: 75%;
  }
  .elf-col-xxl-offset-18.elf-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .elf-col-xxl-push-19.elf-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .elf-col-xxl-pull-19.elf-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .elf-col-xxl-offset-19.elf-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .elf-col-xxl-push-20.elf-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .elf-col-xxl-pull-20.elf-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .elf-col-xxl-offset-20.elf-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .elf-col-xxl-push-21.elf-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .elf-col-xxl-pull-21.elf-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .elf-col-xxl-offset-21.elf-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .elf-col-xxl-push-22.elf-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .elf-col-xxl-pull-22.elf-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .elf-col-xxl-offset-22.elf-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .elf-col-xxl-push-23.elf-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .elf-col-xxl-pull-23.elf-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .elf-col-xxl-offset-23.elf-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .elf-col-xxl-push-24.elf-col-rtl {
    right: 100%;
    left: auto;
  }
  .elf-col-xxl-pull-24.elf-col-rtl {
    right: auto;
    left: 100%;
  }
  .elf-col-xxl-offset-24.elf-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.elf-row-rtl {
  direction: rtl;
}
.elf-image {
  position: relative;
  display: inline-block;
}
.elf-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.elf-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.elf-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.elf-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-image-mask-info .anticon {
  margin-inline-end: 4px;
}
.elf-image-mask:hover {
  opacity: 1;
}
.elf-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.elf-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.elf-image-preview.elf-zoom-enter,
.elf-image-preview.elf-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.elf-image-preview-mask-hidden {
  display: none;
}
.elf-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.elf-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.elf-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: auto;
}
.elf-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.elf-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.elf-image-preview-moving .elf-image-preview-img {
  cursor: grabbing;
}
.elf-image-preview-moving .elf-image-preview-img-wrapper {
  transition-duration: 0s;
}
.elf-image-preview-wrap {
  z-index: 1080;
}
.elf-image-preview-operations-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1081;
  width: 100%;
}
.elf-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.elf-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-image-preview-operations-operation:hover {
  background: rgba(0, 0, 0, 0.2);
}
.elf-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.elf-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.elf-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.elf-image-preview-operations-icon {
  font-size: 18px;
}
.elf-image-preview-switch-left,
.elf-image-preview-switch-right {
  position: fixed;
  top: 50%;
  right: 8px;
  z-index: 1081;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s;
  pointer-events: auto;
}
.elf-image-preview-switch-left:hover,
.elf-image-preview-switch-right:hover {
  background: rgba(0, 0, 0, 0.2);
}
.elf-image-preview-switch-left-disabled,
.elf-image-preview-switch-right-disabled,
.elf-image-preview-switch-left-disabled:hover,
.elf-image-preview-switch-right-disabled:hover {
  color: rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}
.elf-image-preview-switch-left-disabled > .anticon,
.elf-image-preview-switch-right-disabled > .anticon,
.elf-image-preview-switch-left-disabled:hover > .anticon,
.elf-image-preview-switch-right-disabled:hover > .anticon {
  cursor: not-allowed;
}
.elf-image-preview-switch-left > .anticon,
.elf-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.elf-image-preview-switch-left {
  left: 8px;
}
.elf-image-preview-switch-right {
  right: 8px;
}
.elf-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.elf-input-affix-wrapper::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.elf-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input-affix-wrapper:hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-affix-wrapper:focus,
.elf-input-affix-wrapper-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-input-affix-wrapper:focus,
.elf-input-rtl .elf-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input-affix-wrapper-borderless,
.elf-input-affix-wrapper-borderless:hover,
.elf-input-affix-wrapper-borderless:focus,
.elf-input-affix-wrapper-borderless-focused,
.elf-input-affix-wrapper-borderless-disabled,
.elf-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.elf-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.elf-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.elf-input-affix-wrapper-rtl {
  direction: rtl;
}
.elf-input-affix-wrapper:not(.elf-input-affix-wrapper-disabled):hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
  z-index: 1;
}
.elf-input-rtl .elf-input-affix-wrapper:not(.elf-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-search-with-button .elf-input-affix-wrapper:not(.elf-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.elf-input-affix-wrapper-focused,
.elf-input-affix-wrapper:focus {
  z-index: 1;
}
.elf-input-affix-wrapper-disabled .elf-input[disabled] {
  background: transparent;
}
.elf-input-affix-wrapper > .elf-input {
  font-size: inherit;
  border: none;
  outline: none;
}
.elf-input-affix-wrapper > .elf-input:focus {
  box-shadow: none !important;
}
.elf-input-affix-wrapper > .elf-input:not(textarea) {
  padding: 0;
}
.elf-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.elf-input-prefix,
.elf-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.elf-input-prefix > *:not(:last-child),
.elf-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.elf-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}
.elf-input-show-count-has-suffix {
  margin-right: 2px;
}
.elf-input-prefix {
  margin-right: 4px;
}
.elf-input-suffix {
  margin-left: 4px;
}
.anticon.elf-input-clear-icon,
.elf-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.anticon.elf-input-clear-icon:hover,
.elf-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.anticon.elf-input-clear-icon:active,
.elf-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}
.anticon.elf-input-clear-icon-hidden,
.elf-input-clear-icon-hidden {
  visibility: hidden;
}
.anticon.elf-input-clear-icon-has-suffix,
.elf-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.elf-input-affix-wrapper.elf-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
}
.elf-input-affix-wrapper.elf-input-affix-wrapper-textarea-with-clear-btn .elf-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.elf-input-status-error:not(.elf-input-disabled):not(.elf-input-borderless).elf-input,
.elf-input-status-error:not(.elf-input-disabled):not(.elf-input-borderless).elf-input:hover {
  background: #18161e;
  border-color: var(--elf-error-color);
}
.elf-input-status-error:not(.elf-input-disabled):not(.elf-input-borderless).elf-input:focus,
.elf-input-status-error:not(.elf-input-disabled):not(.elf-input-borderless).elf-input-focused {
  border-color: var(--elf-error-color-hover);
  box-shadow: 0 0 0 2px var(--elf-error-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-status-error .elf-input-prefix {
  color: var(--elf-error-color);
}
.elf-input-status-warning:not(.elf-input-disabled):not(.elf-input-borderless).elf-input,
.elf-input-status-warning:not(.elf-input-disabled):not(.elf-input-borderless).elf-input:hover {
  background: #fff;
  border-color: var(--elf-warning-color);
}
.elf-input-status-warning:not(.elf-input-disabled):not(.elf-input-borderless).elf-input:focus,
.elf-input-status-warning:not(.elf-input-disabled):not(.elf-input-borderless).elf-input-focused {
  border-color: var(--elf-warning-color-hover);
  box-shadow: 0 0 0 2px var(--elf-warning-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-status-warning .elf-input-prefix {
  color: var(--elf-warning-color);
}
.elf-input-affix-wrapper-status-error:not(.elf-input-affix-wrapper-disabled):not(
    .elf-input-affix-wrapper-borderless
  ).elf-input-affix-wrapper,
.elf-input-affix-wrapper-status-error:not(.elf-input-affix-wrapper-disabled):not(
    .elf-input-affix-wrapper-borderless
  ).elf-input-affix-wrapper:hover {
  background: #fff;
  border-color: var(--elf-error-color);
}
.elf-input-affix-wrapper-status-error:not(.elf-input-affix-wrapper-disabled):not(
    .elf-input-affix-wrapper-borderless
  ).elf-input-affix-wrapper:focus,
.elf-input-affix-wrapper-status-error:not(.elf-input-affix-wrapper-disabled):not(
    .elf-input-affix-wrapper-borderless
  ).elf-input-affix-wrapper-focused {
  border-color: var(--elf-error-color-hover);
  box-shadow: 0 0 0 2px var(--elf-error-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-affix-wrapper-status-error .elf-input-prefix {
  color: var(--elf-error-color);
}
.elf-input-affix-wrapper-status-warning:not(.elf-input-affix-wrapper-disabled):not(
    .elf-input-affix-wrapper-borderless
  ).elf-input-affix-wrapper,
.elf-input-affix-wrapper-status-warning:not(.elf-input-affix-wrapper-disabled):not(
    .elf-input-affix-wrapper-borderless
  ).elf-input-affix-wrapper:hover {
  background: #fff;
  border-color: var(--elf-warning-color);
}
.elf-input-affix-wrapper-status-warning:not(.elf-input-affix-wrapper-disabled):not(
    .elf-input-affix-wrapper-borderless
  ).elf-input-affix-wrapper:focus,
.elf-input-affix-wrapper-status-warning:not(.elf-input-affix-wrapper-disabled):not(
    .elf-input-affix-wrapper-borderless
  ).elf-input-affix-wrapper-focused {
  border-color: var(--elf-warning-color-hover);
  box-shadow: 0 0 0 2px var(--elf-warning-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-affix-wrapper-status-warning .elf-input-prefix {
  color: var(--elf-warning-color);
}
.elf-input-textarea-status-error.elf-input-textarea-has-feedback .elf-input,
.elf-input-textarea-status-warning.elf-input-textarea-has-feedback .elf-input,
.elf-input-textarea-status-success.elf-input-textarea-has-feedback .elf-input,
.elf-input-textarea-status-validating.elf-input-textarea-has-feedback .elf-input {
  padding-right: 24px;
}
.elf-input-group-wrapper-status-error .elf-input-group-addon {
  color: var(--elf-error-color);
  border-color: var(--elf-error-color);
}
.elf-input-group-wrapper-status-warning .elf-input-group-addon {
  color: var(--elf-warning-color);
  border-color: var(--elf-warning-color);
}
.elf-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #fff;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #18161e;
  background-image: none;
  border: 1px solid #231f30;
  border-radius: 2px;
  transition: all 0.3s;
}
.elf-input::-moz-placeholder {
  color: #796f94;
  -moz-user-select: none;
       user-select: none;
}
.elf-input::placeholder {
  color: #796f94;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input:hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input:focus,
.elf-input-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-input:focus,
.elf-input-rtl .elf-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input-borderless,
.elf-input-borderless:hover,
.elf-input-borderless:focus,
.elf-input-borderless-focused,
.elf-input-borderless-disabled,
.elf-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.elf-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.elf-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-input-sm {
  padding: 0px 7px;
}
.elf-input-rtl {
  direction: rtl;
}
.elf-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.elf-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.elf-input-group > [class*='col-'] {
  padding-right: 8px;
}
.elf-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.elf-input-group-addon,
.elf-input-group-wrap,
.elf-input-group > .elf-input {
  display: table-cell;
}
.elf-input-group-addon:not(:first-child):not(:last-child),
.elf-input-group-wrap:not(:first-child):not(:last-child),
.elf-input-group > .elf-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.elf-input-group-addon,
.elf-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.elf-input-group-wrap > * {
  display: block !important;
}
.elf-input-group .elf-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.elf-input-group .elf-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.elf-input-group .elf-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.elf-input-search-with-button .elf-input-group .elf-input:hover {
  z-index: 0;
}
.elf-input-group-addon {
  position: relative;
  padding: 0 16px;
  color: #796f94;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #231f30;
  border: 1px solid #231f30;
  border-radius: 2px;
  transition: all 0.3s;
}
.elf-input-group-addon .elf-select {
  margin: -5px -11px;
}
.elf-input-group-addon .elf-select.elf-select-single:not(.elf-select-customize-input) .elf-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.elf-input-group-addon .elf-select-open .elf-select-selector,
.elf-input-group-addon .elf-select-focused .elf-select-selector {
  color: var(--elf-primary-color);
}
.elf-input-group-addon .elf-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.elf-input-group-addon .elf-cascader-picker .elf-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.elf-input-group > .elf-input:first-child,
.elf-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-group > .elf-input:first-child .elf-select .elf-select-selector,
.elf-input-group-addon:first-child .elf-select .elf-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-group > .elf-input-affix-wrapper:not(:first-child) .elf-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-group > .elf-input-affix-wrapper:not(:last-child) .elf-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-group-addon:first-child {
  border-right: 0;
}
.elf-input-group-addon:last-child {
  border-left: 0;
}
.elf-input-group > .elf-input:last-child,
.elf-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-group > .elf-input:last-child .elf-select .elf-select-selector,
.elf-input-group-addon:last-child .elf-select .elf-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-group-lg .elf-input,
.elf-input-group-lg > .elf-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-input-group-sm .elf-input,
.elf-input-group-sm > .elf-input-group-addon {
  padding: 0px 7px;
}
.elf-input-group-lg .elf-select-single .elf-select-selector {
  height: 40px;
}
.elf-input-group-sm .elf-select-single .elf-select-selector {
  height: 24px;
}
.elf-input-group .elf-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-search .elf-input-group .elf-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.elf-input-group .elf-input-affix-wrapper:not(:first-child),
.elf-input-search .elf-input-group .elf-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-group.elf-input-group-compact {
  display: block;
}
.elf-input-group.elf-input-group-compact::before {
  display: table;
  content: '';
}
.elf-input-group.elf-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.elf-input-group.elf-input-group-compact::before {
  display: table;
  content: '';
}
.elf-input-group.elf-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.elf-input-group.elf-input-group-compact-addon:not(:first-child):not(:last-child),
.elf-input-group.elf-input-group-compact-wrap:not(:first-child):not(:last-child),
.elf-input-group.elf-input-group-compact > .elf-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.elf-input-group.elf-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.elf-input-group.elf-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.elf-input-group.elf-input-group-compact > .elf-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.elf-input-group.elf-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.elf-input-group.elf-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.elf-input-group.elf-input-group-compact > .elf-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.elf-input-group.elf-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.elf-input-group.elf-input-group-compact > .elf-input-affix-wrapper {
  display: inline-flex;
}
.elf-input-group.elf-input-group-compact > .elf-picker-range {
  display: inline-flex;
}
.elf-input-group.elf-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.elf-input-group.elf-input-group-compact .elf-input {
  float: none;
}
.elf-input-group.elf-input-group-compact > .elf-select > .elf-select-selector,
.elf-input-group.elf-input-group-compact > .elf-select-auto-complete .elf-input,
.elf-input-group.elf-input-group-compact > .elf-cascader-picker .elf-input,
.elf-input-group.elf-input-group-compact > .elf-input-group-wrapper .elf-input {
  border-right-width: 1px;
  border-radius: 0;
}
.elf-input-group.elf-input-group-compact > .elf-select > .elf-select-selector:hover,
.elf-input-group.elf-input-group-compact > .elf-select-auto-complete .elf-input:hover,
.elf-input-group.elf-input-group-compact > .elf-cascader-picker .elf-input:hover,
.elf-input-group.elf-input-group-compact > .elf-input-group-wrapper .elf-input:hover {
  z-index: 1;
}
.elf-input-group.elf-input-group-compact > .elf-select > .elf-select-selector:focus,
.elf-input-group.elf-input-group-compact > .elf-select-auto-complete .elf-input:focus,
.elf-input-group.elf-input-group-compact > .elf-cascader-picker .elf-input:focus,
.elf-input-group.elf-input-group-compact > .elf-input-group-wrapper .elf-input:focus {
  z-index: 1;
}
.elf-input-group.elf-input-group-compact > .elf-select-focused {
  z-index: 1;
}
.elf-input-group.elf-input-group-compact > .elf-select > .elf-select-arrow {
  z-index: 1;
}
.elf-input-group.elf-input-group-compact > *:first-child,
.elf-input-group.elf-input-group-compact > .elf-select:first-child > .elf-select-selector,
.elf-input-group.elf-input-group-compact > .elf-select-auto-complete:first-child .elf-input,
.elf-input-group.elf-input-group-compact > .elf-cascader-picker:first-child .elf-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.elf-input-group.elf-input-group-compact > *:last-child,
.elf-input-group.elf-input-group-compact > .elf-select:last-child > .elf-select-selector,
.elf-input-group.elf-input-group-compact > .elf-cascader-picker:last-child .elf-input,
.elf-input-group.elf-input-group-compact > .elf-cascader-picker-focused:last-child .elf-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.elf-input-group.elf-input-group-compact > .elf-select-auto-complete .elf-input {
  vertical-align: top;
}
.elf-input-group.elf-input-group-compact .elf-input-group-wrapper + .elf-input-group-wrapper {
  margin-left: -1px;
}
.elf-input-group.elf-input-group-compact .elf-input-group-wrapper + .elf-input-group-wrapper .elf-input-affix-wrapper {
  border-radius: 0;
}
.elf-input-group.elf-input-group-compact
  .elf-input-group-wrapper:not(:last-child).elf-input-search
  > .elf-input-group
  > .elf-input-group-addon
  > .elf-input-search-button {
  border-radius: 0;
}
.elf-input-group.elf-input-group-compact
  .elf-input-group-wrapper:not(:last-child).elf-input-search
  > .elf-input-group
  > .elf-input {
  border-radius: 2px 0 0 2px;
}
.elf-input-group > .elf-input-rtl:first-child,
.elf-input-group-rtl .elf-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.elf-input-group-rtl .elf-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.elf-input-group-rtl .elf-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.elf-input-group-rtl.elf-input-group > .elf-input:last-child,
.elf-input-group-rtl.elf-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.elf-input-group-rtl.elf-input-group .elf-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.elf-input-group-rtl.elf-input-group .elf-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > *:first-child,
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > .elf-select:first-child > .elf-select-selector,
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > .elf-select-auto-complete:first-child .elf-input,
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > .elf-cascader-picker:first-child .elf-input {
  border-radius: 0 2px 2px 0;
}
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > *:last-child,
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > .elf-select:last-child > .elf-select-selector,
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > .elf-select-auto-complete:last-child .elf-input,
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > .elf-cascader-picker:last-child .elf-input,
.elf-input-group-rtl.elf-input-group.elf-input-group-compact > .elf-cascader-picker-focused:last-child .elf-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.elf-input-group.elf-input-group-compact .elf-input-group-wrapper-rtl + .elf-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.elf-input-group.elf-input-group-compact
  .elf-input-group-wrapper-rtl:not(:last-child).elf-input-search
  > .elf-input-group
  > .elf-input {
  border-radius: 0 2px 2px 0;
}
.elf-input-group > .elf-input-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.elf-input-group > .elf-input-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.elf-input-group-rtl .elf-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.elf-input-group-rtl .elf-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.elf-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.elf-input-password-icon.anticon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.elf-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.elf-input[type='color'] {
  height: 32px;
}
.elf-input[type='color'].elf-input-lg {
  height: 40px;
}
.elf-input[type='color'].elf-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.elf-input-textarea-show-count > .elf-input {
  height: 100%;
}
.elf-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.elf-input-textarea-show-count.elf-input-textarea-in-form-item::after {
  margin-bottom: -22px;
}
.elf-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.elf-input-compact-item:not(.elf-input-compact-last-item):not(.elf-input-compact-item-rtl) {
  margin-right: -1px;
}
.elf-input-compact-item:not(.elf-input-compact-last-item).elf-input-compact-item-rtl {
  margin-left: -1px;
}
.elf-input-compact-item:hover,
.elf-input-compact-item:focus,
.elf-input-compact-item:active {
  z-index: 2;
}
.elf-input-compact-item[disabled] {
  z-index: 0;
}
.elf-input-compact-item:not(.elf-input-compact-first-item):not(.elf-input-compact-last-item).elf-input {
  border-radius: 0;
}
.elf-input-compact-item.elf-input.elf-input-compact-first-item:not(.elf-input-compact-last-item):not(
    .elf-input-compact-item-rtl
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-compact-item.elf-input.elf-input-compact-last-item:not(.elf-input-compact-first-item):not(
    .elf-input-compact-item-rtl
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-compact-item.elf-input.elf-input-compact-item-rtl.elf-input-compact-first-item:not(
    .elf-input-compact-last-item
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-compact-item.elf-input.elf-input-compact-item-rtl.elf-input-compact-last-item:not(
    .elf-input-compact-first-item
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-search .elf-input:hover,
.elf-input-search .elf-input:focus {
  border-color: var(--elf-primary-5);
}
.elf-input-search .elf-input:hover + .elf-input-group-addon .elf-input-search-button:not(.elf-btn-primary),
.elf-input-search .elf-input:focus + .elf-input-group-addon .elf-input-search-button:not(.elf-btn-primary) {
  border-left-color: var(--elf-primary-5);
}
.elf-input-search .elf-input-affix-wrapper {
  border-radius: 0;
}
.elf-input-search .elf-input-lg {
  line-height: 1.5713;
}
.elf-input-search > .elf-input-group > .elf-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.elf-input-search > .elf-input-group > .elf-input-group-addon:last-child .elf-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.elf-input-search
  > .elf-input-group
  > .elf-input-group-addon:last-child
  .elf-input-search-button:not(.elf-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.elf-input-search
  > .elf-input-group
  > .elf-input-group-addon:last-child
  .elf-input-search-button:not(.elf-btn-primary).elf-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.elf-input-search-button {
  height: 32px;
}
.elf-input-search-button:hover,
.elf-input-search-button:focus {
  z-index: 1;
}
.elf-input-search-large .elf-input-search-button {
  height: 40px;
}
.elf-input-search-small .elf-input-search-button {
  height: 24px;
}
.elf-input-search.elf-input-compact-item:not(.elf-input-compact-item-rtl):not(.elf-input-compact-last-item)
  .elf-input-group-addon
  .elf-input-search-button {
  margin-right: -1px;
  border-radius: 0;
}
.elf-input-search.elf-input-compact-item:not(.elf-input-compact-first-item) .elf-input,
.elf-input-search.elf-input-compact-item:not(.elf-input-compact-first-item) .elf-input-affix-wrapper {
  border-radius: 0;
}
.elf-input-search.elf-input-compact-item > .elf-input-group-addon .elf-input-search-button:hover,
.elf-input-search.elf-input-compact-item > .elf-input:hover,
.elf-input-search.elf-input-compact-item .elf-input-affix-wrapper:hover,
.elf-input-search.elf-input-compact-item > .elf-input-group-addon .elf-input-search-button:focus,
.elf-input-search.elf-input-compact-item > .elf-input:focus,
.elf-input-search.elf-input-compact-item .elf-input-affix-wrapper:focus,
.elf-input-search.elf-input-compact-item > .elf-input-group-addon .elf-input-search-button:active,
.elf-input-search.elf-input-compact-item > .elf-input:active,
.elf-input-search.elf-input-compact-item .elf-input-affix-wrapper:active {
  z-index: 2;
}
.elf-input-search.elf-input-compact-item > .elf-input-affix-wrapper-focused {
  z-index: 2;
}
.elf-input-search.elf-input-compact-item-rtl:not(.elf-input-compact-last-item)
  .elf-input-group-addon:last-child
  .elf-input-search-button {
  margin-left: -1px;
  border-radius: 0;
}
.elf-input-group-wrapper-rtl {
  direction: rtl;
}
.elf-input-group-rtl {
  direction: rtl;
}
.elf-input-affix-wrapper.elf-input-affix-wrapper-rtl > input.elf-input {
  border: none;
  outline: none;
}
.elf-input-affix-wrapper-rtl .elf-input-prefix {
  margin: 0 0 0 4px;
}
.elf-input-affix-wrapper-rtl .elf-input-suffix {
  margin: 0 4px 0 0;
}
.elf-input-textarea-rtl {
  direction: rtl;
}
.elf-input-textarea-rtl.elf-input-textarea-show-count::after {
  text-align: left;
}
.elf-input-affix-wrapper-rtl .elf-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.elf-input-affix-wrapper-rtl .elf-input-clear-icon {
  right: auto;
  left: 8px;
}
.elf-input-search-rtl {
  direction: rtl;
}
.elf-input-search-rtl .elf-input:hover + .elf-input-group-addon .elf-input-search-button:not(.elf-btn-primary),
.elf-input-search-rtl .elf-input:focus + .elf-input-group-addon .elf-input-search-button:not(.elf-btn-primary) {
  border-left-color: #d9d9d9;
}
.elf-input-search-rtl .elf-input:hover + .elf-input-group-addon .elf-input-search-button:not(.elf-btn-primary):hover,
.elf-input-search-rtl .elf-input:focus + .elf-input-group-addon .elf-input-search-button:not(.elf-btn-primary):hover {
  border-left-color: var(--elf-primary-5);
}
.elf-input-search-rtl > .elf-input-group > .elf-input-affix-wrapper:hover,
.elf-input-search-rtl > .elf-input-group > .elf-input-affix-wrapper-focused {
  border-right-color: var(--elf-primary-5);
}
.elf-input-search-rtl > .elf-input-group > .elf-input-group-addon:last-child {
  right: -1px;
  left: auto;
}
.elf-input-search-rtl > .elf-input-group > .elf-input-group-addon:last-child .elf-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .elf-input {
    height: 32px;
  }
  .elf-input-lg {
    height: 40px;
  }
  .elf-input-sm {
    height: 24px;
  }
  .elf-input-affix-wrapper > input.elf-input {
    height: auto;
  }
}
.elf-input-number-affix-wrapper {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  width: 90px;
  padding: 0;
  padding-inline-start: 11px;
}
.elf-input-number-affix-wrapper::-moz-placeholder {
  color: #796f94;
  -moz-user-select: none;
       user-select: none;
}
.elf-input-number-affix-wrapper::placeholder {
  color: #796f94;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-input-number-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input-number-affix-wrapper:hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-input-number-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-number-affix-wrapper:focus,
.elf-input-number-affix-wrapper-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-input-number-affix-wrapper:focus,
.elf-input-rtl .elf-input-number-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-number-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input-number-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input-number-affix-wrapper-borderless,
.elf-input-number-affix-wrapper-borderless:hover,
.elf-input-number-affix-wrapper-borderless:focus,
.elf-input-number-affix-wrapper-borderless-focused,
.elf-input-number-affix-wrapper-borderless-disabled,
.elf-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.elf-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.elf-input-number-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-input-number-affix-wrapper-sm {
  padding: 0px 7px;
}
.elf-input-number-affix-wrapper-rtl {
  direction: rtl;
}
.elf-input-number-affix-wrapper:not(.elf-input-number-affix-wrapper-disabled):hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
  z-index: 1;
}
.elf-input-rtl .elf-input-number-affix-wrapper:not(.elf-input-number-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-number-affix-wrapper-focused,
.elf-input-number-affix-wrapper:focus {
  z-index: 1;
}
.elf-input-number-affix-wrapper-disabled .elf-input-number[disabled] {
  background: transparent;
}
.elf-input-number-affix-wrapper > div.elf-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.elf-input-number-affix-wrapper > div.elf-input-number.elf-input-number-focused {
  box-shadow: none !important;
}
.elf-input-number-affix-wrapper input.elf-input-number-input {
  padding: 0;
}
.elf-input-number-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.elf-input-number-affix-wrapper .elf-input-number-handler-wrap {
  z-index: 2;
}
.elf-input-number-prefix,
.elf-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}
.elf-input-number-prefix {
  margin-inline-end: 4px;
}
.elf-input-number-suffix {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  margin-right: 11px;
  margin-left: 4px;
}
.elf-input-number-group-wrapper .elf-input-number-affix-wrapper {
  width: 100%;
}
.elf-input-number-status-error:not(.elf-input-number-disabled):not(.elf-input-number-borderless).elf-input-number,
.elf-input-number-status-error:not(.elf-input-number-disabled):not(
    .elf-input-number-borderless
  ).elf-input-number:hover {
  background: #18161e;
  border-color: var(--elf-error-color);
}
.elf-input-number-status-error:not(.elf-input-number-disabled):not(.elf-input-number-borderless).elf-input-number:focus,
.elf-input-number-status-error:not(.elf-input-number-disabled):not(
    .elf-input-number-borderless
  ).elf-input-number-focused {
  border-color: var(--elf-error-color-hover);
  box-shadow: 0 0 0 2px var(--elf-error-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-number-status-error .elf-input-number-prefix {
  color: var(--elf-error-color);
}
.elf-input-number-status-warning:not(.elf-input-number-disabled):not(.elf-input-number-borderless).elf-input-number,
.elf-input-number-status-warning:not(.elf-input-number-disabled):not(
    .elf-input-number-borderless
  ).elf-input-number:hover {
  background: #fff;
  border-color: var(--elf-warning-color);
}
.elf-input-number-status-warning:not(.elf-input-number-disabled):not(
    .elf-input-number-borderless
  ).elf-input-number:focus,
.elf-input-number-status-warning:not(.elf-input-number-disabled):not(
    .elf-input-number-borderless
  ).elf-input-number-focused {
  border-color: var(--elf-warning-color-hover);
  box-shadow: 0 0 0 2px var(--elf-warning-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-number-status-warning .elf-input-number-prefix {
  color: var(--elf-warning-color);
}
.elf-input-number-affix-wrapper-status-error:not(.elf-input-number-affix-wrapper-disabled):not(
    .elf-input-number-affix-wrapper-borderless
  ).elf-input-number-affix-wrapper,
.elf-input-number-affix-wrapper-status-error:not(.elf-input-number-affix-wrapper-disabled):not(
    .elf-input-number-affix-wrapper-borderless
  ).elf-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: var(--elf-error-color);
}
.elf-input-number-affix-wrapper-status-error:not(.elf-input-number-affix-wrapper-disabled):not(
    .elf-input-number-affix-wrapper-borderless
  ).elf-input-number-affix-wrapper:focus,
.elf-input-number-affix-wrapper-status-error:not(.elf-input-number-affix-wrapper-disabled):not(
    .elf-input-number-affix-wrapper-borderless
  ).elf-input-number-affix-wrapper-focused {
  border-color: var(--elf-error-color-hover);
  box-shadow: 0 0 0 2px var(--elf-error-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-number-affix-wrapper-status-error .elf-input-number-prefix {
  color: var(--elf-error-color);
}
.elf-input-number-affix-wrapper-status-warning:not(.elf-input-number-affix-wrapper-disabled):not(
    .elf-input-number-affix-wrapper-borderless
  ).elf-input-number-affix-wrapper,
.elf-input-number-affix-wrapper-status-warning:not(.elf-input-number-affix-wrapper-disabled):not(
    .elf-input-number-affix-wrapper-borderless
  ).elf-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: var(--elf-warning-color);
}
.elf-input-number-affix-wrapper-status-warning:not(.elf-input-number-affix-wrapper-disabled):not(
    .elf-input-number-affix-wrapper-borderless
  ).elf-input-number-affix-wrapper:focus,
.elf-input-number-affix-wrapper-status-warning:not(.elf-input-number-affix-wrapper-disabled):not(
    .elf-input-number-affix-wrapper-borderless
  ).elf-input-number-affix-wrapper-focused {
  border-color: var(--elf-warning-color-hover);
  box-shadow: 0 0 0 2px var(--elf-warning-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-number-affix-wrapper-status-warning .elf-input-number-prefix {
  color: var(--elf-warning-color);
}
.elf-input-number-group-wrapper-status-error .elf-input-number-group-addon {
  color: var(--elf-error-color);
  border-color: var(--elf-error-color);
}
.elf-input-number-group-wrapper-status-warning .elf-input-number-group-addon {
  color: var(--elf-warning-color);
  border-color: var(--elf-warning-color);
}
.elf-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #fff;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #18161e;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 1px solid #231f30;
  border-radius: 6px;
}
.elf-input-number::-moz-placeholder {
  color: #796f94;
  -moz-user-select: none;
       user-select: none;
}
.elf-input-number::placeholder {
  color: #796f94;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input-number:hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-number:focus,
.elf-input-number-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-input-number:focus,
.elf-input-rtl .elf-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input-number-borderless,
.elf-input-number-borderless:hover,
.elf-input-number-borderless:focus,
.elf-input-number-borderless-focused,
.elf-input-number-borderless-disabled,
.elf-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.elf-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.elf-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-input-number-sm {
  padding: 0px 7px;
}
.elf-input-number-rtl {
  direction: rtl;
}
.elf-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.elf-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.elf-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.elf-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.elf-input-number-group-addon,
.elf-input-number-group-wrap,
.elf-input-number-group > .elf-input-number {
  display: table-cell;
}
.elf-input-number-group-addon:not(:first-child):not(:last-child),
.elf-input-number-group-wrap:not(:first-child):not(:last-child),
.elf-input-number-group > .elf-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.elf-input-number-group-addon,
.elf-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.elf-input-number-group-wrap > * {
  display: block !important;
}
.elf-input-number-group .elf-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.elf-input-number-group .elf-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.elf-input-number-group .elf-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.elf-input-search-with-button .elf-input-number-group .elf-input-number:hover {
  z-index: 0;
}
.elf-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.elf-input-number-group-addon .elf-select {
  margin: -5px -11px;
}
.elf-input-number-group-addon .elf-select.elf-select-single:not(.elf-select-customize-input) .elf-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.elf-input-number-group-addon .elf-select-open .elf-select-selector,
.elf-input-number-group-addon .elf-select-focused .elf-select-selector {
  color: var(--elf-primary-color);
}
.elf-input-number-group-addon .elf-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.elf-input-number-group-addon .elf-cascader-picker .elf-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.elf-input-number-group > .elf-input-number:first-child,
.elf-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-number-group > .elf-input-number:first-child .elf-select .elf-select-selector,
.elf-input-number-group-addon:first-child .elf-select .elf-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-number-group > .elf-input-number-affix-wrapper:not(:first-child) .elf-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-number-group > .elf-input-number-affix-wrapper:not(:last-child) .elf-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-number-group-addon:first-child {
  border-right: 0;
}
.elf-input-number-group-addon:last-child {
  border-left: 0;
}
.elf-input-number-group > .elf-input-number:last-child,
.elf-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-number-group > .elf-input-number:last-child .elf-select .elf-select-selector,
.elf-input-number-group-addon:last-child .elf-select .elf-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-number-group-lg .elf-input-number,
.elf-input-number-group-lg > .elf-input-number-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-input-number-group-sm .elf-input-number,
.elf-input-number-group-sm > .elf-input-number-group-addon {
  padding: 0px 7px;
}
.elf-input-number-group-lg .elf-select-single .elf-select-selector {
  height: 40px;
}
.elf-input-number-group-sm .elf-select-single .elf-select-selector {
  height: 24px;
}
.elf-input-number-group .elf-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-search .elf-input-number-group .elf-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.elf-input-number-group .elf-input-number-affix-wrapper:not(:first-child),
.elf-input-search .elf-input-number-group .elf-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-number-group.elf-input-number-group-compact {
  display: block;
}
.elf-input-number-group.elf-input-number-group-compact::before {
  display: table;
  content: '';
}
.elf-input-number-group.elf-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.elf-input-number-group.elf-input-number-group-compact::before {
  display: table;
  content: '';
}
.elf-input-number-group.elf-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.elf-input-number-group.elf-input-number-group-compact-addon:not(:first-child):not(:last-child),
.elf-input-number-group.elf-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.elf-input-number-group.elf-input-number-group-compact > .elf-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.elf-input-number-group.elf-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.elf-input-number-group.elf-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.elf-input-number-group.elf-input-number-group-compact > .elf-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.elf-input-number-group.elf-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.elf-input-number-group.elf-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.elf-input-number-group.elf-input-number-group-compact > .elf-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.elf-input-number-group.elf-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.elf-input-number-group.elf-input-number-group-compact > .elf-input-number-affix-wrapper {
  display: inline-flex;
}
.elf-input-number-group.elf-input-number-group-compact > .elf-picker-range {
  display: inline-flex;
}
.elf-input-number-group.elf-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.elf-input-number-group.elf-input-number-group-compact .elf-input-number {
  float: none;
}
.elf-input-number-group.elf-input-number-group-compact > .elf-select > .elf-select-selector,
.elf-input-number-group.elf-input-number-group-compact > .elf-select-auto-complete .elf-input,
.elf-input-number-group.elf-input-number-group-compact > .elf-cascader-picker .elf-input,
.elf-input-number-group.elf-input-number-group-compact > .elf-input-group-wrapper .elf-input {
  border-right-width: 1px;
  border-radius: 0;
}
.elf-input-number-group.elf-input-number-group-compact > .elf-select > .elf-select-selector:hover,
.elf-input-number-group.elf-input-number-group-compact > .elf-select-auto-complete .elf-input:hover,
.elf-input-number-group.elf-input-number-group-compact > .elf-cascader-picker .elf-input:hover,
.elf-input-number-group.elf-input-number-group-compact > .elf-input-group-wrapper .elf-input:hover {
  z-index: 1;
}
.elf-input-number-group.elf-input-number-group-compact > .elf-select > .elf-select-selector:focus,
.elf-input-number-group.elf-input-number-group-compact > .elf-select-auto-complete .elf-input:focus,
.elf-input-number-group.elf-input-number-group-compact > .elf-cascader-picker .elf-input:focus,
.elf-input-number-group.elf-input-number-group-compact > .elf-input-group-wrapper .elf-input:focus {
  z-index: 1;
}
.elf-input-number-group.elf-input-number-group-compact > .elf-select-focused {
  z-index: 1;
}
.elf-input-number-group.elf-input-number-group-compact > .elf-select > .elf-select-arrow {
  z-index: 1;
}
.elf-input-number-group.elf-input-number-group-compact > *:first-child,
.elf-input-number-group.elf-input-number-group-compact > .elf-select:first-child > .elf-select-selector,
.elf-input-number-group.elf-input-number-group-compact > .elf-select-auto-complete:first-child .elf-input,
.elf-input-number-group.elf-input-number-group-compact > .elf-cascader-picker:first-child .elf-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.elf-input-number-group.elf-input-number-group-compact > *:last-child,
.elf-input-number-group.elf-input-number-group-compact > .elf-select:last-child > .elf-select-selector,
.elf-input-number-group.elf-input-number-group-compact > .elf-cascader-picker:last-child .elf-input,
.elf-input-number-group.elf-input-number-group-compact > .elf-cascader-picker-focused:last-child .elf-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.elf-input-number-group.elf-input-number-group-compact > .elf-select-auto-complete .elf-input {
  vertical-align: top;
}
.elf-input-number-group.elf-input-number-group-compact .elf-input-group-wrapper + .elf-input-group-wrapper {
  margin-left: -1px;
}
.elf-input-number-group.elf-input-number-group-compact
  .elf-input-group-wrapper
  + .elf-input-group-wrapper
  .elf-input-affix-wrapper {
  border-radius: 0;
}
.elf-input-number-group.elf-input-number-group-compact
  .elf-input-group-wrapper:not(:last-child).elf-input-search
  > .elf-input-group
  > .elf-input-group-addon
  > .elf-input-search-button {
  border-radius: 0;
}
.elf-input-number-group.elf-input-number-group-compact
  .elf-input-group-wrapper:not(:last-child).elf-input-search
  > .elf-input-group
  > .elf-input {
  border-radius: 2px 0 0 2px;
}
.elf-input-number-group > .elf-input-number-rtl:first-child,
.elf-input-number-group-rtl .elf-input-number-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.elf-input-number-group-rtl .elf-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.elf-input-number-group-rtl .elf-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.elf-input-number-group-rtl.elf-input-number-group > .elf-input-number:last-child,
.elf-input-number-group-rtl.elf-input-number-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.elf-input-number-group-rtl.elf-input-number-group .elf-input-number-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.elf-input-number-group-rtl.elf-input-number-group .elf-input-number-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact > *:first-child,
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact
  > .elf-select:first-child
  > .elf-select-selector,
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact
  > .elf-select-auto-complete:first-child
  .elf-input,
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact
  > .elf-cascader-picker:first-child
  .elf-input {
  border-radius: 0 2px 2px 0;
}
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact > *:last-child,
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact
  > .elf-select:last-child
  > .elf-select-selector,
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact
  > .elf-select-auto-complete:last-child
  .elf-input,
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact
  > .elf-cascader-picker:last-child
  .elf-input,
.elf-input-number-group-rtl.elf-input-number-group.elf-input-number-group-compact
  > .elf-cascader-picker-focused:last-child
  .elf-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.elf-input-number-group.elf-input-number-group-compact .elf-input-group-wrapper-rtl + .elf-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.elf-input-number-group.elf-input-number-group-compact
  .elf-input-group-wrapper-rtl:not(:last-child).elf-input-search
  > .elf-input-group
  > .elf-input {
  border-radius: 0 2px 2px 0;
}
.elf-input-number-group > .elf-input-number-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.elf-input-number-group > .elf-input-number-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.elf-input-number-group-rtl .elf-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.elf-input-number-group-rtl .elf-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.elf-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.elf-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  transition: all 0.1s linear;
}
.elf-input-number-handler:active {
  background: #f4f4f4;
}
.elf-input-number-handler:hover .elf-input-number-handler-up-inner,
.elf-input-number-handler:hover .elf-input-number-handler-down-inner {
  color: var(--elf-primary-5);
}
.elf-input-number-handler-up-inner,
.elf-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-input-number-handler-up-inner > *,
.elf-input-number-handler-down-inner > * {
  line-height: 1;
}
.elf-input-number-handler-up-inner svg,
.elf-input-number-handler-down-inner svg {
  display: inline-block;
}
.elf-input-number-handler-up-inner::before,
.elf-input-number-handler-down-inner::before {
  display: none;
}
.elf-input-number-handler-up-inner .elf-input-number-handler-up-inner-icon,
.elf-input-number-handler-up-inner .elf-input-number-handler-down-inner-icon,
.elf-input-number-handler-down-inner .elf-input-number-handler-up-inner-icon,
.elf-input-number-handler-down-inner .elf-input-number-handler-down-inner-icon {
  display: block;
}
.elf-input-number:hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-number:hover + .elf-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.elf-input-number-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-input-number-disabled .elf-input-number-input {
  cursor: not-allowed;
}
.elf-input-number-disabled .elf-input-number-handler-wrap {
  display: none;
}
.elf-input-number-readonly .elf-input-number-handler-wrap {
  display: none;
}
.elf-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -webkit-appearance: textfield !important;
     -moz-appearance: textfield !important;
          appearance: textfield !important;
}
.elf-input-number-input::-moz-placeholder {
  color: #796f94;
  -moz-user-select: none;
       user-select: none;
}
.elf-input-number-input::placeholder {
  color: #796f94;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-input-number-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-input-number-input[type='number']::-webkit-inner-spin-button,
.elf-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.elf-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.elf-input-number-lg input {
  height: 38px;
}
.elf-input-number-sm {
  padding: 0;
}
.elf-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.elf-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.elf-input-number-handler-wrap .elf-input-number-handler .elf-input-number-handler-up-inner,
.elf-input-number-handler-wrap .elf-input-number-handler .elf-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.elf-input-number-borderless .elf-input-number-handler-wrap {
  border-left-width: 0;
}
.elf-input-number-handler-wrap:hover .elf-input-number-handler {
  height: 40%;
}
.elf-input-number:hover .elf-input-number-handler-wrap,
.elf-input-number-focused .elf-input-number-handler-wrap {
  opacity: 1;
}
.elf-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}
.elf-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.elf-input-number-handler-up:hover {
  height: 60% !important;
}
.elf-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.elf-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.elf-input-number-handler-down:hover {
  height: 60% !important;
}
.elf-input-number-borderless .elf-input-number-handler-down {
  border-top-width: 0;
}
.elf-input-number:hover:not(.elf-input-number-borderless) .elf-input-number-handler-down,
.elf-input-number-focused:not(.elf-input-number-borderless) .elf-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
}
.elf-input-number-handler-up-disabled,
.elf-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.elf-input-number-handler-up-disabled:hover .elf-input-number-handler-up-inner,
.elf-input-number-handler-down-disabled:hover .elf-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.elf-input-number-borderless {
  box-shadow: none;
}
.elf-input-number-out-of-range input {
  color: var(--elf-error-color);
}
.elf-input-number-compact-item:not(.elf-input-number-compact-last-item):not(.elf-input-number-compact-item-rtl) {
  margin-right: -1px;
}
.elf-input-number-compact-item:not(.elf-input-number-compact-last-item).elf-input-number-compact-item-rtl {
  margin-left: -1px;
}
.elf-input-number-compact-item:hover,
.elf-input-number-compact-item:focus,
.elf-input-number-compact-item:active {
  z-index: 2;
}
.elf-input-number-compact-item.elf-input-number-focused {
  z-index: 2;
}
.elf-input-number-compact-item[disabled] {
  z-index: 0;
}
.elf-input-number-compact-item:not(.elf-input-number-compact-first-item):not(
    .elf-input-number-compact-last-item
  ).elf-input-number {
  border-radius: 0;
}
.elf-input-number-compact-item.elf-input-number.elf-input-number-compact-first-item:not(
    .elf-input-number-compact-last-item
  ):not(.elf-input-number-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-number-compact-item.elf-input-number.elf-input-number-compact-last-item:not(
    .elf-input-number-compact-first-item
  ):not(.elf-input-number-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-number-compact-item.elf-input-number.elf-input-number-compact-item-rtl.elf-input-number-compact-first-item:not(
    .elf-input-number-compact-last-item
  ) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-input-number-compact-item.elf-input-number.elf-input-number-compact-item-rtl.elf-input-number-compact-last-item:not(
    .elf-input-number-compact-first-item
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-input-number-rtl {
  direction: rtl;
}
.elf-input-number-rtl .elf-input-number-handler {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.elf-input-number-rtl .elf-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.elf-input-number-rtl.elf-input-number-borderless .elf-input-number-handler-wrap {
  border-right-width: 0;
}
.elf-input-number-rtl .elf-input-number-handler-up {
  border-top-right-radius: 0;
}
.elf-input-number-rtl .elf-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.elf-input-number-rtl .elf-input-number-input {
  direction: ltr;
  text-align: right;
}
.elf-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.elf-layout,
.elf-layout * {
  box-sizing: border-box;
}
.elf-layout.elf-layout-has-sider {
  flex-direction: row;
}
.elf-layout.elf-layout-has-sider > .elf-layout,
.elf-layout.elf-layout-has-sider > .elf-layout-content {
  width: 0;
}
.elf-layout-header,
.elf-layout-footer {
  flex: 0 0 auto;
}
.elf-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.elf-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.elf-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.elf-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.elf-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.elf-layout-sider-children::-webkit-scrollbar {
    display: none;
  }
.elf-layout-sider-children .elf-menu.elf-menu-inline-collapsed {
  width: auto;
}
.elf-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.elf-layout-sider-right {
  order: 1;
}
.elf-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.elf-layout-sider-zero-width > * {
  overflow: hidden;
}
.elf-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.elf-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.elf-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.elf-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.elf-layout-sider-light {
  background: #fff;
}
.elf-layout-sider-light .elf-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.elf-layout-sider-light .elf-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.elf-layout-rtl {
  direction: rtl;
}
.elf-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
.elf-list * {
  outline: none;
}
.elf-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.elf-list-pagination .elf-pagination-options {
  text-align: left;
}
.elf-list-more {
  margin-top: 12px;
  text-align: center;
}
.elf-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.elf-list-spin {
  min-height: 40px;
  text-align: center;
}
.elf-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.elf-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.elf-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}
.elf-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.elf-list-item-meta-avatar {
  margin-right: 16px;
}
.elf-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85);
}
.elf-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.elf-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.elf-list-item-meta-title > a:hover {
  color: var(--elf-primary-color);
}
.elf-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.elf-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.elf-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.elf-list-item-action > li:first-child {
  padding-left: 0;
}
.elf-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-list-header {
  background: transparent;
}
.elf-list-footer {
  background: transparent;
}
.elf-list-header,
.elf-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.elf-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.elf-list-split .elf-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-list-split .elf-list-item:last-child {
  border-bottom: none;
}
.elf-list-split .elf-list-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-list-split.elf-list-empty .elf-list-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-list-loading .elf-list-spin-nested-loading {
  min-height: 32px;
}
.elf-list-split.elf-list-something-after-last-item .elf-spin-container > .elf-list-items > .elf-list-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-list-lg .elf-list-item {
  padding: 16px 24px;
}
.elf-list-sm .elf-list-item {
  padding: 8px 16px;
}
.elf-list-vertical .elf-list-item {
  align-items: initial;
}
.elf-list-vertical .elf-list-item-main {
  display: block;
  flex: 1;
}
.elf-list-vertical .elf-list-item-extra {
  margin-left: 40px;
}
.elf-list-vertical .elf-list-item-meta {
  margin-bottom: 16px;
}
.elf-list-vertical .elf-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.elf-list-vertical .elf-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.elf-list-vertical .elf-list-item-action > li {
  padding: 0 16px;
}
.elf-list-vertical .elf-list-item-action > li:first-child {
  padding-left: 0;
}
.elf-list-grid .elf-col > .elf-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.elf-list-item-no-flex {
  display: block;
}
.elf-list:not(.elf-list-vertical) .elf-list-item-no-flex .elf-list-item-action {
  float: right;
}
.elf-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.elf-list-bordered .elf-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.elf-list-bordered .elf-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.elf-list-bordered .elf-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.elf-list-bordered .elf-list-pagination {
  margin: 16px 24px;
}
.elf-list-bordered.elf-list-sm .elf-list-item {
  padding: 8px 16px;
}
.elf-list-bordered.elf-list-sm .elf-list-header,
.elf-list-bordered.elf-list-sm .elf-list-footer {
  padding: 8px 16px;
}
.elf-list-bordered.elf-list-lg .elf-list-item {
  padding: 16px 24px;
}
.elf-list-bordered.elf-list-lg .elf-list-header,
.elf-list-bordered.elf-list-lg .elf-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .elf-list-item-action {
    margin-left: 24px;
  }
  .elf-list-vertical .elf-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .elf-list-item {
    flex-wrap: wrap;
  }
  .elf-list-item-action {
    margin-left: 12px;
  }
  .elf-list-vertical .elf-list-item {
    flex-wrap: wrap-reverse;
  }
  .elf-list-vertical .elf-list-item-main {
    min-width: 220px;
  }
  .elf-list-vertical .elf-list-item-extra {
    margin: auto auto 16px;
  }
}
.elf-list-rtl {
  direction: rtl;
  text-align: right;
}
.elf-list-rtl .ReactVirtualized__List .elf-list-item {
  direction: rtl;
}
.elf-list-rtl .elf-list-pagination {
  text-align: left;
}
.elf-list-rtl .elf-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.elf-list-rtl .elf-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.elf-list.elf-list-rtl .elf-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.elf-list-rtl .elf-list-item-action-split {
  right: auto;
  left: 0;
}
.elf-list-rtl.elf-list-vertical .elf-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.elf-list-rtl.elf-list-vertical .elf-list-item-action {
  margin-right: auto;
}
.elf-list-rtl .elf-list-vertical .elf-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.elf-list-rtl .elf-list:not(.elf-list-vertical) .elf-list-item-no-flex .elf-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .elf-list-rtl .elf-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .elf-list-rtl .elf-list-vertical .elf-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .elf-list-rtl .elf-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .elf-list-rtl.elf-list-vertical .elf-list-item-extra {
    margin: auto auto 16px;
  }
}
.elf-mentions-status-error:not(.elf-mentions-disabled):not(.elf-mentions-borderless).elf-mentions,
.elf-mentions-status-error:not(.elf-mentions-disabled):not(.elf-mentions-borderless).elf-mentions:hover {
  background: #fff;
  border-color: var(--elf-error-color);
}
.elf-mentions-status-error:not(.elf-mentions-disabled):not(.elf-mentions-borderless).elf-mentions:focus,
.elf-mentions-status-error:not(.elf-mentions-disabled):not(.elf-mentions-borderless).elf-mentions-focused {
  border-color: var(--elf-error-color-hover);
  box-shadow: 0 0 0 2px var(--elf-error-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-mentions-status-error .elf-input-prefix {
  color: var(--elf-error-color);
}
.elf-mentions-status-warning:not(.elf-mentions-disabled):not(.elf-mentions-borderless).elf-mentions,
.elf-mentions-status-warning:not(.elf-mentions-disabled):not(.elf-mentions-borderless).elf-mentions:hover {
  background: #fff;
  border-color: var(--elf-warning-color);
}
.elf-mentions-status-warning:not(.elf-mentions-disabled):not(.elf-mentions-borderless).elf-mentions:focus,
.elf-mentions-status-warning:not(.elf-mentions-disabled):not(.elf-mentions-borderless).elf-mentions-focused {
  border-color: var(--elf-warning-color-hover);
  box-shadow: 0 0 0 2px var(--elf-warning-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-mentions-status-warning .elf-input-prefix {
  color: var(--elf-warning-color);
}
.elf-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.elf-mentions::-moz-placeholder {
  color: #796f94;
  -moz-user-select: none;
       user-select: none;
}
.elf-mentions::placeholder {
  color: #796f94;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-mentions:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-mentions:hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-mentions:focus,
.elf-mentions-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-mentions:focus,
.elf-input-rtl .elf-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-mentions-borderless,
.elf-mentions-borderless:hover,
.elf-mentions-borderless:focus,
.elf-mentions-borderless-focused,
.elf-mentions-borderless-disabled,
.elf-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.elf-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.elf-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-mentions-sm {
  padding: 0px 7px;
}
.elf-mentions-rtl {
  direction: rtl;
}
.elf-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-mentions-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-mentions > textarea,
.elf-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
    -o-tab-size: inherit;
       tab-size: inherit;
}
.elf-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.elf-mentions > textarea::-moz-placeholder {
  color: #796f94;
  -moz-user-select: none;
       user-select: none;
}
.elf-mentions > textarea::placeholder {
  color: #796f94;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-mentions > textarea:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.elf-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.elf-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.elf-mentions-dropdown-hidden {
  display: none;
}
.elf-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.elf-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.elf-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.elf-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
.elf-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
.elf-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.elf-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fafafa;
}
.elf-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.elf-mentions-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.elf-mentions-rtl {
  direction: rtl;
}
.elf-menu-item-danger.elf-menu-item {
  color: var(--elf-error-color);
}
.elf-menu-item-danger.elf-menu-item:hover,
.elf-menu-item-danger.elf-menu-item-active {
  color: var(--elf-error-color);
}
.elf-menu-item-danger.elf-menu-item:active {
  background: #fff1f0;
}
.elf-menu-item-danger.elf-menu-item-selected {
  color: var(--elf-error-color);
}
.elf-menu-item-danger.elf-menu-item-selected > a,
.elf-menu-item-danger.elf-menu-item-selected > a:hover {
  color: var(--elf-error-color);
}
.elf-menu:not(.elf-menu-horizontal) .elf-menu-item-danger.elf-menu-item-selected {
  background-color: #fff1f0;
}
.elf-menu-inline .elf-menu-item-danger.elf-menu-item::after {
  border-right-color: var(--elf-error-color);
}
.elf-menu-dark .elf-menu-item-danger.elf-menu-item,
.elf-menu-dark .elf-menu-item-danger.elf-menu-item:hover,
.elf-menu-dark .elf-menu-item-danger.elf-menu-item > a {
  color: var(--elf-error-color);
}
.elf-menu-dark.elf-menu-dark:not(.elf-menu-horizontal) .elf-menu-item-danger.elf-menu-item-selected {
  color: #fff;
  background-color: var(--elf-error-color);
}
.elf-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.elf-menu::before {
  display: table;
  content: '';
}
.elf-menu::after {
  display: table;
  clear: both;
  content: '';
}
.elf-menu::before {
  display: table;
  content: '';
}
.elf-menu::after {
  display: table;
  clear: both;
  content: '';
}
.elf-menu.elf-menu-root:focus-visible {
  box-shadow: 0 0 0 2px var(--elf-primary-2);
}
.elf-menu ul,
.elf-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.elf-menu-overflow {
  display: flex;
}
.elf-menu-overflow-item {
  flex: none;
}
.elf-menu-hidden,
.elf-menu-submenu-hidden {
  display: none;
}
.elf-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.elf-menu-horizontal .elf-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-menu-submenu,
.elf-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-menu-submenu-selected {
  color: var(--elf-primary-color);
}
.elf-menu-item:active,
.elf-menu-submenu-title:active {
  background: var(--elf-primary-1);
}
.elf-menu-submenu .elf-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-menu-title-content {
  transition: color 0.3s;
}
.elf-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.elf-menu-item a:hover {
  color: var(--elf-primary-color);
}
.elf-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.elf-menu-item > .elf-badge a {
  color: rgba(0, 0, 0, 0.85);
}
.elf-menu-item > .elf-badge a:hover {
  color: var(--elf-primary-color);
}
.elf-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: solid;
  border-width: 1px 0 0;
}
.elf-menu-item-divider-dashed {
  border-style: dashed;
}
.elf-menu-horizontal .elf-menu-item,
.elf-menu-horizontal .elf-menu-submenu {
  margin-top: -1px;
}
.elf-menu-horizontal > .elf-menu-item:hover,
.elf-menu-horizontal > .elf-menu-item-active,
.elf-menu-horizontal > .elf-menu-submenu .elf-menu-submenu-title:hover {
  background-color: transparent;
}
.elf-menu-item-selected {
  color: var(--elf-primary-color);
}
.elf-menu-item-selected a,
.elf-menu-item-selected a:hover {
  color: var(--elf-primary-color);
}
.elf-menu:not(.elf-menu-horizontal) .elf-menu-item-selected {
  background-color: var(--elf-primary-1);
}
.elf-menu-inline,
.elf-menu-vertical,
.elf-menu-vertical-left {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-menu-vertical-right {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-menu-vertical.elf-menu-sub,
.elf-menu-vertical-left.elf-menu-sub,
.elf-menu-vertical-right.elf-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.elf-menu-vertical.elf-menu-sub:not([class*='-active']),
.elf-menu-vertical-left.elf-menu-sub:not([class*='-active']),
.elf-menu-vertical-right.elf-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.elf-menu-vertical.elf-menu-sub .elf-menu-item,
.elf-menu-vertical-left.elf-menu-sub .elf-menu-item,
.elf-menu-vertical-right.elf-menu-sub .elf-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.elf-menu-vertical.elf-menu-sub .elf-menu-item::after,
.elf-menu-vertical-left.elf-menu-sub .elf-menu-item::after,
.elf-menu-vertical-right.elf-menu-sub .elf-menu-item::after {
  border-right: 0;
}
.elf-menu-vertical.elf-menu-sub > .elf-menu-item,
.elf-menu-vertical-left.elf-menu-sub > .elf-menu-item,
.elf-menu-vertical-right.elf-menu-sub > .elf-menu-item,
.elf-menu-vertical.elf-menu-sub > .elf-menu-submenu,
.elf-menu-vertical-left.elf-menu-sub > .elf-menu-submenu,
.elf-menu-vertical-right.elf-menu-sub > .elf-menu-submenu {
  transform-origin: 0 0;
}
.elf-menu-horizontal.elf-menu-sub {
  min-width: 114px;
}
.elf-menu-horizontal .elf-menu-item,
.elf-menu-horizontal .elf-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.elf-menu-item,
.elf-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-menu-item .elf-menu-item-icon,
.elf-menu-submenu-title .elf-menu-item-icon,
.elf-menu-item .anticon,
.elf-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s;
}
.elf-menu-item .elf-menu-item-icon + span,
.elf-menu-submenu-title .elf-menu-item-icon + span,
.elf-menu-item .anticon + span,
.elf-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.elf-menu-item .elf-menu-item-icon.svg,
.elf-menu-submenu-title .elf-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.elf-menu-item.elf-menu-item-only-child > .anticon,
.elf-menu-submenu-title.elf-menu-item-only-child > .anticon,
.elf-menu-item.elf-menu-item-only-child > .elf-menu-item-icon,
.elf-menu-submenu-title.elf-menu-item-only-child > .elf-menu-item-icon {
  margin-right: 0;
}
.elf-menu-item:not(.elf-menu-item-disabled):focus-visible,
.elf-menu-submenu-title:not(.elf-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px var(--elf-primary-2);
}
.elf-menu > .elf-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.elf-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  transform-origin: 0 0;
}
.elf-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.elf-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.elf-menu-submenu > .elf-menu {
  background-color: #fff;
  border-radius: 2px;
}
.elf-menu-submenu > .elf-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-menu-submenu-popup > .elf-menu {
  background-color: #fff;
}
.elf-menu-submenu-expand-icon,
.elf-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-menu-submenu-arrow::before,
.elf-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.elf-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.elf-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.elf-menu-submenu:hover > .elf-menu-submenu-title > .elf-menu-submenu-expand-icon,
.elf-menu-submenu:hover > .elf-menu-submenu-title > .elf-menu-submenu-arrow {
  color: var(--elf-primary-color);
}
.elf-menu-inline-collapsed .elf-menu-submenu-arrow::before,
.elf-menu-submenu-inline .elf-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.elf-menu-inline-collapsed .elf-menu-submenu-arrow::after,
.elf-menu-submenu-inline .elf-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.elf-menu-submenu-horizontal .elf-menu-submenu-arrow {
  display: none;
}
.elf-menu-submenu-open.elf-menu-submenu-inline > .elf-menu-submenu-title > .elf-menu-submenu-arrow {
  transform: translateY(-2px);
}
.elf-menu-submenu-open.elf-menu-submenu-inline > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.elf-menu-submenu-open.elf-menu-submenu-inline > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.elf-menu-vertical .elf-menu-submenu-selected,
.elf-menu-vertical-left .elf-menu-submenu-selected,
.elf-menu-vertical-right .elf-menu-submenu-selected {
  color: var(--elf-primary-color);
}
.elf-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: none;
}
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item:hover,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu:hover,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item-active,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu-active,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item-open,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu-open,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item-selected,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu-selected {
  color: var(--elf-primary-color);
}
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item:hover::after,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu:hover::after,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item-active::after,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu-active::after,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item-open::after,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu-open::after,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-item-selected::after,
.elf-menu-horizontal:not(.elf-menu-dark) > .elf-menu-submenu-selected::after {
  border-bottom: 2px solid var(--elf-primary-color);
}
.elf-menu-horizontal > .elf-menu-item,
.elf-menu-horizontal > .elf-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.elf-menu-horizontal > .elf-menu-item::after,
.elf-menu-horizontal > .elf-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.elf-menu-horizontal > .elf-menu-submenu > .elf-menu-submenu-title {
  padding: 0;
}
.elf-menu-horizontal > .elf-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.elf-menu-horizontal > .elf-menu-item a:hover {
  color: var(--elf-primary-color);
}
.elf-menu-horizontal > .elf-menu-item a::before {
  bottom: -2px;
}
.elf-menu-horizontal > .elf-menu-item-selected a {
  color: var(--elf-primary-color);
}
.elf-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.elf-menu-vertical .elf-menu-item,
.elf-menu-vertical-left .elf-menu-item,
.elf-menu-vertical-right .elf-menu-item,
.elf-menu-inline .elf-menu-item {
  position: relative;
}
.elf-menu-vertical .elf-menu-item::after,
.elf-menu-vertical-left .elf-menu-item::after,
.elf-menu-vertical-right .elf-menu-item::after,
.elf-menu-inline .elf-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid var(--elf-primary-color);
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.elf-menu-vertical .elf-menu-item,
.elf-menu-vertical-left .elf-menu-item,
.elf-menu-vertical-right .elf-menu-item,
.elf-menu-inline .elf-menu-item,
.elf-menu-vertical .elf-menu-submenu-title,
.elf-menu-vertical-left .elf-menu-submenu-title,
.elf-menu-vertical-right .elf-menu-submenu-title,
.elf-menu-inline .elf-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.elf-menu-vertical .elf-menu-submenu,
.elf-menu-vertical-left .elf-menu-submenu,
.elf-menu-vertical-right .elf-menu-submenu,
.elf-menu-inline .elf-menu-submenu {
  padding-bottom: 0.02px;
}
.elf-menu-vertical .elf-menu-item:not(:last-child),
.elf-menu-vertical-left .elf-menu-item:not(:last-child),
.elf-menu-vertical-right .elf-menu-item:not(:last-child),
.elf-menu-inline .elf-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.elf-menu-vertical > .elf-menu-item,
.elf-menu-vertical-left > .elf-menu-item,
.elf-menu-vertical-right > .elf-menu-item,
.elf-menu-inline > .elf-menu-item,
.elf-menu-vertical > .elf-menu-submenu > .elf-menu-submenu-title,
.elf-menu-vertical-left > .elf-menu-submenu > .elf-menu-submenu-title,
.elf-menu-vertical-right > .elf-menu-submenu > .elf-menu-submenu-title,
.elf-menu-inline > .elf-menu-submenu > .elf-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.elf-menu-vertical .elf-menu-item-group-list .elf-menu-submenu-title,
.elf-menu-vertical .elf-menu-submenu-title {
  padding-right: 34px;
}
.elf-menu-inline {
  width: 100%;
}
.elf-menu-inline .elf-menu-selected::after,
.elf-menu-inline .elf-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-menu-inline .elf-menu-item,
.elf-menu-inline .elf-menu-submenu-title {
  width: calc(100% + 1px);
}
.elf-menu-inline .elf-menu-item-group-list .elf-menu-submenu-title,
.elf-menu-inline .elf-menu-submenu-title {
  padding-right: 34px;
}
.elf-menu-inline.elf-menu-root .elf-menu-item,
.elf-menu-inline.elf-menu-root .elf-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.elf-menu-inline.elf-menu-root .elf-menu-item > .elf-menu-title-content,
.elf-menu-inline.elf-menu-root .elf-menu-submenu-title > .elf-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.elf-menu-inline.elf-menu-root .elf-menu-item > *,
.elf-menu-inline.elf-menu-root .elf-menu-submenu-title > * {
  flex: none;
}
.elf-menu.elf-menu-inline-collapsed {
  width: 80px;
}
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item-group > .elf-menu-item-group-list > .elf-menu-item,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-submenu
  > .elf-menu-submenu-title,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-submenu > .elf-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item .elf-menu-submenu-arrow,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-item
  .elf-menu-submenu-arrow,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-submenu
  > .elf-menu-submenu-title
  .elf-menu-submenu-arrow,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-submenu > .elf-menu-submenu-title .elf-menu-submenu-arrow {
  opacity: 0;
}
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item .elf-menu-item-icon,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-item
  .elf-menu-item-icon,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-submenu
  > .elf-menu-submenu-title
  .elf-menu-item-icon,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-submenu > .elf-menu-submenu-title .elf-menu-item-icon,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item .anticon,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item-group > .elf-menu-item-group-list > .elf-menu-item .anticon,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-submenu
  > .elf-menu-submenu-title
  .anticon,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-submenu > .elf-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item .elf-menu-item-icon + span,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-item
  .elf-menu-item-icon
  + span,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-submenu
  > .elf-menu-submenu-title
  .elf-menu-item-icon
  + span,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-submenu > .elf-menu-submenu-title .elf-menu-item-icon + span,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item .anticon + span,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-item-group > .elf-menu-item-group-list > .elf-menu-item .anticon + span,
.elf-menu.elf-menu-inline-collapsed
  > .elf-menu-item-group
  > .elf-menu-item-group-list
  > .elf-menu-submenu
  > .elf-menu-submenu-title
  .anticon
  + span,
.elf-menu.elf-menu-inline-collapsed > .elf-menu-submenu > .elf-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.elf-menu.elf-menu-inline-collapsed .elf-menu-item-icon,
.elf-menu.elf-menu-inline-collapsed .anticon {
  display: inline-block;
}
.elf-menu.elf-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.elf-menu.elf-menu-inline-collapsed-tooltip .elf-menu-item-icon,
.elf-menu.elf-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.elf-menu.elf-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.elf-menu.elf-menu-inline-collapsed .elf-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.elf-menu-item-group-list .elf-menu-item,
.elf-menu-item-group-list .elf-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.elf-menu-root.elf-menu-vertical,
.elf-menu-root.elf-menu-vertical-left,
.elf-menu-root.elf-menu-vertical-right,
.elf-menu-root.elf-menu-inline {
  box-shadow: none;
}
.elf-menu-root.elf-menu-inline-collapsed .elf-menu-item > .elf-menu-inline-collapsed-noicon,
.elf-menu-root.elf-menu-inline-collapsed .elf-menu-submenu .elf-menu-submenu-title > .elf-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.elf-menu-sub.elf-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.elf-menu-sub.elf-menu-inline > .elf-menu-item,
.elf-menu-sub.elf-menu-inline > .elf-menu-submenu > .elf-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.elf-menu-sub.elf-menu-inline .elf-menu-item-group-title {
  padding-left: 32px;
}
.elf-menu-item-disabled,
.elf-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.elf-menu-item-disabled::after,
.elf-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.elf-menu-item-disabled a,
.elf-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
}
.elf-menu-item-disabled > .elf-menu-submenu-title,
.elf-menu-submenu-disabled > .elf-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.elf-menu-item-disabled > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-submenu-disabled > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-item-disabled > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after,
.elf-menu-submenu-disabled > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.elf-layout-header .elf-menu {
  line-height: inherit;
}
.elf-menu-inline-collapsed-tooltip a,
.elf-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.elf-menu-light .elf-menu-item:hover,
.elf-menu-light .elf-menu-item-active,
.elf-menu-light .elf-menu:not(.elf-menu-inline) .elf-menu-submenu-open,
.elf-menu-light .elf-menu-submenu-active,
.elf-menu-light .elf-menu-submenu-title:hover {
  color: var(--elf-primary-color);
}
.elf-menu.elf-menu-root:focus-visible {
  box-shadow: 0 0 0 2px var(--elf-primary-7);
}
.elf-menu-dark .elf-menu-item:focus-visible,
.elf-menu-dark .elf-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px var(--elf-primary-7);
}
.elf-menu.elf-menu-dark,
.elf-menu-dark .elf-menu-sub,
.elf-menu.elf-menu-dark .elf-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.elf-menu.elf-menu-dark .elf-menu-submenu-title .elf-menu-submenu-arrow,
.elf-menu-dark .elf-menu-sub .elf-menu-submenu-title .elf-menu-submenu-arrow,
.elf-menu.elf-menu-dark .elf-menu-sub .elf-menu-submenu-title .elf-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.elf-menu.elf-menu-dark .elf-menu-submenu-title .elf-menu-submenu-arrow::after,
.elf-menu-dark .elf-menu-sub .elf-menu-submenu-title .elf-menu-submenu-arrow::after,
.elf-menu.elf-menu-dark .elf-menu-sub .elf-menu-submenu-title .elf-menu-submenu-arrow::after,
.elf-menu.elf-menu-dark .elf-menu-submenu-title .elf-menu-submenu-arrow::before,
.elf-menu-dark .elf-menu-sub .elf-menu-submenu-title .elf-menu-submenu-arrow::before,
.elf-menu.elf-menu-dark .elf-menu-sub .elf-menu-submenu-title .elf-menu-submenu-arrow::before {
  background: #fff;
}
.elf-menu-dark.elf-menu-submenu-popup {
  background: transparent;
}
.elf-menu-dark .elf-menu-inline.elf-menu-sub {
  background: #000c17;
}
.elf-menu-dark.elf-menu-horizontal {
  border-bottom: 0;
}
.elf-menu-dark.elf-menu-horizontal > .elf-menu-item,
.elf-menu-dark.elf-menu-horizontal > .elf-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.elf-menu-dark.elf-menu-horizontal > .elf-menu-item:hover {
  background-color: var(--elf-primary-color);
}
.elf-menu-dark.elf-menu-horizontal > .elf-menu-item > a::before {
  bottom: 0;
}
.elf-menu-dark .elf-menu-item,
.elf-menu-dark .elf-menu-item-group-title,
.elf-menu-dark .elf-menu-item > a,
.elf-menu-dark .elf-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.elf-menu-dark.elf-menu-inline,
.elf-menu-dark.elf-menu-vertical,
.elf-menu-dark.elf-menu-vertical-left,
.elf-menu-dark.elf-menu-vertical-right {
  border-right: 0;
}
.elf-menu-dark.elf-menu-inline .elf-menu-item,
.elf-menu-dark.elf-menu-vertical .elf-menu-item,
.elf-menu-dark.elf-menu-vertical-left .elf-menu-item,
.elf-menu-dark.elf-menu-vertical-right .elf-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.elf-menu-dark.elf-menu-inline .elf-menu-item::after,
.elf-menu-dark.elf-menu-vertical .elf-menu-item::after,
.elf-menu-dark.elf-menu-vertical-left .elf-menu-item::after,
.elf-menu-dark.elf-menu-vertical-right .elf-menu-item::after {
  border-right: 0;
}
.elf-menu-dark.elf-menu-inline .elf-menu-item,
.elf-menu-dark.elf-menu-inline .elf-menu-submenu-title {
  width: 100%;
}
.elf-menu-dark .elf-menu-item:hover,
.elf-menu-dark .elf-menu-item-active,
.elf-menu-dark .elf-menu-submenu-active,
.elf-menu-dark .elf-menu-submenu-open,
.elf-menu-dark .elf-menu-submenu-selected,
.elf-menu-dark .elf-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.elf-menu-dark .elf-menu-item:hover > a,
.elf-menu-dark .elf-menu-item-active > a,
.elf-menu-dark .elf-menu-submenu-active > a,
.elf-menu-dark .elf-menu-submenu-open > a,
.elf-menu-dark .elf-menu-submenu-selected > a,
.elf-menu-dark .elf-menu-submenu-title:hover > a,
.elf-menu-dark .elf-menu-item:hover > span > a,
.elf-menu-dark .elf-menu-item-active > span > a,
.elf-menu-dark .elf-menu-submenu-active > span > a,
.elf-menu-dark .elf-menu-submenu-open > span > a,
.elf-menu-dark .elf-menu-submenu-selected > span > a,
.elf-menu-dark .elf-menu-submenu-title:hover > span > a {
  color: #fff;
}
.elf-menu-dark .elf-menu-item:hover > .elf-menu-submenu-title > .elf-menu-submenu-arrow,
.elf-menu-dark .elf-menu-item-active > .elf-menu-submenu-title > .elf-menu-submenu-arrow,
.elf-menu-dark .elf-menu-submenu-active > .elf-menu-submenu-title > .elf-menu-submenu-arrow,
.elf-menu-dark .elf-menu-submenu-open > .elf-menu-submenu-title > .elf-menu-submenu-arrow,
.elf-menu-dark .elf-menu-submenu-selected > .elf-menu-submenu-title > .elf-menu-submenu-arrow,
.elf-menu-dark .elf-menu-submenu-title:hover > .elf-menu-submenu-title > .elf-menu-submenu-arrow {
  opacity: 1;
}
.elf-menu-dark .elf-menu-item:hover > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after,
.elf-menu-dark .elf-menu-item-active > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after,
.elf-menu-dark .elf-menu-submenu-active > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after,
.elf-menu-dark .elf-menu-submenu-open > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after,
.elf-menu-dark .elf-menu-submenu-selected > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after,
.elf-menu-dark .elf-menu-submenu-title:hover > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after,
.elf-menu-dark .elf-menu-item:hover > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-dark .elf-menu-item-active > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-dark .elf-menu-submenu-active > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-dark .elf-menu-submenu-open > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-dark .elf-menu-submenu-selected > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-dark .elf-menu-submenu-title:hover > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before {
  background: #fff;
}
.elf-menu-dark .elf-menu-item:hover {
  background-color: transparent;
}
.elf-menu-dark.elf-menu-dark:not(.elf-menu-horizontal) .elf-menu-item-selected {
  background-color: var(--elf-primary-color);
}
.elf-menu-dark .elf-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.elf-menu-dark .elf-menu-item-selected::after {
  border-right: 0;
}
.elf-menu-dark .elf-menu-item-selected > a,
.elf-menu-dark .elf-menu-item-selected > span > a,
.elf-menu-dark .elf-menu-item-selected > a:hover,
.elf-menu-dark .elf-menu-item-selected > span > a:hover {
  color: #fff;
}
.elf-menu-dark .elf-menu-item-selected .elf-menu-item-icon,
.elf-menu-dark .elf-menu-item-selected .anticon {
  color: #fff;
}
.elf-menu-dark .elf-menu-item-selected .elf-menu-item-icon + span,
.elf-menu-dark .elf-menu-item-selected .anticon + span {
  color: #fff;
}
.elf-menu.elf-menu-dark .elf-menu-item-selected,
.elf-menu-submenu-popup.elf-menu-dark .elf-menu-item-selected {
  background-color: var(--elf-primary-color);
}
.elf-menu-dark .elf-menu-item-disabled,
.elf-menu-dark .elf-menu-submenu-disabled,
.elf-menu-dark .elf-menu-item-disabled > a,
.elf-menu-dark .elf-menu-submenu-disabled > a,
.elf-menu-dark .elf-menu-item-disabled > span > a,
.elf-menu-dark .elf-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.elf-menu-dark .elf-menu-item-disabled > .elf-menu-submenu-title,
.elf-menu-dark .elf-menu-submenu-disabled > .elf-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.elf-menu-dark .elf-menu-item-disabled > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-dark .elf-menu-submenu-disabled > .elf-menu-submenu-title > .elf-menu-submenu-arrow::before,
.elf-menu-dark .elf-menu-item-disabled > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after,
.elf-menu-dark .elf-menu-submenu-disabled > .elf-menu-submenu-title > .elf-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.elf-menu.elf-menu-rtl {
  direction: rtl;
  text-align: right;
}
.elf-menu-rtl .elf-menu-item-group-title {
  text-align: right;
}
.elf-menu-rtl.elf-menu-inline,
.elf-menu-rtl.elf-menu-vertical {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-menu-rtl.elf-menu-dark.elf-menu-inline,
.elf-menu-rtl.elf-menu-dark.elf-menu-vertical {
  border-left: none;
}
.elf-menu-rtl.elf-menu-vertical.elf-menu-sub > .elf-menu-item,
.elf-menu-rtl.elf-menu-vertical-left.elf-menu-sub > .elf-menu-item,
.elf-menu-rtl.elf-menu-vertical-right.elf-menu-sub > .elf-menu-item,
.elf-menu-rtl.elf-menu-vertical.elf-menu-sub > .elf-menu-submenu,
.elf-menu-rtl.elf-menu-vertical-left.elf-menu-sub > .elf-menu-submenu,
.elf-menu-rtl.elf-menu-vertical-right.elf-menu-sub > .elf-menu-submenu {
  transform-origin: top right;
}
.elf-menu-rtl .elf-menu-item .elf-menu-item-icon,
.elf-menu-rtl .elf-menu-submenu-title .elf-menu-item-icon,
.elf-menu-rtl .elf-menu-item .anticon,
.elf-menu-rtl .elf-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.elf-menu-rtl .elf-menu-item.elf-menu-item-only-child > .elf-menu-item-icon,
.elf-menu-rtl .elf-menu-submenu-title.elf-menu-item-only-child > .elf-menu-item-icon,
.elf-menu-rtl .elf-menu-item.elf-menu-item-only-child > .anticon,
.elf-menu-rtl .elf-menu-submenu-title.elf-menu-item-only-child > .anticon {
  margin-left: 0;
}
.elf-menu-submenu-rtl.elf-menu-submenu-popup {
  transform-origin: 100% 0;
}
.elf-menu-rtl .elf-menu-submenu-vertical > .elf-menu-submenu-title .elf-menu-submenu-arrow,
.elf-menu-rtl .elf-menu-submenu-vertical-left > .elf-menu-submenu-title .elf-menu-submenu-arrow,
.elf-menu-rtl .elf-menu-submenu-vertical-right > .elf-menu-submenu-title .elf-menu-submenu-arrow,
.elf-menu-rtl .elf-menu-submenu-inline > .elf-menu-submenu-title .elf-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.elf-menu-rtl .elf-menu-submenu-vertical > .elf-menu-submenu-title .elf-menu-submenu-arrow::before,
.elf-menu-rtl .elf-menu-submenu-vertical-left > .elf-menu-submenu-title .elf-menu-submenu-arrow::before,
.elf-menu-rtl .elf-menu-submenu-vertical-right > .elf-menu-submenu-title .elf-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.elf-menu-rtl .elf-menu-submenu-vertical > .elf-menu-submenu-title .elf-menu-submenu-arrow::after,
.elf-menu-rtl .elf-menu-submenu-vertical-left > .elf-menu-submenu-title .elf-menu-submenu-arrow::after,
.elf-menu-rtl .elf-menu-submenu-vertical-right > .elf-menu-submenu-title .elf-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.elf-menu-rtl.elf-menu-vertical .elf-menu-item::after,
.elf-menu-rtl.elf-menu-vertical-left .elf-menu-item::after,
.elf-menu-rtl.elf-menu-vertical-right .elf-menu-item::after,
.elf-menu-rtl.elf-menu-inline .elf-menu-item::after {
  right: auto;
  left: 0;
}
.elf-menu-rtl.elf-menu-vertical .elf-menu-item,
.elf-menu-rtl.elf-menu-vertical-left .elf-menu-item,
.elf-menu-rtl.elf-menu-vertical-right .elf-menu-item,
.elf-menu-rtl.elf-menu-inline .elf-menu-item,
.elf-menu-rtl.elf-menu-vertical .elf-menu-submenu-title,
.elf-menu-rtl.elf-menu-vertical-left .elf-menu-submenu-title,
.elf-menu-rtl.elf-menu-vertical-right .elf-menu-submenu-title,
.elf-menu-rtl.elf-menu-inline .elf-menu-submenu-title {
  text-align: right;
}
.elf-menu-rtl.elf-menu-inline .elf-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.elf-menu-rtl.elf-menu-vertical .elf-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.elf-menu-rtl.elf-menu-inline-collapsed.elf-menu-vertical .elf-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.elf-menu-rtl .elf-menu-item-group-list .elf-menu-item,
.elf-menu-rtl .elf-menu-item-group-list .elf-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.elf-menu-sub.elf-menu-inline {
  border: 0;
}
.elf-menu-rtl.elf-menu-sub.elf-menu-inline .elf-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.elf-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.elf-message-notice {
  padding: 8px;
  text-align: center;
}
.elf-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.elf-message-success .anticon {
  color: var(--elf-success-color);
}
.elf-message-error .anticon {
  color: var(--elf-error-color);
}
.elf-message-warning .anticon {
  color: var(--elf-warning-color);
}
.elf-message-info .anticon,
.elf-message-loading .anticon {
  color: var(--elf-info-color);
}
.elf-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.elf-message-notice.elf-move-up-leave.elf-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.elf-message-rtl {
  direction: rtl;
}
.elf-message-rtl span {
  direction: rtl;
}
.elf-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.elf-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.elf-modal.elf-zoom-enter,
.elf-modal.elf-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.elf-modal-mask-hidden {
  display: none;
}
.elf-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 1000;
}
.elf-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.elf-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.elf-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.elf-modal-close-x {
  display: block;
  width: 54px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.elf-modal-close:focus,
.elf-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.elf-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px 2px 0 0;
}
.elf-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.elf-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0 0 2px 2px;
}
.elf-modal-footer .elf-btn + .elf-btn:not(.elf-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.elf-modal-open {
  overflow: hidden;
}
.elf-modal-centered {
  text-align: center;
}
.elf-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.elf-modal-centered .elf-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .elf-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .elf-modal-centered .elf-modal {
    flex: 1;
  }
}
.elf-modal-confirm .elf-modal-header {
  display: none;
}
.elf-modal-confirm .elf-modal-body {
  padding: 32px 32px 24px;
}
.elf-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.elf-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.elf-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.elf-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.elf-modal-confirm-body .elf-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.elf-modal-confirm-body .elf-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.elf-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.elf-modal-confirm-body > .anticon + .elf-modal-confirm-title + .elf-modal-confirm-content {
  margin-left: 38px;
}
.elf-modal-confirm .elf-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}
.elf-modal-confirm .elf-modal-confirm-btns .elf-btn + .elf-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.elf-modal-confirm-error .elf-modal-confirm-body > .anticon {
  color: var(--elf-error-color);
}
.elf-modal-confirm-warning .elf-modal-confirm-body > .anticon,
.elf-modal-confirm-confirm .elf-modal-confirm-body > .anticon {
  color: var(--elf-warning-color);
}
.elf-modal-confirm-info .elf-modal-confirm-body > .anticon {
  color: var(--elf-info-color);
}
.elf-modal-confirm-success .elf-modal-confirm-body > .anticon {
  color: var(--elf-success-color);
}
.elf-modal-confirm .elf-zoom-leave .elf-modal-confirm-btns {
  pointer-events: none;
}
.elf-modal-wrap-rtl {
  direction: rtl;
}
.elf-modal-wrap-rtl .elf-modal-close {
  right: initial;
  left: 0;
}
.elf-modal-wrap-rtl .elf-modal-footer {
  text-align: left;
}
.elf-modal-wrap-rtl .elf-modal-footer .elf-btn + .elf-btn {
  margin-right: 8px;
  margin-left: 0;
}
.elf-modal-wrap-rtl .elf-modal-confirm-body {
  direction: rtl;
}
.elf-modal-wrap-rtl .elf-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.elf-modal-wrap-rtl .elf-modal-confirm-body > .anticon + .elf-modal-confirm-title + .elf-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.elf-modal-wrap-rtl .elf-modal-confirm-btns {
  text-align: left;
}
.elf-modal-wrap-rtl .elf-modal-confirm-btns .elf-btn + .elf-btn {
  margin-right: 8px;
  margin-left: 0;
}
.elf-modal-wrap-rtl.elf-modal-centered .elf-modal {
  text-align: right;
}
.elf-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.elf-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.elf-notification-hook-holder {
  position: relative;
}
.elf-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.elf-notification-top .elf-notification-notice,
.elf-notification-bottom .elf-notification-notice {
  margin-right: auto;
  margin-left: auto;
}
.elf-notification-topLeft .elf-notification-notice,
.elf-notification-bottomLeft .elf-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.elf-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.elf-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.elf-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.elf-notification-notice-description {
  font-size: 14px;
}
.elf-notification-notice-closable .elf-notification-notice-message {
  padding-right: 24px;
}
.elf-notification-notice-with-icon .elf-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.elf-notification-notice-with-icon .elf-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.elf-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.elf-notification-notice-icon-success {
  color: var(--elf-success-color);
}
.anticon.elf-notification-notice-icon-info {
  color: var(--elf-info-color);
}
.anticon.elf-notification-notice-icon-warning {
  color: var(--elf-warning-color);
}
.anticon.elf-notification-notice-icon-error {
  color: var(--elf-error-color);
}
.elf-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.elf-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.elf-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.elf-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.elf-notification-fade-enter,
.elf-notification-fade-appear {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  opacity: 0;
  animation-play-state: paused;
}
.elf-notification-fade-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.elf-notification-fade-enter.elf-notification-fade-enter-active,
.elf-notification-fade-appear.elf-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.elf-notification-fade-leave.elf-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.elf-notification-rtl {
  direction: rtl;
}
.elf-notification-rtl .elf-notification-notice-closable .elf-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.elf-notification-rtl .elf-notification-notice-with-icon .elf-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.elf-notification-rtl .elf-notification-notice-with-icon .elf-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.elf-notification-rtl .elf-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.elf-notification-rtl .elf-notification-notice-close {
  right: auto;
  left: 22px;
}
.elf-notification-rtl .elf-notification-notice-btn {
  float: left;
}
.elf-notification-top,
.elf-notification-bottom {
  margin-right: 0;
  margin-left: 0;
}
.elf-notification-top .elf-notification-fade-enter.elf-notification-fade-enter-active,
.elf-notification-top .elf-notification-fade-appear.elf-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}
.elf-notification-bottom .elf-notification-fade-enter.elf-notification-fade-enter-active,
.elf-notification-bottom .elf-notification-fade-appear.elf-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}
.elf-notification-topLeft,
.elf-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.elf-notification-topLeft .elf-notification-fade-enter.elf-notification-fade-enter-active,
.elf-notification-bottomLeft .elf-notification-fade-enter.elf-notification-fade-enter-active,
.elf-notification-topLeft .elf-notification-fade-appear.elf-notification-fade-appear-active,
.elf-notification-bottomLeft .elf-notification-fade-appear.elf-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
@keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
.elf-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.elf-page-header-ghost {
  background-color: inherit;
}
.elf-page-header.has-breadcrumb {
  padding-top: 12px;
}
.elf-page-header.has-footer {
  padding-bottom: 0;
}
.elf-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.elf-page-header-back-button {
  color: var(--elf-primary-color);
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: #000;
}
.elf-page-header-back-button:focus-visible,
.elf-page-header-back-button:hover {
  color: var(--elf-primary-color-hover);
}
.elf-page-header-back-button:active {
  color: var(--elf-primary-color-active);
}
.elf-page-header .elf-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.elf-breadcrumb + .elf-page-header-heading {
  margin-top: 8px;
}
.elf-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.elf-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.elf-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-page-header-heading .elf-avatar {
  margin-right: 12px;
}
.elf-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.elf-page-header-heading-extra > * {
  white-space: unset;
}
.elf-page-header-content {
  padding-top: 12px;
}
.elf-page-header-footer {
  margin-top: 16px;
}
.elf-page-header-footer .elf-tabs > .elf-tabs-nav {
  margin: 0;
}
.elf-page-header-footer .elf-tabs > .elf-tabs-nav::before {
  border: none;
}
.elf-page-header-footer .elf-tabs .elf-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.elf-page-header-compact .elf-page-header-heading {
  flex-wrap: wrap;
}
.elf-page-header-rtl {
  direction: rtl;
}
.elf-page-header-rtl .elf-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.elf-page-header-rtl .elf-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.elf-page-header-rtl .elf-page-header-heading .elf-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.elf-page-header-rtl .elf-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.elf-page-header-rtl .elf-page-header-heading-tags {
  float: right;
}
.elf-page-header-rtl .elf-page-header-heading-extra {
  float: left;
}
.elf-page-header-rtl .elf-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.elf-page-header-rtl .elf-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.elf-page-header-rtl .elf-page-header-footer .elf-tabs-bar .elf-tabs-nav {
  float: right;
}
.elf-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.elf-pagination ul,
.elf-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.elf-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.elf-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.elf-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.elf-pagination-item a:hover {
  text-decoration: none;
}
.elf-pagination-item:hover {
  border-color: var(--elf-primary-color);
  transition: all 0.3s;
}
.elf-pagination-item:hover a {
  color: var(--elf-primary-color);
}
.elf-pagination-item:focus-visible {
  border-color: var(--elf-primary-color);
  transition: all 0.3s;
}
.elf-pagination-item:focus-visible a {
  color: var(--elf-primary-color);
}
.elf-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: var(--elf-primary-color);
}
.elf-pagination-item-active a {
  color: var(--elf-primary-color);
}
.elf-pagination-item-active:hover {
  border-color: var(--elf-primary-5);
}
.elf-pagination-item-active:focus-visible {
  border-color: var(--elf-primary-5);
}
.elf-pagination-item-active:hover a {
  color: var(--elf-primary-5);
}
.elf-pagination-item-active:focus-visible a {
  color: var(--elf-primary-5);
}
.elf-pagination-jump-prev,
.elf-pagination-jump-next {
  outline: 0;
}
.elf-pagination-jump-prev .elf-pagination-item-container,
.elf-pagination-jump-next .elf-pagination-item-container {
  position: relative;
}
.elf-pagination-jump-prev .elf-pagination-item-container .elf-pagination-item-link-icon,
.elf-pagination-jump-next .elf-pagination-item-container .elf-pagination-item-link-icon {
  color: var(--elf-primary-color);
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.elf-pagination-jump-prev .elf-pagination-item-container .elf-pagination-item-link-icon-svg,
.elf-pagination-jump-next .elf-pagination-item-container .elf-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.elf-pagination-jump-prev .elf-pagination-item-container .elf-pagination-item-ellipsis,
.elf-pagination-jump-next .elf-pagination-item-container .elf-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.elf-pagination-jump-prev:hover .elf-pagination-item-link-icon,
.elf-pagination-jump-next:hover .elf-pagination-item-link-icon {
  opacity: 1;
}
.elf-pagination-jump-prev:hover .elf-pagination-item-ellipsis,
.elf-pagination-jump-next:hover .elf-pagination-item-ellipsis {
  opacity: 0;
}
.elf-pagination-jump-prev:focus-visible .elf-pagination-item-link-icon,
.elf-pagination-jump-next:focus-visible .elf-pagination-item-link-icon {
  opacity: 1;
}
.elf-pagination-jump-prev:focus-visible .elf-pagination-item-ellipsis,
.elf-pagination-jump-next:focus-visible .elf-pagination-item-ellipsis {
  opacity: 0;
}
.elf-pagination-prev,
.elf-pagination-jump-prev,
.elf-pagination-jump-next {
  margin-right: 8px;
}
.elf-pagination-prev,
.elf-pagination-next,
.elf-pagination-jump-prev,
.elf-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-pagination-prev,
.elf-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.elf-pagination-prev button,
.elf-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-pagination-prev:hover button,
.elf-pagination-next:hover button {
  border-color: var(--elf-primary-5);
}
.elf-pagination-prev .elf-pagination-item-link,
.elf-pagination-next .elf-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.elf-pagination-prev:focus-visible .elf-pagination-item-link,
.elf-pagination-next:focus-visible .elf-pagination-item-link {
  color: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
}
.elf-pagination-prev:hover .elf-pagination-item-link,
.elf-pagination-next:hover .elf-pagination-item-link {
  color: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
}
.elf-pagination-disabled,
.elf-pagination-disabled:hover {
  cursor: not-allowed;
}
.elf-pagination-disabled .elf-pagination-item-link,
.elf-pagination-disabled:hover .elf-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.elf-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.elf-pagination-disabled:focus-visible .elf-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.elf-pagination-slash {
  margin: 0 10px 0 5px;
}
.elf-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .elf-pagination-options *::-ms-backdrop,
  .elf-pagination-options {
    vertical-align: top;
  }
}
.elf-pagination-options-size-changer.elf-select {
  display: inline-block;
  width: auto;
}
.elf-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.elf-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.elf-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.elf-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.elf-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.elf-pagination-options-quick-jumper input:hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-pagination-options-quick-jumper input:focus,
.elf-pagination-options-quick-jumper input-focused {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-pagination-options-quick-jumper input:focus,
.elf-input-rtl .elf-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.elf-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.elf-pagination-options-quick-jumper input-borderless,
.elf-pagination-options-quick-jumper input-borderless:hover,
.elf-pagination-options-quick-jumper input-borderless:focus,
.elf-pagination-options-quick-jumper input-borderless-focused,
.elf-pagination-options-quick-jumper input-borderless-disabled,
.elf-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.elf-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.elf-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.elf-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.elf-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.elf-pagination-simple .elf-pagination-prev,
.elf-pagination-simple .elf-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.elf-pagination-simple .elf-pagination-prev .elf-pagination-item-link,
.elf-pagination-simple .elf-pagination-next .elf-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.elf-pagination-simple .elf-pagination-prev .elf-pagination-item-link::after,
.elf-pagination-simple .elf-pagination-next .elf-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.elf-pagination-simple .elf-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.elf-pagination-simple .elf-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.elf-pagination-simple .elf-pagination-simple-pager input:hover {
  border-color: var(--elf-primary-color);
}
.elf-pagination-simple .elf-pagination-simple-pager input:focus {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
}
.elf-pagination-simple .elf-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.elf-pagination.elf-pagination-mini .elf-pagination-total-text,
.elf-pagination.elf-pagination-mini .elf-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.elf-pagination.elf-pagination-mini .elf-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.elf-pagination.elf-pagination-mini .elf-pagination-item:not(.elf-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.elf-pagination.elf-pagination-mini .elf-pagination-prev,
.elf-pagination.elf-pagination-mini .elf-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.elf-pagination.elf-pagination-mini .elf-pagination-prev .elf-pagination-item-link,
.elf-pagination.elf-pagination-mini .elf-pagination-next .elf-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.elf-pagination.elf-pagination-mini .elf-pagination-prev .elf-pagination-item-link::after,
.elf-pagination.elf-pagination-mini .elf-pagination-next .elf-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.elf-pagination.elf-pagination-mini .elf-pagination-jump-prev,
.elf-pagination.elf-pagination-mini .elf-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.elf-pagination.elf-pagination-mini .elf-pagination-options {
  margin-left: 2px;
}
.elf-pagination.elf-pagination-mini .elf-pagination-options-size-changer {
  top: 0px;
}
.elf-pagination.elf-pagination-mini .elf-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.elf-pagination.elf-pagination-mini .elf-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.elf-pagination.elf-pagination-disabled {
  cursor: not-allowed;
}
.elf-pagination.elf-pagination-disabled .elf-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.elf-pagination.elf-pagination-disabled .elf-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.elf-pagination.elf-pagination-disabled .elf-pagination-item-active {
  background: #e6e6e6;
}
.elf-pagination.elf-pagination-disabled .elf-pagination-item-active a {
  color: rgba(0, 0, 0, 0.25);
}
.elf-pagination.elf-pagination-disabled .elf-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.elf-pagination-simple.elf-pagination.elf-pagination-disabled .elf-pagination-item-link {
  background: transparent;
}
.elf-pagination.elf-pagination-disabled .elf-pagination-item-link-icon {
  opacity: 0;
}
.elf-pagination.elf-pagination-disabled .elf-pagination-item-ellipsis {
  opacity: 1;
}
.elf-pagination.elf-pagination-disabled .elf-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .elf-pagination-item-after-jump-prev,
  .elf-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .elf-pagination-options {
    display: none;
  }
}
.elf-pagination-rtl .elf-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.elf-pagination-rtl .elf-pagination-item,
.elf-pagination-rtl .elf-pagination-prev,
.elf-pagination-rtl .elf-pagination-jump-prev,
.elf-pagination-rtl .elf-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.elf-pagination-rtl .elf-pagination-slash {
  margin: 0 5px 0 10px;
}
.elf-pagination-rtl .elf-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.elf-pagination-rtl .elf-pagination-options .elf-pagination-options-size-changer.elf-select {
  margin-right: 0;
  margin-left: 8px;
}
.elf-pagination-rtl .elf-pagination-options .elf-pagination-options-quick-jumper {
  margin-left: 0;
}
.elf-pagination-rtl.elf-pagination-simple .elf-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.elf-pagination-rtl.elf-pagination-simple .elf-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.elf-pagination-rtl.elf-pagination.mini .elf-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.elf-popconfirm {
  z-index: 1060;
}
.elf-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
}
.elf-popover-content {
  position: relative;
}
.elf-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.elf-popover-hidden {
  display: none;
}
.elf-popover-placement-top,
.elf-popover-placement-topLeft,
.elf-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.elf-popover-placement-right,
.elf-popover-placement-rightTop,
.elf-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.elf-popover-placement-bottom,
.elf-popover-placement-bottomLeft,
.elf-popover-placement-bottomRight {
  padding-top: 4px;
}
.elf-popover-placement-left,
.elf-popover-placement-leftTop,
.elf-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.elf-popover-inner {
  background-color: #0e0c15;
  background-clip: padding-box;
  border-radius: 6px;
  border: 1px solid #8b60f7;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .elf-popover {
    /* IE10+ */
  }
  .elf-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.elf-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}
.elf-popover-message {
  display: flex;
  align-items: center;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.elf-popover-message-icon {
  display: inline-block;
  margin-right: 8px;
  color: var(--elf-warning-color);
  font-size: 14px;
}
.elf-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.elf-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}
.elf-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.elf-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.elf-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path(
    'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
  );
          clip-path: path(
    'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
  );
}
.elf-popover-placement-top .elf-popover-arrow,
.elf-popover-placement-topLeft .elf-popover-arrow,
.elf-popover-placement-topRight .elf-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.elf-popover-placement-top .elf-popover-arrow-content,
.elf-popover-placement-topLeft .elf-popover-arrow-content,
.elf-popover-placement-topRight .elf-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.elf-popover-placement-top .elf-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.elf-popover-placement-topLeft .elf-popover-arrow {
  left: 16px;
}
.elf-popover-placement-topRight .elf-popover-arrow {
  right: 16px;
}
.elf-popover-placement-right .elf-popover-arrow,
.elf-popover-placement-rightTop .elf-popover-arrow,
.elf-popover-placement-rightBottom .elf-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.elf-popover-placement-right .elf-popover-arrow-content,
.elf-popover-placement-rightTop .elf-popover-arrow-content,
.elf-popover-placement-rightBottom .elf-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.elf-popover-placement-right .elf-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.elf-popover-placement-rightTop .elf-popover-arrow {
  top: 12px;
}
.elf-popover-placement-rightBottom .elf-popover-arrow {
  bottom: 12px;
}
.elf-popover-placement-bottom .elf-popover-arrow,
.elf-popover-placement-bottomLeft .elf-popover-arrow,
.elf-popover-placement-bottomRight .elf-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.elf-popover-placement-bottom .elf-popover-arrow-content,
.elf-popover-placement-bottomLeft .elf-popover-arrow-content,
.elf-popover-placement-bottomRight .elf-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.elf-popover-placement-bottom .elf-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.elf-popover-placement-bottomLeft .elf-popover-arrow {
  left: 16px;
}
.elf-popover-placement-bottomRight .elf-popover-arrow {
  right: 16px;
}
.elf-popover-placement-left .elf-popover-arrow,
.elf-popover-placement-leftTop .elf-popover-arrow,
.elf-popover-placement-leftBottom .elf-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.elf-popover-placement-left .elf-popover-arrow-content,
.elf-popover-placement-leftTop .elf-popover-arrow-content,
.elf-popover-placement-leftBottom .elf-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.elf-popover-placement-left .elf-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.elf-popover-placement-leftTop .elf-popover-arrow {
  top: 12px;
}
.elf-popover-placement-leftBottom .elf-popover-arrow {
  bottom: 12px;
}
.elf-popover-pink .elf-popover-inner {
  background-color: #eb2f96;
}
.elf-popover-pink .elf-popover-arrow-content {
  background-color: #eb2f96;
}
.elf-popover-magenta .elf-popover-inner {
  background-color: #eb2f96;
}
.elf-popover-magenta .elf-popover-arrow-content {
  background-color: #eb2f96;
}
.elf-popover-red .elf-popover-inner {
  background-color: #f5222d;
}
.elf-popover-red .elf-popover-arrow-content {
  background-color: #f5222d;
}
.elf-popover-volcano .elf-popover-inner {
  background-color: #fa541c;
}
.elf-popover-volcano .elf-popover-arrow-content {
  background-color: #fa541c;
}
.elf-popover-orange .elf-popover-inner {
  background-color: #fa8c16;
}
.elf-popover-orange .elf-popover-arrow-content {
  background-color: #fa8c16;
}
.elf-popover-yellow .elf-popover-inner {
  background-color: #fadb14;
}
.elf-popover-yellow .elf-popover-arrow-content {
  background-color: #fadb14;
}
.elf-popover-gold .elf-popover-inner {
  background-color: #faad14;
}
.elf-popover-gold .elf-popover-arrow-content {
  background-color: #faad14;
}
.elf-popover-cyan .elf-popover-inner {
  background-color: #13c2c2;
}
.elf-popover-cyan .elf-popover-arrow-content {
  background-color: #13c2c2;
}
.elf-popover-lime .elf-popover-inner {
  background-color: #a0d911;
}
.elf-popover-lime .elf-popover-arrow-content {
  background-color: #a0d911;
}
.elf-popover-green .elf-popover-inner {
  background-color: #52c41a;
}
.elf-popover-green .elf-popover-arrow-content {
  background-color: #52c41a;
}
.elf-popover-blue .elf-popover-inner {
  background-color: #8b60f7;
}
.elf-popover-blue .elf-popover-arrow-content {
  background-color: #8b60f7;
}
.elf-popover-geekblue .elf-popover-inner {
  background-color: #2f54eb;
}
.elf-popover-geekblue .elf-popover-arrow-content {
  background-color: #2f54eb;
}
.elf-popover-purple .elf-popover-inner {
  background-color: #722ed1;
}
.elf-popover-purple .elf-popover-arrow-content {
  background-color: #722ed1;
}
.elf-popover-rtl {
  direction: rtl;
  text-align: right;
}
.elf-popover-rtl .elf-popover-message-icon {
  margin-right: 0;
  margin-left: 8px;
}
.elf-popover-rtl .elf-popover-message-title {
  padding-left: 16px;
}
.elf-popover-rtl .elf-popover-buttons {
  text-align: left;
}
.elf-popover-rtl .elf-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.elf-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.elf-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.elf-progress-steps {
  display: inline-block;
}
.elf-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.elf-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.elf-progress-steps-item-active {
  background: var(--elf-primary-color);
}
.elf-progress-small.elf-progress-line,
.elf-progress-small.elf-progress-line .elf-progress-text .anticon {
  font-size: 12px;
}
.elf-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.elf-progress-show-info .elf-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.elf-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
}
.elf-progress-circle-trail {
  stroke: rgba(0, 0, 0, 0.04);
}
.elf-progress-circle-path {
  animation: elf-progress-appear 0.3s;
}
.elf-progress-inner:not(.elf-progress-circle-gradient) .elf-progress-circle-path {
  stroke: var(--elf-primary-color);
}
.elf-progress-success-bg,
.elf-progress-bg {
  position: relative;
  background-color: var(--elf-primary-color);
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.elf-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--elf-success-color);
}
.elf-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.elf-progress-text .anticon {
  font-size: 14px;
}
.elf-progress-status-active .elf-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: elf-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.elf-progress-status-exception .elf-progress-bg {
  background-color: var(--elf-error-color);
}
.elf-progress-status-exception .elf-progress-text {
  color: var(--elf-error-color);
}
.elf-progress-status-exception .elf-progress-inner:not(.elf-progress-circle-gradient) .elf-progress-circle-path {
  stroke: var(--elf-error-color);
}
.elf-progress-status-success .elf-progress-bg {
  background-color: var(--elf-success-color);
}
.elf-progress-status-success .elf-progress-text {
  color: var(--elf-success-color);
}
.elf-progress-status-success .elf-progress-inner:not(.elf-progress-circle-gradient) .elf-progress-circle-path {
  stroke: var(--elf-success-color);
}
.elf-progress-circle .elf-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.elf-progress-circle .elf-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.elf-progress-circle .elf-progress-text .anticon {
  font-size: 1.16666667em;
}
.elf-progress-circle.elf-progress-status-exception .elf-progress-text {
  color: var(--elf-error-color);
}
.elf-progress-circle.elf-progress-status-success .elf-progress-text {
  color: var(--elf-success-color);
}
@keyframes elf-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.elf-progress-rtl {
  direction: rtl;
}
.elf-progress-rtl.elf-progress-show-info .elf-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.elf-progress-rtl .elf-progress-success-bg {
  right: 0;
  left: auto;
}
.elf-progress-rtl.elf-progress-line .elf-progress-text,
.elf-progress-rtl.elf-progress-steps .elf-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.elf-radio-group {
  padding: 3px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  font-size: 0;
  background: rgba(51, 36, 88, 0.4);
}
.elf-radio-group .elf-badge-count {
  z-index: 1;
}
.elf-radio-group > .elf-badge:not(:first-child) > .elf-radio-button-wrapper {
  border-left: none;
}
.elf-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.elf-radio-wrapper-disabled {
  cursor: not-allowed;
}
.elf-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.elf-radio-wrapper.elf-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.elf-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.elf-radio-wrapper:hover .elf-radio,
.elf-radio:hover .elf-radio-inner,
.elf-radio-input:focus + .elf-radio-inner {
  border-color: var(--elf-primary-color);
}
.elf-radio-input:focus + .elf-radio-inner {
  box-shadow: 0 0 0 3px var(--elf-primary-color-deprecated-f-12);
}
.elf-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--elf-primary-color);
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.elf-radio:hover::after,
.elf-radio-wrapper:hover .elf-radio::after {
  visibility: visible;
}
.elf-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.elf-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: var(--elf-primary-color);
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.elf-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.elf-radio.elf-radio-disabled .elf-radio-inner {
  border-color: #d9d9d9;
}
.elf-radio-checked .elf-radio-inner {
  border-color: var(--elf-primary-color);
}
.elf-radio-checked .elf-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-radio-disabled {
  cursor: not-allowed;
}
.elf-radio-disabled .elf-radio-inner {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.elf-radio-disabled .elf-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.elf-radio-disabled .elf-radio-input {
  cursor: not-allowed;
}
.elf-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.elf-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.elf-radio-button-wrapper {
  position: relative;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.elf-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85);
}
.elf-radio-button-wrapper > .elf-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.elf-radio-group-large .elf-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.elf-radio-group-small .elf-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.elf-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.elf-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.elf-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.elf-radio-button-wrapper:hover {
  position: relative;
  color: var(--elf-primary-color);
}
.elf-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px var(--elf-primary-color-deprecated-f-12);
}
.elf-radio-button-wrapper .elf-radio-inner,
.elf-radio-button-wrapper input[type='checkbox'],
.elf-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled) {
  z-index: 1;
  color: var(--elf-primary-color);
  background: #fff;
  border-color: var(--elf-primary-color);
}
.elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled)::before {
  background-color: var(--elf-primary-color);
}
.elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):first-child {
  border-color: var(--elf-primary-color);
}
.elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):hover {
  color: var(--elf-primary-5);
  border-color: var(--elf-primary-5);
}
.elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):hover::before {
  background-color: var(--elf-primary-5);
}
.elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):active {
  color: var(--elf-primary-7);
  border-color: var(--elf-primary-7);
}
.elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):active::before {
  background-color: var(--elf-primary-7);
}
.elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px var(--elf-primary-color-deprecated-f-12);
}
.elf-radio-group-solid .elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
}
.elf-radio-group-solid .elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: var(--elf-primary-5);
  border-color: var(--elf-primary-5);
}
.elf-radio-group-solid .elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):active {
  color: #fff;
  background: var(--elf-primary-7);
  border-color: var(--elf-primary-7);
}
.elf-radio-group-solid .elf-radio-button-wrapper-checked:not(.elf-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px var(--elf-primary-color-deprecated-f-12);
}
.elf-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.elf-radio-button-wrapper-disabled:first-child,
.elf-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.elf-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.elf-radio-button-wrapper-disabled.elf-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.elf-radio-group.elf-radio-group-rtl {
  direction: rtl;
}
.elf-radio-wrapper.elf-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.elf-radio-button-wrapper.elf-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.elf-radio-button-wrapper.elf-radio-button-wrapper-rtl.elf-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.elf-radio-button-wrapper.elf-radio-button-wrapper-rtl.elf-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
.elf-radio-button-wrapper-checked:not(
    [class*=' elf-radio-button-wrapper-disabled']
  ).elf-radio-button-wrapper:first-child {
  border-right-color: var(--elf-primary-5);
}
.elf-radio-button-wrapper.elf-radio-button-wrapper-rtl.elf-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.elf-radio-button-wrapper.elf-radio-button-wrapper-rtl.elf-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}
.elf-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.elf-rate-disabled .elf-rate-star {
  cursor: default;
}
.elf-rate-disabled .elf-rate-star > div:hover {
  transform: scale(1);
}
.elf-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
.elf-rate-star:not(:last-child) {
  margin-right: 8px;
}
.elf-rate-star > div {
  transition: all 0.3s, outline 0s;
}
.elf-rate-star > div:hover {
  transform: scale(1.1);
}
.elf-rate-star > div:focus {
  outline: 0;
}
.elf-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}
.elf-rate-star-first,
.elf-rate-star-second {
  color: rgba(0, 0, 0, 0.06);
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-rate-star-first .anticon,
.elf-rate-star-second .anticon {
  vertical-align: middle;
}
.elf-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.elf-rate-star-half .elf-rate-star-first,
.elf-rate-star-half .elf-rate-star-second {
  opacity: 1;
}
.elf-rate-star-half .elf-rate-star-first,
.elf-rate-star-full .elf-rate-star-second {
  color: inherit;
}
.elf-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.elf-rate-rtl {
  direction: rtl;
}
.elf-rate-rtl .elf-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.elf-rate-rtl .elf-rate-star-first {
  right: 0;
  left: auto;
}
.elf-result {
  padding: 48px 32px;
}
.elf-result-success .elf-result-icon > .anticon {
  color: var(--elf-success-color);
}
.elf-result-error .elf-result-icon > .anticon {
  color: var(--elf-error-color);
}
.elf-result-info .elf-result-icon > .anticon {
  color: var(--elf-info-color);
}
.elf-result-warning .elf-result-icon > .anticon {
  color: var(--elf-warning-color);
}
.elf-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.elf-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.elf-result-icon > .anticon {
  font-size: 72px;
}
.elf-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.elf-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.elf-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.elf-result-extra > * {
  margin-right: 8px;
}
.elf-result-extra > *:last-child {
  margin-right: 0;
}
.elf-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.elf-result-rtl {
  direction: rtl;
}
.elf-result-rtl .elf-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.elf-result-rtl .elf-result-extra > *:last-child {
  margin-left: 0;
}
.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.elf-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}
.elf-segmented.elf-segmented-block {
  display: flex;
}
.elf-segmented.elf-segmented-block .elf-segmented-item {
  flex: 1;
  min-width: 0;
}
.elf-segmented:not(.elf-segmented-disabled):hover,
.elf-segmented:not(.elf-segmented-disabled):focus {
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: #262626;
}
.elf-segmented-item:hover,
.elf-segmented-item:focus {
  color: #262626;
}
.elf-segmented-item-label {
  min-height: 28px;
  padding: 0 11px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.elf-segmented-item-icon + * {
  margin-left: 6px;
}
.elf-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.elf-segmented.elf-segmented-lg .elf-segmented-item-label {
  min-height: 36px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 36px;
}
.elf-segmented.elf-segmented-sm .elf-segmented-item-label {
  min-height: 20px;
  padding: 0 7px;
  line-height: 20px;
}
.elf-segmented-item-disabled,
.elf-segmented-item-disabled:hover,
.elf-segmented-item-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}
.elf-segmented-thumb-motion-appear-active {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}
.elf-segmented.elf-segmented-rtl {
  direction: rtl;
}
.elf-segmented.elf-segmented-rtl .elf-segmented-item-icon {
  margin-right: 0;
  margin-left: 6px;
}
.elf-select-single .elf-select-selector {
  display: flex;
}
.elf-select-single .elf-select-selector .elf-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.elf-select-single .elf-select-selector .elf-select-selection-search-input {
  width: 100%;
}
.elf-select-single .elf-select-selector .elf-select-selection-item,
.elf-select-single .elf-select-selector .elf-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
.elf-select-single .elf-select-selector .elf-select-selection-item {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-select-single .elf-select-selector .elf-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.elf-select-single .elf-select-selector::after,
.elf-select-single .elf-select-selector .elf-select-selection-item::after,
.elf-select-single .elf-select-selector .elf-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.elf-select-single.elf-select-show-arrow .elf-select-selection-search {
  right: 25px;
}
.elf-select-single.elf-select-show-arrow .elf-select-selection-item,
.elf-select-single.elf-select-show-arrow .elf-select-selection-placeholder {
  padding-right: 18px;
}
.elf-select-single.elf-select-open .elf-select-selection-item {
  color: #bfbfbf;
}
.elf-select-single:not(.elf-select-customize-input) .elf-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.elf-select-single:not(.elf-select-customize-input) .elf-select-selector .elf-select-selection-search-input {
  height: 30px;
}
.elf-select-single:not(.elf-select-customize-input) .elf-select-selector::after {
  line-height: 30px;
}
.elf-select-single.elf-select-customize-input .elf-select-selector::after {
  display: none;
}
.elf-select-single.elf-select-customize-input .elf-select-selector .elf-select-selection-search {
  position: static;
  width: 100%;
}
.elf-select-single.elf-select-customize-input .elf-select-selector .elf-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.elf-select-single.elf-select-customize-input .elf-select-selector .elf-select-selection-placeholder::after {
  display: none;
}
.elf-select-single.elf-select-lg:not(.elf-select-customize-input) .elf-select-selector {
  height: 40px;
}
.elf-select-single.elf-select-lg:not(.elf-select-customize-input) .elf-select-selector::after,
.elf-select-single.elf-select-lg:not(.elf-select-customize-input) .elf-select-selector .elf-select-selection-item,
.elf-select-single.elf-select-lg:not(.elf-select-customize-input)
  .elf-select-selector
  .elf-select-selection-placeholder {
  line-height: 38px;
}
.elf-select-single.elf-select-lg:not(.elf-select-customize-input):not(.elf-select-customize-input)
  .elf-select-selection-search-input {
  height: 38px;
}
.elf-select-single.elf-select-sm:not(.elf-select-customize-input) .elf-select-selector {
  height: 24px;
}
.elf-select-single.elf-select-sm:not(.elf-select-customize-input) .elf-select-selector::after,
.elf-select-single.elf-select-sm:not(.elf-select-customize-input) .elf-select-selector .elf-select-selection-item,
.elf-select-single.elf-select-sm:not(.elf-select-customize-input)
  .elf-select-selector
  .elf-select-selection-placeholder {
  line-height: 22px;
}
.elf-select-single.elf-select-sm:not(.elf-select-customize-input):not(.elf-select-customize-input)
  .elf-select-selection-search-input {
  height: 22px;
}
.elf-select-single.elf-select-sm:not(.elf-select-customize-input) .elf-select-selection-search {
  right: 7px;
  left: 7px;
}
.elf-select-single.elf-select-sm:not(.elf-select-customize-input) .elf-select-selector {
  padding: 0 7px;
}
.elf-select-single.elf-select-sm:not(.elf-select-customize-input).elf-select-show-arrow .elf-select-selection-search {
  right: 28px;
}
.elf-select-single.elf-select-sm:not(.elf-select-customize-input).elf-select-show-arrow .elf-select-selection-item,
.elf-select-single.elf-select-sm:not(.elf-select-customize-input).elf-select-show-arrow
  .elf-select-selection-placeholder {
  padding-right: 21px;
}
.elf-select-single.elf-select-lg:not(.elf-select-customize-input) .elf-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.elf-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.elf-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.elf-select-multiple .elf-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.elf-select-show-search.elf-select-multiple .elf-select-selector {
  cursor: text;
}
.elf-select-disabled.elf-select-multiple .elf-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.elf-select-multiple .elf-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.elf-select-multiple.elf-select-show-arrow .elf-select-selector,
.elf-select-multiple.elf-select-allow-clear .elf-select-selector {
  padding-right: 24px;
}
.elf-select-multiple .elf-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-inline-end: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}
.elf-select-disabled.elf-select-multiple .elf-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.elf-select-multiple .elf-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.elf-select-multiple .elf-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.elf-select-multiple .elf-select-selection-item-remove > * {
  line-height: 1;
}
.elf-select-multiple .elf-select-selection-item-remove svg {
  display: inline-block;
}
.elf-select-multiple .elf-select-selection-item-remove::before {
  display: none;
}
.elf-select-multiple .elf-select-selection-item-remove .elf-select-multiple .elf-select-selection-item-remove-icon {
  display: block;
}
.elf-select-multiple .elf-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.elf-select-multiple .elf-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.elf-select-multiple
  .elf-select-selection-overflow-item
  + .elf-select-selection-overflow-item
  .elf-select-selection-search {
  margin-inline-start: 0;
}
.elf-select-multiple .elf-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 7px;
}
.elf-select-multiple .elf-select-selection-search-input,
.elf-select-multiple .elf-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.elf-select-multiple .elf-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.elf-select-multiple .elf-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.elf-select-multiple .elf-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.elf-select-multiple.elf-select-lg .elf-select-selector::after {
  line-height: 32px;
}
.elf-select-multiple.elf-select-lg .elf-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.elf-select-multiple.elf-select-lg .elf-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.elf-select-multiple.elf-select-lg .elf-select-selection-search-input,
.elf-select-multiple.elf-select-lg .elf-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.elf-select-multiple.elf-select-sm .elf-select-selector::after {
  line-height: 16px;
}
.elf-select-multiple.elf-select-sm .elf-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.elf-select-multiple.elf-select-sm .elf-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.elf-select-multiple.elf-select-sm .elf-select-selection-search-input,
.elf-select-multiple.elf-select-sm .elf-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.elf-select-multiple.elf-select-sm .elf-select-selection-placeholder {
  left: 7px;
}
.elf-select-multiple.elf-select-sm .elf-select-selection-search {
  margin-inline-start: 3px;
}
.elf-select-multiple.elf-select-lg .elf-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.elf-select-disabled .elf-select-selection-item-remove {
  display: none;
}
.elf-select-status-error.elf-select:not(.elf-select-disabled):not(.elf-select-customize-input):not(
    .elf-pagination-size-changer
  )
  .elf-select-selector {
  background-color: #18161e;
  border-color: var(--elf-error-color) !important;
}
.elf-select-status-error.elf-select:not(.elf-select-disabled):not(.elf-select-customize-input):not(
    .elf-pagination-size-changer
  ).elf-select-open
  .elf-select-selector,
.elf-select-status-error.elf-select:not(.elf-select-disabled):not(.elf-select-customize-input):not(
    .elf-pagination-size-changer
  ).elf-select-focused
  .elf-select-selector {
  border-color: var(--elf-error-color-hover);
  box-shadow: 0 0 0 2px var(--elf-error-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-select-status-warning.elf-select:not(.elf-select-disabled):not(.elf-select-customize-input):not(
    .elf-pagination-size-changer
  )
  .elf-select-selector {
  background-color: #fff;
  border-color: var(--elf-warning-color) !important;
}
.elf-select-status-warning.elf-select:not(.elf-select-disabled):not(.elf-select-customize-input):not(
    .elf-pagination-size-changer
  ).elf-select-open
  .elf-select-selector,
.elf-select-status-warning.elf-select:not(.elf-select-disabled):not(.elf-select-customize-input):not(
    .elf-pagination-size-changer
  ).elf-select-focused
  .elf-select-selector {
  border-color: var(--elf-warning-color-hover);
  box-shadow: 0 0 0 2px var(--elf-warning-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-select-status-error.elf-select-has-feedback .elf-select-clear,
.elf-select-status-warning.elf-select-has-feedback .elf-select-clear,
.elf-select-status-success.elf-select-has-feedback .elf-select-clear,
.elf-select-status-validating.elf-select-has-feedback .elf-select-clear {
  right: 32px;
}
.elf-select-status-error.elf-select-has-feedback .elf-select-selection-selected-value,
.elf-select-status-warning.elf-select-has-feedback .elf-select-selection-selected-value,
.elf-select-status-success.elf-select-has-feedback .elf-select-selection-selected-value,
.elf-select-status-validating.elf-select-has-feedback .elf-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.elf-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.elf-select:not(.elf-select-customize-input) .elf-select-selector {
  position: relative;
  background-color: #18161e;
  border: 1px solid #231f30;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-select:not(.elf-select-customize-input) .elf-select-selector input {
  cursor: pointer;
}
.elf-select-show-search.elf-select:not(.elf-select-customize-input) .elf-select-selector {
  cursor: text;
}
.elf-select-show-search.elf-select:not(.elf-select-customize-input) .elf-select-selector input {
  cursor: auto;
}
.elf-select-focused:not(.elf-select-disabled).elf-select:not(.elf-select-customize-input) .elf-select-selector {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl
  .elf-select-focused:not(.elf-select-disabled).elf-select:not(.elf-select-customize-input)
  .elf-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-select-disabled.elf-select:not(.elf-select-customize-input) .elf-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.elf-select-multiple.elf-select-disabled.elf-select:not(.elf-select-customize-input) .elf-select-selector {
  background: #f5f5f5;
}
.elf-select-disabled.elf-select:not(.elf-select-customize-input) .elf-select-selector input {
  cursor: not-allowed;
}
.elf-select:not(.elf-select-customize-input) .elf-select-selector .elf-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.elf-select:not(.elf-select-customize-input)
  .elf-select-selector
  .elf-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.elf-select:not(.elf-select-disabled):hover .elf-select-selector {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-select:not(.elf-select-disabled):hover .elf-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .elf-select-selection-item *::-ms-backdrop,
  .elf-select-selection-item {
    flex: auto;
  }
}
.elf-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #796f94;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .elf-select-selection-placeholder *::-ms-backdrop,
  .elf-select-selection-placeholder {
    flex: auto;
  }
}
.elf-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.elf-select-arrow > * {
  line-height: 1;
}
.elf-select-arrow svg {
  display: inline-block;
}
.elf-select-arrow::before {
  display: none;
}
.elf-select-arrow .elf-select-arrow-icon {
  display: block;
}
.elf-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.elf-select-arrow .anticon > svg {
  vertical-align: top;
}
.elf-select-arrow .anticon:not(.elf-select-suffix) {
  pointer-events: auto;
}
.elf-select-disabled .elf-select-arrow {
  cursor: not-allowed;
}
.elf-select-arrow > *:not(:last-child) {
  margin-inline-end: 8px;
}
.elf-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.elf-select-clear::before {
  display: block;
}
.elf-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.elf-select:hover .elf-select-clear {
  opacity: 1;
}
.elf-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #0e0c15;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.elf-select-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-select-dropdown-placement-bottomLeft,
.elf-select-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.elf-select-dropdown.elf-slide-up-enter.elf-slide-up-enter-active.elf-select-dropdown-placement-topLeft,
.elf-select-dropdown.elf-slide-up-appear.elf-slide-up-appear-active.elf-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.elf-select-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.elf-select-dropdown.elf-slide-up-leave.elf-slide-up-leave-active.elf-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.elf-select-dropdown-hidden {
  display: none;
}
.elf-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.elf-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.elf-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.elf-select-item:hover {
    background-color: #181327 !important;
  }
.elf-select-item:active {
    color: #8256f2 !important;
  }
.elf-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.elf-select-item-option {
  display: flex;
}
.elf-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-select-item-option-state {
  flex: none;
}
.elf-select-item-option-selected:not(.elf-select-item-option-disabled) {
  color: #fff;
  font-weight: 400;
  background-color: #0e0c15;
  /* background-color: var(--elf-primary-1); */
}
.elf-select-item-option-active:not(.elf-select-item-option-disabled) {
  background-color: #0e0c15;
}
.elf-select-item-option-selected:not(.elf-select-item-option-disabled) .elf-select-item-option-state {
  color: var(--elf-primary-color);
}
.elf-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-select-item-option-disabled.elf-select-item-option-selected {
  background-color: #f5f5f5;
}
.elf-select-item-option-grouped {
  padding-left: 24px;
}
.elf-select-lg {
  font-size: 16px;
}
.elf-select-borderless .elf-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.elf-select.elf-select-in-form-item {
  width: 100%;
}
.elf-select-compact-item:not(.elf-select-compact-last-item) {
  margin-right: -1px;
}
.elf-select-compact-item:not(.elf-select-compact-last-item).elf-select-compact-item-rtl {
  margin-right: 0;
  margin-left: -1px;
}
.elf-select-compact-item:hover > *,
.elf-select-compact-item:focus > *,
.elf-select-compact-item:active > * {
  z-index: 2;
}
.elf-select-compact-item.elf-select-focused > * {
  z-index: 2;
}
.elf-select-compact-item[disabled] > * {
  z-index: 0;
}
.elf-select-compact-item:not(.elf-select-compact-first-item):not(.elf-select-compact-last-item).elf-select
  > .elf-select-selector {
  border-radius: 0;
}
.elf-select-compact-item.elf-select-compact-first-item.elf-select:not(.elf-select-compact-last-item):not(
    .elf-select-compact-item-rtl
  )
  > .elf-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-select-compact-item.elf-select-compact-last-item.elf-select:not(.elf-select-compact-first-item):not(
    .elf-select-compact-item-rtl
  )
  > .elf-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-select-compact-item.elf-select.elf-select-compact-first-item.elf-select-compact-item-rtl:not(
    .elf-select-compact-last-item
  )
  > .elf-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.elf-select-compact-item.elf-select.elf-select-compact-last-item.elf-select-compact-item-rtl:not(
    .elf-select-compact-first-item
  )
  > .elf-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.elf-select-rtl {
  direction: rtl;
}
.elf-select-rtl .elf-select-arrow {
  right: initial;
  left: 11px;
}
.elf-select-rtl .elf-select-clear {
  right: initial;
  left: 11px;
}
.elf-select-dropdown-rtl {
  direction: rtl;
}
.elf-select-dropdown-rtl .elf-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.elf-select-rtl.elf-select-multiple.elf-select-show-arrow .elf-select-selector,
.elf-select-rtl.elf-select-multiple.elf-select-allow-clear .elf-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.elf-select-rtl.elf-select-multiple .elf-select-selection-item {
  text-align: right;
}
.elf-select-rtl.elf-select-multiple .elf-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.elf-select-rtl.elf-select-multiple .elf-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.elf-select-rtl.elf-select-multiple .elf-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.elf-select-rtl.elf-select-multiple.elf-select-sm .elf-select-selection-placeholder {
  right: 7px;
}
.elf-select-rtl.elf-select-single .elf-select-selector .elf-select-selection-item,
.elf-select-rtl.elf-select-single .elf-select-selector .elf-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.elf-select-rtl.elf-select-single.elf-select-show-arrow .elf-select-selection-search {
  right: 11px;
  left: 25px;
}
.elf-select-rtl.elf-select-single.elf-select-show-arrow .elf-select-selection-item,
.elf-select-rtl.elf-select-single.elf-select-show-arrow .elf-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.elf-select-rtl.elf-select-single.elf-select-sm:not(.elf-select-customize-input).elf-select-show-arrow
  .elf-select-selection-search {
  right: 6px;
}
.elf-select-rtl.elf-select-single.elf-select-sm:not(.elf-select-customize-input).elf-select-show-arrow
  .elf-select-selection-item,
.elf-select-rtl.elf-select-single.elf-select-sm:not(.elf-select-customize-input).elf-select-show-arrow
  .elf-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.elf-skeleton {
  display: table;
  width: 100%;
}
.elf-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.elf-skeleton-header .elf-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.elf-skeleton-header .elf-skeleton-avatar.elf-skeleton-avatar-circle {
  border-radius: 50%;
}
.elf-skeleton-header .elf-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.elf-skeleton-header .elf-skeleton-avatar-lg.elf-skeleton-avatar-circle {
  border-radius: 50%;
}
.elf-skeleton-header .elf-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.elf-skeleton-header .elf-skeleton-avatar-sm.elf-skeleton-avatar-circle {
  border-radius: 50%;
}
.elf-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.elf-skeleton-content .elf-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.elf-skeleton-content .elf-skeleton-title + .elf-skeleton-paragraph {
  margin-top: 24px;
}
.elf-skeleton-content .elf-skeleton-paragraph {
  padding: 0;
}
.elf-skeleton-content .elf-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.elf-skeleton-content .elf-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.elf-skeleton-content .elf-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.elf-skeleton-with-avatar .elf-skeleton-content .elf-skeleton-title {
  margin-top: 12px;
}
.elf-skeleton-with-avatar .elf-skeleton-content .elf-skeleton-title + .elf-skeleton-paragraph {
  margin-top: 28px;
}
.elf-skeleton-round .elf-skeleton-content .elf-skeleton-title,
.elf-skeleton-round .elf-skeleton-content .elf-skeleton-paragraph > li {
  border-radius: 100px;
}
.elf-skeleton-active .elf-skeleton-title,
.elf-skeleton-active .elf-skeleton-paragraph > li,
.elf-skeleton-active .elf-skeleton-avatar,
.elf-skeleton-active .elf-skeleton-button,
.elf-skeleton-active .elf-skeleton-input,
.elf-skeleton-active .elf-skeleton-image {
  position: relative;
  /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
  z-index: 0;
  overflow: hidden;
  background: transparent;
}
.elf-skeleton-active .elf-skeleton-title::after,
.elf-skeleton-active .elf-skeleton-paragraph > li::after,
.elf-skeleton-active .elf-skeleton-avatar::after,
.elf-skeleton-active .elf-skeleton-button::after,
.elf-skeleton-active .elf-skeleton-input::after,
.elf-skeleton-active .elf-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  animation: elf-skeleton-loading 1.4s ease infinite;
  content: '';
}
.elf-skeleton.elf-skeleton-block {
  width: 100%;
}
.elf-skeleton.elf-skeleton-block .elf-skeleton-button {
  width: 100%;
}
.elf-skeleton.elf-skeleton-block .elf-skeleton-input {
  width: 100%;
}
.elf-skeleton-element {
  display: inline-block;
  width: auto;
}
.elf-skeleton-element .elf-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.elf-skeleton-element .elf-skeleton-button.elf-skeleton-button-square {
  width: 32px;
  min-width: 32px;
}
.elf-skeleton-element .elf-skeleton-button.elf-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.elf-skeleton-element .elf-skeleton-button.elf-skeleton-button-round {
  border-radius: 32px;
}
.elf-skeleton-element .elf-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.elf-skeleton-element .elf-skeleton-button-lg.elf-skeleton-button-square {
  width: 40px;
  min-width: 40px;
}
.elf-skeleton-element .elf-skeleton-button-lg.elf-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.elf-skeleton-element .elf-skeleton-button-lg.elf-skeleton-button-round {
  border-radius: 40px;
}
.elf-skeleton-element .elf-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.elf-skeleton-element .elf-skeleton-button-sm.elf-skeleton-button-square {
  width: 24px;
  min-width: 24px;
}
.elf-skeleton-element .elf-skeleton-button-sm.elf-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.elf-skeleton-element .elf-skeleton-button-sm.elf-skeleton-button-round {
  border-radius: 24px;
}
.elf-skeleton-element .elf-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.elf-skeleton-element .elf-skeleton-avatar.elf-skeleton-avatar-circle {
  border-radius: 50%;
}
.elf-skeleton-element .elf-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.elf-skeleton-element .elf-skeleton-avatar-lg.elf-skeleton-avatar-circle {
  border-radius: 50%;
}
.elf-skeleton-element .elf-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.elf-skeleton-element .elf-skeleton-avatar-sm.elf-skeleton-avatar-circle {
  border-radius: 50%;
}
.elf-skeleton-element .elf-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.elf-skeleton-element .elf-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.elf-skeleton-element .elf-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.elf-skeleton-element .elf-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.elf-skeleton-element .elf-skeleton-image.elf-skeleton-image-circle {
  border-radius: 50%;
}
.elf-skeleton-element .elf-skeleton-image-path {
  fill: #bfbfbf;
}
.elf-skeleton-element .elf-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.elf-skeleton-element .elf-skeleton-image-svg.elf-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes elf-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.elf-skeleton-rtl {
  direction: rtl;
}
.elf-skeleton-rtl .elf-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.elf-skeleton-rtl.elf-skeleton.elf-skeleton-active .elf-skeleton-content .elf-skeleton-title,
.elf-skeleton-rtl.elf-skeleton.elf-skeleton-active .elf-skeleton-content .elf-skeleton-paragraph > li {
  animation-name: elf-skeleton-loading-rtl;
}
.elf-skeleton-rtl.elf-skeleton.elf-skeleton-active .elf-skeleton-avatar {
  animation-name: elf-skeleton-loading-rtl;
}
@keyframes elf-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.elf-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.elf-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.elf-slider-vertical .elf-slider-rail {
  width: 4px;
  height: 100%;
}
.elf-slider-vertical .elf-slider-track {
  width: 4px;
}
.elf-slider-vertical .elf-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.elf-slider-vertical .elf-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.elf-slider-vertical .elf-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.elf-slider-vertical .elf-slider-step {
  width: 4px;
  height: 100%;
}
.elf-slider-vertical .elf-slider-dot {
  top: auto;
  margin-left: -2px;
}
.elf-slider-tooltip .elf-tooltip-inner {
  min-width: unset;
}
.elf-slider-rtl.elf-slider-vertical .elf-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.elf-slider-rtl.elf-slider-vertical .elf-slider-mark {
  right: 12px;
  left: auto;
}
.elf-slider-rtl.elf-slider-vertical .elf-slider-mark-text {
  right: 4px;
  left: auto;
}
.elf-slider-rtl.elf-slider-vertical .elf-slider-dot {
  right: 2px;
  left: auto;
}
.elf-slider-with-marks {
  margin-bottom: 28px;
}
.elf-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.elf-slider-track {
  position: absolute;
  height: 4px;
  background-color: var(--elf-primary-3);
  border-radius: 2px;
  transition: background-color 0.3s;
}
.elf-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px var(--elf-primary-3);
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.elf-slider-handle-dragging {
  z-index: 1;
}
.elf-slider-handle:focus {
  border-color: var(--elf-primary-color-deprecated-t-20);
  outline: none;
  box-shadow: 0 0 0 5px var(--elf-primary-color-deprecated-f-12);
}
.elf-slider-handle.elf-tooltip-open {
  border-color: var(--elf-primary-color);
}
.elf-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}
.elf-slider:hover .elf-slider-rail {
  background-color: #e1e1e1;
}
.elf-slider:hover .elf-slider-track {
  background-color: var(--elf-primary-4);
}
.elf-slider:hover .elf-slider-handle:not(.elf-tooltip-open) {
  border-color: var(--elf-primary-4);
}
.elf-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.elf-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}
.elf-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.elf-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  cursor: pointer;
}
.elf-slider-dot-active {
  border-color: var(--elf-primary-color-deprecated-t-50);
}
.elf-slider-disabled {
  cursor: not-allowed;
}
.elf-slider-disabled .elf-slider-rail {
  background-color: #f5f5f5 !important;
}
.elf-slider-disabled .elf-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.elf-slider-disabled .elf-slider-handle,
.elf-slider-disabled .elf-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.elf-slider-disabled .elf-slider-mark-text,
.elf-slider-disabled .elf-slider-dot {
  cursor: not-allowed !important;
}
.elf-slider-rtl {
  direction: rtl;
}
.elf-slider-rtl .elf-slider-mark {
  right: 0;
  left: auto;
}
.elf-space {
  display: inline-flex;
}
.elf-space-vertical {
  flex-direction: column;
}
.elf-space-align-center {
  align-items: center;
}
.elf-space-align-start {
  align-items: flex-start;
}
.elf-space-align-end {
  align-items: flex-end;
}
.elf-space-align-baseline {
  align-items: baseline;
}
.elf-space-item:empty {
  display: none;
}
.elf-space-compact {
  display: inline-flex;
}
.elf-space-compact-block {
  display: flex;
  width: 100%;
}
.elf-space-compact-vertical {
  flex-direction: column;
}
.elf-space-rtl {
  direction: rtl;
}
.elf-space-compact-rtl {
  direction: rtl;
}
.elf-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: var(--elf-primary-color);
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.elf-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.elf-spin-nested-loading {
  position: relative;
}
.elf-spin-nested-loading > div > .elf-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.elf-spin-nested-loading > div > .elf-spin .elf-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.elf-spin-nested-loading > div > .elf-spin .elf-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.elf-spin-nested-loading > div > .elf-spin.elf-spin-show-text .elf-spin-dot {
  margin-top: -20px;
}
.elf-spin-nested-loading > div > .elf-spin-sm .elf-spin-dot {
  margin: -7px;
}
.elf-spin-nested-loading > div > .elf-spin-sm .elf-spin-text {
  padding-top: 2px;
}
.elf-spin-nested-loading > div > .elf-spin-sm.elf-spin-show-text .elf-spin-dot {
  margin-top: -17px;
}
.elf-spin-nested-loading > div > .elf-spin-lg .elf-spin-dot {
  margin: -16px;
}
.elf-spin-nested-loading > div > .elf-spin-lg .elf-spin-text {
  padding-top: 11px;
}
.elf-spin-nested-loading > div > .elf-spin-lg.elf-spin-show-text .elf-spin-dot {
  margin-top: -26px;
}
.elf-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.elf-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.elf-spin-blur {
  clear: both;
  opacity: 0.5;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.elf-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.elf-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.elf-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.elf-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: var(--elf-primary-color);
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.elf-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.elf-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.elf-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.elf-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.elf-spin-dot-spin {
  transform: rotate(0deg);
  animation: antRotate 1.2s infinite linear;
}
.elf-spin-sm .elf-spin-dot {
  font-size: 14px;
}
.elf-spin-sm .elf-spin-dot i {
  width: 6px;
  height: 6px;
}
.elf-spin-lg .elf-spin-dot {
  font-size: 32px;
}
.elf-spin-lg .elf-spin-dot i {
  width: 14px;
  height: 14px;
}
.elf-spin.elf-spin-show-text .elf-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .elf-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
.elf-spin-rtl {
  direction: rtl;
}
.elf-spin-rtl .elf-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.elf-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.elf-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.elf-statistic-skeleton {
  padding-top: 16px;
}
.elf-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.elf-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.elf-statistic-content-prefix,
.elf-statistic-content-suffix {
  display: inline-block;
}
.elf-statistic-content-prefix {
  margin-right: 4px;
}
.elf-statistic-content-suffix {
  margin-left: 4px;
}
.elf-statistic-rtl {
  direction: rtl;
}
.elf-statistic-rtl .elf-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.elf-statistic-rtl .elf-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.elf-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.elf-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.elf-steps-item-container {
  outline: none;
}
.elf-steps-item:last-child {
  flex: none;
}
.elf-steps-item:last-child > .elf-steps-item-container > .elf-steps-item-tail,
.elf-steps-item:last-child > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title::after {
  display: none;
}
.elf-steps-item-icon,
.elf-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.elf-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.elf-steps-item-icon .elf-steps-icon {
  position: relative;
  top: -0.5px;
  color: var(--elf-primary-color);
  line-height: 1;
}
.elf-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.elf-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.elf-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px;
}
.elf-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: rgba(0, 0, 0, 0.06);
  content: '';
}
.elf-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.elf-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.elf-steps-item-wait .elf-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.elf-steps-item-wait .elf-steps-item-icon > .elf-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.elf-steps-item-wait .elf-steps-item-icon > .elf-steps-icon .elf-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.elf-steps-item-wait > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.elf-steps-item-wait > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title::after {
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-steps-item-wait > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.elf-steps-item-wait > .elf-steps-item-container > .elf-steps-item-tail::after {
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-steps-item-process .elf-steps-item-icon {
  background-color: #fff;
  border-color: var(--elf-primary-color);
}
.elf-steps-item-process .elf-steps-item-icon > .elf-steps-icon {
  color: var(--elf-primary-color);
}
.elf-steps-item-process .elf-steps-item-icon > .elf-steps-icon .elf-steps-icon-dot {
  background: var(--elf-primary-color);
}
.elf-steps-item-process > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.elf-steps-item-process > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title::after {
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-steps-item-process > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-description {
  color: rgba(0, 0, 0, 0.85);
}
.elf-steps-item-process > .elf-steps-item-container > .elf-steps-item-tail::after {
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-steps-item-process > .elf-steps-item-container > .elf-steps-item-icon {
  background: var(--elf-primary-color);
}
.elf-steps-item-process > .elf-steps-item-container > .elf-steps-item-icon .elf-steps-icon {
  color: #fff;
}
.elf-steps-item-process > .elf-steps-item-container > .elf-steps-item-title {
  font-weight: 500;
}
.elf-steps-item-finish .elf-steps-item-icon {
  background-color: #fff;
  border-color: var(--elf-primary-color);
}
.elf-steps-item-finish .elf-steps-item-icon > .elf-steps-icon {
  color: var(--elf-primary-color);
}
.elf-steps-item-finish .elf-steps-item-icon > .elf-steps-icon .elf-steps-icon-dot {
  background: var(--elf-primary-color);
}
.elf-steps-item-finish > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.elf-steps-item-finish > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title::after {
  background-color: var(--elf-primary-color);
}
.elf-steps-item-finish > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.elf-steps-item-finish > .elf-steps-item-container > .elf-steps-item-tail::after {
  background-color: var(--elf-primary-color);
}
.elf-steps-item-error .elf-steps-item-icon {
  background-color: #fff;
  border-color: var(--elf-error-color);
}
.elf-steps-item-error .elf-steps-item-icon > .elf-steps-icon {
  color: var(--elf-error-color);
}
.elf-steps-item-error .elf-steps-item-icon > .elf-steps-icon .elf-steps-icon-dot {
  background: var(--elf-error-color);
}
.elf-steps-item-error > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title {
  color: var(--elf-error-color);
}
.elf-steps-item-error > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-title::after {
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-steps-item-error > .elf-steps-item-container > .elf-steps-item-content > .elf-steps-item-description {
  color: var(--elf-error-color);
}
.elf-steps-item-error > .elf-steps-item-container > .elf-steps-item-tail::after {
  background-color: rgba(0, 0, 0, 0.06);
}
.elf-steps-item.elf-steps-next-error .elf-steps-item-title::after {
  background: var(--elf-error-color);
}
.elf-steps-item-disabled {
  cursor: not-allowed;
}
.elf-steps .elf-steps-item:not(.elf-steps-item-active) > .elf-steps-item-container[role='button'] {
  cursor: pointer;
}
.elf-steps .elf-steps-item:not(.elf-steps-item-active) > .elf-steps-item-container[role='button'] .elf-steps-item-title,
.elf-steps
  .elf-steps-item:not(.elf-steps-item-active)
  > .elf-steps-item-container[role='button']
  .elf-steps-item-subtitle,
.elf-steps
  .elf-steps-item:not(.elf-steps-item-active)
  > .elf-steps-item-container[role='button']
  .elf-steps-item-description,
.elf-steps
  .elf-steps-item:not(.elf-steps-item-active)
  > .elf-steps-item-container[role='button']
  .elf-steps-item-icon
  .elf-steps-icon {
  transition: color 0.3s;
}
.elf-steps
  .elf-steps-item:not(.elf-steps-item-active)
  > .elf-steps-item-container[role='button']:hover
  .elf-steps-item-title,
.elf-steps
  .elf-steps-item:not(.elf-steps-item-active)
  > .elf-steps-item-container[role='button']:hover
  .elf-steps-item-subtitle,
.elf-steps
  .elf-steps-item:not(.elf-steps-item-active)
  > .elf-steps-item-container[role='button']:hover
  .elf-steps-item-description {
  color: var(--elf-primary-color);
}
.elf-steps
  .elf-steps-item:not(.elf-steps-item-active):not(.elf-steps-item-process)
  > .elf-steps-item-container[role='button']:hover
  .elf-steps-item-icon {
  border-color: var(--elf-primary-color);
}
.elf-steps
  .elf-steps-item:not(.elf-steps-item-active):not(.elf-steps-item-process)
  > .elf-steps-item-container[role='button']:hover
  .elf-steps-item-icon
  .elf-steps-icon {
  color: var(--elf-primary-color);
}
.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item:first-child {
  padding-left: 0;
}
.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item:last-child .elf-steps-item-title {
  padding-right: 0;
}
.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item-tail {
  display: none;
}
.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.elf-steps-item-custom > .elf-steps-item-container > .elf-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.elf-steps-item-custom > .elf-steps-item-container > .elf-steps-item-icon > .elf-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.elf-steps-item-custom.elf-steps-item-process .elf-steps-item-icon > .elf-steps-icon {
  color: var(--elf-primary-color);
}
.elf-steps:not(.elf-steps-vertical) .elf-steps-item-custom .elf-steps-item-icon {
  width: auto;
  background: none;
}
.elf-steps-small.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item {
  padding-left: 12px;
}
.elf-steps-small.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item:first-child {
  padding-left: 0;
}
.elf-steps-small .elf-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.elf-steps-small .elf-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.elf-steps-small .elf-steps-item-title::after {
  top: 12px;
}
.elf-steps-small .elf-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.elf-steps-small .elf-steps-item-tail {
  top: 8px;
}
.elf-steps-small .elf-steps-item-custom .elf-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.elf-steps-small .elf-steps-item-custom .elf-steps-item-icon > .elf-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.elf-steps-vertical {
  display: flex;
  flex-direction: column;
}
.elf-steps-vertical > .elf-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.elf-steps-vertical > .elf-steps-item .elf-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.elf-steps-vertical > .elf-steps-item .elf-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.elf-steps-vertical > .elf-steps-item .elf-steps-item-title {
  line-height: 32px;
}
.elf-steps-vertical > .elf-steps-item .elf-steps-item-description {
  padding-bottom: 12px;
}
.elf-steps-vertical > .elf-steps-item > .elf-steps-item-container > .elf-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.elf-steps-vertical > .elf-steps-item > .elf-steps-item-container > .elf-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.elf-steps-vertical > .elf-steps-item:not(:last-child) > .elf-steps-item-container > .elf-steps-item-tail {
  display: block;
}
.elf-steps-vertical
  > .elf-steps-item
  > .elf-steps-item-container
  > .elf-steps-item-content
  > .elf-steps-item-title::after {
  display: none;
}
.elf-steps-vertical.elf-steps-small .elf-steps-item-container .elf-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.elf-steps-vertical.elf-steps-small .elf-steps-item-container .elf-steps-item-title {
  line-height: 24px;
}
.elf-steps-label-vertical .elf-steps-item {
  overflow: visible;
}
.elf-steps-label-vertical .elf-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.elf-steps-label-vertical .elf-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.elf-steps-label-vertical .elf-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.elf-steps-label-vertical .elf-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.elf-steps-label-vertical .elf-steps-item-title::after {
  display: none;
}
.elf-steps-label-vertical .elf-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.elf-steps-label-vertical.elf-steps-small:not(.elf-steps-dot) .elf-steps-item-icon {
  margin-left: 46px;
}
.elf-steps-dot .elf-steps-item-title,
.elf-steps-dot.elf-steps-small .elf-steps-item-title {
  line-height: 1.5715;
}
.elf-steps-dot .elf-steps-item-tail,
.elf-steps-dot.elf-steps-small .elf-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.elf-steps-dot .elf-steps-item-tail::after,
.elf-steps-dot.elf-steps-small .elf-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.elf-steps-dot .elf-steps-item:first-child .elf-steps-icon-dot,
.elf-steps-dot.elf-steps-small .elf-steps-item:first-child .elf-steps-icon-dot {
  left: 2px;
}
.elf-steps-dot .elf-steps-item-icon,
.elf-steps-dot.elf-steps-small .elf-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.elf-steps-dot .elf-steps-item-icon .elf-steps-icon-dot,
.elf-steps-dot.elf-steps-small .elf-steps-item-icon .elf-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.elf-steps-dot .elf-steps-item-icon .elf-steps-icon-dot::after,
.elf-steps-dot.elf-steps-small .elf-steps-item-icon .elf-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.elf-steps-dot .elf-steps-item-content,
.elf-steps-dot.elf-steps-small .elf-steps-item-content {
  width: 140px;
}
.elf-steps-dot .elf-steps-item-process .elf-steps-item-icon,
.elf-steps-dot.elf-steps-small .elf-steps-item-process .elf-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.elf-steps-dot .elf-steps-item-process .elf-steps-icon:first-child .elf-steps-icon-dot,
.elf-steps-dot.elf-steps-small .elf-steps-item-process .elf-steps-icon:first-child .elf-steps-icon-dot {
  left: 0;
}
.elf-steps-vertical.elf-steps-dot .elf-steps-item-icon {
  margin-top: 13px;
  margin-left: 0;
  background: none;
}
.elf-steps-vertical.elf-steps-dot .elf-steps-item > .elf-steps-item-container > .elf-steps-item-tail {
  top: 6.5px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.elf-steps-vertical.elf-steps-dot.elf-steps-small .elf-steps-item-icon {
  margin-top: 10px;
}
.elf-steps-vertical.elf-steps-dot.elf-steps-small .elf-steps-item > .elf-steps-item-container > .elf-steps-item-tail {
  top: 3.5px;
}
.elf-steps-vertical.elf-steps-dot .elf-steps-item:first-child .elf-steps-icon-dot {
  left: 0;
}
.elf-steps-vertical.elf-steps-dot .elf-steps-item-content {
  width: inherit;
}
.elf-steps-vertical.elf-steps-dot
  .elf-steps-item-process
  .elf-steps-item-container
  .elf-steps-item-icon
  .elf-steps-icon-dot {
  top: -1px;
  left: -1px;
}
.elf-steps-navigation {
  padding-top: 12px;
}
.elf-steps-navigation.elf-steps-small .elf-steps-item-container {
  margin-left: -12px;
}
.elf-steps-navigation .elf-steps-item {
  overflow: visible;
  text-align: center;
}
.elf-steps-navigation .elf-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.elf-steps-navigation .elf-steps-item-container .elf-steps-item-content {
  max-width: auto;
}
.elf-steps-navigation .elf-steps-item-container .elf-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-steps-navigation .elf-steps-item-container .elf-steps-item-title::after {
  display: none;
}
.elf-steps-navigation .elf-steps-item:not(.elf-steps-item-active) .elf-steps-item-container[role='button'] {
  cursor: pointer;
}
.elf-steps-navigation .elf-steps-item:not(.elf-steps-item-active) .elf-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.elf-steps-navigation .elf-steps-item:last-child {
  flex: 1;
}
.elf-steps-navigation .elf-steps-item:last-child::after {
  display: none;
}
.elf-steps-navigation .elf-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.elf-steps-navigation .elf-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: var(--elf-primary-color);
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.elf-steps-navigation .elf-steps-item.elf-steps-item-active::before {
  left: 0;
  width: 100%;
}
.elf-steps-navigation.elf-steps-vertical > .elf-steps-item {
  margin-right: 0 !important;
}
.elf-steps-navigation.elf-steps-vertical > .elf-steps-item::before {
  display: none;
}
.elf-steps-navigation.elf-steps-vertical > .elf-steps-item.elf-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.elf-steps-navigation.elf-steps-vertical > .elf-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.elf-steps-navigation.elf-steps-vertical > .elf-steps-item > .elf-steps-item-container > .elf-steps-item-tail {
  visibility: hidden;
}
.elf-steps-navigation.elf-steps-horizontal > .elf-steps-item > .elf-steps-item-container > .elf-steps-item-tail {
  visibility: hidden;
}
.elf-steps-rtl {
  direction: rtl;
}
.elf-steps.elf-steps-rtl .elf-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.elf-steps-rtl .elf-steps-item-tail {
  right: 0;
  left: auto;
}
.elf-steps-rtl .elf-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.elf-steps-rtl .elf-steps-item-title .elf-steps-item-subtitle {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.elf-steps-rtl .elf-steps-item-title::after {
  right: 100%;
  left: auto;
}
.elf-steps-rtl.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.elf-steps-rtl.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item:first-child {
  padding-right: 0;
}
.elf-steps-rtl.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item:last-child .elf-steps-item-title {
  padding-left: 0;
}
.elf-steps-rtl .elf-steps-item-custom .elf-steps-item-icon > .elf-steps-icon {
  right: 0.5px;
  left: auto;
}
.elf-steps-rtl.elf-steps-navigation.elf-steps-small .elf-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.elf-steps-rtl.elf-steps-navigation .elf-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.elf-steps-rtl.elf-steps-navigation .elf-steps-item-container .elf-steps-item-title {
  padding-left: 0;
}
.elf-steps-rtl.elf-steps-navigation .elf-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.elf-steps-rtl.elf-steps-small.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.elf-steps-rtl.elf-steps-small.elf-steps-horizontal:not(.elf-steps-label-vertical) .elf-steps-item:first-child {
  padding-right: 0;
}
.elf-steps-rtl.elf-steps-small .elf-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.elf-steps-rtl.elf-steps-vertical > .elf-steps-item .elf-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.elf-steps-rtl.elf-steps-vertical > .elf-steps-item > .elf-steps-item-container > .elf-steps-item-tail {
  right: 16px;
  left: auto;
}
.elf-steps-rtl.elf-steps-vertical.elf-steps-small .elf-steps-item-container .elf-steps-item-tail {
  right: 12px;
  left: auto;
}
.elf-steps-rtl.elf-steps-label-vertical .elf-steps-item-title {
  padding-left: 0;
}
.elf-steps-rtl.elf-steps-dot .elf-steps-item-tail,
.elf-steps-rtl.elf-steps-dot.elf-steps-small .elf-steps-item-tail {
  margin: 0 70px 0 0;
}
.elf-steps-rtl.elf-steps-dot .elf-steps-item-tail::after,
.elf-steps-rtl.elf-steps-dot.elf-steps-small .elf-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.elf-steps-rtl.elf-steps-dot .elf-steps-item:first-child .elf-steps-icon-dot,
.elf-steps-rtl.elf-steps-dot.elf-steps-small .elf-steps-item:first-child .elf-steps-icon-dot {
  right: 2px;
  left: auto;
}
.elf-steps-rtl.elf-steps-dot .elf-steps-item-icon,
.elf-steps-rtl.elf-steps-dot.elf-steps-small .elf-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.elf-steps-dot .elf-steps-item-icon .elf-steps-icon-dot,
.elf-steps-dot.elf-steps-small .elf-steps-item-icon .elf-steps-icon-dot {
  /* expand hover area */
}
.elf-steps-rtl.elf-steps-dot .elf-steps-item-icon .elf-steps-icon-dot,
.elf-steps-rtl.elf-steps-dot.elf-steps-small .elf-steps-item-icon .elf-steps-icon-dot {
  float: right;
}
.elf-steps-rtl.elf-steps-dot .elf-steps-item-icon .elf-steps-icon-dot::after,
.elf-steps-rtl.elf-steps-dot.elf-steps-small .elf-steps-item-icon .elf-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.elf-steps-rtl.elf-steps-vertical.elf-steps-dot .elf-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.elf-steps-rtl.elf-steps-vertical.elf-steps-dot .elf-steps-item > .elf-steps-item-container > .elf-steps-item-tail {
  right: -9px;
  left: auto;
}
.elf-steps-rtl.elf-steps-vertical.elf-steps-dot .elf-steps-item:first-child .elf-steps-icon-dot {
  right: 0;
  left: auto;
}
.elf-steps-rtl.elf-steps-vertical.elf-steps-dot .elf-steps-item-process .elf-steps-icon-dot {
  right: -2px;
  left: auto;
}
.elf-steps-rtl.elf-steps-with-progress.elf-steps-horizontal.elf-steps-label-horizontal .elf-steps-item:first-child {
  padding-right: 4px;
  padding-left: 0;
}
.elf-steps-rtl.elf-steps-with-progress.elf-steps-horizontal.elf-steps-label-horizontal
  .elf-steps-item:first-child.elf-steps-item-active {
  padding-right: 4px;
}
.elf-steps-with-progress .elf-steps-item {
  padding-top: 4px;
}
.elf-steps-with-progress .elf-steps-item .elf-steps-item-tail {
  top: 4px !important;
}
.elf-steps-with-progress.elf-steps-horizontal .elf-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}
.elf-steps-with-progress.elf-steps-label-vertical .elf-steps-item .elf-steps-item-tail {
  top: 14px !important;
}
.elf-steps-with-progress .elf-steps-item-icon {
  position: relative;
}
.elf-steps-with-progress .elf-steps-item-icon .elf-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.elf-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.elf-switch-checked:focus {
  box-shadow: 0 0 0 2px var(--elf-primary-1);
}
.elf-switch:focus:hover {
  box-shadow: none;
}
.elf-switch-checked {
  background-color: var(--elf-primary-color);
}
.elf-switch-loading,
.elf-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.elf-switch-loading *,
.elf-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.elf-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.elf-switch-checked .elf-switch-inner {
  margin: 0 25px 0 7px;
}
.elf-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.elf-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.elf-switch-checked .elf-switch-handle {
  left: calc(100% - 18px - 2px);
}
.elf-switch:not(.elf-switch-disabled):active .elf-switch-handle::before {
  right: -30%;
  left: 0;
}
.elf-switch:not(.elf-switch-disabled):active.elf-switch-checked .elf-switch-handle::before {
  right: 0;
  left: -30%;
}
.elf-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.elf-switch-checked .elf-switch-loading-icon {
  color: var(--elf-primary-color);
}
.elf-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.elf-switch-small .elf-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.elf-switch-small .elf-switch-handle {
  width: 12px;
  height: 12px;
}
.elf-switch-small .elf-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.elf-switch-small.elf-switch-checked .elf-switch-inner {
  margin: 0 18px 0 5px;
}
.elf-switch-small.elf-switch-checked .elf-switch-handle {
  left: calc(100% - 12px - 2px);
}
.elf-switch-rtl {
  direction: rtl;
}
.elf-switch-rtl .elf-switch-inner {
  margin: 0 25px 0 7px;
}
.elf-switch-rtl .elf-switch-handle {
  right: 2px;
  left: auto;
}
.elf-switch-rtl:not(.elf-switch-rtl-disabled):active .elf-switch-handle::before {
  right: 0;
  left: -30%;
}
.elf-switch-rtl:not(.elf-switch-rtl-disabled):active.elf-switch-checked .elf-switch-handle::before {
  right: -30%;
  left: 0;
}
.elf-switch-rtl.elf-switch-checked .elf-switch-inner {
  margin: 0 7px 0 25px;
}
.elf-switch-rtl.elf-switch-checked .elf-switch-handle {
  right: calc(100% - 18px - 2px);
}
.elf-switch-rtl.elf-switch-small.elf-switch-checked .elf-switch-handle {
  right: calc(100% - 12px - 2px);
}
.elf-table.elf-table-middle {
  font-size: 14px;
}
.elf-table.elf-table-middle .elf-table-title,
.elf-table.elf-table-middle .elf-table-footer,
.elf-table.elf-table-middle .elf-table-thead > tr > th,
.elf-table.elf-table-middle .elf-table-tbody > tr > td,
.elf-table.elf-table-middle tfoot > tr > th,
.elf-table.elf-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.elf-table.elf-table-middle .elf-table-filter-trigger {
  margin-right: -4px;
}
.elf-table.elf-table-middle .elf-table-expanded-row-fixed {
  margin: -12px -8px;
}
.elf-table.elf-table-middle .elf-table-tbody .elf-table-wrapper:only-child .elf-table {
  margin: -12px -8px -12px 40px;
}
.elf-table.elf-table-middle .elf-table-selection-column {
  padding-inline-start: 2px;
}
.elf-table.elf-table-small {
  font-size: 14px;
}
.elf-table.elf-table-small .elf-table-title,
.elf-table.elf-table-small .elf-table-footer,
.elf-table.elf-table-small .elf-table-thead > tr > th,
.elf-table.elf-table-small .elf-table-tbody > tr > td,
.elf-table.elf-table-small tfoot > tr > th,
.elf-table.elf-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.elf-table.elf-table-small .elf-table-filter-trigger {
  margin-right: -4px;
}
.elf-table.elf-table-small .elf-table-expanded-row-fixed {
  margin: -8px -8px;
}
.elf-table.elf-table-small .elf-table-tbody .elf-table-wrapper:only-child .elf-table {
  margin: -8px -8px -8px 40px;
}
.elf-table.elf-table-small .elf-table-selection-column {
  padding-inline-start: 2px;
}
.elf-table.elf-table-bordered > .elf-table-title {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 0;
}
.elf-table.elf-table-bordered > .elf-table-container {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-content > table > thead > tr > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-header > table > thead > tr > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-body > table > thead > tr > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-summary > table > thead > tr > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-content > table > tbody > tr > td,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-header > table > tbody > tr > td,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-body > table > tbody > tr > td,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-summary > table > tbody > tr > td,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-content > table > tfoot > tr > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-header > table > tfoot > tr > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-body > table > tfoot > tr > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-summary > table > tfoot > tr > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-content > table > tfoot > tr > td,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-header > table > tfoot > tr > td,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-body > table > tfoot > tr > td,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-summary > table > tfoot > tr > td {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-content > table > thead > tr:not(:last-child) > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-header > table > thead > tr:not(:last-child) > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-body > table > thead > tr:not(:last-child) > th,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-content > table > thead > tr > th::before,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-header > table > thead > tr > th::before,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-body > table > thead > tr > th::before,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-content
  > table
  > thead
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-header
  > table
  > thead
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-body
  > table
  > thead
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-summary
  > table
  > thead
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-content
  > table
  > tbody
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-header
  > table
  > tbody
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-body
  > table
  > tbody
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-summary
  > table
  > tbody
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-content
  > table
  > tfoot
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-header
  > table
  > tfoot
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-body
  > table
  > tfoot
  > tr
  > .elf-table-cell-fix-right-first::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-summary
  > table
  > tfoot
  > tr
  > .elf-table-cell-fix-right-first::after {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-content
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-header
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-body
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-summary
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed {
  margin: -16px -17px;
}
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-content
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-header
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-body
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed::after,
.elf-table.elf-table-bordered
  > .elf-table-container
  > .elf-table-summary
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  content: '';
}
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-content > table,
.elf-table.elf-table-bordered > .elf-table-container > .elf-table-header > table {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-table.elf-table-bordered.elf-table-scroll-horizontal
  > .elf-table-container
  > .elf-table-body
  > table
  > tbody
  > tr.elf-table-expanded-row
  > td,
.elf-table.elf-table-bordered.elf-table-scroll-horizontal
  > .elf-table-container
  > .elf-table-body
  > table
  > tbody
  > tr.elf-table-placeholder
  > td {
  border-right: 0;
}
.elf-table.elf-table-bordered.elf-table-middle
  > .elf-table-container
  > .elf-table-content
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed,
.elf-table.elf-table-bordered.elf-table-middle
  > .elf-table-container
  > .elf-table-body
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed {
  margin: -12px -9px;
}
.elf-table.elf-table-bordered.elf-table-small
  > .elf-table-container
  > .elf-table-content
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed,
.elf-table.elf-table-bordered.elf-table-small
  > .elf-table-container
  > .elf-table-body
  > table
  > tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed {
  margin: -8px -9px;
}
.elf-table.elf-table-bordered > .elf-table-footer {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-top: 0;
}
.elf-table-cell .elf-table-container:first-child {
  border-top: 0;
}
.elf-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}
.elf-table-wrapper {
  clear: both;
  max-width: 100%;
}
.elf-table-wrapper::before {
  display: table;
  content: '';
}
.elf-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.elf-table-wrapper::before {
  display: table;
  content: '';
}
.elf-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.elf-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.elf-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.elf-table-thead > tr > th,
.elf-table-tbody > tr > td,
.elf-table tfoot > tr > th,
.elf-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.elf-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.elf-table-cell-ellipsis.elf-table-cell-fix-left-last,
.elf-table-cell-ellipsis.elf-table-cell-fix-right-first {
  overflow: visible;
}
.elf-table-cell-ellipsis.elf-table-cell-fix-left-last .elf-table-cell-content,
.elf-table-cell-ellipsis.elf-table-cell-fix-right-first .elf-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.elf-table-cell-ellipsis .elf-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.elf-table-title {
  padding: 16px 16px;
}
.elf-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.elf-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: background 0.3s ease;
}
.elf-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.elf-table-thead
  > tr
  > th:not(:last-child):not(.elf-table-selection-column):not(.elf-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.elf-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.elf-table-tbody > tr > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: background 0.3s;
}
.elf-table-tbody > tr > td > .elf-table-wrapper:only-child .elf-table,
.elf-table-tbody > tr > td > .elf-table-expanded-row-fixed > .elf-table-wrapper:only-child .elf-table {
  margin: -16px -16px -16px 32px;
}
.elf-table-tbody > tr > td > .elf-table-wrapper:only-child .elf-table-tbody > tr:last-child > td,
.elf-table-tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed
  > .elf-table-wrapper:only-child
  .elf-table-tbody
  > tr:last-child
  > td {
  border-bottom: 0;
}
.elf-table-tbody > tr > td > .elf-table-wrapper:only-child .elf-table-tbody > tr:last-child > td:first-child,
.elf-table-tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed
  > .elf-table-wrapper:only-child
  .elf-table-tbody
  > tr:last-child
  > td:first-child,
.elf-table-tbody > tr > td > .elf-table-wrapper:only-child .elf-table-tbody > tr:last-child > td:last-child,
.elf-table-tbody
  > tr
  > td
  > .elf-table-expanded-row-fixed
  > .elf-table-wrapper:only-child
  .elf-table-tbody
  > tr:last-child
  > td:last-child {
  border-radius: 0;
}
.elf-table-tbody > tr.elf-table-row:hover > td,
.elf-table-tbody > tr > td.elf-table-cell-row-hover {
  background: #fafafa;
}
.elf-table-tbody > tr.elf-table-row-selected > td {
  background: var(--elf-primary-1);
  border-color: rgba(0, 0, 0, 0.03);
}
.elf-table-tbody > tr.elf-table-row-selected:hover > td {
  background: var(--elf-primary-color-active-deprecated-d-02);
}
.elf-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.elf-table-summary {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
}
.elf-table-summary > tr > th,
.elf-table-summary > tr > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-table-pagination.elf-pagination {
  margin: 16px 0;
}
.elf-table-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
.elf-table-pagination > * {
  flex: none;
}
.elf-table-pagination-left {
  justify-content: flex-start;
}
.elf-table-pagination-center {
  justify-content: center;
}
.elf-table-pagination-right {
  justify-content: flex-end;
}
.elf-table-thead th.elf-table-column-has-sorters {
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-table-thead th.elf-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.elf-table-thead th.elf-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.elf-table-thead th.elf-table-column-has-sorters:focus-visible {
  color: var(--elf-primary-color);
}
.elf-table-thead th.elf-table-column-has-sorters.elf-table-cell-fix-left:hover,
.elf-table-thead th.elf-table-column-has-sorters.elf-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.elf-table-thead th.elf-table-column-sort {
  background: #f5f5f5;
}
.elf-table-thead th.elf-table-column-sort::before {
  background-color: transparent !important;
}
td.elf-table-column-sort {
  background: #fafafa;
}
.elf-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1;
}
.elf-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.elf-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.elf-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.elf-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.elf-table-column-sorter-up,
.elf-table-column-sorter-down {
  font-size: 11px;
}
.elf-table-column-sorter-up.active,
.elf-table-column-sorter-down.active {
  color: var(--elf-primary-color);
}
.elf-table-column-sorter-up + .elf-table-column-sorter-down {
  margin-top: -0.3em;
}
.elf-table-column-sorters:hover .elf-table-column-sorter {
  color: #a6a6a6;
}
.elf-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.elf-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
  background: rgba(0, 0, 0, 0.04);
}
.elf-table-filter-trigger.active {
  color: var(--elf-primary-color);
}
.elf-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.elf-table-filter-dropdown .elf-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.elf-table-filter-dropdown .elf-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.elf-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.elf-table-filter-dropdown-tree .elf-tree-treenode .elf-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.elf-table-filter-dropdown-tree .elf-tree-treenode-checkbox-checked .elf-tree-node-content-wrapper,
.elf-table-filter-dropdown-tree .elf-tree-treenode-checkbox-checked .elf-tree-node-content-wrapper:hover {
  background-color: var(--elf-primary-2);
}
.elf-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px rgba(0, 0, 0, 0.06) solid;
}
.elf-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.elf-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.elf-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.elf-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.elf-table-filter-dropdown .elf-checkbox-wrapper + span,
.elf-table-filter-dropdown-submenu .elf-checkbox-wrapper + span {
  padding-left: 8px;
}
.elf-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-table-selection-col {
  width: 32px;
}
.elf-table-bordered .elf-table-selection-col {
  width: 50px;
}
table tr th.elf-table-selection-column,
table tr td.elf-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.elf-table-selection-column .elf-radio-wrapper,
table tr td.elf-table-selection-column .elf-radio-wrapper {
  margin-right: 0;
}
table tr th.elf-table-selection-column.elf-table-cell-fix-left {
  z-index: 3;
}
table tr th.elf-table-selection-column::after {
  background-color: transparent !important;
}
.elf-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.elf-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
}
.elf-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.elf-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.elf-table-expand-icon-col {
  width: 48px;
}
.elf-table-row-expand-icon-cell {
  text-align: center;
}
.elf-table-row-expand-icon-cell .elf-table-row-expand-icon {
  display: inline-flex;
  float: none;
  vertical-align: sub;
}
.elf-table-row-indent {
  float: left;
  height: 1px;
}
.elf-table-row-expand-icon {
  color: var(--elf-primary-color);
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  transform: scale(0.94117647);
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-table-row-expand-icon:focus-visible,
.elf-table-row-expand-icon:hover {
  color: var(--elf-primary-color-hover);
}
.elf-table-row-expand-icon:active {
  color: var(--elf-primary-color-active);
}
.elf-table-row-expand-icon:focus,
.elf-table-row-expand-icon:hover,
.elf-table-row-expand-icon:active {
  border-color: currentcolor;
}
.elf-table-row-expand-icon::before,
.elf-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: '';
}
.elf-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.elf-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.elf-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.elf-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.elf-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.elf-table-row-expand-icon-spaced::before,
.elf-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.elf-table-row-indent + .elf-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.elf-table-expanded-row > td,
tr.elf-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.elf-table-expanded-row .elf-descriptions-view {
  display: flex;
}
tr.elf-table-expanded-row .elf-descriptions-view table {
  flex: auto;
  width: auto;
}
.elf-table .elf-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.elf-table-tbody > tr.elf-table-placeholder {
  text-align: center;
}
.elf-table-empty .elf-table-tbody > tr.elf-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.elf-table-tbody > tr.elf-table-placeholder:hover > td {
  background: #fff;
}
.elf-table-cell-fix-left,
.elf-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.elf-table-cell-fix-left-first::after,
.elf-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.elf-table-cell-fix-left-all::after {
  display: none;
}
.elf-table-cell-fix-right-first::after,
.elf-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.elf-table .elf-table-container::before,
.elf-table .elf-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: calc(calc(2 + 1) + 1);
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.elf-table .elf-table-container::before {
  left: 0;
}
.elf-table .elf-table-container::after {
  right: 0;
}
.elf-table-ping-left:not(.elf-table-has-fix-left) > .elf-table-container {
  position: relative;
}
.elf-table-ping-left:not(.elf-table-has-fix-left) > .elf-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.elf-table-ping-left .elf-table-cell-fix-left-first::after,
.elf-table-ping-left .elf-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.elf-table-ping-left .elf-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.elf-table-ping-right:not(.elf-table-has-fix-right) > .elf-table-container {
  position: relative;
}
.elf-table-ping-right:not(.elf-table-has-fix-right) > .elf-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.elf-table-ping-right .elf-table-cell-fix-right-first::after,
.elf-table-ping-right .elf-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.elf-table-sticky-holder {
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.elf-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: rgba(204, 204, 204, 0.06);
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  opacity: 0.6;
}
.elf-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.elf-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.elf-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.elf-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .elf-table-ping-left .elf-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .elf-table-ping-right .elf-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.elf-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.elf-table-title {
  border-radius: 2px 2px 0 0;
}
.elf-table-title + .elf-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.elf-table-title + .elf-table-container table {
  border-radius: 0;
}
.elf-table-title + .elf-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.elf-table-title + .elf-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.elf-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.elf-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.elf-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.elf-table-footer {
  border-radius: 0 0 2px 2px;
}
.elf-table-wrapper-rtl {
  direction: rtl;
}
.elf-table-rtl {
  direction: rtl;
}
.elf-table-wrapper-rtl .elf-table table {
  text-align: right;
}
.elf-table-wrapper-rtl .elf-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.elf-table-wrapper-rtl
  .elf-table-thead
  > tr
  > th:not(:last-child):not(.elf-table-selection-column):not(.elf-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.elf-table-wrapper-rtl .elf-table-thead > tr > th {
  text-align: right;
}
.elf-table-tbody > tr .elf-table-wrapper:only-child .elf-table.elf-table-rtl {
  margin: -16px 33px -16px -16px;
}
.elf-table-wrapper.elf-table-wrapper-rtl .elf-table-pagination-left {
  justify-content: flex-end;
}
.elf-table-wrapper.elf-table-wrapper-rtl .elf-table-pagination-right {
  justify-content: flex-start;
}
.elf-table-wrapper-rtl .elf-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.elf-table-wrapper-rtl .elf-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.elf-table-rtl .elf-table-thead tr th.elf-table-column-has-sorters .elf-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.elf-table-wrapper-rtl .elf-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.elf-dropdown-rtl .elf-table-filter-dropdown .elf-checkbox-wrapper + span,
.elf-dropdown-rtl .elf-table-filter-dropdown-submenu .elf-checkbox-wrapper + span,
.elf-dropdown-menu-submenu-rtl.elf-table-filter-dropdown .elf-checkbox-wrapper + span,
.elf-dropdown-menu-submenu-rtl.elf-table-filter-dropdown-submenu .elf-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.elf-table-wrapper-rtl .elf-table-selection {
  text-align: center;
}
.elf-table-wrapper-rtl .elf-table-row-indent {
  float: right;
}
.elf-table-wrapper-rtl .elf-table-row-expand-icon {
  float: right;
}
.elf-table-wrapper-rtl .elf-table-row-indent + .elf-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.elf-table-wrapper-rtl .elf-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.elf-table-wrapper-rtl .elf-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.elf-table-wrapper-rtl .elf-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.elf-tabs-small > .elf-tabs-nav .elf-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.elf-tabs-large > .elf-tabs-nav .elf-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.elf-tabs-card.elf-tabs-small > .elf-tabs-nav .elf-tabs-tab {
  padding: 6px 16px;
}
.elf-tabs-card.elf-tabs-large > .elf-tabs-nav .elf-tabs-tab {
  padding: 7px 16px 6px;
}
.elf-tabs-rtl {
  direction: rtl;
}
.elf-tabs-rtl .elf-tabs-nav .elf-tabs-tab {
  margin: 0 0 0 32px;
}
.elf-tabs-rtl .elf-tabs-nav .elf-tabs-tab:last-of-type {
  margin-left: 0;
}
.elf-tabs-rtl .elf-tabs-nav .elf-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.elf-tabs-rtl .elf-tabs-nav .elf-tabs-tab .elf-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.elf-tabs-rtl .elf-tabs-nav .elf-tabs-tab .elf-tabs-tab-remove .anticon {
  margin: 0;
}
.elf-tabs-rtl.elf-tabs-left > .elf-tabs-nav {
  order: 1;
}
.elf-tabs-rtl.elf-tabs-left > .elf-tabs-content-holder {
  order: 0;
}
.elf-tabs-rtl.elf-tabs-right > .elf-tabs-nav {
  order: 0;
}
.elf-tabs-rtl.elf-tabs-right > .elf-tabs-content-holder {
  order: 1;
}
.elf-tabs-rtl.elf-tabs-card.elf-tabs-top > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-rtl.elf-tabs-card.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-rtl.elf-tabs-card.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-rtl.elf-tabs-card.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab {
  margin-right: 2px;
  margin-left: 0;
}
.elf-tabs-rtl.elf-tabs-card.elf-tabs-top > .elf-tabs-nav .elf-tabs-nav-add,
.elf-tabs-rtl.elf-tabs-card.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-nav-add,
.elf-tabs-rtl.elf-tabs-card.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-nav-add,
.elf-tabs-rtl.elf-tabs-card.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-nav-add {
  margin-right: 2px;
  margin-left: 0;
}
.elf-tabs-dropdown-rtl {
  direction: rtl;
}
.elf-tabs-dropdown-rtl .elf-tabs-dropdown-menu-item {
  text-align: right;
}
.elf-tabs-top,
.elf-tabs-bottom {
  flex-direction: column;
}
.elf-tabs-top > .elf-tabs-nav,
.elf-tabs-bottom > .elf-tabs-nav,
.elf-tabs-top > div > .elf-tabs-nav,
.elf-tabs-bottom > div > .elf-tabs-nav {
  margin: 0 0 16px 0;
}
.elf-tabs-top > .elf-tabs-nav::before,
.elf-tabs-bottom > .elf-tabs-nav::before,
.elf-tabs-top > div > .elf-tabs-nav::before,
.elf-tabs-bottom > div > .elf-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  content: '';
}
.elf-tabs-top > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-ink-bar {
  height: 2px;
}
.elf-tabs-top > .elf-tabs-nav .elf-tabs-ink-bar-animated,
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-ink-bar-animated,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-ink-bar-animated,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.elf-tabs-top > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-top > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.elf-tabs-top > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.elf-tabs-top > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.elf-tabs-top > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-left::before,
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-left::before,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-left::before,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.elf-tabs-top > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-right::after,
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-right::after,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-right::after,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.elf-tabs-top > .elf-tabs-nav::before,
.elf-tabs-top > div > .elf-tabs-nav::before {
  bottom: 0;
}
.elf-tabs-top > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-ink-bar {
  bottom: 0;
}
.elf-tabs-bottom > .elf-tabs-nav,
.elf-tabs-bottom > div > .elf-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.elf-tabs-bottom > .elf-tabs-nav::before,
.elf-tabs-bottom > div > .elf-tabs-nav::before {
  top: 0;
}
.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-ink-bar {
  top: 0;
}
.elf-tabs-bottom > .elf-tabs-content-holder,
.elf-tabs-bottom > div > .elf-tabs-content-holder {
  order: 0;
}
.elf-tabs-left > .elf-tabs-nav,
.elf-tabs-right > .elf-tabs-nav,
.elf-tabs-left > div > .elf-tabs-nav,
.elf-tabs-right > div > .elf-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-tab,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-tab,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-tab,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab {
  margin: 16px 0 0 0;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-wrap,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-wrap,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-wrap,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-wrap {
  flex-direction: column;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-top::before,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-top::before,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-top::before,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-bottom::after,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-bottom::after,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-bottom::after,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-wrap.elf-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-ink-bar {
  width: 2px;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-ink-bar-animated,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-ink-bar-animated,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-ink-bar-animated,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-list,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-list,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-list,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-list,
.elf-tabs-left > .elf-tabs-nav .elf-tabs-nav-operations,
.elf-tabs-right > .elf-tabs-nav .elf-tabs-nav-operations,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-nav-operations,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.elf-tabs-left > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-ink-bar {
  right: 0;
}
.elf-tabs-left > .elf-tabs-content-holder,
.elf-tabs-left > div > .elf-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-tabs-left > .elf-tabs-content-holder > .elf-tabs-content > .elf-tabs-tabpane,
.elf-tabs-left > div > .elf-tabs-content-holder > .elf-tabs-content > .elf-tabs-tabpane {
  padding-left: 24px;
}
.elf-tabs-right > .elf-tabs-nav,
.elf-tabs-right > div > .elf-tabs-nav {
  order: 1;
}
.elf-tabs-right > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-ink-bar {
  left: 0;
}
.elf-tabs-right > .elf-tabs-content-holder,
.elf-tabs-right > div > .elf-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-tabs-right > .elf-tabs-content-holder > .elf-tabs-content > .elf-tabs-tabpane,
.elf-tabs-right > div > .elf-tabs-content-holder > .elf-tabs-content > .elf-tabs-tabpane {
  padding-right: 24px;
}
.elf-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.elf-tabs-dropdown-hidden {
  display: none;
}
.elf-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.elf-tabs-dropdown-menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-tabs-dropdown-menu-item > span {
  flex: 1;
  white-space: nowrap;
}
.elf-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.elf-tabs-dropdown-menu-item-remove:hover {
  color: var(--elf-primary-5);
}
.elf-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.elf-tabs-dropdown-menu-item-disabled,
.elf-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.elf-tabs-card > .elf-tabs-nav .elf-tabs-tab,
.elf-tabs-card > div > .elf-tabs-nav .elf-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-tabs-card > .elf-tabs-nav .elf-tabs-tab-active,
.elf-tabs-card > div > .elf-tabs-nav .elf-tabs-tab-active {
  color: var(--elf-primary-color);
  background: #fff;
}
.elf-tabs-card > .elf-tabs-nav .elf-tabs-ink-bar,
.elf-tabs-card > div > .elf-tabs-nav .elf-tabs-ink-bar {
  visibility: hidden;
}
.elf-tabs-card.elf-tabs-top > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-card.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-card.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-card.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab {
  margin-left: 2px;
}
.elf-tabs-card.elf-tabs-top > .elf-tabs-nav .elf-tabs-tab,
.elf-tabs-card.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-tab {
  border-radius: 2px 2px 0 0;
}
.elf-tabs-card.elf-tabs-top > .elf-tabs-nav .elf-tabs-tab-active,
.elf-tabs-card.elf-tabs-top > div > .elf-tabs-nav .elf-tabs-tab-active {
  border-bottom-color: #fff;
}
.elf-tabs-card.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-tab,
.elf-tabs-card.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-tab {
  border-radius: 0 0 2px 2px;
}
.elf-tabs-card.elf-tabs-bottom > .elf-tabs-nav .elf-tabs-tab-active,
.elf-tabs-card.elf-tabs-bottom > div > .elf-tabs-nav .elf-tabs-tab-active {
  border-top-color: #fff;
}
.elf-tabs-card.elf-tabs-left > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-card.elf-tabs-right > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-card.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab,
.elf-tabs-card.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-tab + .elf-tabs-tab {
  margin-top: 2px;
}
.elf-tabs-card.elf-tabs-left > .elf-tabs-nav .elf-tabs-tab,
.elf-tabs-card.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-tab {
  border-radius: 2px 0 0 2px;
}
.elf-tabs-card.elf-tabs-left > .elf-tabs-nav .elf-tabs-tab-active,
.elf-tabs-card.elf-tabs-left > div > .elf-tabs-nav .elf-tabs-tab-active {
  border-right-color: #fff;
}
.elf-tabs-card.elf-tabs-right > .elf-tabs-nav .elf-tabs-tab,
.elf-tabs-card.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-tab {
  border-radius: 0 2px 2px 0;
}
.elf-tabs-card.elf-tabs-right > .elf-tabs-nav .elf-tabs-tab-active,
.elf-tabs-card.elf-tabs-right > div > .elf-tabs-nav .elf-tabs-tab-active {
  border-left-color: #fff;
}
.elf-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
}
.elf-tabs > .elf-tabs-nav,
.elf-tabs > div > .elf-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-wrap,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-wrap::before,
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-wrap::after,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-list,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-operations,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-operations-hidden,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-more,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-more::after,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-add,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-add {
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px 2px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-add:hover,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-add:hover {
  color: var(--elf-primary-5);
}
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-add:active,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-add:active,
.elf-tabs > .elf-tabs-nav .elf-tabs-nav-add:focus,
.elf-tabs > div > .elf-tabs-nav .elf-tabs-nav-add:focus {
  color: var(--elf-primary-7);
}
.elf-tabs-extra-content {
  flex: none;
}
.elf-tabs-centered > .elf-tabs-nav .elf-tabs-nav-wrap:not([class*='elf-tabs-nav-wrap-ping']),
.elf-tabs-centered > div > .elf-tabs-nav .elf-tabs-nav-wrap:not([class*='elf-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.elf-tabs-ink-bar {
  position: absolute;
  background: var(--elf-primary-color);
  pointer-events: none;
}
.elf-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.elf-tabs-tab-btn:focus,
.elf-tabs-tab-remove:focus,
.elf-tabs-tab-btn:active,
.elf-tabs-tab-remove:active {
  color: var(--elf-primary-7);
}
.elf-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.elf-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}
.elf-tabs-tab:hover {
  color: var(--elf-primary-5);
}
.elf-tabs-tab.elf-tabs-tab-active .elf-tabs-tab-btn {
  color: var(--elf-primary-color);
  text-shadow: 0 0 0.25px currentcolor;
}
.elf-tabs-tab.elf-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-tabs-tab.elf-tabs-tab-disabled .elf-tabs-tab-btn:focus,
.elf-tabs-tab.elf-tabs-tab-disabled .elf-tabs-tab-remove:focus,
.elf-tabs-tab.elf-tabs-tab-disabled .elf-tabs-tab-btn:active,
.elf-tabs-tab.elf-tabs-tab-disabled .elf-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.elf-tabs-tab .elf-tabs-tab-remove .anticon {
  margin: 0;
}
.elf-tabs-tab .anticon {
  margin-right: 12px;
}
.elf-tabs-tab + .elf-tabs-tab {
  margin: 0 0 0 32px;
}
.elf-tabs-content {
  position: relative;
  width: 100%;
}
.elf-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.elf-tabs-tabpane {
  outline: none;
}
.elf-tabs-tabpane-hidden {
  display: none;
}
.elf-tabs-switch-appear,
.elf-tabs-switch-enter {
  transition: none;
}
.elf-tabs-switch-appear-start,
.elf-tabs-switch-enter-start {
  opacity: 0;
}
.elf-tabs-switch-appear-active,
.elf-tabs-switch-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.elf-tabs-switch-leave {
  position: absolute;
  transition: none;
  inset: 0;
}
.elf-tabs-switch-leave-start {
  opacity: 1;
}
.elf-tabs-switch-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}
.elf-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.elf-tag,
.elf-tag a,
.elf-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.elf-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.elf-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.elf-tag-has-color {
  border-color: transparent;
}
.elf-tag-has-color,
.elf-tag-has-color a,
.elf-tag-has-color a:hover,
.elf-tag-has-color .anticon-close,
.elf-tag-has-color .anticon-close:hover {
  color: #fff;
}
.elf-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.elf-tag-checkable:not(.elf-tag-checkable-checked):hover {
  color: var(--elf-primary-color);
}
.elf-tag-checkable:active,
.elf-tag-checkable-checked {
  color: #fff;
}
.elf-tag-checkable-checked {
  background-color: var(--elf-primary-6);
}
.elf-tag-checkable:active {
  background-color: var(--elf-primary-7);
}
.elf-tag-hidden {
  display: none;
}
.elf-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.elf-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.elf-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.elf-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.elf-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.elf-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.elf-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.elf-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.elf-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.elf-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.elf-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.elf-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.elf-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.elf-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.elf-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.elf-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.elf-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.elf-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.elf-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.elf-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.elf-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.elf-tag-blue-inverse {
  color: #fff;
  background: #8b60f7;
  border-color: #8b60f7;
}
.elf-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.elf-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.elf-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.elf-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.elf-tag-success {
  color: var(--elf-success-color);
  background: var(--elf-success-color-deprecated-bg);
  border-color: var(--elf-success-color-deprecated-border);
}
.elf-tag-processing {
  color: var(--elf-info-color);
  background: var(--elf-info-color-deprecated-bg);
  border-color: var(--elf-info-color-deprecated-border);
}
.elf-tag-error {
  color: var(--elf-error-color);
  background: var(--elf-error-color-deprecated-bg);
  border-color: var(--elf-error-color-deprecated-border);
}
.elf-tag-warning {
  color: var(--elf-warning-color);
  background: var(--elf-warning-color-deprecated-bg);
  border-color: var(--elf-warning-color-deprecated-border);
}
.elf-tag > .anticon + span,
.elf-tag > span + .anticon {
  margin-left: 7px;
}
.elf-tag.elf-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.elf-tag-rtl .elf-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.elf-tag-rtl.elf-tag > .anticon + span,
.elf-tag-rtl.elf-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.elf-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
  list-style: none;
}
.elf-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.elf-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid rgba(0, 0, 0, 0.06);
}
.elf-timeline-item-pending .elf-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.elf-timeline-item-pending .elf-timeline-item-tail {
  display: none;
}
.elf-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.elf-timeline-item-head-blue {
  color: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
}
.elf-timeline-item-head-red {
  color: var(--elf-error-color);
  border-color: var(--elf-error-color);
}
.elf-timeline-item-head-green {
  color: var(--elf-success-color);
  border-color: var(--elf-success-color);
}
.elf-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.elf-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.elf-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.elf-timeline-item-last > .elf-timeline-item-tail {
  display: none;
}
.elf-timeline-item-last > .elf-timeline-item-content {
  min-height: 48px;
}
.elf-timeline.elf-timeline-alternate .elf-timeline-item-tail,
.elf-timeline.elf-timeline-right .elf-timeline-item-tail,
.elf-timeline.elf-timeline-label .elf-timeline-item-tail,
.elf-timeline.elf-timeline-alternate .elf-timeline-item-head,
.elf-timeline.elf-timeline-right .elf-timeline-item-head,
.elf-timeline.elf-timeline-label .elf-timeline-item-head,
.elf-timeline.elf-timeline-alternate .elf-timeline-item-head-custom,
.elf-timeline.elf-timeline-right .elf-timeline-item-head-custom,
.elf-timeline.elf-timeline-label .elf-timeline-item-head-custom {
  left: 50%;
}
.elf-timeline.elf-timeline-alternate .elf-timeline-item-head,
.elf-timeline.elf-timeline-right .elf-timeline-item-head,
.elf-timeline.elf-timeline-label .elf-timeline-item-head {
  margin-left: -4px;
}
.elf-timeline.elf-timeline-alternate .elf-timeline-item-head-custom,
.elf-timeline.elf-timeline-right .elf-timeline-item-head-custom,
.elf-timeline.elf-timeline-label .elf-timeline-item-head-custom {
  margin-left: 1px;
}
.elf-timeline.elf-timeline-alternate .elf-timeline-item-left .elf-timeline-item-content,
.elf-timeline.elf-timeline-right .elf-timeline-item-left .elf-timeline-item-content,
.elf-timeline.elf-timeline-label .elf-timeline-item-left .elf-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.elf-timeline.elf-timeline-alternate .elf-timeline-item-right .elf-timeline-item-content,
.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-content,
.elf-timeline.elf-timeline-label .elf-timeline-item-right .elf-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-tail,
.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-head,
.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-content {
  width: calc(100% - 18px);
}
.elf-timeline.elf-timeline-pending .elf-timeline-item-last .elf-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted rgba(0, 0, 0, 0.06);
}
.elf-timeline.elf-timeline-reverse .elf-timeline-item-last .elf-timeline-item-tail {
  display: none;
}
.elf-timeline.elf-timeline-reverse .elf-timeline-item-pending .elf-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted rgba(0, 0, 0, 0.06);
}
.elf-timeline.elf-timeline-reverse .elf-timeline-item-pending .elf-timeline-item-content {
  min-height: 48px;
}
.elf-timeline.elf-timeline-label .elf-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}
.elf-timeline.elf-timeline-label .elf-timeline-item-right .elf-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.elf-timeline-rtl {
  direction: rtl;
}
.elf-timeline-rtl .elf-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid rgba(0, 0, 0, 0.06);
  border-left: none;
}
.elf-timeline-rtl .elf-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.elf-timeline-rtl .elf-timeline-item-content {
  margin: 0 18px 0 0;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-alternate .elf-timeline-item-tail,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-tail,
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-tail,
.elf-timeline-rtl.elf-timeline.elf-timeline-alternate .elf-timeline-item-head,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-head,
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-head,
.elf-timeline-rtl.elf-timeline.elf-timeline-alternate .elf-timeline-item-head-custom,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-head-custom,
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-alternate .elf-timeline-item-head,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-head,
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-alternate .elf-timeline-item-head-custom,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-head-custom,
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-alternate .elf-timeline-item-left .elf-timeline-item-content,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-left .elf-timeline-item-content,
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-left .elf-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-alternate .elf-timeline-item-right .elf-timeline-item-content,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-content,
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-right .elf-timeline-item-content {
  text-align: left;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-tail,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-head,
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-right .elf-timeline-item-right .elf-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-pending .elf-timeline-item-last .elf-timeline-item-tail {
  border-right: 2px dotted rgba(0, 0, 0, 0.06);
  border-left: none;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-reverse .elf-timeline-item-pending .elf-timeline-item-tail {
  border-right: 2px dotted rgba(0, 0, 0, 0.06);
  border-left: none;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-label {
  text-align: left;
}
.elf-timeline-rtl.elf-timeline.elf-timeline-label .elf-timeline-item-right .elf-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.elf-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  width: -moz-max-content;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.elf-tooltip-content {
  position: relative;
}
.elf-tooltip-hidden {
  display: none;
}
.elf-tooltip-placement-top,
.elf-tooltip-placement-topLeft,
.elf-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.elf-tooltip-placement-right,
.elf-tooltip-placement-rightTop,
.elf-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.elf-tooltip-placement-bottom,
.elf-tooltip-placement-bottomLeft,
.elf-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.elf-tooltip-placement-left,
.elf-tooltip-placement-leftTop,
.elf-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.elf-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.elf-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.elf-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.elf-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path(
    'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
  );
          clip-path: path(
    'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
  );
}
.elf-tooltip-placement-top .elf-tooltip-arrow,
.elf-tooltip-placement-topLeft .elf-tooltip-arrow,
.elf-tooltip-placement-topRight .elf-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.elf-tooltip-placement-top .elf-tooltip-arrow-content,
.elf-tooltip-placement-topLeft .elf-tooltip-arrow-content,
.elf-tooltip-placement-topRight .elf-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.elf-tooltip-placement-top .elf-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.elf-tooltip-placement-topLeft .elf-tooltip-arrow {
  left: 13px;
}
.elf-tooltip-placement-topRight .elf-tooltip-arrow {
  right: 13px;
}
.elf-tooltip-placement-right .elf-tooltip-arrow,
.elf-tooltip-placement-rightTop .elf-tooltip-arrow,
.elf-tooltip-placement-rightBottom .elf-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.elf-tooltip-placement-right .elf-tooltip-arrow-content,
.elf-tooltip-placement-rightTop .elf-tooltip-arrow-content,
.elf-tooltip-placement-rightBottom .elf-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.elf-tooltip-placement-right .elf-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.elf-tooltip-placement-rightTop .elf-tooltip-arrow {
  top: 5px;
}
.elf-tooltip-placement-rightBottom .elf-tooltip-arrow {
  bottom: 5px;
}
.elf-tooltip-placement-left .elf-tooltip-arrow,
.elf-tooltip-placement-leftTop .elf-tooltip-arrow,
.elf-tooltip-placement-leftBottom .elf-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.elf-tooltip-placement-left .elf-tooltip-arrow-content,
.elf-tooltip-placement-leftTop .elf-tooltip-arrow-content,
.elf-tooltip-placement-leftBottom .elf-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.elf-tooltip-placement-left .elf-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.elf-tooltip-placement-leftTop .elf-tooltip-arrow {
  top: 5px;
}
.elf-tooltip-placement-leftBottom .elf-tooltip-arrow {
  bottom: 5px;
}
.elf-tooltip-placement-bottom .elf-tooltip-arrow,
.elf-tooltip-placement-bottomLeft .elf-tooltip-arrow,
.elf-tooltip-placement-bottomRight .elf-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.elf-tooltip-placement-bottom .elf-tooltip-arrow-content,
.elf-tooltip-placement-bottomLeft .elf-tooltip-arrow-content,
.elf-tooltip-placement-bottomRight .elf-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.elf-tooltip-placement-bottom .elf-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.elf-tooltip-placement-bottomLeft .elf-tooltip-arrow {
  left: 13px;
}
.elf-tooltip-placement-bottomRight .elf-tooltip-arrow {
  right: 13px;
}
.elf-tooltip-pink .elf-tooltip-inner {
  background-color: #eb2f96;
}
.elf-tooltip-pink .elf-tooltip-arrow-content::before {
  background: #eb2f96;
}
.elf-tooltip-magenta .elf-tooltip-inner {
  background-color: #eb2f96;
}
.elf-tooltip-magenta .elf-tooltip-arrow-content::before {
  background: #eb2f96;
}
.elf-tooltip-red .elf-tooltip-inner {
  background-color: #f5222d;
}
.elf-tooltip-red .elf-tooltip-arrow-content::before {
  background: #f5222d;
}
.elf-tooltip-volcano .elf-tooltip-inner {
  background-color: #fa541c;
}
.elf-tooltip-volcano .elf-tooltip-arrow-content::before {
  background: #fa541c;
}
.elf-tooltip-orange .elf-tooltip-inner {
  background-color: #fa8c16;
}
.elf-tooltip-orange .elf-tooltip-arrow-content::before {
  background: #fa8c16;
}
.elf-tooltip-yellow .elf-tooltip-inner {
  background-color: #fadb14;
}
.elf-tooltip-yellow .elf-tooltip-arrow-content::before {
  background: #fadb14;
}
.elf-tooltip-gold .elf-tooltip-inner {
  background-color: #faad14;
}
.elf-tooltip-gold .elf-tooltip-arrow-content::before {
  background: #faad14;
}
.elf-tooltip-cyan .elf-tooltip-inner {
  background-color: #13c2c2;
}
.elf-tooltip-cyan .elf-tooltip-arrow-content::before {
  background: #13c2c2;
}
.elf-tooltip-lime .elf-tooltip-inner {
  background-color: #a0d911;
}
.elf-tooltip-lime .elf-tooltip-arrow-content::before {
  background: #a0d911;
}
.elf-tooltip-green .elf-tooltip-inner {
  background-color: #52c41a;
}
.elf-tooltip-green .elf-tooltip-arrow-content::before {
  background: #52c41a;
}
.elf-tooltip-blue .elf-tooltip-inner {
  background-color: #8b60f7;
}
.elf-tooltip-blue .elf-tooltip-arrow-content::before {
  background: #8b60f7;
}
.elf-tooltip-geekblue .elf-tooltip-inner {
  background-color: #2f54eb;
}
.elf-tooltip-geekblue .elf-tooltip-arrow-content::before {
  background: #2f54eb;
}
.elf-tooltip-purple .elf-tooltip-inner {
  background-color: #722ed1;
}
.elf-tooltip-purple .elf-tooltip-arrow-content::before {
  background: #722ed1;
}
.elf-tooltip-rtl {
  direction: rtl;
}
.elf-tooltip-rtl .elf-tooltip-inner {
  text-align: right;
}
.elf-transfer-customize-list .elf-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.elf-transfer-customize-list .elf-table-wrapper .elf-table-small {
  border: 0;
  border-radius: 0;
}
.elf-transfer-customize-list .elf-table-wrapper .elf-table-small .elf-table-selection-column {
  width: 40px;
  min-width: 40px;
}
.elf-transfer-customize-list
  .elf-table-wrapper
  .elf-table-small
  > .elf-table-content
  > .elf-table-body
  > table
  > .elf-table-thead
  > tr
  > th {
  background: #fafafa;
}
.elf-transfer-customize-list .elf-table-wrapper .elf-table-small > .elf-table-content .elf-table-row:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-transfer-customize-list .elf-table-wrapper .elf-table-small .elf-table-body {
  margin: 0;
}
.elf-transfer-customize-list .elf-table-wrapper .elf-table-pagination.elf-pagination {
  margin: 16px 0 4px;
}
.elf-transfer-customize-list .elf-input[disabled] {
  background-color: transparent;
}
.elf-transfer-status-error .elf-transfer-list {
  border-color: var(--elf-error-color);
}
.elf-transfer-status-error .elf-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.elf-transfer-status-error .elf-transfer-list-search:not([disabled]):hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-transfer-status-error .elf-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-transfer-status-error .elf-transfer-list-search:not([disabled]):focus {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-transfer-status-error .elf-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-transfer-status-warning .elf-transfer-list {
  border-color: var(--elf-warning-color);
}
.elf-transfer-status-warning .elf-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.elf-transfer-status-warning .elf-transfer-list-search:not([disabled]):hover {
  border-color: var(--elf-primary-5);
  border-right-width: 1px;
}
.elf-input-rtl .elf-transfer-status-warning .elf-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-transfer-status-warning .elf-transfer-list-search:not([disabled]):focus {
  border-color: var(--elf-primary-color-hover);
  box-shadow: 0 0 0 2px var(--elf-primary-color-outline);
  border-right-width: 1px;
  outline: 0;
}
.elf-input-rtl .elf-transfer-status-warning .elf-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.elf-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: stretch;
}
.elf-transfer-disabled .elf-transfer-list {
  background: #f5f5f5;
}
.elf-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.elf-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.elf-transfer-list-search .anticon-search {
  color: rgba(0, 0, 0, 0.25);
}
.elf-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px 2px 0 0;
}
.elf-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.elf-transfer-list-header > * {
  flex: none;
}
.elf-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.elf-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.elf-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.elf-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.elf-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.elf-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.elf-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 0.3s;
}
.elf-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.elf-transfer-list-content-item > * {
  flex: none;
}
.elf-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-transfer-list-content-item-remove {
  position: relative;
  color: #d9d9d9;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-transfer-list-content-item-remove:hover {
  color: var(--elf-primary-color-hover);
}
.elf-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.elf-transfer-list-content-item:not(.elf-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.elf-transfer-list-content-item:not(
    .elf-transfer-list-content-item-disabled
  ).elf-transfer-list-content-item-checked:hover {
  background-color: var(--elf-primary-color-active-deprecated-d-02);
}
.elf-transfer-list-content-show-remove
  .elf-transfer-list-content-item:not(.elf-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.elf-transfer-list-content-item-checked {
  background-color: var(--elf-primary-1);
}
.elf-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.elf-transfer-list-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.elf-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.elf-transfer-operation .elf-btn {
  display: block;
}
.elf-transfer-operation .elf-btn:first-child {
  margin-bottom: 4px;
}
.elf-transfer-operation .elf-btn .anticon {
  font-size: 12px;
}
.elf-transfer .elf-empty-image {
  max-height: -2px;
}
.elf-transfer-rtl {
  direction: rtl;
}
.elf-transfer-rtl .elf-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.elf-transfer-rtl .elf-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.elf-transfer-rtl .elf-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.elf-transfer-rtl .elf-transfer-list-header {
  right: 0;
  left: auto;
}
.elf-transfer-rtl .elf-transfer-list-header-title {
  text-align: left;
}
.elf-transfer-rtl .elf-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.elf-transfer-rtl .elf-transfer-list-pagination {
  text-align: left;
}
.elf-transfer-rtl .elf-transfer-list-footer {
  right: 0;
  left: auto;
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.elf-tree.elf-tree-directory .elf-tree-treenode {
  position: relative;
}
.elf-tree.elf-tree-directory .elf-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.elf-tree.elf-tree-directory .elf-tree-treenode:hover::before {
  background: #f5f5f5;
}
.elf-tree.elf-tree-directory .elf-tree-treenode > * {
  z-index: 1;
}
.elf-tree.elf-tree-directory .elf-tree-treenode .elf-tree-switcher {
  transition: color 0.3s;
}
.elf-tree.elf-tree-directory .elf-tree-treenode .elf-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-tree.elf-tree-directory .elf-tree-treenode .elf-tree-node-content-wrapper:hover {
  background: transparent;
}
.elf-tree.elf-tree-directory .elf-tree-treenode .elf-tree-node-content-wrapper.elf-tree-node-selected {
  color: #fff;
  background: transparent;
}
.elf-tree.elf-tree-directory .elf-tree-treenode-selected:hover::before,
.elf-tree.elf-tree-directory .elf-tree-treenode-selected::before {
  background: var(--elf-primary-color);
}
.elf-tree.elf-tree-directory .elf-tree-treenode-selected .elf-tree-switcher {
  color: #fff;
}
.elf-tree.elf-tree-directory .elf-tree-treenode-selected .elf-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.elf-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.elf-tree-checkbox-wrapper:hover .elf-tree-checkbox-inner,
.elf-tree-checkbox:hover .elf-tree-checkbox-inner,
.elf-tree-checkbox-input:focus + .elf-tree-checkbox-inner {
  border-color: var(--elf-primary-color);
}
.elf-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--elf-primary-color);
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.elf-tree-checkbox:hover::after,
.elf-tree-checkbox-wrapper:hover .elf-tree-checkbox::after {
  visibility: visible;
}
.elf-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.elf-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.elf-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.elf-tree-checkbox-checked .elf-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.elf-tree-checkbox-checked .elf-tree-checkbox-inner {
  background-color: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
}
.elf-tree-checkbox-disabled {
  cursor: not-allowed;
}
.elf-tree-checkbox-disabled.elf-tree-checkbox-checked .elf-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.elf-tree-checkbox-disabled .elf-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.elf-tree-checkbox-disabled .elf-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.elf-tree-checkbox-disabled .elf-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.elf-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-tree-checkbox-disabled:hover::after,
.elf-tree-checkbox-wrapper:hover .elf-tree-checkbox-disabled::after {
  visibility: hidden;
}
.elf-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.elf-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.elf-tree-checkbox-wrapper.elf-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.elf-tree-checkbox-wrapper + .elf-tree-checkbox-wrapper {
  margin-left: 8px;
}
.elf-tree-checkbox-wrapper.elf-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.elf-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.elf-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.elf-tree-checkbox-group-item {
  margin-right: 8px;
}
.elf-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.elf-tree-checkbox-group-item + .elf-tree-checkbox-group-item {
  margin-left: 0;
}
.elf-tree-checkbox-indeterminate .elf-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.elf-tree-checkbox-indeterminate .elf-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--elf-primary-color);
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.elf-tree-checkbox-indeterminate.elf-tree-checkbox-disabled .elf-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.elf-tree-checkbox-rtl {
  direction: rtl;
}
.elf-tree-checkbox-group-rtl .elf-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.elf-tree-checkbox-group-rtl .elf-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.elf-tree-checkbox-group-rtl .elf-tree-checkbox-group-item + .elf-tree-checkbox-group-item {
  margin-left: 8px;
}
.elf-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.elf-tree-focused:not(:hover):not(.elf-tree-active-focused) {
  background: var(--elf-primary-1);
}
.elf-tree-list-holder-inner {
  align-items: flex-start;
}
.elf-tree.elf-tree-block-node .elf-tree-list-holder-inner {
  align-items: stretch;
}
.elf-tree.elf-tree-block-node .elf-tree-list-holder-inner .elf-tree-node-content-wrapper {
  flex: auto;
}
.elf-tree.elf-tree-block-node .elf-tree-list-holder-inner .elf-tree-treenode.dragging {
  position: relative;
}
.elf-tree.elf-tree-block-node .elf-tree-list-holder-inner .elf-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid var(--elf-primary-color);
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.elf-tree .elf-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.elf-tree .elf-tree-treenode-disabled .elf-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-tree .elf-tree-treenode-disabled .elf-tree-node-content-wrapper:hover {
  background: transparent;
}
.elf-tree .elf-tree-treenode-active .elf-tree-node-content-wrapper {
  background: #f5f5f5;
}
.elf-tree .elf-tree-treenode:not(.elf-tree .elf-tree-treenode-disabled).filter-node .elf-tree-title {
  color: inherit;
  font-weight: 500;
}
.elf-tree .elf-tree-treenode-draggable .elf-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.elf-tree-treenode:hover .elf-tree .elf-tree-treenode-draggable .elf-tree-draggable-icon {
  opacity: 0.45;
}
.elf-tree .elf-tree-treenode-draggable.elf-tree-treenode-disabled .elf-tree-draggable-icon {
  visibility: hidden;
}
.elf-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.elf-tree-draggable-icon {
  visibility: hidden;
}
.elf-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-tree-switcher .elf-tree-switcher-icon,
.elf-tree-switcher .elf-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.elf-tree-switcher .elf-tree-switcher-icon svg,
.elf-tree-switcher .elf-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.elf-tree-switcher-noop {
  cursor: default;
}
.elf-tree-switcher_close .elf-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.elf-tree-switcher-loading-icon {
  color: var(--elf-primary-color);
}
.elf-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.elf-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.elf-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.elf-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.elf-tree .elf-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.elf-tree .elf-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.elf-tree .elf-tree-node-content-wrapper.elf-tree-node-selected {
  background-color: var(--elf-primary-2);
}
.elf-tree .elf-tree-node-content-wrapper .elf-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.elf-tree .elf-tree-node-content-wrapper .elf-tree-iconEle:empty {
  display: none;
}
.elf-tree-unselectable .elf-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.elf-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-tree-node-content-wrapper .elf-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: var(--elf-primary-color);
  border-radius: 1px;
  pointer-events: none;
}
.elf-tree-node-content-wrapper .elf-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid var(--elf-primary-color);
  border-radius: 50%;
  content: '';
}
.elf-tree .elf-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px var(--elf-primary-color);
}
.elf-tree-show-line .elf-tree-indent-unit {
  position: relative;
  height: 100%;
}
.elf-tree-show-line .elf-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.elf-tree-show-line .elf-tree-indent-unit-end::before {
  display: none;
}
.elf-tree-show-line .elf-tree-switcher {
  background: #fff;
}
.elf-tree-show-line .elf-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.elf-tree .elf-tree-treenode-leaf-last .elf-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.elf-tree-rtl {
  direction: rtl;
}
.elf-tree-rtl .elf-tree-node-content-wrapper[draggable='true'] .elf-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.elf-tree .elf-tree-treenode-rtl {
  direction: rtl;
}
.elf-tree-rtl .elf-tree-switcher_close .elf-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.elf-tree-rtl.elf-tree-show-line .elf-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.elf-tree-rtl .elf-tree-checkbox {
  margin: 4px 0 0 8px;
}
.elf-tree-select-dropdown-rtl .elf-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.elf-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.elf-select-tree-checkbox-wrapper:hover .elf-select-tree-checkbox-inner,
.elf-select-tree-checkbox:hover .elf-select-tree-checkbox-inner,
.elf-select-tree-checkbox-input:focus + .elf-select-tree-checkbox-inner {
  border-color: var(--elf-primary-color);
}
.elf-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--elf-primary-color);
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.elf-select-tree-checkbox:hover::after,
.elf-select-tree-checkbox-wrapper:hover .elf-select-tree-checkbox::after {
  visibility: visible;
}
.elf-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.elf-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.elf-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.elf-select-tree-checkbox-checked .elf-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.elf-select-tree-checkbox-checked .elf-select-tree-checkbox-inner {
  background-color: var(--elf-primary-color);
  border-color: var(--elf-primary-color);
}
.elf-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.elf-select-tree-checkbox-disabled.elf-select-tree-checkbox-checked .elf-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.elf-select-tree-checkbox-disabled .elf-select-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.elf-select-tree-checkbox-disabled .elf-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.elf-select-tree-checkbox-disabled .elf-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.elf-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-select-tree-checkbox-disabled:hover::after,
.elf-select-tree-checkbox-wrapper:hover .elf-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.elf-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.elf-select-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.elf-select-tree-checkbox-wrapper.elf-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.elf-select-tree-checkbox-wrapper + .elf-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.elf-select-tree-checkbox-wrapper.elf-select-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.elf-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.elf-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.elf-select-tree-checkbox-group-item {
  margin-right: 8px;
}
.elf-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.elf-select-tree-checkbox-group-item + .elf-select-tree-checkbox-group-item {
  margin-left: 0;
}
.elf-select-tree-checkbox-indeterminate .elf-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.elf-select-tree-checkbox-indeterminate .elf-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--elf-primary-color);
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.elf-select-tree-checkbox-indeterminate.elf-select-tree-checkbox-disabled .elf-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.elf-select-tree-checkbox-rtl {
  direction: rtl;
}
.elf-select-tree-checkbox-group-rtl .elf-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.elf-select-tree-checkbox-group-rtl .elf-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.elf-select-tree-checkbox-group-rtl .elf-select-tree-checkbox-group-item + .elf-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.elf-tree-select-dropdown {
  padding: 8px 4px;
}
.elf-tree-select-dropdown-rtl {
  direction: rtl;
}
.elf-tree-select-dropdown .elf-select-tree {
  border-radius: 0;
}
.elf-tree-select-dropdown .elf-select-tree-list-holder-inner {
  align-items: stretch;
}
.elf-tree-select-dropdown
  .elf-select-tree-list-holder-inner
  .elf-select-tree-treenode
  .elf-select-tree-node-content-wrapper {
  flex: auto;
}
.elf-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.elf-select-tree-focused:not(:hover):not(.elf-select-tree-active-focused) {
  background: var(--elf-primary-1);
}
.elf-select-tree-list-holder-inner {
  align-items: flex-start;
}
.elf-select-tree.elf-select-tree-block-node .elf-select-tree-list-holder-inner {
  align-items: stretch;
}
.elf-select-tree.elf-select-tree-block-node .elf-select-tree-list-holder-inner .elf-select-tree-node-content-wrapper {
  flex: auto;
}
.elf-select-tree.elf-select-tree-block-node .elf-select-tree-list-holder-inner .elf-select-tree-treenode.dragging {
  position: relative;
}
.elf-select-tree.elf-select-tree-block-node
  .elf-select-tree-list-holder-inner
  .elf-select-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid var(--elf-primary-color);
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.elf-select-tree .elf-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.elf-select-tree .elf-select-tree-treenode-disabled .elf-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-select-tree .elf-select-tree-treenode-disabled .elf-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.elf-select-tree .elf-select-tree-treenode-active .elf-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.elf-select-tree
  .elf-select-tree-treenode:not(.elf-select-tree .elf-select-tree-treenode-disabled).filter-node
  .elf-select-tree-title {
  color: inherit;
  font-weight: 500;
}
.elf-select-tree .elf-select-tree-treenode-draggable .elf-select-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.elf-select-tree-treenode:hover .elf-select-tree .elf-select-tree-treenode-draggable .elf-select-tree-draggable-icon {
  opacity: 0.45;
}
.elf-select-tree .elf-select-tree-treenode-draggable.elf-select-tree-treenode-disabled .elf-select-tree-draggable-icon {
  visibility: hidden;
}
.elf-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.elf-select-tree-draggable-icon {
  visibility: hidden;
}
.elf-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-select-tree-switcher .elf-tree-switcher-icon,
.elf-select-tree-switcher .elf-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.elf-select-tree-switcher .elf-tree-switcher-icon svg,
.elf-select-tree-switcher .elf-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.elf-select-tree-switcher-noop {
  cursor: default;
}
.elf-select-tree-switcher_close .elf-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.elf-select-tree-switcher-loading-icon {
  color: var(--elf-primary-color);
}
.elf-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.elf-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.elf-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.elf-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.elf-select-tree .elf-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.elf-select-tree .elf-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.elf-select-tree .elf-select-tree-node-content-wrapper.elf-select-tree-node-selected {
  background-color: var(--elf-primary-2);
}
.elf-select-tree .elf-select-tree-node-content-wrapper .elf-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.elf-select-tree .elf-select-tree-node-content-wrapper .elf-select-tree-iconEle:empty {
  display: none;
}
.elf-select-tree-unselectable .elf-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.elf-select-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.elf-select-tree-node-content-wrapper .elf-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: var(--elf-primary-color);
  border-radius: 1px;
  pointer-events: none;
}
.elf-select-tree-node-content-wrapper .elf-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid var(--elf-primary-color);
  border-radius: 50%;
  content: '';
}
.elf-select-tree .elf-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px var(--elf-primary-color);
}
.elf-select-tree-show-line .elf-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.elf-select-tree-show-line .elf-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.elf-select-tree-show-line .elf-select-tree-indent-unit-end::before {
  display: none;
}
.elf-select-tree-show-line .elf-select-tree-switcher {
  background: #fff;
}
.elf-select-tree-show-line .elf-select-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.elf-select-tree .elf-select-tree-treenode-leaf-last .elf-select-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.elf-tree-select-dropdown-rtl .elf-select-tree .elf-select-tree-switcher_close .elf-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.elf-tree-select-dropdown-rtl .elf-select-tree .elf-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.elf-typography {
  color: rgba(0, 0, 0, 0.85);
  word-break: break-word;
}
.elf-typography.elf-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.elf-typography.elf-typography-success {
  color: var(--elf-success-color);
}
.elf-typography.elf-typography-warning {
  color: var(--elf-warning-color);
}
.elf-typography.elf-typography-danger {
  color: var(--elf-error-color);
}
a.elf-typography.elf-typography-danger:active,
a.elf-typography.elf-typography-danger:focus {
  color: var(--elf-error-color-active);
}
a.elf-typography.elf-typography-danger:hover {
  color: var(--elf-error-color-hover);
}
.elf-typography.elf-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
div.elf-typography,
.elf-typography p {
  margin-bottom: 1em;
}
h1.elf-typography,
div.elf-typography-h1,
div.elf-typography-h1 > textarea,
.elf-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.elf-typography,
div.elf-typography-h2,
div.elf-typography-h2 > textarea,
.elf-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.elf-typography,
div.elf-typography-h3,
div.elf-typography-h3 > textarea,
.elf-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.elf-typography,
div.elf-typography-h4,
div.elf-typography-h4 > textarea,
.elf-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.elf-typography,
div.elf-typography-h5,
div.elf-typography-h5 > textarea,
.elf-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.elf-typography + h1.elf-typography,
.elf-typography + h2.elf-typography,
.elf-typography + h3.elf-typography,
.elf-typography + h4.elf-typography,
.elf-typography + h5.elf-typography {
  margin-top: 1.2em;
}
.elf-typography div + h1,
.elf-typography ul + h1,
.elf-typography li + h1,
.elf-typography p + h1,
.elf-typography h1 + h1,
.elf-typography h2 + h1,
.elf-typography h3 + h1,
.elf-typography h4 + h1,
.elf-typography h5 + h1,
.elf-typography div + h2,
.elf-typography ul + h2,
.elf-typography li + h2,
.elf-typography p + h2,
.elf-typography h1 + h2,
.elf-typography h2 + h2,
.elf-typography h3 + h2,
.elf-typography h4 + h2,
.elf-typography h5 + h2,
.elf-typography div + h3,
.elf-typography ul + h3,
.elf-typography li + h3,
.elf-typography p + h3,
.elf-typography h1 + h3,
.elf-typography h2 + h3,
.elf-typography h3 + h3,
.elf-typography h4 + h3,
.elf-typography h5 + h3,
.elf-typography div + h4,
.elf-typography ul + h4,
.elf-typography li + h4,
.elf-typography p + h4,
.elf-typography h1 + h4,
.elf-typography h2 + h4,
.elf-typography h3 + h4,
.elf-typography h4 + h4,
.elf-typography h5 + h4,
.elf-typography div + h5,
.elf-typography ul + h5,
.elf-typography li + h5,
.elf-typography p + h5,
.elf-typography h1 + h5,
.elf-typography h2 + h5,
.elf-typography h3 + h5,
.elf-typography h4 + h5,
.elf-typography h5 + h5 {
  margin-top: 1.2em;
}
a.elf-typography-ellipsis,
span.elf-typography-ellipsis {
  display: inline-block;
  max-width: 100%;
}
a.elf-typography,
.elf-typography a {
  color: var(--elf-primary-color);
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.elf-typography:focus-visible,
.elf-typography a:focus-visible,
a.elf-typography:hover,
.elf-typography a:hover {
  color: var(--elf-primary-color-hover);
}
a.elf-typography:active,
.elf-typography a:active {
  color: var(--elf-primary-color-active);
}
a.elf-typography:active,
.elf-typography a:active,
a.elf-typography:hover,
.elf-typography a:hover {
  text-decoration: none;
}
a.elf-typography[disabled],
.elf-typography a[disabled],
a.elf-typography.elf-typography-disabled,
.elf-typography a.elf-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
a.elf-typography[disabled]:active,
.elf-typography a[disabled]:active,
a.elf-typography.elf-typography-disabled:active,
.elf-typography a.elf-typography-disabled:active,
a.elf-typography[disabled]:hover,
.elf-typography a[disabled]:hover,
a.elf-typography.elf-typography-disabled:hover,
.elf-typography a.elf-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
a.elf-typography[disabled]:active,
.elf-typography a[disabled]:active,
a.elf-typography.elf-typography-disabled:active,
.elf-typography a.elf-typography-disabled:active {
  pointer-events: none;
}
.elf-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.elf-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.elf-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.elf-typography u,
.elf-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.elf-typography s,
.elf-typography del {
  text-decoration: line-through;
}
.elf-typography strong {
  font-weight: 600;
}
.elf-typography-expand,
.elf-typography-edit,
.elf-typography-copy {
  color: var(--elf-primary-color);
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.elf-typography-expand:focus-visible,
.elf-typography-edit:focus-visible,
.elf-typography-copy:focus-visible,
.elf-typography-expand:hover,
.elf-typography-edit:hover,
.elf-typography-copy:hover {
  color: var(--elf-primary-color-hover);
}
.elf-typography-expand:active,
.elf-typography-edit:active,
.elf-typography-copy:active {
  color: var(--elf-primary-color-active);
}
.elf-typography-copy-success,
.elf-typography-copy-success:hover,
.elf-typography-copy-success:focus {
  color: var(--elf-success-color);
}
.elf-typography-edit-content {
  position: relative;
}
div.elf-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px);
}
.elf-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  pointer-events: none;
}
.elf-typography-edit-content textarea {
  height: 1em;
  margin: 0 !important;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-transition: none;
}
.elf-typography ul,
.elf-typography ol {
  margin: 0 0 1em;
  padding: 0;
}
.elf-typography ul li,
.elf-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.elf-typography ul {
  list-style-type: circle;
}
.elf-typography ul ul {
  list-style-type: disc;
}
.elf-typography ol {
  list-style-type: decimal;
}
.elf-typography pre,
.elf-typography blockquote {
  margin: 1em 0;
}
.elf-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.elf-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.elf-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.elf-typography-single-line {
  white-space: nowrap;
}
.elf-typography-ellipsis-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
}
a.elf-typography-ellipsis-single-line,
span.elf-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.elf-typography-ellipsis-multiple-line {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.elf-typography-rtl {
  direction: rtl;
}
.elf-typography-rtl .elf-typography-expand,
.elf-typography-rtl .elf-typography-edit,
.elf-typography-rtl .elf-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.elf-typography-rtl .elf-typography-expand {
  float: left;
}
div.elf-typography-edit-content.elf-typography-rtl {
  right: -12px;
  left: auto;
}
.elf-typography-rtl .elf-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.elf-typography-rtl.elf-typography ul li,
.elf-typography-rtl.elf-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.elf-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
.elf-upload p {
  margin: 0;
}
.elf-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.elf-upload input[type='file'] {
  cursor: pointer;
}
.elf-upload.elf-upload-select {
  display: inline-block;
}
.elf-upload.elf-upload-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.elf-upload.elf-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #18161e;
  border: 1px dashed #8b60f7;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.elf-upload.elf-upload-select-picture-card > .elf-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.elf-upload.elf-upload-select-picture-card:hover {
  border-color: var(--elf-primary-color);
}
.elf-upload-disabled.elf-upload.elf-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.elf-upload.elf-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.elf-upload.elf-upload-drag .elf-upload {
  padding: 16px 0;
}
.elf-upload.elf-upload-drag.elf-upload-drag-hover:not(.elf-upload-disabled) {
  border-color: var(--elf-primary-7);
}
.elf-upload.elf-upload-drag.elf-upload-disabled {
  cursor: not-allowed;
}
.elf-upload.elf-upload-drag .elf-upload-btn {
  display: table;
  height: 100%;
}
.elf-upload.elf-upload-drag .elf-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.elf-upload.elf-upload-drag:not(.elf-upload-disabled):hover {
  border-color: var(--elf-primary-5);
}
.elf-upload.elf-upload-drag p.elf-upload-drag-icon {
  margin-bottom: 20px;
}
.elf-upload.elf-upload-drag p.elf-upload-drag-icon .anticon {
  color: var(--elf-primary-5);
  font-size: 48px;
}
.elf-upload.elf-upload-drag p.elf-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.elf-upload.elf-upload-drag p.elf-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.elf-upload.elf-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.elf-upload.elf-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.elf-upload.elf-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.elf-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.elf-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.elf-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.elf-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.elf-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.elf-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  line-height: 1.5715;
}
.elf-upload-list::before {
  display: table;
  content: '';
}
.elf-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.elf-upload-list::before {
  display: table;
  content: '';
}
.elf-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.elf-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.elf-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elf-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.elf-upload-list-item-card-actions-btn {
  opacity: 0;
}
.elf-upload-list-item-card-actions-btn.elf-btn-sm {
  height: 22.001px;
  line-height: 1;
  vertical-align: top;
}
.elf-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.elf-upload-list-item-card-actions-btn:focus,
.elf-upload-list-item-card-actions.picture .elf-upload-list-item-card-actions-btn {
  opacity: 1;
}
.elf-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.elf-upload-list-item-card-actions:hover .anticon {
  color: rgba(0, 0, 0, 0.85);
}
.elf-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
}
.elf-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.elf-upload-list-item-info .anticon-loading .anticon,
.elf-upload-list-item-info .elf-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.elf-upload-list-item:hover .elf-upload-list-item-info {
  background-color: #f5f5f5;
}
.elf-upload-list-item:hover .elf-upload-list-item-card-actions-btn {
  opacity: 1;
}
.elf-upload-list-item-error,
.elf-upload-list-item-error .elf-upload-text-icon > .anticon,
.elf-upload-list-item-error .elf-upload-list-item-name {
  color: var(--elf-error-color);
}
.elf-upload-list-item-error .elf-upload-list-item-card-actions .anticon {
  color: var(--elf-error-color);
}
.elf-upload-list-item-error .elf-upload-list-item-card-actions-btn {
  opacity: 1;
}
.elf-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.elf-upload-list-picture .elf-upload-list-item,
.elf-upload-list-picture-card .elf-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.elf-upload-list-picture .elf-upload-list-item:hover,
.elf-upload-list-picture-card .elf-upload-list-item:hover {
  background: transparent;
}
.elf-upload-list-picture .elf-upload-list-item-error,
.elf-upload-list-picture-card .elf-upload-list-item-error {
  border-color: var(--elf-error-color);
}
.elf-upload-list-picture .elf-upload-list-item-info,
.elf-upload-list-picture-card .elf-upload-list-item-info {
  padding: 0;
}
.elf-upload-list-picture .elf-upload-list-item:hover .elf-upload-list-item-info,
.elf-upload-list-picture-card .elf-upload-list-item:hover .elf-upload-list-item-info {
  background: transparent;
}
.elf-upload-list-picture .elf-upload-list-item-uploading,
.elf-upload-list-picture-card .elf-upload-list-item-uploading {
  border-style: dashed;
}
.elf-upload-list-picture .elf-upload-list-item-thumbnail,
.elf-upload-list-picture-card .elf-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.elf-upload-list-picture .elf-upload-list-item-thumbnail .anticon,
.elf-upload-list-picture-card .elf-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.elf-upload-list-picture .elf-upload-list-item-error .elf-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.elf-upload-list-picture-card
  .elf-upload-list-item-error
  .elf-upload-list-item-thumbnail
  .anticon
  svg
  path[fill='#e6f7ff'] {
  fill: var(--elf-error-color-deprecated-bg);
}
.elf-upload-list-picture .elf-upload-list-item-error .elf-upload-list-item-thumbnail .anticon svg path[fill='#8B60F7'],
.elf-upload-list-picture-card
  .elf-upload-list-item-error
  .elf-upload-list-item-thumbnail
  .anticon
  svg
  path[fill='#8B60F7'] {
  fill: var(--elf-error-color);
}
.elf-upload-list-picture .elf-upload-list-item-icon,
.elf-upload-list-picture-card .elf-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.elf-upload-list-picture .elf-upload-list-item-icon .anticon,
.elf-upload-list-picture-card .elf-upload-list-item-icon .anticon {
  font-size: 26px;
}
.elf-upload-list-picture .elf-upload-list-item-image,
.elf-upload-list-picture-card .elf-upload-list-item-image {
  max-width: 100%;
}
.elf-upload-list-picture .elf-upload-list-item-thumbnail img,
.elf-upload-list-picture-card .elf-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.elf-upload-list-picture .elf-upload-list-item-name,
.elf-upload-list-picture-card .elf-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.elf-upload-list-picture .elf-upload-list-item-uploading .elf-upload-list-item-name,
.elf-upload-list-picture-card .elf-upload-list-item-uploading .elf-upload-list-item-name {
  margin-bottom: 12px;
}
.elf-upload-list-picture .elf-upload-list-item-progress,
.elf-upload-list-picture-card .elf-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.elf-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.elf-upload-list-picture-card .elf-upload-list-item {
  height: 100%;
  margin: 0;
}
.elf-upload-list-picture-card .elf-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.elf-upload-list-picture-card .elf-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.elf-upload-list-picture-card .elf-upload-list-item:hover .elf-upload-list-item-info::before {
  opacity: 1;
}
.elf-upload-list-picture-card .elf-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.elf-upload-list-picture-card .elf-upload-list-item-actions .anticon-eye,
.elf-upload-list-picture-card .elf-upload-list-item-actions .anticon-download,
.elf-upload-list-picture-card .elf-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.elf-upload-list-picture-card .elf-upload-list-item-actions .anticon-eye:hover,
.elf-upload-list-picture-card .elf-upload-list-item-actions .anticon-download:hover,
.elf-upload-list-picture-card .elf-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.elf-upload-list-picture-card .elf-upload-list-item-info:hover + .elf-upload-list-item-actions,
.elf-upload-list-picture-card .elf-upload-list-item-actions:hover {
  opacity: 1;
}
.elf-upload-list-picture-card .elf-upload-list-item-thumbnail,
.elf-upload-list-picture-card .elf-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.elf-upload-list-picture-card .elf-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.elf-upload-list-picture-card .elf-upload-list-item-file + .elf-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.elf-upload-list-picture-card .elf-upload-list-item-uploading.elf-upload-list-item {
  background-color: #fafafa;
}
.elf-upload-list-picture-card .elf-upload-list-item-uploading .elf-upload-list-item-info {
  height: auto;
}
.elf-upload-list-picture-card .elf-upload-list-item-uploading .elf-upload-list-item-info::before,
.elf-upload-list-picture-card .elf-upload-list-item-uploading .elf-upload-list-item-info .anticon-eye,
.elf-upload-list-picture-card .elf-upload-list-item-uploading .elf-upload-list-item-info .anticon-delete {
  display: none;
}
.elf-upload-list-picture-card .elf-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.elf-upload-list-text-container,
.elf-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.elf-upload-list-text-container::before,
.elf-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.elf-upload-list-text-container .elf-upload-span,
.elf-upload-list-picture-container .elf-upload-span {
  display: block;
  flex: auto;
}
.elf-upload-list-text .elf-upload-span,
.elf-upload-list-picture .elf-upload-span {
  display: flex;
  align-items: center;
}
.elf-upload-list-text .elf-upload-span > *,
.elf-upload-list-picture .elf-upload-span > * {
  flex: none;
}
.elf-upload-list-text .elf-upload-list-item-name,
.elf-upload-list-picture .elf-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.elf-upload-list-text .elf-upload-list-item-card-actions,
.elf-upload-list-picture .elf-upload-list-item-card-actions {
  position: static;
}
.elf-upload-list-text .elf-upload-text-icon .anticon {
  position: static;
}
.elf-upload-list .elf-upload-animate-inline-appear,
.elf-upload-list .elf-upload-animate-inline-enter,
.elf-upload-list .elf-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: forwards;
}
.elf-upload-list .elf-upload-animate-inline-appear,
.elf-upload-list .elf-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.elf-upload-list .elf-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.elf-upload-rtl {
  direction: rtl;
}
.elf-upload-rtl.elf-upload.elf-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.elf-upload-list-rtl {
  direction: rtl;
}
.elf-upload-list-rtl .elf-upload-list-item-list-type-text:hover .elf-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.elf-upload-list-rtl .elf-upload-list-item-list-type-text:hover .elf-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.elf-upload-list-rtl .elf-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.elf-upload-list-rtl .elf-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.elf-upload-list-rtl .elf-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.elf-upload-list-rtl .elf-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.elf-upload-list-rtl .elf-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.elf-upload-list-rtl .elf-upload-list-item-error .elf-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.elf-upload-list-rtl .elf-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.elf-upload-list-picture .elf-upload-list-item-info,
.elf-upload-list-picture-card .elf-upload-list-item-info {
  padding: 0;
}
.elf-upload-list-rtl.elf-upload-list-picture .elf-upload-list-item-thumbnail,
.elf-upload-list-rtl.elf-upload-list-picture-card .elf-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.elf-upload-list-rtl.elf-upload-list-picture .elf-upload-list-item-icon,
.elf-upload-list-rtl.elf-upload-list-picture-card .elf-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.elf-upload-list-rtl.elf-upload-list-picture .elf-upload-list-item-name,
.elf-upload-list-rtl.elf-upload-list-picture-card .elf-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.elf-upload-list-rtl.elf-upload-list-picture .elf-upload-list-item-name-icon-count-1,
.elf-upload-list-rtl.elf-upload-list-picture-card .elf-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.elf-upload-list-rtl.elf-upload-list-picture .elf-upload-list-item-name-icon-count-2,
.elf-upload-list-rtl.elf-upload-list-picture-card .elf-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.elf-upload-list-rtl.elf-upload-list-picture .elf-upload-list-item-progress,
.elf-upload-list-rtl.elf-upload-list-picture-card .elf-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.elf-upload-list-rtl .elf-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.elf-upload-list-rtl.elf-upload-list-picture-card .elf-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.elf-upload-list-rtl.elf-upload-list-picture-card .elf-upload-list-item-file + .elf-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
.elf-message-notice-content {
  border-radius: 6px;
}

